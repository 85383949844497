import firebase from "firebase/compat";
import Timestamp = firebase.firestore.Timestamp;

export enum ChatReportStatus {
  pending = "pending",
  approved = "approved",
  rejected = "rejected",
}

export enum ReporterType {
  seller = "seller",
  customer = "customer",
}

interface ChatReportHistory {
  reviewer?: ChatReportReviewer;
  status?: ChatReportStatus;
  timestamp: string;
  id?: string;
}

interface ChatReportReviewer {
  firstname: string;
  id: string;
  lastname: string;
}

export interface ChatReportData {
  id: string;
  chatId: string;
  reportedCount: number;
  isSellerReporter: boolean;
  product: {
    brandName: string;
    id: string;
    productName: string;
  };
  customer: {
    email?: string;
    firstname?: string;
    lastname?: string;
    phone?: string;
    id: string;
    vip: boolean;
  };
  seller: {
    event: string;
    handle?: string;
    id: string;
    email?: string;
    firstname?: string;
    lastname?: string;
  };
  reviewer?: ChatReportReviewer;
  status?: ChatReportStatus;
  history?: ChatReportHistory[];
  timestamps: {
    created: string;
    approved?: string;
    rejected?: string;
  };
}

export interface ICustomer {
  id: string;
  firstname: string;
  lastname: string;
}

export interface ChatMessageListProps {
  chatId: string;
  seller: ICustomer;
  customer: ICustomer;
}

export interface IMedia {
  contentType: string;
  mediaSize: number;
  mediaUrl: string;
}

export enum MessageType {
  text = "text",
  attachment = "attachment",
}
export interface ChatMessage {
  id: string;
  senderId: string;
  sentAt: Timestamp | Date;
  messageText: string;
  messageType: MessageType;
  media?: IMedia[];
}
