import * as React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { getFile } from "@shopthing-opn-shared/admin-dashboard";

import {
  Edit,
  FormTab,
  TabbedForm,
  TextInput,
  useNotify,
  NumberInput
} from "react-admin";

import { FieldProps } from "ra-ui-materialui";
import { Record } from "ra-core";
import { SaveOnlyToolbar } from "../toolbars/save-only";
import { Settings } from "../../ts/interfaces/settings-interface";
import { TrailerUpload } from "../generics/trailer-media";
import { getApp } from "firebase/app";

interface IEditProperties {
  id: string;
  basePath: string;
  [x: string]: unknown;
  record: Settings;
}

const useStyles = makeStyles( ( theme: Theme ) =>
  createStyles( {
    headerSeparator: {
      margin: theme.spacing( 4, 0, 0, 0 ),
    },
    formField: {
      margin: theme.spacing( 1, 2, 1, 0 ),
    },
    media: {
      maxWidth: "100%",
      maxHeight: "500px",
    },
  } )
);

const SettingsMedia: React.FC<FieldProps<Settings>> = ( properties ) =>
{
  const classes = useStyles();
  const { record } = properties;
  const app = getApp();
  const minutes = Math.floor( record.holdDurationInSeconds / 60 );
  const seconds = record.holdDurationInSeconds % 60;
  record.holdDurationInMinutes =
    String( minutes ).padStart( 2, "0" ) + ":" + String( seconds ).padStart( 2, "0" );

  const [poster, setPoster] = React.useState( "" );
  const [trailer, setTrailer] = React.useState( "" );

  const notShippedEmailInDaysShortState = React.createRef<HTMLInputElement>();
  const notShippedEmailInDaysLongState = React.createRef<HTMLInputElement>();

  async function loadFile( filePath: string, setter: ( value: string ) => void )
  {
    if ( filePath )
    {
      const posterUrl = await getFile( filePath, app );
      setter( posterUrl );
    }
  }

  React.useEffect( () =>
  {
    loadFile( record.allEventPosterPath, setPoster );
    loadFile( record.allEventTrailerPath, setTrailer );
  } );

  return (
    <React.Fragment>
      <TextInput disabled source="id" className={classes.formField} />
      <NumberInput
        disabled
        source="posterRawSmall"
        className={classes.formField}
      />
      <NumberInput
        disabled
        source="shipmentReminderInterval"
        className={classes.formField}
      />
      <TextInput disabled source="contactEmail" className={classes.formField} />
      <TextInput
        disabled
        source="createAccountURL"
        className={classes.formField}
      />
      <TextInput
        disabled
        source="feedbackEmail"
        className={classes.formField}
      />
      <TextInput
        disabled
        source="privacyPolicy"
        className={classes.formField}
      />
      <TextInput
        disabled
        source="termsAndConditions"
        className={classes.formField}
      />
      <div>
        <h2>All Event Trailer</h2>
        {trailer ? (
          <video src={trailer} className={classes.media} controls />
        ) : null}
      </div>
      <div>
        <h2>All Event Preview Poster</h2>
        {poster ? <img src={poster} className={classes.media} /> : null}
      </div>
      <div>
        <h2 className={classes.headerSeparator}>Default Hold Duration</h2>
        <p className={classes.formField}>
          This setting will change the default hold period for all members on
          production.
          <br />
          <br />
          Please enter hold duration in minutes and seconds using this format.
          MM:SS
        </p>
        <TextInput
          label="Hold Duration In Minutes"
          source="holdDurationInMinutes"
          className={classes.formField}
          validate={( holdDurationInMinutes: string ) =>
          {
            const pattern = /^(([0-9][0-9]):([0-5][0-9]))$/;
            const isValid = pattern.test( holdDurationInMinutes );

            if ( !isValid )
            {
              return "Please enter the hold duration in MM:SS";
            }
          }}
        />
      </div>
      <div>
        <h2>Default Early Access Duration</h2>
        <p className={classes.formField}>
          This setting will change the early access delay for all events in the
          future.
          <br />
          <br />
          Please enter early access delay in minutes using this format. MM
        </p>
        <TextInput
          label="Duration In Minutes"
          source="earlyAccessDelayInMinutes"
          className={classes.formField}
          validate={( earlyAccessDelayInMinutes: string ) =>
          {
            if ( !/^[0-9]+$/.test( earlyAccessDelayInMinutes ) )
            {
              return "Please enter the duration in MM";
            }
          }}
        />
      </div>
      <div>
        <h2>Email Settings for Delayed Shipments</h2>
        <p className={classes.formField}>
          The number of days for an order to be in Confirmed status before a
          customer receives the first shipment status update email.
          <br />
          <br />
          Please enter the number of days using this format. DD
        </p>
        <TextInput
          label="Days in Confirmed Status"
          source="notShippedEmailInDaysShort"
          className={classes.formField}
          inputRef={notShippedEmailInDaysShortState}
          validate={( notShippedEmailInDaysShort: string ) =>
          {
            if ( !/^[0-9]+$/.test( notShippedEmailInDaysShort ) )
            {
              return "Please enter the duration in DD";
            }
            if (
              parseInt( notShippedEmailInDaysLongState.current.value, 10 ) <
              parseInt( notShippedEmailInDaysShortState.current.value, 10 )
            )
            {
              return "First email should be send prior to second email";
            }
          }}
        />
        <p className={classes.formField}>
          The number of days for an order to be in Confirmed status before a
          customer receives the second shipment status email.
          <br />
          <br />
          Please enter the number of days using this format. DD
        </p>
        <TextInput
          label="Days in Confirmed Status"
          source="notShippedEmailInDaysLong"
          className={classes.formField}
          inputRef={notShippedEmailInDaysLongState}
          validate={( notShippedEmailInDaysLong: string ) =>
          {
            if ( !/^[0-9]+$/.test( notShippedEmailInDaysLong ) )
            {
              return "Please enter the duration in DD";
            }
            if (
              parseInt( notShippedEmailInDaysLongState.current.value, 10 ) <
              parseInt( notShippedEmailInDaysShortState.current.value, 10 )
            )
            {
              return "First email should be send prior to second email";
            }
          }}
        />
      </div>
    </React.Fragment>
  );
};

const transform = async ( data: Record ) =>
{
  const holdDurations = data.holdDurationInMinutes.split( ":" );
  let holdDurationInSeconds = 0;
  holdDurationInSeconds =
    parseInt( holdDurations[0], 10 ) * 60 + parseInt( holdDurations[1], 10 );

  const update = {
    id: data.id,
    updates: {
      holdDurationInSeconds: await Promise.resolve( holdDurationInSeconds ),
      earlyAccessDelayInMinutes: await Promise.resolve(
        parseInt( data.earlyAccessDelayInMinutes, 10 )
      ),
      notShippedEmailInDaysShort: await Promise.resolve(
        parseInt( data.notShippedEmailInDaysShort, 10 )
      ),
      notShippedEmailInDaysLong: await Promise.resolve(
        parseInt( data.notShippedEmailInDaysLong, 10 )
      ),
    },
  };

  return update;
};

export const SettingsEdit: React.FunctionComponent<IEditProperties> = (
  properties
) =>
{
  const notify = useNotify();
  const onSuccess = () =>
  {
    notify( "Settings updated" );
    window.location.href = `#${properties.basePath}/${properties.id}`;
  };

  const onFailure = ( error: Error ) =>
  {
    notify( `${error.message}`, "warning" );
  };

  return (
    <Edit
      undoable={false}
      transform={transform}
      onSuccess={onSuccess}
      onFailure={onFailure}
      {...properties}
    >
      <TabbedForm toolbar={<SaveOnlyToolbar />}>
        <FormTab label="Settings">
          <SettingsMedia />
        </FormTab>
        <FormTab label="Feature Flags" />
        <FormTab label="Upload Trailer">
          <TrailerUpload
            uploadPath={`fixedAssets/all-event/${new Date().toISOString()}`}
            label="All Event"
          />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
