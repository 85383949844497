import React from "react";
import {
  TextInput,
  ReferenceInput,
  SimpleForm,
  SelectInput,
  useUpdate,
  useNotify,
  usePermissions
} from "react-admin";
import { IconButton, Typography, Link, Button } from "@material-ui/core";
import { CustomModal } from "@shopthing-opn-shared/admin-dashboard";
import { makeStyles } from "@material-ui/core/styles";
import { notifyMessage } from "../../utils/primitive";

interface IReturnButton {
  id: string;
  action: string;
  type: "initiate" | "close" | "accept" | "resolve";
  label: string;
  successMessage: string;
  includeLocation?: boolean;
  includeReasons?: boolean;
  additionalBodyParams?: {
    [key: string]: string;
  };
}

const useStyles = makeStyles( {
  customTextArea: {
    "& .MuiFilledInput-root": {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      backgroundColor: "inherit",
      borderStyle: "solid",
      borderWidth: "thin",
      borderColor: "grey",
    },
  },
} );

export const ReturnButton: React.FunctionComponent<IReturnButton> = (
  properties
) =>
{
  const [open, setOpen] = React.useState( false );
  const [reason, setReason] = React.useState( null );
  const [note, setNote] = React.useState( null );
  const [location, setLocation] = React.useState( null );

  const classes = useStyles();
  const notify = useNotify();
  const [updateAction, { loading }] = useUpdate(
    properties.action,
    properties.id,
    {
      ...( properties.includeReasons && { reason } ),
      note,
      ...( properties.includeLocation && {
        location,
      } ),
      ...properties.additionalBodyParams,
    },
    {},
    {
      onSuccess: () =>
      {
        // So we need to change this so we have text that depends on the type
        notify( properties.successMessage );
        window.location.reload();
      },
      onFailure: ( error: Error ) =>
      {
        notifyMessage( error, notify );
      },
    }
  );

  const handleOpen = () =>
  {
    setOpen( true );
  };

  const handleClose = () =>
  {
    setReason( null );
    setNote( null );
    setLocation( null );
    setOpen( false );
  };

  interface InputEvent {
    target: EventTarget;
  }

  interface EventTarget {
    name: string;
    value: string;
  }

  const reasonInputChange = ( event: InputEvent ) =>
  {
    setReason( event.target.value );
  };

  const noteInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) =>
  {
    setNote( event.target.value );
  };

  const { loaded } = usePermissions();
  const body = loaded ? (
    <div>
      <SimpleForm toolbar={<></>}>
        {properties.includeReasons && (
          <ReferenceInput
            label="Return Reason"
            name="reason"
            source="reason"
            reference="return-reasons"
            onChange={reasonInputChange}
            filter={{ type: properties.type }}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
        )}
        <TextInput
          source="note"
          name="note"
          label="Notes"
          multiline={true}
          fullWidth={true}
          resettable={false}
          onChange={noteInputChange}
          rows="5"
          className={classes.customTextArea}
        />
        {properties.includeLocation && (
          <>
            <SimpleForm toolbar={null}>
              <ReferenceInput
                label="Location"
                source="location"
                reference="return-locations"
                onChange={( event: InputEvent ) =>
                  setLocation( event.target.value )
                }
              >
                <SelectInput
                  source="location"
                  optionText="name"
                  optionValue="id"
                />
              </ReferenceInput>
            </SimpleForm>
          </>
        )}
      </SimpleForm>
      <Button
        onClick={updateAction}
        disabled={
          ( properties.includeReasons && !reason ) ||
          !note ||
          ( properties.includeLocation && !location ) ||
          loading
        }
      >
        {properties.label}
      </Button>
    </div>
  ) : null;

  return (
    <>
      <IconButton
        onClick={handleOpen}
        color={"primary"}
        disabled={loading}
        disableRipple
        disableFocusRipple
      >
        {/* I probably need to style this - depending on where it's located. */}
        <Typography>
          <Link>{properties.label}</Link>
        </Typography>
      </IconButton>
      <CustomModal open={open} onClose={handleClose}>
        {body}
      </CustomModal>
    </>
  );
};
