import React from "react";
import { barcodeTemplate } from "../../templates/barcode-template";
import { Typography, Button } from "@material-ui/core";
import {
  CustomModal,
  DymoContext,
  DymoProvider
} from "@shopthing-opn-shared/admin-dashboard";

import { Spacer } from "../generics/spacer";
import { PrintBarcodeLabelButton } from "./product-barcode-label";

interface IBarcodeButton {
  productInventoryItemId: string;
  [x: string]: unknown;
  disabled?: boolean;
  styleClassName?: string;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  redirect?: boolean;
}

const ModalBody = ( {
  productInventoryItemId,
  setOpen,
  redirect,
}: IBarcodeButton ) =>
{
  const { printLabel } = React.useContext( DymoContext );
  const handleConfirm = () =>
  {
    printLabel( [
      { title: "CATEGORY", value: productInventoryItemId },
      { title: "TITLE", value: productInventoryItemId },
      { title: "DESC", value: productInventoryItemId },
      {
        title: "qrcode",
        value: JSON.stringify( {
          productInventoryItemId,
        } ),
      },
    ] );
    setOpen( false );
    if ( redirect === true )
    {
      history.replaceState(
        {},
        "",
        `${window.location.origin}/#/product-inventory-items`
      );
      history.go();
    }
  };
  return (
    <>
      <Typography>Generate Barcode Label</Typography>
      <Spacer />
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Button onClick={handleConfirm}>Print (using Dymo)</Button>
        <PrintBarcodeLabelButton
          productInventoryItemId={productInventoryItemId}
          setOpen={setOpen}
          redirect={redirect}
        />
      </div>
    </>
  );
};

export const BarcodeButton: React.FunctionComponent<IBarcodeButton> = ( {
  productInventoryItemId,
  disabled,
  styleClassName,
  redirect,
}: IBarcodeButton ) =>
{
  const [open, setOpen] = React.useState( false );
  const handleOpen = () =>
  {
    setOpen( true );
  };

  const handleClose = () =>
  {
    setOpen( false );
  };

  return (
    <>
      <DymoProvider
        barcodeTemplate={barcodeTemplate}
        scriptPath={"/scripts/dymo.js"}
      >
        <Button
          onClick={handleOpen}
          color={"primary"}
          disableRipple
          disableFocusRipple
          disabled={disabled}
          className={styleClassName}
        >
          Generate Barcode
        </Button>

        <CustomModal open={open} onClose={handleClose}>
          <ModalBody
            setOpen={setOpen}
            productInventoryItemId={productInventoryItemId}
            redirect={redirect}
          />
        </CustomModal>
      </DymoProvider>
    </>
  );
};
