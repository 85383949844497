import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  DateField,
  Filter
} from "react-admin";
import { BasicPagination, perPage } from "../generics/pagination";
import { FilterProps } from "ra-ui-materialui";
import { PaginatedSelectInput } from "../generics/paginated-select-input";
import { paginatedSelectInputConfig } from "../component-config";
import {
  makeLocalStorageKey,
  StoragePage,
  StorageType
} from "../../utils/local-storage";
import { SellerMessages } from "../../ts/interfaces/seller-messages-interface";

const setShopper = ( id: string, value: string ) =>
  localStorage.setItem(
    makeLocalStorageKey( StoragePage.sellerMessages, StorageType.seller, id ),
    value
  );

const PostFilter: React.FC = (
  properties: FilterProps & {
    data?: SellerMessages[];
    filterValues?: SellerMessages;
  }
) => (
  <Filter {...properties}>
    <PaginatedSelectInput
      source="id"
      data={properties.data}
      label="Shopper"
      list={{
        resource: "users",
        nameMappingKey: "handle",
        perPage: paginatedSelectInputConfig.perPage,
        filter: { hasMessagesFromShopThing: true },
        sort: {
          field: "sortOptions.handle",
          order: "ASC",
        },
        storageFunction: setShopper,
      }}
    />
  </Filter>
);

export const SellerMessagesList = (
  properties: SellerMessages[]
): JSX.Element => (
  <List
    {...properties}
    pagination={<BasicPagination />}
    bulkActionButtons={false}
    filters={<PostFilter />}
    exporter={false}
    perPage={perPage}
    sort={{
      field: "termsOfServiceAccepted.date",
      order: "DESC",
    }}
  >
    <Datagrid rowClick="show">
      <FunctionField
        label="From"
        sortable={false}
        render={( record: SellerMessages ) =>
          ( record.from?.firstName || "" ) +
          ( record.from?.firstName && record.from?.lastName ? " " : "" ) +
          ( record.from?.lastName || "" )
        }
      />
      <TextField label="Subject line" source="subject" sortable={false} />
      <DateField
        label="Date sent"
        source="timestamps.sent"
        showTime
        sortable={false}
      />
    </Datagrid>
  </List>
);
