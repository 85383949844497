import _ from "lodash";
import * as React from "react";
import { formatPriceWithoutCurrency } from "../../utils/primitive";
import { Refund } from "../../ts/interfaces/order-interface";
import { Spacer } from "../generics/spacer";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import { TrueFalseIcon } from "../generics/true-false-icon";
import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles( () =>
  createStyles( {
    fullWidth: {
      width: "100%",
    },
    table: {},
  } )
);

interface RefundDetailProperties {
  refund: Refund;
}

export const RefundDetailsTable: React.FunctionComponent<
  RefundDetailProperties
> = ( properties: RefundDetailProperties ) =>
{
  const { refund } = properties;
  const classes = useStyles();
  return (
    <>
      <Spacer />
      <Typography variant={"h5"}>Refund Details</Typography>
      <Spacer />
      <TableContainer>
        <Table aria-label="simple table" className={classes.table}>
          <TableHead>
            <TableRow className={classes.table}>
              <TableCell className={classes.table} width={310} align="left">
                <Typography variant={"caption"}>Refund Date</Typography>
              </TableCell>
              <TableCell className={classes.table} width={250} align="left">
                <Typography variant={"caption"}>Reason</Typography>
              </TableCell>
              <TableCell className={classes.table} width={250} align="left">
                <Typography variant={"caption"}>Amount</Typography>
              </TableCell>
              <TableCell className={classes.table} width={250} align="left">
                <Typography variant={"caption"}>Reverse Transfer</Typography>
              </TableCell>
              <TableCell className={classes.table} width={250} align="left">
                <Typography variant={"caption"}>
                  Extra Processing Fee
                </Typography>
              </TableCell>
              <TableCell className={classes.table} align="left">
                <Typography variant={"caption"}>Notes</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key={refund.id}>
              <TableCell className={classes.table} align="left">
                {refund.refundTimestamp
                  ? new Date( refund.refundTimestamp ).toLocaleString( "en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                  } )
                  : " "}
              </TableCell>
              <TableCell className={classes.table} align="left">
                {_.get( refund, "refundCategory", null )}
              </TableCell>
              <TableCell className={classes.table} align="left">
                {refund.amount}
              </TableCell>
              <TableCell className={classes.table} align="left">
                <TrueFalseIcon
                  value={_.get( refund, "reverseTransfer", false )}
                />
              </TableCell>
              <TableCell className={classes.table} align="left">
                {_.isNumber( refund.restOfTheProcessingFeeAmount )
                  ? formatPriceWithoutCurrency(
                    _.toString( refund.restOfTheProcessingFeeAmount )
                  )
                  : null}
              </TableCell>
              <TableCell className={classes.table} align="left">
                {_.get( refund, "notes", null )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Spacer />
    </>
  );
};
