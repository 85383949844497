import decode from "jwt-decode";
import { UserClaims } from "src/ts/interfaces/role-interface";

const retrieveAccessToken = function (): string
{
  return localStorage.getItem( "usertoken" );
};

export const getParsedToken = async (): Promise<UserClaims> =>
{
  try
  {
    const token = await retrieveAccessToken();
    if ( token )
    {
      return decode( token );
    }
  }
  catch ( error )
  {
    console.error( error );
    return {};
  }
};

export const authHeader = async (): Promise<Headers> =>
{
  let accessToken = null;
  try
  {
    const result = await retrieveAccessToken();
    if ( result )
    {
      accessToken = result;
    }
  }
  catch ( error )
  {
    console.error( error );
  }

  return new Headers( {
    Accept: "*/*",
    "Content-Type": "application/json",
    "st-usertoken": `${accessToken}`,
  } );
};
