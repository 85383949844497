import * as React from "react";
import { Typography, Grid } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

interface ErrorProps {
  message: string;
}

const useStyles = makeStyles( () =>
  createStyles( {
    typography: {
      color: "white",
      backgroundColor: "red",
      borderRadius: 5,
      padding: 20,
    },
  } )
);

export const InTextError = ( properties: ErrorProps ): JSX.Element =>
{
  const classes = useStyles();

  return (
    <>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="body1"
          align="justify"
          className={classes.typography}
        >
          {properties.message}
        </Typography>
      </Grid>
    </>
  );
};
