import * as React from "react";

import {
  Datagrid,
  List,
  useListContext,
  CreateButton,
  TabbedShowLayout,
  Tab
} from "react-admin";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const Empty = () =>
{
  const { basePath } = useListContext();

  return (
    <TabbedShowLayout>
      <Tab label="Import Fulfillments">
        <Box textAlign="center" m={1} style={{ marginTop: "20px" }}>
          <Typography variant="h5" paragraph>
            {`Import Second Closet Fulfillment`}
          </Typography>
          <Typography paragraph>
            {`Press "CREATE" or "+" to import a fulfillment CSV`}
          </Typography>
          <CreateButton basePath={basePath} />
        </Box>
      </Tab>
    </TabbedShowLayout>
  );
};

export const ClosetList = ( properties: Record<string, never> ): JSX.Element => (
  <List {...properties} empty={<Empty />} title="Second Closet">
    <Datagrid />
  </List>
);
