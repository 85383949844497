import { makeStyles, createStyles } from "@material-ui/core/styles";

export const ButtonStyles = makeStyles(
  createStyles( {
    saveButton: {
      background: "#4f3cc9",
      color: "#fff",
      "&:hover": {
        background: "#2e3088",
      },
      "&:disabled": {
        color: "#fff",
        background: "#6c757d",
      },
    },
    resetSortButton: {
      marginLeft: "1rem",
      span: {
        paddingLeft: "0rem",
      },
    },
  } )
);
