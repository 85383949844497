import { Editorial } from "../../ts/interfaces/event-interface";
import * as React from "react";
import { List, Datagrid, TextField } from "react-admin";
import { BasicPagination, perPage } from "../generics/pagination";
import { DateField } from "ra-ui-materialui";
import { TruthyBooleanField } from "../generics/truthy-boolean-field";

export const CollaborationEventList = (
  properties: Editorial[]
): JSX.Element => (
  <List
    {...properties}
    pagination={<BasicPagination />}
    bulkActionButtons={false}
    exporter={false}
    sort={undefined}
    perPage={perPage}
  >
    <Datagrid rowClick="edit">
      <TextField label="Event ID" source="id" sortable={false} />
      <TextField label="Name" source="name" sortable={false} />
      <TruthyBooleanField
        label="Pinned Event"
        source="isPinned"
        sortable={false}
      />
      <TruthyBooleanField
        label="Closet Sale"
        source="isClosetSale"
        sortable={false}
      />
      <DateField
        label="Start Date"
        source="startDate"
        sortable={false}
        showTime
      />
      <DateField label="End Date" source="endDate" sortable={false} showTime />
    </Datagrid>
  </List>
);
