import * as React from "react";

import { Pagination, useListContext } from "react-admin";

export const perPage = 100;

export const BasicPagination = ( props: {
  hideWhenEmpty?: boolean;
} ): JSX.Element =>
{
  const { ids, loading } = useListContext();

  return ( props.hideWhenEmpty ? !loading && ids?.length : true ) ? (
    <Pagination rowsPerPageOptions={[100, 250, 500]} />
  ) : null;
};
