import * as React from "react";
import {
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Divider,
  Box,
  Button
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { PickupPackingSlip } from "./pickup-packing-slip";
import { ConvertPickupForm } from "./convert-pickup-form";
import { FulfillPickup } from "./fulfill-pickup";
import { Fulfillments, Order } from "src/ts/interfaces/order-interface";
import { ImageButton } from "../potential-bundles/image-button";
import { Spacer } from "../generics/spacer";
import { useAuth } from "../../contexts/auth-context";
import {
  BooleanRoles,
  hasPermissions
} from "../../ts/interfaces/role-interface";

const useStyles = makeStyles( () =>
  createStyles( {
    table: {
      border: 0,
    },
  } )
);

interface IPickupOrderTable {
  order: Order;
  [x: string]: unknown;
}

export const PickupOrderTable = (
  properties: IPickupOrderTable
): JSX.Element =>
{
  const { order } = properties;
  const classes = useStyles();
  const [labelOrder, setLabelledOrder] = React.useState( [] );
  const { permissions } = useAuth();

  const PackagingSlipHandler = () =>
  {
    setLabelledOrder( [order.id] );
  };
  return (
    <>
      <Spacer />
      <Box>
        {hasPermissions( [BooleanRoles.Admin], permissions ) &&
          ( order.status === "confirmed" || order.status === "pending" ) && (
          <ConvertPickupForm id={order.id}></ConvertPickupForm>
        )}{" "}
        {hasPermissions( [BooleanRoles.Admin], permissions ) && (
          <FulfillPickup
            orderId={order.id}
            orderLatestDisputeStatus={order.latestDisputeStatus}
            orderDisputeResolved={order.disputeResolved}
          />
        )}
        <Button
          disabled={
            order.latestDisputeStatus &&
            ( typeof order.disputeResolved !== "boolean" ||
              order.disputeResolved === false )
          }
          onClick={PackagingSlipHandler}
        >
          Print Packaging Slip
        </Button>
      </Box>
      <Spacer />
      <Divider />
      <TableContainer>
        <div key={order.id}>
          <Spacer />
          <Typography>Order Details</Typography>
          <Table aria-label="simple table" className={classes.table}>
            <TableHead>
              <TableRow className={classes.table}>
                <TableCell className={classes.table} align="left">
                  <Typography variant={"caption"}>Shopper</Typography>
                </TableCell>
                <TableCell className={classes.table} align="left">
                  <Typography variant={"caption"}>Brand</Typography>
                </TableCell>
                <TableCell className={classes.table} align="left">
                  <Typography variant={"caption"}>Product</Typography>
                </TableCell>
                <TableCell className={classes.table} align="left">
                  <Typography variant={"caption"}>Size</Typography>
                </TableCell>
                <TableCell className={classes.table} align="left">
                  <Typography variant={"caption"}>SKU</Typography>
                </TableCell>
                <TableCell className={classes.table} align="center">
                  <Typography variant={"caption"}>Quantity</Typography>
                </TableCell>
                <TableCell className={classes.table} align="center">
                  {" "}
                  <Typography variant={"caption"}>Billing Match</Typography>
                </TableCell>
                <TableCell className={classes.table} align="center">
                  {" "}
                  <Typography variant={"caption"}>
                    <ImageButton
                      imageUrl={order.cart.items[0].media[0].productPoster}
                    />
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell className={classes.table} align="left">
                  <Typography align="left">{order.seller.handle}</Typography>
                </TableCell>
                <TableCell className={classes.table} align="left">
                  <Typography align="left">
                    {order.cart.items[0].brandName}
                  </Typography>
                </TableCell>
                <TableCell className={classes.table} align="left">
                  <Typography align="left">
                    {order.cart.items[0].productName}
                  </Typography>
                </TableCell>
                <TableCell className={classes.table} align="left">
                  <Typography align="left">
                    {order.cart.items[0].description}
                  </Typography>
                </TableCell>
                <TableCell className={classes.table} align="left">
                  <Typography align="left">
                    {order.cart.items[0].skuId}
                  </Typography>
                </TableCell>
                <TableCell className={classes.table} align="center">
                  <Typography align="center">
                    {order.cart.items[0].quantity}
                  </Typography>
                </TableCell>
                <TableCell className={classes.table} align="center"></TableCell>
                <TableCell className={classes.table} align="center"></TableCell>
              </TableRow>
            </TableBody>
          </Table>
          {order.fulfillments && (
            <Typography>
              Fulfillment details - {0} of {order.cart.items[0].quantity}{" "}
              Quantities Scanned
            </Typography>
          )}

          {order.fulfillments &&
            Object.values( order.fulfillments ).map(
              ( fulfillment: Fulfillments ) => (
                <div key={fulfillment.items[0].skuId}>
                  <Table aria-label="simple table" className={classes.table}>
                    <TableHead>
                      <TableRow className={classes.table}>
                        <TableCell className={classes.table} align="left">
                          <Typography variant={"caption"}>
                            Fulfillment ID
                          </Typography>
                        </TableCell>
                        <TableCell className={classes.table} align="left">
                          <Typography variant={"caption"}>Sku ID</Typography>
                        </TableCell>
                        <TableCell className={classes.table} align="left">
                          <Typography variant={"caption"}>Quantity</Typography>
                        </TableCell>
                        <TableCell className={classes.table} align="left">
                          <Typography variant={"caption"}>Location</Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell className={classes.table} align="left">
                          <Typography align="left">
                            {fulfillment.externalId}
                          </Typography>
                        </TableCell>
                        <TableCell className={classes.table} align="left">
                          <Typography align="left">
                            {fulfillment.items[0].skuId}
                          </Typography>
                        </TableCell>
                        <TableCell className={classes.table} align="left">
                          <Typography align="left">
                            {fulfillment.items[0].quantity}
                          </Typography>
                        </TableCell>
                        <TableCell className={classes.table} align="left">
                          <Typography align="left">
                            {fulfillment.location || ""}
                          </Typography>
                        </TableCell>
                        <TableCell
                          className={classes.table}
                          align="left"
                        ></TableCell>
                        <TableCell className={classes.table} align="left">
                          <Typography align="left"></Typography>
                        </TableCell>
                        <TableCell
                          className={classes.table}
                          align="center"
                        ></TableCell>
                        <TableCell
                          className={classes.table}
                          align="center"
                        ></TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              )
            )}
          <Divider />
        </div>
      </TableContainer>
      {labelOrder.length > 0 &&
        labelOrder.map( () => (
          <PickupPackingSlip key={order.id} order={order} />
        ) )}
    </>
  );
};
