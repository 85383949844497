import { Address } from "./order-interface";
import { FulfillmentType } from "./shared";

export enum SellerPermittedEventTypes {
  closetSale = "closetSale",
  regular = "regular",
}

export enum SellerTransferTypes {
  onFulfillment = "onFulfillment",
  onDelivery = "onDelivery",
  onConfirmed = "onConfirmed",
}

export enum TaxEntityType {
  SENDER = "SENDER",
  RECEIVER = "RECEIVER",
}
export enum TaxIdType {
  VAT = "VAT",
  VATIOSS = "VAT/IOSS",
  GST = "GST",
  EORI = "EORI",
  IOSS = "IOSS",
  PAN = "PAN",
  OTHER = "OTHER",
  SDT = "SDT (IOSS)",
  FTZ = "FTZ",
  DAN = "DAN",
  TAN = "TAN",
  DTF = "DTF",
  CNP = "CNP",
  DUN = "DUN",
  EIN = "EIN",
  EOR = "EOR",
  SSN = "SSN",
  FED = "FED",
  STA = "STA",
  PID = "PID",
  HMRC = "HMRC",
  TIN = "TIN",
  VOEC = "VOEC",
}

export interface TaxIdentifier {
  entity: TaxEntityType;
  taxId: string;
  taxIdType: TaxIdType;
  issuingCountry: string;
}

export interface EasyPostAccount {
  name: string;
  accountId: string;
  deliveryConfirmationOption: string;
  domesticDeliveryConfirmationOption?: string;
  internationalDeliveryConfirmationOption?: string;
  labelFormat?: string;
  labelSize?: string;
  incoterm?: string;
  useResidentialAddress?: boolean;
  sellerIds?: string[];
  fulfillmentTypes?: FulfillmentType[];
  carrierPrefix?: string;
  isInternationalCarrierOnly?: boolean;
  useForInternational?: boolean;
  removePrintCustom1?: boolean;
  customsSigner?: string;
  taxIdentifiers?: TaxIdentifier[];
  commercialInvoiceSize?: string;
  commercialInvoiceFormat?: string;
  addCustomerEmailToLabel?: boolean;
}

export interface Customer {
  id: string;
  email: string;
  phone?: string;
  firstname: string;
  lastname: string;
  isSeller: boolean;
  isSellerActive?: boolean;
  handle?: string;
  fulfillmentType?:
    | FulfillmentType.shopthing
    | FulfillmentType.seller
    | FulfillmentType.partner;
  fromAddresses: Address[];
  permittedEventTypes: SellerPermittedEventTypes[];
  passedFulfillmentReview: boolean;
  stripeCardId?: string;
  stripeAccountSource?: "CA" | "US";
  issuedCreditCard?: boolean;
  cardDetails?: StripeCreditCardHolder;
  transferType?: SellerTransferTypes;
  membershipStatus?: string;
  instagramHandle?: string;
  easyPostAccounts?: {
    [key: string]: EasyPostAccount[];
  };
}

export interface Seller extends Customer {
  fromAddresses: Address[];
  addresses?: Address[];
  creditCard?: StripeCreditCardHolder;
}

export interface StripeCreditCardHolder {
  cancellation_reason?: string | null;
  cardholder?: CardHolderInfo;
  created?: Date;
  currency?: string;
  exp_month?: number;
  exp_year?: number;
  last4?: string;
  livemode: boolean;
  replaced_by?: string | null;
  replacement_for?: string | null;
  replacement_reason?: string | null;
  shipping?: ShippingHolder;
  spending_controls?: SpendingControls | null;
  status: string;
  type: string;
}

export interface SpendingControls {
  allowed_categories?: string[] | null;
  blocked_categories?: string[] | null;
  spending_limits?: SpendingLimit[] | null;
  spending_limits_currency?: string | null;
}

export interface SpendingLimit {
  amount: number;
  categories: string[] | null;
  interval: Interval;
}

export interface MembershipSubscription {
  membershipStatus: string;
  memberSince: Date;
  memberRenewalDate: Date;
  expiryDate: Date;
  type: string;
  price: number;
  currency: string;
}

type Interval =
  | "all_time"
  | "daily"
  | "monthly"
  | "per_authorization"
  | "weekly"
  | "yearly";

export interface CardHolderInfo {
  billing: StripeAddress;
  company: {
    tax_id_provided: boolean;
  };
  name: string;
  email: string;
  livemode: boolean;
  created: Date;
  requirements?: Requirements;
  type: string;
  status: string;
  phone_number: string;
}

export interface Requirements {
  disabled_reason: string | null;
}

export interface ShippingHolder {
  address: StripeAddress;
  carrier: string;
  eta: Date;
  service: string;
  status: string;
  tracking_url: string;
  type: string;
}

export interface StripeAddress {
  city: string;
  country: string;
  line1: string;
  line2?: string | null;
  postal_code: string;
  state: string;
}

export interface CreditCard {
  exp_month: string;
  exp_year: string;
  brand: string;
  last4: string;
  card_country: string;
  funding: string;
  id: string;
  isShopperDefaultSubscriptionCardId: boolean;
  name: string;
  object: string;
  source: string;
}
