import * as React from "react";
import { List, Datagrid, TextField } from "react-admin";
import { BasicPagination, perPage } from "../generics/pagination";
import { DateField, FunctionField } from "ra-ui-materialui";
import { TruthyBooleanField } from "../generics/truthy-boolean-field";
import { Announcement } from "src/ts/interfaces/announcements";

import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";

export const AnnouncementList = (
  properties: Record<string, unknown>
): JSX.Element => (
  <List
    {...properties}
    pagination={<BasicPagination />}
    bulkActionButtons={false}
    exporter={false}
    sort={undefined}
    perPage={perPage}
  >
    <Datagrid rowClick="show">
      <TextField label="ID" source="id" sortable={false} />

      <TextField label="Name" source="name" sortable={false} />

      <DateField
        label="Start Date"
        source="startDate"
        sortable={false}
        showTime
      />

      <DateField label="End Date" source="endDate" sortable={false} showTime />

      <TruthyBooleanField
        label="Onboarding"
        source="isOnboarding"
        sortable={false}
      />

      <FunctionField
        label="Active"
        render={( announcement: Announcement ) =>
        {
          const startDate = new Date( announcement.startDate );
          const endDate = new Date( announcement.endDate );
          const now = Date.now();
          const isActive = startDate.getTime() < now && now < endDate.getTime();
          return isActive ? (
            <span>
              <DoneIcon data-testid="true" fontSize="small" />
            </span>
          ) : (
            <span>
              <ClearIcon data-testid="false" fontSize="small" />
            </span>
          );
        }}
        sortable={false}
      />
    </Datagrid>
  </List>
);
