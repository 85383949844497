import * as React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import get from "lodash/get";
import { FunctionField } from "react-admin";
import { FieldProps } from "ra-ui-materialui";
import { Record } from "ra-core";

const useStyles = makeStyles( {
  days: {
    color: "red",
    fontWeight: "bold",
  },
} );

/**
 * Number of Days to Fulfillment is calculated in two ways:
 *
 *  1. If the order is still in progress,  Current Date - Confirmed Date.
 *  2. If the order is fulfilled,  Fulfilled Date - Confirmed Date
 *
 * @param confirmed - Confirmed Date
 * @param fulfilled - Fulfilled Date if it's available
 * @param useCurrentTimeIfUnfulfilled - We should only use current time for "confirmed" or "pending" statuses.
 * @returns
 */
export const getNumOfDaysToFulfill = (
  confirmed: Date | undefined,
  fulfilled: Date | undefined,
  useCurrentTimeIfUnfulfilled: boolean
): number | undefined =>
{
  if ( !confirmed )
  {
    return undefined;
  }

  const MS_PER_DAY = 1000 * 60 * 60 * 24;

  let endDate: Date;

  if ( fulfilled )
  {
    // Use fulfilled date if available
    endDate = new Date( fulfilled );
  }
  else if ( useCurrentTimeIfUnfulfilled )
  {
    // use current date as a secondary option
    endDate = new Date();
  }
  else
  {
    // fallback on returning no value
    return undefined;
  }

  const startDate = new Date( confirmed );

  return Math.round( ( endDate.getTime() - startDate.getTime() ) / MS_PER_DAY );
};

/**
 * Return Function Field to display value
 *
 * @param props
 * @returns
 */
export const DaysToFulfillField: React.FC<FieldProps<Record>> = ( props: {
  record: Record;
} ) =>
{
  const classes = useStyles();

  const { record } = props;
  const confirmedDate = get( record, "timestamps.confirmed" );
  const fulfilledDate = get( record, "timestamps.fulfilled" );

  const useCurrentTimeIfUnfulfilled = ["confirmed", "pending"].includes(
    record.status
  );

  const numOfDaysToFulfill = getNumOfDaysToFulfill(
    confirmedDate,
    fulfilledDate,
    useCurrentTimeIfUnfulfilled
  );

  const newRecord: { status: string; numOfDaysToFulfill: number } = {
    status: record.status,
    numOfDaysToFulfill,
  };

  return (
    <FunctionField
      record={newRecord}
      className={classnames( {
        [classes.days]: numOfDaysToFulfill >= 15,
      } )}
      render={( record: { status: string; numOfDaysToFulfill: number } ) =>
        record.numOfDaysToFulfill != null
          ? record.numOfDaysToFulfill
          : ( record.status === "refunded"
            ? "-"
            : "" )
      }
    />
  );
};

DaysToFulfillField.propTypes = {
  label: PropTypes.string,
  addLabel: PropTypes.bool,
};

DaysToFulfillField.defaultProps = {
  addLabel: true,
  sortable: false,
};
