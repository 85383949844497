import _ from "lodash";
import React, { useEffect, useState } from "react";
import {
  Card,
  Grid,
  Typography,
  makeStyles,
  Button,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import { useDataProvider, useRefresh } from "react-admin";
import { useNotification } from ".././custom-notification/notification";
import { ShippingLabelConfiguration } from "../../ts/interfaces/shipping-labels";
import { ButtonStyles } from "../generics/button-styles";

const useStyles = makeStyles( {
  "border-top": {
    borderTop: "thin solid lightgrey",
  },
  "primary-text": {
    fontWeight: "bold",
  },
  "padding-1rem": {
    padding: "1rem",
  },
  "no-left-padding": {
    padding: "8px 0px",
  },
} );

const ShippingLabelCard = ( properties: {
  label: ShippingLabelConfiguration;
} ) =>
{
  const classes = useStyles();
  const buttonClasses = ButtonStyles();
  const { showNotification } = useNotification();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const [loading, setLoading] = useState( false );
  const [error, setError] = useState<string | null>( null );
  const [labelData, setLabelData] = useState<ShippingLabelConfiguration>(
    properties.label
  );
  const [disableUpdateButton, setDisableUpdateButton] = useState( true );

  const handleDataChanges =
    ( type: "addEmailCheckbox" ) =>
      ( event: React.ChangeEvent<HTMLInputElement> ) =>
      {
        setError( null );
        setDisableUpdateButton( false );
        const { checked } = event.target;
        setLabelData( ( prevState ) =>
        {
          if ( type === "addEmailCheckbox" )
          {
            return {
              ...prevState,
              addCustomerEmailToLabel: checked,
            };
          }
          else
          {
            return prevState;
          }
        } );
      };

  const updateShippingLabel = async () =>
  {
    setLoading( true );
    setError( null );

    dataProvider
      .updateInternalShippingLabels( "settings", labelData )
      .then( () =>
      {
        showNotification( {
          message: `Shipping label updated for ${labelData.accountId}`,
          type: "notify",
          timeout: 2000,
          onClose: () =>
          {
            setDisableUpdateButton( true );
            refresh();
          },
        } );
      } )
      .catch( ( error: Error ) =>
      {
        setError( "Failed to update shipping label" );
        showNotification( {
          message: `Error: ${error.message || error}`,
          type: "error",
          timeout: 2000,
        } );
      } )
      .finally( () =>
      {
        setLoading( false );
      } );
  };

  return (
    <Card>
      <Grid container className={classes["padding-1rem"]}>
        <Grid item xs={8} sm={11} className={classes["no-left-padding"]}>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                className={classes["primary-text"]}
                id={labelData.accountId + "-name"}
              >
                {labelData.name}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="body1" id={labelData.accountId + "-id"}>
                Account Id: {labelData.accountId}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="body1" id={labelData.accountId + "-market"}>
                Market: {_.get( labelData, "market", "" ).toUpperCase()}
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ marginTop: "1rem" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={labelData.addCustomerEmailToLabel}
                    onChange={handleDataChanges( "addEmailCheckbox" )}
                    name="setCustomerEmail"
                    color="primary"
                  />
                }
                label="Add Customer Email"
              />
            </Grid>
            <Grid item xs={12} style={{ marginTop: "1rem" }}>
              <Button
                onClick={updateShippingLabel}
                className={buttonClasses.saveButton}
                variant="contained"
                color="primary"
                disabled={loading || disableUpdateButton}
              >
                {loading ? "Updating..." : "Update Label"}
              </Button>
            </Grid>

            {error && (
              <Grid item xs={12} style={{ marginTop: "1rem" }}>
                <Typography color="error">{error}</Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

const refineLabels = ( data: { [x: string]: ShippingLabelConfiguration[] } ) =>
{
  const labels: ShippingLabelConfiguration[] = [];
  for ( const market of Object.keys( data ) )
  {
    if ( data[market].length > 0 )
    {
      data[market].forEach( ( label ) =>
      {
        labels.push( { ...label, market } );
      } );
    }
  }

  return labels;
};

export const ShippingLabels = (): JSX.Element =>
{
  const dataProvider = useDataProvider();
  const { showNotification } = useNotification();
  const [shippingLabels, setShippingLabels] = useState(
    [] as ShippingLabelConfiguration[]
  );

  const getInternalShippingLabels = () =>
  {
    dataProvider
      .getInternalShippingLabels( "settings" )
      .then(
        ( { data }: { data: { [x: string]: ShippingLabelConfiguration[] } } ) =>
        {
          setShippingLabels( refineLabels( data ) );
        }
      )
      .catch( ( error: Error ) =>
      {
        showNotification( {
          message: `${error}`,
          type: "error",
          timeout: 4000,
        } );
      } );
  };

  useEffect( () =>
  {
    getInternalShippingLabels();
  }, [] );

  return (
    <React.Fragment>
      {shippingLabels &&
        shippingLabels.map( ( label ) => (
          <ShippingLabelCard label={label} key={label.accountId} />
        ) )}
    </React.Fragment>
  );
};
