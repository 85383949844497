import React from "react";
import { Toolbar } from "react-admin";
import { SaveButton } from "ra-ui-materialui";
import { Customer } from "../../../ts/interfaces/user-interface";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles( {
  root: {
    justifyContent: "flex-end",
  },
  hideIcon: {
    "& svg": {
      display: "none",
    },
  },
} );

export const IssueToolbar: React.FunctionComponent<unknown> = ( properties: {
  record?: Customer;
  [x: string]: unknown;
} ) =>
{
  const classes = useStyles();

  return (
    <Toolbar {...properties} className={classes.root}>
      <SaveButton label="Issue Credit Card" className={classes.hideIcon} />
    </Toolbar>
  );
};
