import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  useListContext,
  SelectInput,
  Filter
} from "react-admin";
import { FilterProps } from "ra-ui-materialui";
import { Order, Review } from "../../ts/interfaces/order-interface";
import { Settings } from "../../ts/interfaces/settings-interface";
import { BasicPagination, perPage } from "../generics/pagination";

const ReviewStatusFilter: React.FC = ( properties: FilterProps ) => (
  <Filter {...properties}>
    <SelectInput
      source="reviewStatus"
      label="Review Type"
      alwaysOn
      allowEmpty={false}
      choices={[
        { id: "pending", name: "Pending" },
        { id: "approved", name: "Approved" },
        { id: "rejected", name: "Rejected" },
      ]}
    />
  </Filter>
);

const ListView: React.FC = () =>
{
  const { loading, ids, filterValues } = useListContext();
  return loading ? (
    <></>
  ) : ( ids.length === 0 ? (
    <div style={{ padding: "0 20px" }}>
      <br />
      <h3 style={{ textTransform: "capitalize" }}>
        No {filterValues.reviewStatus} Reviews
      </h3>
      <br />
    </div>
  ) : (
    <Datagrid
      rowClick={( _id: string, _: string, review: Review ) =>
        `orders/${review.orderId}/show/4`
      }
    >
      <TextField label="Order ID" source="orderId" sortable={false} />
      <FunctionField
        label="VIP"
        render={( order: Order ) =>
          `${
            typeof order.customer.vip === "boolean"
              ? ( order.customer.vip === true
                ? "Yes"
                : "No" )
              : "N/A"
          }`
        }
        source="customer.vip"
        sortable={false}
      />
      <FunctionField
        label="Customer"
        render={( order: Order ) =>
          `${order.customer.firstname} ${order.customer.lastname}`
        }
        sortable={false}
      />
      <TextField
        label="Customer Email"
        source="customer.email"
        sortable={false}
      />
      <TextField label="Shopper" source="seller.handle" sortable={false} />
      <TextField label="Rating" source="stars" sortable={false} />
    </Datagrid>
  ) );
};

export const OrderReviewList = ( properties: Settings[] ): JSX.Element => (
  <List
    {...properties}
    pagination={<BasicPagination hideWhenEmpty />}
    title="Manage Reviews"
    bulkActionButtons={false}
    exporter={false}
    filters={<ReviewStatusFilter />}
    filterDefaultValues={{ reviewStatus: "pending" }}
    perPage={perPage}
  >
    <ListView />
  </List>
);
