import { useDataProvider, useUpdate, useNotify, Toolbar } from "react-admin";
import * as React from "react";
import { useEffect, useState } from "react";
import { Spacer } from "../generics/spacer";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Grid,
  FormLabel,
  Input,
  Button
} from "@material-ui/core";
import { SimpleForm } from "ra-ui-materialui";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { formatPrice, notifyMessage } from "../../utils/primitive";

const useStyles = makeStyles( ( theme: Theme ) =>
  createStyles( {
    fullWidth: {
      width: "100%",
    },
    table: {},
    customSimpleForm: {
      width: "100%",
      margin: "0 auto",
      marginBottom: theme.spacing( 2 ),
    },
    noteInput: {
      width: "100%",
    },
  } )
);

interface FailedTransferHistoryProperties {
  id: string;
  targetResource: string;
}

interface FailedTransferHistoryData {
  reason: string;
  type: string;
  amount?: number;
  currency?: string;
  notes?: string;
  author?: string;
}

const NoteBox = ( properties: { id: string } ) =>
{
  const notify = useNotify();
  const classes = useStyles();

  const [note, setNote] = React.useState( " " );
  const [disableSaveButton, setDisableSaveButton] = React.useState( true );

  React.useEffect( () =>
  {
    const hasNote = note.trim().length > 0;
    setDisableSaveButton( !hasNote );
  }, [note] );

  const [addTransferFailureNotes] = useUpdate(
    "add-note-to-transfer-failure",
    properties.id,
    {
      note,
    },
    {},
    {
      onSuccess: () =>
      {
        notify( "Note Saved" );
        window.location.reload();
      },
      onFailure: ( error: Error ) =>
      {
        notifyMessage( error, notify );
        setDisableSaveButton( false );
      },
    }
  );

  const inputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) =>
  {
    setNote( event.target.value );
  };

  const handleSaveButtonClick = () =>
  {
    setDisableSaveButton( true );
    addTransferFailureNotes();
  };

  return (
    <Grid container>
      <FormLabel>Note</FormLabel>
      <SimpleForm
        className={classes.customSimpleForm}
        toolbar={
          <Toolbar>
            <Button
              onClick={handleSaveButtonClick}
              disabled={disableSaveButton}
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          </Toolbar>
        }
      >
        <Grid xs={12} className={classes.noteInput}>
          <Input
            name="note"
            placeholder={note}
            multiline={true}
            rows="5"
            aria-describedby="my-helper-text"
            type="text"
            fullWidth={true}
            disableUnderline={true}
            required={true}
            onChange={inputChange}
            style={{
              borderStyle: "solid",
              borderWidth: "thin",
              borderColor: "grey",
            }}
          />
        </Grid>
      </SimpleForm>
    </Grid>
  );
};

const FailedTransferHistoryTable: React.FunctionComponent<
  FailedTransferHistoryProperties
> = ( properties: FailedTransferHistoryProperties ) =>
{
  const dataProvider = useDataProvider();
  const [failedTransferHistory, setFailedTransferHistory] = useState( [] );
  const classes = useStyles();

  useEffect( () =>
  {
    dataProvider
      .getFailedTransferHistory( properties.targetResource, {
        id: properties.id,
      } )
      .then( ( { data }: { data: FailedTransferHistoryData[] } ) =>
      {
        setFailedTransferHistory( data );
      } );
  }, [] );

  return (
    <>
      <Spacer />
      <Typography variant={"h5"}>Failed Transfer History</Typography>
      <Spacer />
      <TableContainer>
        <Table aria-label="simple table" className={classes.table}>
          <TableHead>
            <TableRow className={classes.table}>
              <TableCell className={classes.table} width={250} align="left">
                <Typography variant={"caption"}>Update date</Typography>
              </TableCell>
              <TableCell className={classes.table} width={150} align="left">
                <Typography variant={"caption"}>Type</Typography>
              </TableCell>
              <TableCell className={classes.table} width={150} align="left">
                <Typography variant={"caption"}>Amount</Typography>
              </TableCell>
              <TableCell className={classes.table} width={250} align="left">
                <Typography variant={"caption"}>Author</Typography>
              </TableCell>
              <TableCell className={classes.table} width={250} align="left">
                <Typography variant={"caption"}>Notes</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {failedTransferHistory.map( ( entry ) => (
              <TableRow key={entry.id}>
                <TableCell className={classes.table} align="left">
                  {entry.timestamp
                    ? new Date( entry.timestamp ).toLocaleString( "en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      second: "numeric",
                    } )
                    : " "}
                </TableCell>
                <TableCell className={classes.table} align="left">
                  {entry.type}
                </TableCell>
                <TableCell className={classes.table} align="left">
                  {entry.amount
                    ? formatPrice( entry.amount, entry.currency )
                    : ""}
                </TableCell>
                <TableCell className={classes.table} align="left">
                  {entry.author ?? ""}
                </TableCell>
                <TableCell className={classes.table} align="left">
                  {entry.reason}
                </TableCell>
              </TableRow>
            ) )}
          </TableBody>
        </Table>
      </TableContainer>
      <Spacer />
    </>
  );
};

export const FailedTransfer = (
  properties: FailedTransferHistoryProperties
): JSX.Element => (
  <React.Fragment>
    <NoteBox id={properties.id} />
    <Spacer />
    <FailedTransferHistoryTable {...properties} />
  </React.Fragment>
);
