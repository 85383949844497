import * as React from "react";

import {
  Typography,
  Grid,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Card,
  FormControl,
  makeStyles,
  Button
} from "@material-ui/core";
import { Customer, EasyPostAccount } from "../../ts/interfaces/user-interface";
import { CustomViewProps } from "./edit";
import { useNotify, useUpdate } from "ra-core";
import { ButtonStyles } from "../generics/button-styles";
import { ORIGIN_COUNTRIES } from "../../constants/origin-countries";

const useStyles = makeStyles( ( theme ) => ( {
  formControl: {
    margin: theme.spacing( 1 ),
    minWidth: 120,
  },
  select: {
    // padding: theme.spacing(1), // Add padding to menu items
    "& .MuiSelect-selectMenu": {
      paddingLeft: theme.spacing( 1 ),
    },
  },
  marginTopAndBottom: {
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  paddingLeft: {
    paddingLeft: "1rem",
  },
} ) );

export const PartnerCarriers: React.FunctionComponent<CustomViewProps> = (
  properties: CustomViewProps
) =>
{
  const buttonClasses = ButtonStyles();
  const classes = useStyles();
  const record = properties.record as Customer;

  const [selectedCountry, updateSelectedCountry] = React.useState<
    string | null
  >( null );

  const [checkboxValues, setCheckboxValues] = React.useState<{
    [key: string]: boolean;
  }>( {} );

  React.useEffect( () =>
  {
    setCheckboxValues(
      record.easyPostAccounts?.[selectedCountry as string]?.reduce(
        ( acc, account ) =>
        {
          if ( !account.addCustomerEmailToLabel )
          {
            return acc;
          }

          acc[`${account.accountId}-setCustomerEmail`] =
            account.addCustomerEmailToLabel || false;
          return acc;
        },
        {} as Record<string, boolean>
      ) || {}
    ); // Reset checkbox values when changing country
  }, [selectedCountry] );

  const handleChange = ( event: React.ChangeEvent<{ value: unknown }> ) =>
  {
    updateSelectedCountry( event.target.value.toString() );
    setCheckboxValues(
      record.easyPostAccounts?.[selectedCountry as string]?.reduce(
        ( acc, account ) =>
        {
          if ( !account.addCustomerEmailToLabel )
          {
            return acc;
          }

          acc[`${account.accountId}-setCustomerEmail`] =
            account.addCustomerEmailToLabel || false;
          return acc;
        },
        {} as Record<string, boolean>
      ) || {}
    ); // Reset checkbox values when changing country
  };

  const handleCheckboxChange = ( event: React.ChangeEvent<HTMLInputElement> ) =>
  {
    const checkboxValuesCopy = { ...checkboxValues };
    checkboxValuesCopy[event.target.name] = event.target.checked;
    setCheckboxValues( checkboxValuesCopy );
  };

  const notify = useNotify();

  const [update, { loading }] = useUpdate(
    "users",
    record.id,
    {
      updates: {
        easyPostAccounts: Object.keys( record.easyPostAccounts ).reduce(
          ( acc, country ) =>
          {
            acc[country] = record.easyPostAccounts[country].map( ( account ) => ( {
              ...account,
              ...( typeof checkboxValues[
                `${account.accountId}-setCustomerEmail`
              ] === "boolean" && {
                addCustomerEmailToLabel:
                  checkboxValues[`${account.accountId}-setCustomerEmail`] ||
                  false,
              } ),
            } ) );
            return acc;
          },
          {} as Record<string, EasyPostAccount[]>
        ),
      },
    },
    undefined,
    {
      onSuccess: () =>
      {
        notify( "Successfully updated Partner EasyPost accounts", "success" );
        setTimeout( () =>
        {
          window.location.reload();
        }, 1500 );
      },
      onFailure: () =>
        notify( "Error updating Partner EasyPost accounts", "error" ),
    }
  );

  return record?.easyPostAccounts ? (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <Grid container item alignItems="center" xs={12}>
          Country:
          <FormControl className={classes.formControl}>
            <Select
              onChange={handleChange}
              displayEmpty
              label="Country"
              className={classes.select}
            >
              {ORIGIN_COUNTRIES.filter( ( country ) =>
                Object.keys( record?.easyPostAccounts ).includes(
                  country.value.toLowerCase()
                )
              ).map( ( country ) => (
                <MenuItem
                  key={country.value}
                  value={country.value.toLowerCase()}
                >
                  {country.name}
                </MenuItem>
              ) )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          {record?.easyPostAccounts[selectedCountry as string]?.map(
            ( account ) => (
              <Grid
                key={account.accountId}
                item
                xs={12}
                className={classes.marginTopAndBottom}
              >
                <Card>
                  <Typography
                    className={`${classes.marginTopAndBottom} ${classes.paddingLeft}`}
                  >
                    Account Id: {account.accountId}
                  </Typography>
                  <FormControlLabel
                    className={`${classes.marginTopAndBottom} ${classes.paddingLeft}`}
                    control={
                      <Checkbox
                        checked={
                          checkboxValues[
                            `${account.accountId}-setCustomerEmail`
                          ] || false
                        }
                        onChange={handleCheckboxChange}
                        name={`${account.accountId}-setCustomerEmail`}
                        color="primary"
                      />
                    }
                    label="Add Customer Email"
                  />
                </Card>
              </Grid>
            )
          )}
        </Grid>
        {selectedCountry && (
          <Grid item xs={12}>
            <Button
              onClick={update}
              className={buttonClasses.saveButton}
              color="primary"
              disabled={loading}
            >
              Update Carrier Accounts
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  ) : (
    <>
      <Typography>No Carrier Accounts set up for this Partner</Typography>
    </>
  );
};
