import * as React from "react";
import { Tab, TabbedShowLayout } from "react-admin";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  Button
} from "@material-ui/core";
import { Spacer } from "../generics/spacer";
import { BundledOrder } from "src/ts/interfaces/bundled-order-interface";
import { Order } from "src/ts/interfaces/order-interface";
import { ShippingLabel } from "../potential-bundles/shipping-label";
import { BundledOrderTable } from "./bundled-order-table";
import { BundledPackingSlip } from "./bundled-packing-slip";
import { AuthContext } from "../../contexts/auth-context";

export interface BundledOrderRecord {
  bundle: BundledOrder;
  entries: Order[];
}
interface OrderMap {
  [x: string]: Order;
}
interface DetailProperties {
  record?: BundledOrderRecord;
  permissions?: {
    exclusiveAddress?: string;
  };
  [x: string]: unknown;
}

export const BundledDetail: React.FunctionComponent<DetailProperties> = (
  detailProperties: DetailProperties
) =>
{
  const [showLabel, setShowLabel] = React.useState( false );
  const [isShipping, setShipping] = React.useState( false );

  const { record } = detailProperties;
  const firstname = record.bundle.address.firstname;
  const lastname = record.bundle.address.lastname;
  const phone = record.bundle.address.phone;

  const bundle = record.bundle;

  const orderMap = {} as OrderMap;
  record.entries.forEach( ( order ) =>
  {
    orderMap[order.id] = order;
  } );

  const closePackingSlip = () =>
  {
    setShowLabel( false );
  };

  const showPackingSlip = () =>
  {
    setShowLabel( true );
  };

  const ShipBundledOrder = async () =>
  {
    setShipping( true );
  };

  return (
    <Card>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={8}>
            <Typography variant="h5" gutterBottom>
              {`Bundled Order ${record.bundle.id}`}
            </Typography>
            <Box
              border={2}
              bgcolor={"#f7f9fa"}
              css={{ borderColor: "#C5D1D9" }}
              padding={2}
            >
              <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography
                    variant={"h6"}
                  >{`Address: ${record.bundle.address.line1}`}</Typography>
                  <Typography
                    variant={"h6"}
                  >{`Postal/Zip: ${record.bundle.address.postalCode}`}</Typography>
                  <Typography
                    variant={"h6"}
                  >{`City: ${record.bundle.address.city}`}</Typography>
                  <Typography
                    variant={"h6"}
                  >{`Country/Region: ${record.bundle.address.country}`}</Typography>
                  <Typography variant={"h6"}>{`Company: ${
                    record.bundle.address.company || ""
                  }`}</Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Typography variant="h5" gutterBottom>
              {`Shipping Details`}
            </Typography>
            <Box
              border={2}
              bgcolor={"#f7f9fa"}
              css={{ borderColor: "#C5D1D9" }}
              padding={2}
              marginLeft={1}
            >
              <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography variant={"h6"}>{`First Name: ${
                    firstname || ""
                  }`}</Typography>
                  <Typography variant={"h6"}>{`Last Name: ${
                    lastname || ""
                  }`}</Typography>
                  <Typography variant={"h6"}>{`Phone: ${
                    phone || ""
                  }`}</Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Spacer />

        {isShipping && (
          <ShippingLabel bundledOrder={bundle} orderMap={orderMap} />
        )}
        {!isShipping && (
          <TabbedShowLayout>
            <Tab label="Orders">
              <Box>
                <Spacer />
                <Button onClick={ShipBundledOrder}>Ship Orders</Button>
                <Button onClick={showPackingSlip}>Print Packaging Slip</Button>
                <Spacer />
              </Box>
              <BundledOrderTable orders={record.entries} bundleId={bundle.id} />
              <Spacer />
            </Tab>
          </TabbedShowLayout>
        )}
        <AuthContext.Provider
          value={{ permissions: detailProperties.permissions }}
        >
          <BundledPackingSlip
            key={bundle.id}
            bundledOrder={bundle}
            orders={record.entries}
            showLabel={showLabel}
            onUnload={closePackingSlip}
          />
        </AuthContext.Provider>
      </CardContent>
    </Card>
  );
};
