import React, { createContext, useContext, useEffect, useState } from "react";
import { authHeader, getParsedToken } from "../utils/auth-header";
import { fetchUtils } from "ra-core";
import { config } from "../config";
const httpClient = fetchUtils.fetchJson;
const UserContext = createContext( {
  fulfilledBy: "",
  stripeAccountSource: "",
  fulfillmentType: "",
  sellerType: "",
} );

export const useUserContext = () => useContext( UserContext );
const getUserProfile = async ( parameters: { id: string } ) =>
{
  try
  {
    const url = `${config.dataProviderRoot}/api/users/${parameters.id}/retrieve`;
    const options = {
      headers: await authHeader(),
      method: "post",
    };
    const { json } = await httpClient( url, options );
    return json.data;
  }
  catch ( error )
  {
    console.log( error );
  }
};

export const UserContextProvider = ( {
  children,
}: {
  children: React.ReactChildren | React.ReactElement;
} ) =>
{
  const [userData, setUserData] = useState( {
    fulfilledBy: "",
    stripeAccountSource: "",
    fulfillmentType: "",
    sellerType: "",
  } );
  useEffect( () =>
  {
    const fetchUserData = async () =>
    {
      const token = await getParsedToken();
      // Use `user_id` because that's a reserved firebase value
      // and not use `uid` because that value is not available for most accounts
      const userData = await getUserProfile( { id: `${token.user_id}` } );
      setUserData( userData );
    };
    fetchUserData();
  }, [] );

  return (
    <UserContext.Provider value={userData}>{children}</UserContext.Provider>
  );
};
