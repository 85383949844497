export enum StoragePage {
  orderList = "orderList",
  manifestList = "manifestList",
  reportList = "reportList",
  productInventoryItemList = "productInventoryItemList",
  sellerMessages = "sellerMessages",
}

export enum StorageType {
  seller = "shopper",
  event = "event",
  manifest = "manifest",
}

export function makeLocalStorageKey(
  page: StoragePage,
  type: StorageType,
  id: string
): string
{
  return `${page}:${type}:${id}`;
}
