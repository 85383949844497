import React, { ChangeEvent } from "react";
import { TextField, Button, Select, MenuItem, Box } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { requiredString } from "../../utils/form-validators";
import { CustomModal } from "@shopthing-opn-shared/admin-dashboard";
import { ConvertPickup } from "./convert-pickup";
import { Spacer } from "../generics/spacer";

interface IPickupForm {
  id: string;
}

interface EventTarget {
  name: string;
  value: string;
}

const useStyles = makeStyles( ( theme: Theme ) =>
  createStyles( {
    chargeInput: {
      position: "absolute",
      width: 500,
      backgroundColor: "#f2f2f2",
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing( 2, 4, 3 ),
      textAlign: "center",
    },
    currentLayout: {
      display: "flex",
      flexDirection: "row",
    },
    description: {
      width: "90%",
    },
    half: {
      width: "45%",
    },
    select: {
      width: "15%",
    },
    boxLayout: {
      display: "flex",
      justifyContent: "space-around",
    },
  } )
);

export const ConvertPickupForm: React.FunctionComponent<IPickupForm> = (
  order: IPickupForm
) =>
{
  const [open, setOpen] = React.useState( false );
  const [values, setValues] = React.useState( {
    firstname: "",
    lastname: "",
    phone: "",
    company: "",
    line1: "",
    line2: "",
    city: "",
    state: "",
    country: "",
    postalCode: "",
  } );
  const [formError, setError] = React.useState( {
    firstname: "",
    lastname: "",
    phone: "",
    line1: "",
    city: "",
    state: "",
    country: "",
    postalCode: "",
  } );

  const classes = useStyles();
  const validators: {
    [key: string]: ( value: string, key: string ) => string;
  } = {
    requiredString,
  };
  const handleOpen = () =>
  {
    setOpen( true );
  };
  const handleClose = () =>
  {
    setOpen( false );
  };
  const handleInputChange = ( event: { target: EventTarget } ) =>
  {
    const { name, value } = event.target;
    setValues( { ...values, [name]: value } );
    const error = validators.requiredString( value, name );
    setError( { ...formError, [name]: error } );
  };

  const handleSelectChange = (
    event: ChangeEvent<{ name: string; value: string }>
  ) =>
  {
    const { name, value } = event.target;
    setValues( { ...values, [name]: value } );
  };

  return (
    <>
      <Button onClick={handleOpen}>Convert To Delivery</Button>

      <CustomModal open={open} onClose={handleClose} style={{ width: 800 }}>
        <>
          <Spacer />
          <TextField
            name="firstname"
            onChange={handleInputChange}
            value={values.firstname}
            error={Boolean( formError.firstname )}
            helperText={formError.firstname}
            className={classes.half}
            type="text"
            label="First Name"
          />{" "}
          <TextField
            name="lastname"
            onChange={handleInputChange}
            value={values.lastname}
            error={Boolean( formError.lastname )}
            helperText={formError.lastname}
            className={classes.half}
            type="text"
            label="Last Name"
          />
          <Spacer />
          <TextField
            name="phone"
            onChange={handleInputChange}
            value={values.phone}
            error={Boolean( formError.phone )}
            helperText={formError.phone}
            className={classes.half}
            type="text"
            label="Phone"
          />{" "}
          <TextField
            name="company"
            onChange={handleInputChange}
            value={values.company}
            error={false}
            className={classes.half}
            type="text"
            label="Company"
          />
          <Spacer />
          <TextField
            name="line1"
            onChange={handleInputChange}
            value={values.line1}
            error={Boolean( formError.line1 )}
            helperText={formError.line1}
            className={classes.description}
            type="text"
            label="Line 1"
          />
          <Spacer />
          <TextField
            name="line2"
            onChange={handleInputChange}
            value={values.line2}
            error={false}
            className={classes.description}
            type="text"
            label="Line 2"
          />
          <Spacer />
          <TextField
            name="city"
            onChange={handleInputChange}
            value={values.city}
            error={Boolean( formError.city )}
            helperText={formError.city}
            className={classes.half}
            type="text"
            label="City"
          />{" "}
          <TextField
            name="state"
            onChange={handleInputChange}
            value={values.state}
            error={Boolean( formError.state )}
            helperText={formError.state}
            className={classes.half}
            type="text"
            label="State"
          />
          <Spacer />
          <Box className={classes.boxLayout}>
            <Select
              label="Country"
              name="country"
              value={values.country.length > 0 ? values.country : "Country"}
              onChange={handleSelectChange}
              className={classes.half}
              placeholder={"Country"}
            >
              <MenuItem value={"Country"} disabled>
                Country
              </MenuItem>
              <MenuItem value={"CA"}>Canada</MenuItem>
              <MenuItem value={"US"}>United States</MenuItem>
            </Select>{" "}
            <TextField
              name="postalCode"
              onChange={handleInputChange}
              value={values.postalCode}
              error={Boolean( formError.postalCode )}
              helperText={formError.postalCode}
              className={classes.half}
              type="text"
              label="Postal Code"
            />
          </Box>
          <Spacer />
          <ConvertPickup
            orderId={order.id}
            logistics={{
              details: {
                firstname: values.firstname,
                lastname: values.lastname,
                phone: values.phone,
                company: values.company || undefined,
                address: {
                  line1: values.line1,
                  line2: values.line2,
                  city: values.city,
                  state: values.state,
                  country: values.country,
                  postalCode: values.postalCode,
                  firstname: values.firstname,
                  lastname: values.lastname,
                  company: values.company,
                  phone: values.phone,
                },
              },
            }}
            redirect={true}
          ></ConvertPickup>
        </>
      </CustomModal>
    </>
  );
};
