import React from "react";
import { Item } from "src/ts/interfaces/order-interface";
import { IconButton, Typography, Button } from "@material-ui/core";
import {
  CustomModal,
  DymoContext
} from "@shopthing-opn-shared/admin-dashboard";
import QRCode from "qrcode.react";
import { Spacer } from "../generics/spacer";
import {
  BooleanRoles,
  extractAuthClaims,
  hasPermissions
} from "../../ts/interfaces/role-interface";
import { useAuth } from "../../contexts/auth-context";
import { PrintBarcodeLabelButton } from "./product-barcode-label";

interface IPrintButton {
  orderId: string;
  fulfillmentId: string;
  skuId: string;
  item: Item;
  productInventoryItemId: string;
  [x: string]: unknown;
}

export const PrintButton: React.FunctionComponent<IPrintButton> = ( {
  orderId,
  fulfillmentId,
  item,
  productInventoryItemId,
}: IPrintButton ) =>
{
  const { printLabel } = React.useContext( DymoContext );
  const [open, setOpen] = React.useState( false );

  const handleOpen = () =>
  {
    setOpen( true );
  };

  const handleClose = () =>
  {
    setOpen( false );
  };

  const handleConfirm = () =>
  {
    printLabel( [
      { title: "CATEGORY", value: item.brandName || "" },
      { title: "TITLE", value: item.productName || "" },
      { title: "DESC", value: item.description || "" },
      {
        title: "qrcode",
        value: JSON.stringify( {
          fulfillmentId,
          orderId,
          productInventoryItemId,
        } ),
      },
    ] );
    setOpen( false );
  };

  const body = (
    <>
      <Typography>
        Reprint Barcode Label for Fulfillment {fulfillmentId}?
      </Typography>
      <Spacer />
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Button onClick={handleConfirm}>Print (using Dymo)</Button>
        <PrintBarcodeLabelButton
          fulfillmentId={fulfillmentId}
          orderId={orderId}
          item={item}
          setOpen={setOpen}
          productInventoryItemId={productInventoryItemId}
        />
      </div>
    </>
  );

  const { permissions } = useAuth();
  const roleClaims = extractAuthClaims( permissions );

  return (
    <>
      <IconButton
        onClick={handleOpen}
        color={"primary"}
        disableRipple
        disableFocusRipple
      >
        <QRCode
          size={30}
          value={JSON.stringify( {
            fulfillmentId,
            orderId,
            productInventoryItemId,
          } )}
        />
      </IconButton>
      {hasPermissions(
        [
          BooleanRoles.Admin,
          BooleanRoles.RefundCustomerService,
          BooleanRoles.Partner,
          BooleanRoles.Seller,
        ],
        roleClaims
      ) && (
        <CustomModal open={open} onClose={handleClose}>
          {body}
        </CustomModal>
      )}
    </>
  );
};
