import * as React from "react";
import { useUpdate } from "react-admin";
import { Typography, Box } from "@material-ui/core";
import { resetHistory } from "../../utils/reset-history";

interface IFulfillOrders {
  bundledOrdersId: string;
  shipping: Shipping;
  rateFee: number;
}

export interface LabelProvider {
  shipmentId: string;
  rateId: string;
  currency: string;
  deliveryDate: string;
  deliveryDateGuaranteed?: boolean | null;
  deliveryDays: number;
  estDeliveryDays: number;
  labelFee: number;
  labelUrl: string;
  provider: string;
}

export interface Shipping {
  carrier: string;
  trackingNumber: string;
  labelProvider: LabelProvider;
}

interface FulfillmentResponse {
  failedOrders: string[];
  id: string;
  orderIds: string[];
}

export const FulfillOrders = ( properties: IFulfillOrders ): JSX.Element =>
{
  const [fulfillment, setFulfillment] = React.useState(
    {} as FulfillmentResponse
  );

  const [update] = useUpdate(
    "bundled-orders",
    properties.bundledOrdersId,
    { shipping: properties.shipping, rateFee: properties.rateFee },
    {},
    {
      onSuccess: ( response: { data: FulfillmentResponse } ) =>
      {
        setFulfillment( response.data );
      },
      onFailure: () =>
      {
        setTimeout( () =>
        {
          resetHistory();
          history.go();
        }, 2000 );
      },
    }
  );
  React.useEffect( () =>
  {
    update();
  }, [] );
  return (
    <>
      {Object.keys( fulfillment ).length > 0 && (
        <Box display={"block"}>
          {fulfillment.orderIds.length > 0 && (
            <>
              <Typography>Fulfilled Orders:</Typography>
              {fulfillment.orderIds.map( ( orderId ) => (
                <Typography key={orderId}>{orderId}</Typography>
              ) )}
            </>
          )}

          {fulfillment.failedOrders.length > 0 && (
            <>
              <Typography>Unfulfilled Orders:</Typography>
              {fulfillment.failedOrders.map( ( orderId ) => (
                <Typography key={orderId}>{orderId}</Typography>
              ) )}
            </>
          )}
        </Box>
      )}
    </>
  );
};
