import * as React from "react";
import { useDataProvider } from "react-admin";
import { BundledDetail, BundledOrderRecord } from "./bundled-detail";
import { useNotify } from "ra-core";
interface IShowProperties {
  id: string;
  [x: string]: unknown;
  record: BundledOrderRecord;
}

export const BundledDetailShow: React.FunctionComponent<IShowProperties> = (
  showProperties
) =>
{
  const customProvider = useDataProvider();
  const notify = useNotify();
  const [bundledData, setBundledData] = React.useState(
    {} as BundledOrderRecord
  );
  React.useEffect( () =>
  {
    ( async () =>
    {
      try
      {
        const custom = await customProvider.getOne( "bundled-orders-proxy", {
          id: showProperties.id,
        } );
        setBundledData( custom.data );
      }
      catch ( error )
      {
        notify( error.message, "error" );
      }
    } )();
  }, [] );
  return (
    Object.keys( bundledData ).length > 0 && (
      <BundledDetail record={bundledData} />
    )
  );
};
