import * as React from "react";
import { PotentialBundle } from "src/ts/interfaces/potential-bundle-interface";
import { SimpleShowLayout, Show, FunctionField, useGetOne } from "react-admin";
import { BundleDetail } from "./bundle-detail";
import { AuthContext } from "../../contexts/auth-context";
import { PopupNotification } from "../popup-notification/popup-notification";
import { LaunchDarklyProvider } from "../../contexts/launch-darkly-context";

interface IShowProperties {
  id: string;
  [x: string]: unknown;
  record: PotentialBundle;
  permissions: {
    exclusiveAddress?: string;
  };
}

export const BundleDetailShow: React.FunctionComponent<IShowProperties> = (
  showProperties
) => (
  <LaunchDarklyProvider>
    <Show {...showProperties} title={`ShopThing`}>
      <>
        <SimpleShowLayout>
          <FunctionField
            addLabel={false}
            render={( bundle: PotentialBundle ) =>
            {
              const fullMode = bundle.orderData ? true : false;
              let bundleData: PotentialBundle;

              const { data, loading, error } = useGetOne(
                "potential-bundles",
                bundle.id
              );

              if ( !fullMode )
              {
                if ( !loading && !error )
                {
                  bundleData = data;
                }
              }
              if ( fullMode )
              {
                return (
                  <AuthContext.Provider
                    value={{ permissions: showProperties.permissions }}
                  >
                    <BundleDetail record={bundle} />
                  </AuthContext.Provider>
                );
              }
              else if ( bundleData )
              {
                return (
                  <AuthContext.Provider
                    value={{ permissions: showProperties.permissions }}
                  >
                    <BundleDetail record={bundleData} />
                  </AuthContext.Provider>
                );
              }
            }}
          />
        </SimpleShowLayout>
        <PopupNotification />
      </>
    </Show>
  </LaunchDarklyProvider>
);
