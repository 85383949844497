import { Order } from "../../ts/interfaces/order-interface";
import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  FunctionField,
  TopToolbar,
  Button,
  useListContext
} from "react-admin";
import { Chip, Grid } from "@material-ui/core";
import { BasicPagination, perPage } from "../generics/pagination";
import { formatPrice } from "../../utils/primitive";
import { ButtonStyles } from "../generics/button-styles";

export const ListActions: React.FC = () =>
{
  const { setSort, currentSort } = useListContext();
  const buttonClasses = ButtonStyles();

  return (
    <TopToolbar>
      <Button
        onClick={() =>
        {
          if (
            currentSort.field !== "timestamps.fulfilled" ||
            currentSort.order.toUpperCase() !== "DESC"
          )
          {
            setSort( "timestamps.fulfilled", "DESC" );
          }
        }}
        label="Reset Sort"
        variant="contained"
        color="primary"
        className={buttonClasses.resetSortButton}
      />
    </TopToolbar>
  );
};

export const FulfilledOrderList = ( properties: Order[] ): JSX.Element => (
  <List
    {...properties}
    actions={<ListActions />}
    pagination={<BasicPagination />}
    bulkActionButtons={false}
    exporter={false}
    perPage={perPage}
    sort={{ field: "timestamps.fulfilled", order: "DESC" }}
  >
    <Datagrid rowClick="show">
      <TextField label="Order ID" source="id" sortable={false} />
      <TextField
        label="Shipping Method"
        source="logistics.option"
        sortable={true}
      />
      <TextField label="Event ID" source="seller.event" sortable={false} />
      <TextField label="Shopper" source="seller.handle" sortable={false} />
      <FunctionField
        label="VIP"
        render={( order: Order ) =>
          `${
            typeof order.customer.vip === "boolean"
              ? ( order.customer.vip === true
                ? "Yes"
                : "No" )
              : "N/A"
          }`
        }
        source="customer.vip"
        sortable={true}
      />
      <FunctionField
        label="Customer"
        render={( order: Order ) =>
          `${order.customer.firstname} ${order.customer.lastname}`
        }
        sortable={false}
      />
      <TextField
        label="Brand"
        source="cart.items[0].brandName"
        sortable={true}
      />
      <FunctionField
        label="Product"
        sortable={false}
        render={( order: Order ) =>
          `${order.cart.items[0].productName}${
            order.cart.items[0].description
              ? ` - ${order.cart.items[0].description}`
              : ""
          }`
        }
      />
      -{" "}
      <FunctionField
        label="Total"
        render={( order: Order ) =>
          `${formatPrice( order.totals.totalPay, order.specifications.currency )}`
        }
        sortable={false}
      />{" "}
      -{" "}
      <DateField
        label="Created At"
        source="timestamps.created"
        showTime
        sortable={false}
      />
      <FunctionField
        label="Status"
        render={( order: Order ) =>
          order.latestDisputeStatus &&
          ( typeof order.disputeResolved !== "boolean" ||
            order.disputeResolved === false ) ? (
              <Grid container spacing={1} wrap="nowrap">
                <Grid item>
                  <Chip label={order.status} />
                </Grid>
                <Grid item>
                  <Chip style={{ color: "red" }} label={`disputed`} />
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={1} wrap="nowrap">
                <Grid item>
                  <Chip
                    label={
                      order.latestDisputeStatus === "lost" &&
                    order.disputeResolved === true
                        ? "Dispute Lost"
                        : order.status
                    }
                  />
                </Grid>
              </Grid>
            )
        }
        sortable={false}
      />
    </Datagrid>
  </List>
);
