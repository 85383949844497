import * as React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

import {
  FormTab,
  TabbedForm,
  TextInput,
  BooleanInput,
  useNotify,
  ReferenceManyField,
  Datagrid,
  TextField,
  Create,
  BooleanField,
  FunctionField
} from "react-admin";

import { useForm } from "react-final-form";

import { Record } from "ra-core";
import Checkbox from "@material-ui/core/Checkbox";

import { Editorial } from "../../ts/interfaces/event-interface";
import { BasicPagination } from "../generics/pagination";
import { TrailerUpload } from "../generics/trailer-media";

interface IEditProperties {
  id: string;
  [x: string]: unknown;
  record: Editorial;
}

const useStyles = makeStyles( ( theme: Theme ) =>
  createStyles( {
    formField: {
      margin: theme.spacing( 1, 2, 1, 0 ),
    },
  } )
);

const formLinkIndicator = "form-link-product-";

/**
 * Due to the nature of react admin, it is not possible to directly modify the parent form state
 * from within a nested list view. Instead pass down a checkbox with access to the form controlller
 * which can update the form's state when a checkbox is toggled.
 */
const SelectProduct = ( properties: { record: Editorial } ) =>
{
  const form = useForm();

  return (
    <Checkbox
      onChange={( event ) =>
      {
        const isChecked = event.target.checked;
        form.change( `${formLinkIndicator}${properties.record.id}`, isChecked );
      }}
    />
  );
};

const transform = async ( data: Record ) =>
{
  const productIds = new Set<string>();

  // Extract the custom form fields set by the checkboxes and concatentate
  // them into an array of products in the editorial
  for ( const [name, value] of Object.entries( data ) )
  {
    if ( name.startsWith( formLinkIndicator ) && value === true )
    {
      productIds.add( name.split( formLinkIndicator )[1] );
    }
  }

  const create: Record = {
    id: "",
    creation: {
      name: data.name,
      isActive: data.isActive,
      productIds: [...productIds],
      trailerPath: await Promise.resolve( data.trailerPath ),
      trailerPosterPath: await Promise.resolve( data.trailerPosterPath ),
    },
  };

  return create;
};

export const EditorialCreate: React.FunctionComponent<IEditProperties> = (
  properties: IEditProperties
) =>
{
  const classes = useStyles();
  const notify = useNotify();

  const onFailure = ( error: Error ) =>
  {
    notify( `Failed creating editorial ${error.message}`, "warning" );
  };

  return (
    <Create transform={transform} onFailure={onFailure} {...properties}>
      <TabbedForm>
        <FormTab label="Editorial">
          <TextInput source="name" className={classes.formField} />
          <BooleanInput
            source="isActive"
            className={classes.formField}
            defaultValue={false}
          />
          <TrailerUpload
            uploadPath={`editorials/${String(
              Math.floor( Math.random() * 10000000 + 1 )
            )}/${new Date().toISOString()}`}
            label="Editorial"
          />
        </FormTab>
        <FormTab label="Add Products To Editorial">
          <ReferenceManyField
            reference="products"
            target="id"
            filter={{}}
            pagination={<BasicPagination />}
          >
            <Datagrid>
              <FunctionField
                label="Add Product"
                render={( editorial: Editorial ) => (
                  <SelectProduct record={editorial} />
                )}
              />
              <TextField label="Product Id" source="id" sortable={false} />
              <TextField
                label="Brand Name"
                source="brandName"
                sortable={false}
              />
              <TextField
                label="Product Name"
                source="productName"
                sortable={false}
              />
              <BooleanField
                label="Is Active"
                source="isActivated"
                sortable={false}
              />
              <BooleanField
                label="Is Published"
                source="isPublished"
                sortable={false}
              />
              <TextField
                label="Shopper"
                source="sellerHandle"
                sortable={false}
              />
              <TextField label="Seller Id" source="sellerId" sortable={false} />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
      </TabbedForm>
    </Create>
  );
};
