import * as React from "react";
import { MenuItemLink, Layout, getResources } from "react-admin";

import { AppBar } from "./components/app-bar";
import { useSelector } from "react-redux";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ListIcon from "@material-ui/icons/ViewList";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { UserContextProvider, useUserContext } from "./contexts/user-context";

function RealtimeOrderOverviewMenuItem()
{
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <p style={{ margin: "0px" }}>Realtime Order</p>
      <p style={{ margin: "0px" }}>Overview</p>
    </div>
  );
}
const MenuWrapper = ( props: { onMenuClick: () => void } ) => (
  <UserContextProvider>
    <Menu {...props} />
  </UserContextProvider>
);
const Menu = ( props: { onMenuClick: () => void } ) =>
{
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const open = useSelector( ( state ) => state.admin.ui.sidebarOpen );
  const resources: [] = useSelector( getResources );

  const useStyles = makeStyles( {
    wrap: {
      whiteSpace: "normal",
    },
  } );

  const classes = useStyles();
  const userData = useUserContext();
  return (
    <Box style={{ margin: "7px" }}>
      {resources.map(
        ( resource: {
          options: { label: string };
          name: string;
          hasList: boolean;
        } ) =>
        {
          const label =
            resource.options?.label?.length > 0
              ? resource.options.label
              : resource.name;
          if ( resource.name === "cards" && userData.sellerType !== "Seller" )
          {
            return <></>;
          }
          return (
            resource.hasList && (
              <MenuItemLink
                key={resource.name}
                to={`/${resource.name}`}
                primaryText={label}
                leftIcon={<ListIcon />}
                onClick={props.onMenuClick}
                sidebarIsOpen={open}
                className={classes.wrap}
              />
            )
          );
        }
      )}
      {resources.find(
        ( resource: { name: string } ) => resource.name === "isAdminUser"
      ) && (
        <MenuItemLink
          to="/dashboards/realtime-order-overview"
          primaryText={<RealtimeOrderOverviewMenuItem />}
          leftIcon={<DashboardIcon />}
          onClick={props.onMenuClick}
          sidebarIsOpen={open}
          {...props}
        />
      )}
    </Box>
  );
};

export const CustomLayout: React.FunctionComponent<JSX.IntrinsicAttributes> = (
  properties: JSX.IntrinsicAttributes
) => <Layout {...properties} appBar={AppBar} menu={MenuWrapper} />;
