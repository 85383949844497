import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Filter,
  SelectInput,
  FunctionField,
  BooleanField,
  DateField
} from "react-admin";
import { FilterProps, NumberField } from "ra-ui-materialui";

import { useLocation } from "react-router";

import { Customer } from "../../ts/interfaces/user-interface";
import { BasicPagination, perPage } from "../generics/pagination";

const UserTypeFilter: React.FC = ( properties: FilterProps ) => (
  <Filter {...properties}>
    <SelectInput
      source="isSeller"
      label="User Type"
      alwaysOn
      allowEmpty={false}
      choices={[
        { id: true, name: "Sellers" },
        { id: false, name: "Customers" },
      ]}
    />
  </Filter>
);

export const UserList = ( properties: Customer[] ): JSX.Element =>
{
  const { search } = useLocation();
  let isSellerView = true;
  if ( search )
  {
    const filter = JSON.parse( new URLSearchParams( search ).get( "filter" ) );
    isSellerView = filter.isSeller === true;
  }

  return (
    <List
      {...properties}
      pagination={<BasicPagination />}
      bulkActionButtons={false}
      exporter={false}
      perPage={perPage}
      filters={<UserTypeFilter />}
      filterDefaultValues={{ isSeller: true }}
      sort={{
        field: "termsOfServiceAccepted.date",
        order: "DESC",
      }}
    >
      <Datagrid rowClick="edit">
        {isSellerView && (
          <FunctionField
            label="Shopper Status"
            sortable={false}
            render={( record: Customer ) =>
              `${
                typeof record.isSellerActive === "boolean"
                  ? ( record.isSellerActive === true
                    ? "Active"
                    : "Deactive" )
                  : "N/A"
              }`
            }
          />
        )}
        <TextField
          label="Membership Status"
          source="membershipStatus"
          sortable={false}
        />
        <TextField label="Customer ID" source="id" sortable={false} />
        <TextField label="Name" source="firstname" sortable={false} />

        {isSellerView && (
          <TextField label="Handle" source="handle" sortable={false} />
        )}

        {isSellerView && (
          <NumberField
            label="# of Followers"
            source="followerCount"
            emptyText="0"
            sortable={false}
          />
        )}

        {isSellerView && (
          <FunctionField
            label="Issued Stripe Credit Card"
            sortable={false}
            render={( record: Customer ) =>
            {
              if ( record.stripeCardId || record.stripeAccountSource !== "US" )
              {
                return (
                  <BooleanField
                    source="issuedCard"
                    record={{ issuedCard: record.stripeAccountSource === "US" }}
                  />
                );
              }
            }}
          />
        )}

        {isSellerView && (
          <TextField label="Seller Type" source="sellerType" sortable={false} />
        )}

        {isSellerView && (
          <DateField
            label="Sign Up Date"
            source="termsOfServiceAccepted.date"
            showTime
            sortable={true}
          />
        )}
      </Datagrid>
    </List>
  );
};
