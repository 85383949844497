import * as React from "react";
import { DateField, FilterProps } from "ra-ui-materialui";
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  Filter,
  SelectInput
} from "react-admin";
import { TruthyBooleanField } from "../generics/truthy-boolean-field";

import { Event } from "../../ts/interfaces/event-interface";
import { BasicPagination, perPage } from "../generics/pagination";
import { usePermissions } from "ra-core";
import {
  BooleanRoles,
  extractAuthClaims,
  hasPermissions
} from "../../ts/interfaces/role-interface";
import { Order } from "../../ts/interfaces/order-interface";
import { capitalize } from "lodash";

export enum FilterInputSourceName {
  pinned = "pinned",
}

export enum PinnedOptionsChoices {
  yes = "yes",
  no = "no",
}

const PostFilter: React.FC = (
  properties: FilterProps & {
    filterValues?: Order;
  }
) =>
{
  const { permissions } = usePermissions();
  const roleClaims = extractAuthClaims( permissions );
  const hasFilterPermissions = hasPermissions( [BooleanRoles.Admin], roleClaims );

  if ( !hasFilterPermissions )
  {
    return null;
  }

  return (
    <Filter {...properties}>
      <SelectInput
        source={FilterInputSourceName.pinned}
        choices={Object.entries( PinnedOptionsChoices ).map( ( [id, name] ) => ( {
          id,
          name: capitalize( name ),
        } ) )}
      />
    </Filter>
  );
};

export const EventList = ( properties: Event[] ): JSX.Element =>
{
  const { permissions } = usePermissions();
  const isAdmin = hasPermissions(
    [
      BooleanRoles.Admin,
      BooleanRoles.Fulfillment,
      BooleanRoles.CustomerService,
      BooleanRoles.FulfillmentReadOnly,
      BooleanRoles.RefundCustomerService,
    ],
    permissions
  );

  return (
    <List
      {...properties}
      pagination={<BasicPagination />}
      filters={<PostFilter />}
      bulkActionButtons={false}
      exporter={false}
      sort={{ field: "startDate", order: "DESC" }}
      perPage={perPage}
    >
      <Datagrid rowClick="show">
        <TextField label="Event ID" source="id" sortable={false} />
        <TextField label="Name" source="name" />
        {isAdmin && (
          <TruthyBooleanField
            label="Pinned Event"
            source="isPinned"
            sortable={false}
          />
        )}
        <DateField label="Start Date" source="startDate" showTime />
        <DateField label="End Date" source="endDate" showTime />
        <TextField label="Partnership" source="partnership" />
        <TextField label="Handle" source="handle" />
        <TextField label="Shopper ID" source="userId" />
        <TruthyBooleanField
          label="Closet Sale"
          source="isClosetSale"
          sortable={false}
        />
        <TruthyBooleanField
          label="Exclusive Event"
          source="exclusiveEvent"
          sortable={false}
        />
        <TruthyBooleanField
          label="Early Access"
          source="earlyAccess"
          sortable={false}
        />
        <FunctionField
          label="Early Access delay in minutes"
          render={( event: Event ) =>
            event.earlyAccess ? event.earlyAccessDelayMinutes : null
          }
          sortable={false}
        />
      </Datagrid>
    </List>
  );
};
