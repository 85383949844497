import * as React from "react";
import {
  ProductInventoryItem,
  ProductInventoryItemStatus
} from "../../ts/interfaces/product-inventory-interface";
import {
  FunctionField,
  Show,
  SimpleShowLayout,
  TopToolbar,
  EditButton,
  TabbedShowLayout,
  Tab,
  useDataProvider,
  ArrayField,
  SingleFieldList
} from "react-admin";
import {
  Box,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import { AuthContext, useAuth } from "../../contexts/auth-context";
import { makeStyles } from "@material-ui/core/styles";
import { ReturnButton } from "../generics/return-action-button";
import { getAge } from "../../utils/primitive";
import {
  BooleanRoles,
  hasPermissions
} from "../../ts/interfaces/role-interface";
import {
  AddProductInventoryToEvent,
  RemoveProductInventoryFomEvent,
  RemoveProductInventory
} from "./event-actions";
import { Spacer } from "../generics/spacer";
import { useEffect, useState } from "react";
import { Record } from "ra-core";
import { MultiTypeFileField } from "../generics/multi-type-file-field";
import { BarcodeButton } from "./barcode-button";
import { ButtonStyles } from "../generics/button-styles";

const useImageFieldStyles = makeStyles( () => ( {
  image: {
    maxWidth: "100%",
    maxHeight: "270px",
    borderRadius: "5px",
  },
} ) );

interface IShowProperties {
  id: string;
  [x: string]: unknown;
  record: ProductInventoryItem;
}

const ProductInventoryTextField = ( {
  label,
  value,
}: {
  label: string;
  value: string | number;
} ) => (
  <Typography variant={"h6"}>
    <span
      style={{
        fontWeight: "bold",
      }}
    >
      {`${label}: `}
    </span>
    {value}
  </Typography>
);

const ProductInventoryFieldContainer = ( props: {
  children: React.ReactChild;
} ) => (
  <Box
    border={2}
    bgcolor={"#f7f9fa"}
    padding={2}
    margin={2}
    borderRadius="5px"
    borderColor="#C5D1D9"
    height="90%"
  >
    {props.children}
  </Box>
);

interface IEditHistoryProperties {
  editHistory: EditHistoryData[];
}

export interface EditHistoryData {
  id: string;
  userId: string;
  firstname: string;
  lastname: string;
  updatedData: string[];
  timestamp: string;
}

const fieldLookup: { [key: string]: string } = {
  categoryPathIds: "Categories",
  salePrice: "Sale price",
  brandId: "Brand",
  tagIds: "Tags",
  regularPrice: "Regular price",
  currency: "Currency",
  location: "Location",
  productName: "Name",
  productDescription: "Description",
  media: "Media",
  skuOption: "Option",
};

const PIIEditHistoryTable = ( properties: IEditHistoryProperties ) => (
  <TableContainer>
    <Table aria-label="note-table">
      <TableHead>
        <TableRow>
          <TableCell align="left">
            <Typography align="left" noWrap={true}>
              Timestamp
            </Typography>
          </TableCell>
          <TableCell align="left">
            <Typography align="left">Author</Typography>
          </TableCell>
          <TableCell align="left">
            <Typography>Fields</Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {properties.editHistory?.map( ( historyEntry ) => (
          <TableRow key={"note"}>
            <TableCell width={250} align="left">
              <Typography align="left" noWrap={true}>
                {historyEntry.timestamp
                  ? new Date( historyEntry.timestamp ).toLocaleString( "en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                  } )
                  : " "}
              </Typography>
            </TableCell>
            <TableCell width={250} align="left">
              <Typography align="left" noWrap={true}>
                {historyEntry.firstname} {historyEntry.lastname}
              </Typography>
            </TableCell>
            <TableCell align="left">
              {historyEntry.updatedData
                .map( ( entry ) => fieldLookup[entry] || entry )
                .join( ", " )}
            </TableCell>
          </TableRow>
        ) )}
      </TableBody>
    </Table>
  </TableContainer>
);

const ProductInventoryDetails = ( {
  record,
}: {
  record: ProductInventoryItem;
} ) =>
{
  const buttonClasses = ButtonStyles();
  const dataProvider = useDataProvider();
  const [editHistory, setEditHistory] = useState( [] );
  useEffect( () =>
  {
    dataProvider
      .getEditHistory( "product-inventory-items", { id: record.id } )
      .then( ( { data }: { data: EditHistoryData[] } ) =>
      {
        setEditHistory( data );
      } );
  }, [] );
  const { permissions } = useAuth();
  useImageFieldStyles();
  const inventoryItemStatus =
    record.status === "in-event"
      ? `In Event ${
        record.currentAssignedEvent &&
          record.currentAssignedEvent.shopperHandle &&
          record.currentAssignedEvent.eventName
          ? `(${record.currentAssignedEvent.shopperHandle}, ${record.currentAssignedEvent.eventName})`
          : ""
      }`
      : record.status;

  return (
    <Grid container spacing={0}>
      <Grid item xs={6}>
        <Box paddingTop={2} paddingX={2}>
          <Typography variant="h5" gutterBottom>
            {`Product Inventory Item #${record.id}`}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={6}>
        {hasPermissions(
          [BooleanRoles.Fulfillment, BooleanRoles.Admin],
          permissions
        ) &&
          record.status === ProductInventoryItemStatus.Initiated && (
          <Box paddingTop={2} paddingX={2} textAlign="right">
            <ReturnButton
              id={record.id}
              successMessage={"Return accepted"}
              action={"return"}
              type={"accept"}
              label={"Accept Return"}
              includeLocation={true}
              includeReasons={true}
              additionalBodyParams={{
                action: "accept",
              }}
            />
            <ReturnButton
              id={record.id}
              successMessage={"Return closed"}
              action={"return"}
              type={"close"}
              label={"Close Return"}
              includeReasons={true}
              additionalBodyParams={{
                action: "close",
              }}
            />
          </Box>
        )}
        {hasPermissions(
          [BooleanRoles.Fulfillment, BooleanRoles.Admin],
          permissions
        ) && (
          <Box
            paddingTop={2}
            paddingX={2}
            textAlign="right"
            alignItems={"right"}
          >
            <Grid container>
              <Grid item xs={12}>
                <AddProductInventoryToEvent
                  productInventoryItemId={record.id}
                  itemStatus={record.status}
                  currency={record.currency}
                />
              </Grid>
              <Grid item xs={12}>
                <RemoveProductInventory
                  productInventoryItemId={record.id}
                  itemStatus={record.status}
                />
              </Grid>
              <Grid item xs={12}>
                <RemoveProductInventoryFomEvent
                  productInventoryItemId={record.id}
                  itemStatus={record.status}
                  eventName={record.currentAssignedEvent?.eventName}
                />
              </Grid>
              {record.manuallyCreated === true &&
                record.numOfTimesSold <= 0 && (
                <Grid item xs={12}>
                  <BarcodeButton
                    disabled={false}
                    productInventoryItemId={record.id}
                    styleClassName={buttonClasses.resetSortButton}
                    redirect={false}
                  />
                </Grid>
              )}
            </Grid>
          </Box>
        )}
      </Grid>
      <Grid item xs={9}>
        <ProductInventoryFieldContainer>
          <SimpleShowLayout record={record}>
            <ProductInventoryTextField
              label="Status"
              value={
                inventoryItemStatus === "released"
                  ? "removed"
                  : inventoryItemStatus
              }
            />
            {record?.seller?.handle && (
              <ProductInventoryTextField
                label="Shopper"
                value={record.seller.handle}
              />
            )}
            {record?.tagNames &&
              record.tagNames.filter( ( tag ) => tag && tag !== "" ).length > 0 && (
              <ProductInventoryTextField
                label={
                  record.tagNames.filter( ( tag ) => tag && tag !== "" )
                    .length === 1
                    ? "Tag"
                    : "Tags"
                }
                value={record.tagNames.join( ", " )}
              />
            )}
            <ProductInventoryTextField label="Brand" value={record.brandName} />
            <ProductInventoryTextField
              label="Product Name"
              value={record.productName}
            />
            <ProductInventoryTextField
              label="Product Description"
              value={record.productDescription}
            />
            {record.skuOption && (
              <ProductInventoryTextField
                label="Option"
                value={record.skuOption}
              />
            )}
            <ProductInventoryTextField
              label="Sale Price"
              value={record.formattedSalePrice}
            />
            <ProductInventoryTextField
              label="SKU Number"
              value={record.skuNumber}
            />
          </SimpleShowLayout>
        </ProductInventoryFieldContainer>
      </Grid>
      <Grid item xs={3}>
        <ProductInventoryFieldContainer>
          <SimpleShowLayout record={record}>
            <ProductInventoryTextField
              label="Date created"
              value={
                record.timestamps?.created
                  ? new Date( record.timestamps.created ).toLocaleDateString(
                    "en-CA",
                    {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    }
                  )
                  : ""
              }
            />
            <ProductInventoryTextField
              label="Age (days)"
              value={
                record?.timestamps?.accepted
                  ? getAge( new Date( record.timestamps.accepted ) )
                  : ""
              }
            />
            {record.location && (
              <ProductInventoryTextField
                label="Location"
                value={record.location}
              />
            )}
            <ProductInventoryTextField
              label="# of Times Sold"
              value={record.numOfTimesSold}
            />
            {record.numOfTimesAddedToEvent && (
              <ProductInventoryTextField
                label="# of Times Added to an Event"
                value={record.numOfTimesAddedToEvent}
              />
            )}
          </SimpleShowLayout>
        </ProductInventoryFieldContainer>
      </Grid>
      <Grid item xs={12}>
        <ProductInventoryFieldContainer>
          <SimpleShowLayout record={record}>
            <ProductInventoryTextField label="Media" value={""} />
            <ArrayField source="media" label="">
              <SingleFieldList linkType={false}>
                <MultiTypeFileField
                  source="storage.preview"
                  typeSource="storage.type"
                />
              </SingleFieldList>
            </ArrayField>
          </SimpleShowLayout>
        </ProductInventoryFieldContainer>
      </Grid>
      <Spacer />
      <Divider />
      <Spacer />
      <TabbedShowLayout>
        {editHistory.length > 0 && (
          <Tab label="Edit History">
            <PIIEditHistoryTable editHistory={editHistory} />
          </Tab>
        )}
      </TabbedShowLayout>
    </Grid>
  );
};

export interface ShowActionsProps {
  basePath?: string;
  className?: string;
  data?: Record;
  hasEdit?: boolean;
  hasList?: boolean;
  resource?: string;
}

const ShowActions = ( {
  basePath,
  className,
  data,
  hasEdit,
  ...rest
}: ShowActionsProps & IShowProperties ) => (
  <TopToolbar className={className} {...rest}>
    {hasEdit && data?.status === "available" && (
      <EditButton basePath={basePath} record={data} />
    )}
  </TopToolbar>
);

export const ProductInventoryShow: React.FunctionComponent<IShowProperties> = (
  showProperties
) => (
  <Show
    {...showProperties}
    title="Product Inventory Item Details"
    actions={<ShowActions {...showProperties} />}
  >
    <FunctionField
      addLabel={false}
      render={( record: ProductInventoryItem ) => (
        <AuthContext.Provider
          value={{ permissions: showProperties.permissions }}
        >
          <ProductInventoryDetails record={record} />
        </AuthContext.Provider>
      )}
    />
  </Show>
);
