import * as React from "react";
import {
  BulkActionProps,
  DateField,
  FilterProps,
  ListProps,
  ListView,
  ReferenceManyFieldProps
} from "ra-ui-materialui";
import {
  ListContextProvider,
  Datagrid,
  TextField,
  SaveButton,
  Toolbar,
  useNotify,
  Pagination,
  useListController,
  useCreate,
  downloadCSV,
  ReferenceInput,
  SelectInput,
  Filter
} from "react-admin";
import { usePermissions } from "ra-core";
import {
  BooleanRoles,
  extractAuthClaims,
  hasPermissions
} from "../../ts/interfaces/role-interface";

export const GenerateReportToolbar: React.FunctionComponent<
  BulkActionProps &
    ListProps & {
      id: string;
      saving: boolean;
      allIds: string[];
      data: { [key: string]: { eventId: string } };
    }
> = ( properties ) =>
{
  const [isLoading, setLoading] = React.useState( false );

  const notify = useNotify();
  const { selectedIds } = properties;

  React.useEffect( () =>
  {
    if ( selectedIds.length === 0 )
    {
      setLoading( true );
    }
    else
    {
      setLoading( false );
    }
  }, [selectedIds.length] );

  const [create] = useCreate(
    "reports",
    {
      id: "",
      creation: { reportType: "multiEvent", eventIds: selectedIds },
    },
    {
      onSuccess: async ( response: { data: { id: string; csv: string } } ) =>
      {
        downloadCSV( response.data.csv, "report" );
        setLoading( false );
      },
      onFailure: ( error: Error ) =>
      {
        notify( error, "warning" );
        setLoading( false );
      },
    }
  );

  return (
    <Toolbar {...properties}>
      <SaveButton
        label="Generate Report"
        handleSubmitWithRedirect={() =>
        {
          create();
          setLoading( true );
        }}
        disabled={isLoading}
      />
    </Toolbar>
  );
};

export const ProductPagination = (): JSX.Element => (
  <Pagination rowsPerPageOptions={[50, 100, 250]} />
);

export const EventsList = (
  properties: ReferenceManyFieldProps & ListProps
): JSX.Element =>
{
  const {
    basePath,
    filter,
    page = 1,
    perPage,
    reference,
    resource,
  } = properties;

  const controllerProps = useListController( {
    basePath,
    filter,
    page,
    perPage,
    reference,
    resource,
  } );
  if ( !controllerProps.loading && controllerProps.loaded )
  {
    return (
      <ListContextProvider value={controllerProps}>
        <ListView
          {...properties}
          {...controllerProps}
          bulkActionButtons={<></>}
          exporter={false}
          pagination={<ProductPagination />}
        />
        <GenerateReportToolbar {...controllerProps} />
      </ListContextProvider>
    );
  }
  return <></>;
};

const MultiEventListFilters = ( props: Partial<FilterProps> ) =>
{
  const { permissions } = usePermissions();
  const roleClaims = extractAuthClaims( permissions );
  const hasFilterPermissions = hasPermissions( [BooleanRoles.Admin], roleClaims );

  if ( !hasFilterPermissions )
  {
    return null;
  }

  return (
    <Filter {...props}>
      <ReferenceInput
        label="Partner"
        name="partnership"
        source="partnership"
        reference="partnerships"
      >
        <SelectInput optionText="name" resettable />
      </ReferenceInput>
    </Filter>
  );
};

export const MultiEventReport = (
  props: ReferenceManyFieldProps & ListProps
): JSX.Element =>
{
  const { permissions } = usePermissions();
  const roleClaims = extractAuthClaims( permissions );
  const isAdmin = hasPermissions( [BooleanRoles.Admin], roleClaims );
  const updatedProperties = {
    reference: "events",
    resource: "events",
    basePath: "events",
    filter: { currentDate: new Date() },
  };

  return (
    <EventsList
      {...props}
      {...updatedProperties}
      reference="events"
      perPage={100}
      filters={<MultiEventListFilters />}
    >
      <Datagrid hasBulkActions={false}>
        <TextField source="id" />
        <TextField source="name" />
        <DateField source="startDate" />
        <DateField source="endDate" />
        {isAdmin && <TextField label="Shopper handle" source="handle" />}
        {isAdmin && <TextField source="partnership" />}
      </Datagrid>
    </EventsList>
  );
};
