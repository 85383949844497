import * as React from "react";

import {
  Checkbox,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Divider,
  Box,
  Button
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { useBarcode } from "../../contexts/barcode-context";
import { useCreate, useNotify, useUpdate } from "react-admin";
import {
  OrderDetails,
  PotentialBundleAddress
} from "src/ts/interfaces/potential-bundle-interface";
import { BundledOrder } from "src/ts/interfaces/bundled-order-interface";
import { Fulfillments, Order } from "src/ts/interfaces/order-interface";
import { ImageButton } from "./image-button";
import { PackingSlip } from "./packing-slip";
import { ShippingLabel } from "./shipping-label";
import { FulfillPickup } from "../pickup-orders/fulfill-pickup";
import { ConvertPickup } from "../pickup-orders/convert-pickup";
import { resetHistory } from "../../utils/reset-history";
import { Spacer } from "../generics/spacer";
import { TrueFalseIcon } from "../generics/true-false-icon";
import {
  BooleanRoles,
  extractAuthClaims,
  hasPermissions
} from "../../ts/interfaces/role-interface";
import { useAuth } from "../../contexts/auth-context";
import { PopupNotificationType } from "../popup-notification/popup-notification";
import { CustomModal } from "@shopthing-opn-shared/admin-dashboard";
import { AddFromAddress } from "./add-from-address";
import { FulfillmentType } from "../../ts/interfaces/shared";
import { timeout } from "../../utils/timeout";
import { pick } from "lodash";

const useStyles = makeStyles( () =>
  createStyles( {
    table: {
      // Add table styling as desired
    },
  } )
);
interface OrderMap {
  [x: string]: Order;
}
interface Response {
  data: BundledOrder;
}

interface IBundledOrdersTable {
  orderDetails: OrderDetails[];
  orderMap: OrderMap;
  bundleAddress: PotentialBundleAddress;
  bundleId: string;
  fulfilledBy: FulfillmentType;
  [x: string]: unknown;
}

export const BundleOrderTable = (
  properties: IBundledOrdersTable
): JSX.Element =>
{
  const { orderDetails, orderMap, bundleAddress, fulfilledBy } = properties;

  const makePackagingSlipOptional =
    fulfilledBy && fulfilledBy !== FulfillmentType.shopthing;
  const sortedOrderDetails = React.useMemo( () =>
  {
    const CAOrderDetails = orderDetails
      .filter(
        ( orderDetail ) => orderDetail.eventLocation?.toLowerCase() === "ca"
      )
      .sort( ( a, b ) => ( a.orderId > b.orderId ? 1 : -1 ) );
    const USOrderDetails = orderDetails
      .filter(
        ( orderDetail ) => orderDetail.eventLocation?.toLowerCase() === "us"
      )
      .sort( ( a, b ) => ( a.orderId > b.orderId ? 1 : -1 ) );
    return [...CAOrderDetails, ...USOrderDetails];
  }, [orderDetails] );

  const {
    orderId,
    fulfillmentId,
    bundledOrderId,
    setBarcode,
    orderState,
    orderDispatch,
    fulfillmentState,
    fulfillmentDispatch,
    displayLabel,
    toggleLabel,
    setNotification,
  } = useBarcode();
  const [bundledOrder, setBundledOrder] = React.useState( {} as BundledOrder );
  const [isShipping, setShipping] = React.useState( false );
  const [showLabel, setShowLabel] = React.useState( false );
  const [openAddFromAddressModal, setOpenAddFromAddressModal] =
    React.useState( false );
  const classes = useStyles();
  const notify = useNotify();

  const createAddress = { ...bundleAddress };
  delete createAddress.oneLiner;

  const creation = {
    fulfillmentService: "ShopThing",
    orderIds: orderState.checkedIds,
    address: createAddress,
    customerNotes: "Thank you for shopping with ShopThing",
  };
  const [create] = useCreate(
    "bundled-orders",
    {
      id: "",
      creation,
    },
    {
      onSuccess: async ( response: Response ) =>
      {
        if ( makePackagingSlipOptional )
        {
          setBundledOrder( response.data );
          setShipping( true );
        }
        else
        {
          await timeout( 25 );
          setBundledOrder( response.data );
          await timeout( 25 );
          setShowLabel( true );
          toggleLabel( true );
        }
      },
      onFailure: ( error: Error ) =>
      {
        if (
          error.message.startsWith(
            "In order to create a shipping label, we need your shipping address."
          )
        )
        {
          setOpenAddFromAddressModal( true );
        }
        else
        {
          setNotification( {
            message: error.message,
            type: PopupNotificationType.error,
          } );
        }
      },
    }
  );

  const validateFulfillmentCount = () =>
  {
    Object.keys( fulfillmentState ).forEach( ( orderId: string ) =>
    {
      const order: Order = orderMap[orderId];
      const isConfirmed = order.status === "confirmed";
      const orderHasFulfillment =
        fulfillmentState &&
        Object.prototype.hasOwnProperty.call( fulfillmentState, orderId );
      const fulfillmentsScanned =
        orderHasFulfillment &&
        fulfillmentState[order.id].checkedIds.length ===
          order.cart.items[0].quantity;
      if (
        fulfillmentsScanned &&
        !orderState.checkedIds.includes( orderId ) &&
        !isConfirmed
      )
      {
        orderDispatch( { id: orderId } );
      }
    } );
  };
  const PackagingSlipHandler = async () =>
  {
    if ( makePackagingSlipOptional )
    {
      toggleLabel( true );
      setShowLabel( true );
    }
    else
    {
      if ( orderState.checkedIds.length > 0 )
      {
        await create();
      }
    }
  };

  const ShipSelectedHandler = async () =>
  {
    if ( makePackagingSlipOptional )
    {
      if ( orderState.checkedIds.length > 0 )
      {
        await create();
      }
    }
    else
    {
      setShipping( true );
    }
  };

  let confirmedOrderId = "";
  let confirmedFulfillmentId = "";

  confirmedOrderId = orderId;
  confirmedFulfillmentId = fulfillmentId;

  const [update] = useUpdate(
    "orders-update-fulfillment",
    `${confirmedOrderId}-${confirmedFulfillmentId}`,
    {
      status: "received",
    },
    {},
    {
      onSuccess: () =>
      {
        history.replaceState(
          {
            successMessage: `Fulfillment ${confirmedFulfillmentId} marked as Received`,
          },
          "",
          window.location.href
        );
        history.go();
      },
    }
  );

  React.useEffect( () =>
  {
    setBundledOrder( {} as BundledOrder );
    if (
      orderId?.length === 0 &&
      fulfillmentId?.length === 0 &&
      history.state?.orderId
    )
    {
      setBarcode(
        history.state.orderId,
        history.state.fulfillmentId,
        bundledOrderId
      );
      const includes =
        fulfillmentState &&
        !fulfillmentState[history.state.orderId]?.checkedIds?.includes(
          history.state.fulfillmentId
        );

      if ( !includes )
      {
        fulfillmentDispatch( {
          orderId: history.state.orderId,
          fulfillmentId: `${history.state.fulfillmentId}`,
          bundledOrderId,
        } );
      }
    }

    try
    {
      const { successMessage } = history.state;
      if ( successMessage )
      {
        notify( successMessage, "success" );
        resetHistory();
      }

      const includes =
        fulfillmentState &&
        fulfillmentState[orderId]?.checkedIds?.includes( fulfillmentId );

      if (
        orderMap[orderId]?.fulfillmentIds.includes( fulfillmentId ) &&
        !includes
      )
      {
        //incoming fulfillment present

        if (
          orderMap[orderId]?.fulfillments[fulfillmentId].status === "created"
        )
        {
          //eligible to be marked received
          //trigger call to update
          confirmedOrderId = orderId;
          confirmedFulfillmentId = fulfillmentId;
          update();
        }
      }
      validateFulfillmentCount();
    }
    catch ( error )
    {
      //no visible error because this will run on every load of this view
    }

    const partialScan =
      Object.keys( orderMap ).filter(
        ( orderId ) => fulfillmentState[orderId]?.checkedIds.length > 0
      ).length > 0;
    if (
      !history?.state?.orderId &&
      !history?.state?.fulfillmentId &&
      !partialScan
    )
    {
      orderDispatch( { reset: true } );
    }
  }, [fulfillmentState] );

  const resetState = () =>
  {
    resetHistory();
    fulfillmentDispatch( { reset: true } );
    orderDispatch( { reset: true } );
    setShipping( false );
    setBundledOrder( {} as BundledOrder );
    toggleLabel( false );
  };

  const generatePackingSlip = (
    orderMap: OrderMap,
    bundleAddress: PotentialBundleAddress
  ) => (
    <PackingSlip
      bundleAddress={bundleAddress}
      orderMap={pick( orderMap, orderState.checkedIds )}
    />
  );

  const handleAddFromAddressModalClose = () =>
  {
    setOpenAddFromAddressModal( false );
  };

  const { permissions } = useAuth();
  const roleClaims = extractAuthClaims( permissions );

  return (
    <>
      <Spacer />
      {openAddFromAddressModal && (
        <CustomModal
          open={openAddFromAddressModal}
          onClose={handleAddFromAddressModalClose}
        >
          <AddFromAddress setOpen={setOpenAddFromAddressModal} />
        </CustomModal>
      )}
      {isShipping && (
        <ShippingLabel bundledOrder={bundledOrder} orderMap={orderMap} />
      )}
      {!isShipping && (
        <>
          <Box>
            <Button
              onClick={ShipSelectedHandler}
              disabled={
                makePackagingSlipOptional
                  ? orderState.checkedIds.length < 1
                  : Object.keys( bundledOrder ).length < 1
              }
            >
              Ship Selected Orders
            </Button>{" "}
            <Button
              disabled={orderState.checkedIds.length < 1}
              onClick={PackagingSlipHandler}
            >
              Print Packaging Slip
            </Button>
            {hasPermissions(
              [BooleanRoles.Admin, BooleanRoles.Partner],
              roleClaims
            ) && (
              <Button
                onClick={() =>
                {
                  resetState();
                }}
              >
                Reset Fulfillment Tracker
              </Button>
            )}
          </Box>
          <Spacer />
          <Divider />
          <TableContainer>
            {sortedOrderDetails.map( ( orderDetail, orderDetailIndex ) =>
            {
              const orderId = orderDetail.orderId;
              const order: Order = orderMap[orderId];
              if ( !order )
              {
                return (
                  <div
                    key={`${bundledOrderId}-${orderId}-${orderDetailIndex}-no-order`}
                  ></div>
                );
              }

              const isConfirmed = order.status === "confirmed";
              const orderHasFulfillment =
                fulfillmentState &&
                Object.prototype.hasOwnProperty.call( fulfillmentState, orderId );
              const shippingAddress = order?.logistics?.details?.address;
              const payment = order.customer.billing.payment;
              const billingAddress = order.customer.billing.address;
              return (
                <div key={`${bundledOrderId}-${orderId}-${orderDetailIndex}`}>
                  <Spacer />
                  <Typography variant="h6">
                    Order {orderDetail.orderId}
                  </Typography>
                  <Table aria-label="simple table" className={classes.table}>
                    <TableHead>
                      <TableRow className={classes.table}>
                        <TableCell className={classes.table} align="left">
                          <Typography variant={"caption"}>Status</Typography>
                        </TableCell>
                        <TableCell className={classes.table} align="left">
                          <Typography variant={"caption"}>Seller</Typography>
                        </TableCell>
                        <TableCell className={classes.table} align="left">
                          <Typography variant={"caption"}>
                            Event Name
                          </Typography>
                        </TableCell>
                        <TableCell className={classes.table} align="left">
                          <Typography variant={"caption"}>Location</Typography>
                        </TableCell>
                        <TableCell className={classes.table} align="left">
                          <Typography variant={"caption"}>Brand</Typography>
                        </TableCell>
                        <TableCell className={classes.table} align="left">
                          <Typography variant={"caption"}>Product</Typography>
                        </TableCell>
                        {order?.cart.items?.[0].externalProductId && (
                          <TableCell className={classes.table} align="left">
                            <Typography variant={"caption"}>
                              Product Id
                            </Typography>
                          </TableCell>
                        )}
                        <TableCell className={classes.table} align="left">
                          <Typography variant={"caption"}>Option</Typography>
                        </TableCell>
                        <TableCell className={classes.table} align="left">
                          <Typography variant={"caption"}>SKU</Typography>
                        </TableCell>
                        <TableCell className={classes.table} align="center">
                          <Typography variant={"caption"}>Quantity</Typography>
                        </TableCell>
                        {order?.logistics.delivery && (
                          <TableCell className={classes.table} align="center">
                            <Typography variant={"caption"}>
                              Billing Match
                            </Typography>
                          </TableCell>
                        )}
                        {order?.billingAndShippingMatch !== true && (
                          <TableCell className={classes.table} align="center">
                            <Typography variant={"caption"}>
                              Passed Fulfillment Review
                            </Typography>
                          </TableCell>
                        )}
                        <TableCell className={classes.table} align="center">
                          <Typography variant={"caption"}>VIP</Typography>
                        </TableCell>
                        <TableCell className={classes.table} align="center">
                          <Typography variant={"caption"}>Type</Typography>
                        </TableCell>
                        {order.specifications?.packageProtectionSettings
                          ?.isApplied && (
                          <TableCell className={classes.table} align="center">
                            <Typography variant={"caption"}>
                              Package Protection
                            </Typography>
                          </TableCell>
                        )}
                        <TableCell className={classes.table} align="center">
                          <ImageButton
                            imageUrl={
                              order.cart.items[0].media[0].productPoster
                            }
                          />
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell className={classes.table} align="left">
                          <Typography align="left">{order.status}</Typography>
                        </TableCell>
                        <TableCell className={classes.table} align="left">
                          <Typography align="left">
                            {orderDetail.sellerHandle}
                          </Typography>
                        </TableCell>
                        <TableCell className={classes.table} align="left">
                          <Typography align="left">
                            {orderDetail.eventName}
                          </Typography>
                        </TableCell>
                        <TableCell className={classes.table} align="left">
                          <Typography align="left">
                            {orderDetail.eventLocation.toUpperCase()}
                          </Typography>
                        </TableCell>
                        <TableCell className={classes.table} align="left">
                          <Typography align="left">
                            {orderDetail.brandName}
                          </Typography>
                        </TableCell>
                        <TableCell className={classes.table} align="left">
                          <Typography align="left">
                            {orderDetail.productName}
                          </Typography>
                        </TableCell>
                        {order.cart.items?.[0].externalProductId && (
                          <TableCell className={classes.table} align="left">
                            <Typography align="left">
                              {order.cart.items[0].externalProductId}
                            </Typography>
                          </TableCell>
                        )}
                        <TableCell className={classes.table} align="left">
                          <Typography align="left">
                            {orderDetail.productDescription}
                          </Typography>
                        </TableCell>
                        <TableCell className={classes.table} align="left">
                          <Typography align="left">
                            {order.cart.items[0].skuId}
                          </Typography>
                        </TableCell>
                        <TableCell className={classes.table} align="center">
                          <Typography align="center">
                            {order.cart.items[0].quantity}
                          </Typography>
                        </TableCell>
                        {order.logistics.delivery && (
                          <TableCell className={classes.table} align="center">
                            <TrueFalseIcon
                              value={order.billingAndShippingMatch}
                            />
                          </TableCell>
                        )}
                        {order.billingAndShippingMatch !== true && (
                          <TableCell className={classes.table} align="center">
                            <TrueFalseIcon
                              value={order.customer.passedFulfillmentReview}
                            />
                          </TableCell>
                        )}
                        <TableCell className={classes.table} align="center">
                          <Typography align="center">
                            {typeof order.customer.vip === "boolean"
                              ? ( order.customer.vip === true
                                ? "Yes"
                                : "No" )
                              : "N/A"}
                          </Typography>
                        </TableCell>
                        <TableCell className={classes.table} align="center">
                          <Typography align="center">
                            {order.logistics.delivery
                              ? order.logistics.type +
                                " - " +
                                order.logistics.option
                              : order.logistics.type}
                          </Typography>
                        </TableCell>
                        {order.specifications?.packageProtectionSettings
                          ?.isApplied && (
                          <TableCell className={classes.table} align="center">
                            <Typography align="center">Yes</Typography>
                          </TableCell>
                        )}
                        <TableCell className={classes.table} align="center">
                          {order.logistics.delivery && (
                            <Checkbox
                              id={`${orderDetail.orderId}`}
                              disabled={
                                orderHasFulfillment ||
                                ( !order.billingAndShippingMatch &&
                                  !order.customer.passedFulfillmentReview ) ||
                                isConfirmed ||
                                ( order.latestDisputeStatus &&
                                  ( typeof order.disputeResolved !== "boolean" ||
                                    order.disputeResolved === false ) ) ||
                                order.latestDisputeStatus === "lost"
                              }
                              onClick={() =>
                              {
                                setBarcode( "", "", "" );
                                orderDispatch( { id: orderDetail.orderId } );
                                setBundledOrder( {} as BundledOrder );
                              }}
                              checked={
                                ( orderState &&
                                  orderState.checkedIds.includes(
                                    orderDetail.orderId
                                  ) &&
                                  !orderHasFulfillment ) ||
                                ( orderHasFulfillment &&
                                  fulfillmentState &&
                                  fulfillmentState[order.id]?.checkedIds
                                    .length === order.cart.items[0].quantity ) ||
                                false
                              }
                            />
                          )}
                          {!order.logistics.delivery && (
                            <>
                              <FulfillPickup orderId={orderDetail.orderId} />
                              <ConvertPickup
                                orderId={orderDetail.orderId}
                                logistics={{
                                  zone: order.logistics.zone,
                                  details: {
                                    firstname: bundleAddress.firstname,
                                    lastname: bundleAddress.lastname,
                                    phone: bundleAddress.phone,
                                    company: bundleAddress.company,
                                    address: bundleAddress,
                                  },
                                }}
                              />
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  <Spacer />
                  <Spacer />
                  {shippingAddress && (
                    <>
                      <Typography>Shipping Details</Typography>
                      <Table
                        aria-label="simple table"
                        className={classes.table}
                      >
                        <TableHead>
                          <TableRow className={classes.table}>
                            <TableCell className={classes.table} align="left">
                              <Typography variant={"caption"}>
                                Line 1
                              </Typography>
                            </TableCell>
                            <TableCell className={classes.table} align="left">
                              <Typography variant={"caption"}>
                                Line 2
                              </Typography>
                            </TableCell>
                            <TableCell className={classes.table} align="left">
                              <Typography variant={"caption"}>City</Typography>
                            </TableCell>
                            <TableCell className={classes.table} align="left">
                              <Typography variant={"caption"}>
                                State/Province
                              </Typography>
                            </TableCell>
                            <TableCell className={classes.table} align="left">
                              <Typography variant={"caption"}>
                                Country
                              </Typography>
                            </TableCell>
                            <TableCell className={classes.table} align="left">
                              <Typography variant={"caption"}>
                                ZIP/Postal Code
                              </Typography>
                            </TableCell>
                            <TableCell className={classes.table} align="left">
                              <Typography variant={"caption"}>Phone</Typography>
                            </TableCell>
                            <TableCell className={classes.table} align="left">
                              <Typography variant={"caption"}>
                                First Name
                              </Typography>
                            </TableCell>
                            <TableCell className={classes.table} align="left">
                              <Typography variant={"caption"}>
                                Last Name
                              </Typography>
                            </TableCell>
                            <TableCell className={classes.table} align="left">
                              <Typography variant={"caption"}>
                                Company
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell className={classes.table} align="left">
                              {shippingAddress.line1}
                            </TableCell>
                            <TableCell className={classes.table} align="left">
                              {shippingAddress.line2}
                            </TableCell>
                            <TableCell className={classes.table} align="left">
                              {shippingAddress.city}
                            </TableCell>
                            <TableCell className={classes.table} align="left">
                              {shippingAddress.state}
                            </TableCell>
                            <TableCell className={classes.table} align="left">
                              {shippingAddress.country}
                            </TableCell>
                            <TableCell className={classes.table} align="left">
                              {shippingAddress.postalCode}
                            </TableCell>
                            <TableCell className={classes.table} align="left">
                              {shippingAddress.phone}
                            </TableCell>
                            <TableCell className={classes.table} align="left">
                              {shippingAddress.firstname}
                            </TableCell>
                            <TableCell className={classes.table} align="left">
                              {shippingAddress.lastname}
                            </TableCell>
                            <TableCell className={classes.table} align="left">
                              {shippingAddress.company}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </>
                  )}
                  <Spacer />
                  <Spacer />
                  <>
                    <Typography>Billing Details</Typography>
                    <Table aria-label="simple table" className={classes.table}>
                      <TableHead>
                        <TableRow className={classes.table}>
                          <TableCell className={classes.table} align="left">
                            <Typography variant={"caption"}>
                              Payment Brand
                            </Typography>
                          </TableCell>
                          <TableCell className={classes.table} align="left">
                            <Typography variant={"caption"}>
                              Card Source Country
                            </Typography>
                          </TableCell>
                          <TableCell className={classes.table} align="left">
                            <Typography variant={"caption"}>Line 1</Typography>
                          </TableCell>
                          <TableCell className={classes.table} align="left">
                            <Typography variant={"caption"}>Line 2</Typography>
                          </TableCell>
                          <TableCell className={classes.table} align="left">
                            <Typography variant={"caption"}>City</Typography>
                          </TableCell>
                          <TableCell className={classes.table} align="left">
                            <Typography variant={"caption"}>
                              State/Province
                            </Typography>
                          </TableCell>
                          <TableCell className={classes.table} align="left">
                            <Typography variant={"caption"}>Country</Typography>
                          </TableCell>
                          <TableCell className={classes.table} align="left">
                            <Typography variant={"caption"}>
                              ZIP/Postal Code
                            </Typography>
                          </TableCell>
                          <TableCell className={classes.table} align="left">
                            <Typography variant={"caption"}>Name</Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell className={classes.table} align="left">
                            {payment.brand}{" "}
                            {payment.wallet?.name
                              ? `(${payment.wallet.name})`
                              : ""}
                          </TableCell>
                          <TableCell className={classes.table} align="left">
                            {payment.cardSourceCountry}
                          </TableCell>
                          <TableCell className={classes.table} align="left">
                            {billingAddress.line1}
                          </TableCell>
                          <TableCell className={classes.table} align="left">
                            {billingAddress.line2}
                          </TableCell>
                          <TableCell className={classes.table} align="left">
                            {billingAddress.city}
                          </TableCell>
                          <TableCell className={classes.table} align="left">
                            {billingAddress.state}
                          </TableCell>
                          <TableCell className={classes.table} align="left">
                            {billingAddress.country}
                          </TableCell>
                          <TableCell className={classes.table} align="left">
                            {billingAddress.postalCode}
                          </TableCell>
                          <TableCell className={classes.table} align="left">
                            {order.customer.billing.name}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </>
                  <Spacer />
                  <Spacer />
                  {order.fulfillments && (
                    <Typography>
                      Fulfillment details -{" "}
                      {fulfillmentState &&
                      Object.prototype.hasOwnProperty.call(
                        fulfillmentState,
                        order.id
                      )
                        ? fulfillmentState[order.id].checkedIds.length
                        : 0}{" "}
                      of {order.cart.items[0].quantity} Quantities Scanned
                    </Typography>
                  )}

                  {order.fulfillments &&
                    Object.values( order.fulfillments ).map(
                      ( fulfillment: Fulfillments, fulfillmentIndex ) => (
                        <div
                          key={`${bundledOrderId}-${orderId}-${orderDetailIndex}-${fulfillmentIndex}`}
                        >
                          <Table
                            aria-label="simple table"
                            className={classes.table}
                          >
                            <TableHead>
                              <TableRow className={classes.table}>
                                <TableCell
                                  className={classes.table}
                                  align="left"
                                >
                                  <Typography variant={"caption"}>
                                    Fulfillment ID
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  className={classes.table}
                                  align="left"
                                >
                                  <Typography variant={"caption"}>
                                    Sku ID
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  className={classes.table}
                                  align="left"
                                >
                                  <Typography variant={"caption"}>
                                    Quantity
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  className={classes.table}
                                  align="left"
                                >
                                  <Typography variant={"caption"}>
                                    Location
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell
                                  className={classes.table}
                                  align="left"
                                >
                                  <Typography align="left">
                                    {fulfillment.externalId}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  className={classes.table}
                                  align="left"
                                >
                                  <Typography align="left">
                                    {fulfillment.items[0].skuId}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  className={classes.table}
                                  align="left"
                                >
                                  <Typography align="left">
                                    {fulfillment.items[0].quantity}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  className={classes.table}
                                  align="left"
                                >
                                  <Typography align="left">
                                    {fulfillment.location || ""}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  className={classes.table}
                                  align="left"
                                >
                                  {order.logistics.delivery && (
                                    <Checkbox
                                      id={`${fulfillment.externalId}`}
                                      checked={
                                        ( fulfillmentState &&
                                          Object.prototype.hasOwnProperty.call(
                                            fulfillmentState,
                                            order.id
                                          ) &&
                                          fulfillmentState[
                                            order.id
                                          ]?.checkedIds.includes(
                                            `${fulfillment.externalId}`
                                          ) ) ||
                                        false
                                      }
                                      disabled={true}
                                    />
                                  )}
                                </TableCell>
                                <TableCell
                                  className={classes.table}
                                  align="left"
                                >
                                  <Typography align="left"></Typography>
                                </TableCell>
                                <TableCell
                                  className={classes.table}
                                  align="center"
                                ></TableCell>
                                <TableCell
                                  className={classes.table}
                                  align="center"
                                ></TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </div>
                      )
                    )}
                  <Divider />
                </div>
              );
            } )}
          </TableContainer>
          {showLabel &&
            displayLabel &&
            bundleAddress &&
            orderMap &&
            generatePackingSlip( orderMap, bundleAddress )}
        </>
      )}
    </>
  );
};
