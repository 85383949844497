import * as React from "react";
import {
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Divider
} from "@material-ui/core";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import { OrderDetails } from "src/ts/interfaces/potential-bundle-interface";

const useStyles = makeStyles( () =>
  createStyles( {
    table: {
      border: 0,
    },
  } )
);

interface IFulfillmentTable {
  orderDetails: OrderDetails[];
}

export const BundleDetailTable = ( {
  orderDetails,
}: IFulfillmentTable ): JSX.Element =>
{
  const classes = useStyles();
  return (
    <>
      <Divider />
      <TableContainer>
        <Table aria-label="simple table" className={classes.table}>
          <TableHead>
            <TableRow className={classes.table}>
              <TableCell className={classes.table} align="left">
                <Typography variant={"caption"}>Id</Typography>
              </TableCell>
              <TableCell className={classes.table} align="left">
                <Typography variant={"caption"}>Event</Typography>
              </TableCell>
              <TableCell className={classes.table} align="left">
                <Typography variant={"caption"}>Brand</Typography>
              </TableCell>
              <TableCell className={classes.table} align="left">
                <Typography variant={"caption"}>Product</Typography>
              </TableCell>
              <TableCell className={classes.table} align="left">
                <Typography variant={"caption"}>VIP</Typography>
              </TableCell>
              <TableCell className={classes.table} align="left">
                <Typography variant={"caption"}>Option</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderDetails.map( ( orderDetails ) => (
              <>
                <TableRow>
                  {" "}
                  <TableCell className={classes.table} align="left">
                    <Typography>{orderDetails.orderId}</Typography>
                  </TableCell>
                  <TableCell className={classes.table} align="left">
                    <Typography>{orderDetails.eventName}</Typography>
                  </TableCell>
                  <TableCell className={classes.table} align="left">
                    <Typography>{orderDetails.brandName}</Typography>
                  </TableCell>
                  <TableCell className={classes.table} align="left">
                    <Typography>{orderDetails.productName}</Typography>
                  </TableCell>
                  <TableCell className={classes.table} align="left">
                    <Typography>
                      {typeof orderDetails.vip === "boolean"
                        ? ( orderDetails.vip === true
                          ? "Yes"
                          : "No" )
                        : "N/A"}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.table} align="left">
                    <Typography>{orderDetails.productDescription}</Typography>
                  </TableCell>
                </TableRow>
              </>
            ) )}
          </TableBody>
        </Table>
      </TableContainer>
      <Divider />
    </>
  );
};
