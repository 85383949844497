import React from "react";
import { Order } from "src/ts/interfaces/order-interface";
import NewWindow from "react-new-window";
import QRCode from "qrcode.react";
import { useBarcode } from "../../contexts/barcode-context";
import {
  OrderMap,
  PotentialBundleAddress
} from "../../ts/interfaces/potential-bundle-interface";
import { isEmpty } from "lodash";

interface IPackingSlipProperties {
  bundleAddress: PotentialBundleAddress;
  orderMap: OrderMap;
  isDelivery?: boolean;
}
export const PackingSlip = (
  properties: IPackingSlipProperties
): JSX.Element =>
{
  const { displayLabel, toggleLabel } = useBarcode();
  const { bundleAddress, orderMap, isDelivery = true } = properties;

  if ( isEmpty( bundleAddress ) || isEmpty( orderMap ) )
  {
    return null;
  }

  const closePackingSlip = () =>
  {
    toggleLabel( false );
  };

  return (
    displayLabel && (
      <NewWindow
        features={{ width: 700, height: 800 }}
        copyStyles={true}
        onUnload={closePackingSlip}
      >
        <div className="container">
          <div className="row">
            <div className="col-8 valign-center">
              <br />
              <h4>ShopThing Packaging Slip</h4>
            </div>
            <div className="col-4">
              <img
                src="https://static1.squarespace.com/static/5cfdb3c88438ae000157a60e/t/5d015726350842000160d98d/1603719698581/?format=1500w"
                height="100px"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-8 valign-center">
              <h4> {isDelivery ? "Ship To:" : "Hand off to:"}</h4>
              <br />
              {bundleAddress.firstname} {bundleAddress.lastname}
              <br />
              {bundleAddress.phone ? `${bundleAddress.phone}` : ""}
              <br />
              {isDelivery ? (
                <>
                  {bundleAddress.line1}{" "}
                  {bundleAddress.line2 ? bundleAddress.line2 : ""}{" "}
                  {bundleAddress.city ? `${bundleAddress.city},` : ""}{" "}
                  {bundleAddress.state || ""}
                  <br />
                  {bundleAddress.postalCode} {bundleAddress.country}
                  <br />
                </>
              ) : (
                ""
              )}
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col">
              <h4>Items Purchased:</h4>
              <br />
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col" colSpan={2}>
                      Item
                    </th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Barcode</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.values( orderMap ).map( ( order: Order ) =>
                  {
                    if ( !order )
                    {
                      return <></>;
                    }
                    const item = order.cart.items[0];
                    const image = item.media[0].productPoster;
                    const brand = item.brandName;
                    const productName = item.productName;
                    const productDescription = item.productDescription || null;
                    const size = item.description;
                    const skuid = item.skuId;
                    const quantity = 1;
                    const fulfillmentIds =
                      order.fulfillments &&
                      Object.values( order.fulfillments ).length > 0
                        ? Object.values( order.fulfillments ).map(
                          ( fulfillment ) => fulfillment.externalId
                        )
                        : [""];
                    return (
                      <>
                        {fulfillmentIds.map( ( fulfillmentId ) => (
                          <>
                            <tr key={order.id}>
                              <th scope="row">1</th>
                              <td>
                                <img
                                  style={{ objectFit: "cover" }}
                                  width={50}
                                  height={50}
                                  src={`${image}`}
                                />
                              </td>
                              <td>
                                <strong>
                                  {brand} {productName}
                                </strong>
                                {productDescription && <br />}
                                {productDescription}
                                <br />
                                {size} {skuid}
                                {item.productTags && (
                                  <div
                                    style={{
                                      display: "flex",
                                      width: "250px",
                                      flexWrap: "wrap",
                                      marginTop: "5px",
                                    }}
                                  >
                                    {item.productTags.map( ( tag, index ) => (
                                      <span
                                        key={index}
                                        style={{
                                          fontSize: "12px",
                                          fontWeight: 600,
                                          backgroundColor: "#333333",
                                          padding: "5px",
                                          color: "#FFFFFF",
                                          marginRight: "5px",
                                          marginBottom: "5px",
                                          borderRadius: "4px",
                                        }}
                                      >
                                        {tag}
                                      </span>
                                    ) )}
                                  </div>
                                )}
                              </td>
                              <td>{quantity}</td>
                              <td>
                                <QRCode
                                  size={90}
                                  value={JSON.stringify( {
                                    fulfillmentId,
                                    orderId: order.id,
                                  } )}
                                />
                              </td>
                            </tr>
                            {item.isFinalSale && (
                              <tr>
                                <td
                                  style={{
                                    padding: "0",
                                    paddingBottom: "12px",
                                    border: "none",
                                    color: "#E85469",
                                  }}
                                  colSpan={5}
                                >
                                  <div>
                                    <strong>FINAL SALE</strong> This Item cannot
                                    be returned or exchanged. Includes VIP.
                                  </div>
                                </td>
                              </tr>
                            )}
                          </>
                        ) )}
                      </>
                    );
                  } )}
                </tbody>
              </table>
            </div>
          </div>
          <>
            Email us at{" "}
            <a href="mailto: hello@shopthing.com">hello@shopthing.com</a> for
            any enquiries about your order.
          </>
        </div>
      </NewWindow>
    )
  );
};
