import * as React from "react";

import {
  Datagrid,
  List,
  TabbedShowLayout,
  Tab,
  useCreate,
  downloadCSV,
  useNotify,
  TabbedShowLayoutTabs
} from "react-admin";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { makeStyles, createStyles, Select, MenuItem } from "@material-ui/core";
import { PaginatedSelect, Option } from "../generics/paginated-select";
import { paginatedSelectInputConfig } from "../component-config";
import { Spacer } from "../generics/spacer";
import CircularProgress from "@material-ui/core/CircularProgress";
import { authHeader } from "../../utils/auth-header";

const useStyles = makeStyles( () =>
  createStyles( {
    topBox: { marginTop: "20px" },
    flexBox: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-evenly",
      minWidth: "200px",
      maxWidth: "500px",
      alignContent: "center",
    },
  } )
);

const PER_PAGE = 1000;

const ReportGenerator = () =>
{
  const classes = useStyles();
  const notify = useNotify();

  const [seller, setSeller] = React.useState( {} as Option );

  const [event, setEvent] = React.useState( {} as Option );

  const [coupon, setCoupon] = React.useState( {} as Option );

  const [startDate, setStartDate] = React.useState( "" );

  const [endDate, setEndDate] = React.useState( "" );

  const [reportType, setReportType] = React.useState( "" );

  const [currency, setCurrency] = React.useState( "" );

  const [ordersPages, setOrderPages] = React.useState( [] );
  const [chosenPage, setChosenPage] = React.useState<number | " ">( " " );
  const [isLoading, setIsLoading] = React.useState( false );

  const clearSelection = () =>
  {
    setSeller( {} as Option );
    setEvent( {} as Option );
    setCoupon( {} as Option );
    setStartDate( "" );
    setEndDate( "" );
    setReportType( "" );
    setChosenPage( " " );
  };

  React.useEffect( () =>
  {
    const url = window.location.href;

    const urlArr = url.split( "/" );
    const match = urlArr[urlArr.length - 1];

    if ( match === "8" )
    {
      setReportType( "coupons" );
    }
    if ( match === "9" )
    {
      setReportType( "orders" );
    }
  }, [reportType, startDate, endDate] );

  const eventReportDisabled = !seller.id || !event.id;
  const shopperReportDisabled = !seller.id || !startDate || !endDate;
  const couponUsageReportDisabled = !coupon.id;
  const reconciliationReportDisabled = !seller.id || !reportType;
  const brandsReportDisabled = !startDate;
  const operationsActivityReportDisabled = !currency;
  const microsoftReportDisabled = !startDate || !endDate;
  const couponsReportDisabled = !startDate || !endDate;
  const ordersReportDisabled = !( startDate && endDate && chosenPage );

  const sendReq = React.useCallback( async () =>
  {
    setIsLoading( true );
    const options = await authHeader();
    fetch( `${process.env.API_ROOT}/api/admin-v2/orders/count`, {
      method: "POST",
      headers: options,
      body: JSON.stringify( { startDate, endDate } ),
    } )
      .then( ( data ) => data.json() )
      .then( ( data ) =>
      {
        if ( data.status !== "complete" )
        {
          notify( data.message, "warning" );
          setIsLoading( false );
          clearSelection();
        }
        else
        {
          const pages = Math.floor( data.data / PER_PAGE );

          const lastPage = data.data % PER_PAGE;

          const arr = new Array( pages + 1 ).fill( " " );

          const newArr = arr.map( ( _, index ) => ( {
            value:
              index === arr.length - 1
                ? index * PER_PAGE + lastPage
                : ( index + 1 ) * PER_PAGE,
            text:
              index === arr.length - 1
                ? `${index * PER_PAGE} - ${index * PER_PAGE + lastPage}`
                : `${index * PER_PAGE} - ${( index + 1 ) * PER_PAGE}`,
          } ) );

          setOrderPages( newArr );
          setChosenPage( newArr[0].value );
          setIsLoading( false );
        }
      } );
  }, [startDate, endDate] );
  let creation;
  if ( reportType === "operationsActivity" )
  {
    creation = {
      reportType,
      currency,
    };
  }
  else if ( reportType === "couponUsage" )
  {
    creation = {
      couponId: coupon.id,
      reportType,
      startDate: startDate || undefined,
      endDate: endDate || undefined,
    };
  }
  else if ( reportType === "coupons" )
  {
    creation = {
      reportType,
      startDate: startDate || undefined,
      endDate: endDate || undefined,
    };
  }
  else if ( reportType === "orders" )
  {
    creation = {
      reportType,
      startDate: startDate || undefined,
      endDate: endDate || undefined,
      limit: PER_PAGE,
      ...( +chosenPage > PER_PAGE
        ? ( +chosenPage % PER_PAGE === 0
          ? { offset: +chosenPage - PER_PAGE }
          : { offset: +chosenPage - ( +chosenPage % PER_PAGE ) } )
        : {} ),
    };
  }
  else
  {
    creation = reconciliationReportDisabled
      ? ( shopperReportDisabled && !eventReportDisabled
        ? {
          eventId: event.id,
          reportType,
        }
        : {
          sellerId: seller.id,
          startDate,
          endDate,
          reportType,
        } )
      : {
        sellerId: seller.id,
        eventId: event.id,
        reportType,
      };
  }
  const [create] = useCreate(
    "reports",
    {
      id: "",
      creation,
    },
    {
      onSuccess: async ( response: { data: { id: string; csv: string } } ) =>
      {
        downloadCSV( response.data.csv, "report" );

        const urlArr = window.location.href.split( "/" );
        const match = urlArr[urlArr.length - 1];
        if ( match !== "9" )
        {
          clearSelection();
        }
      },
      onFailure: ( error: Error ) =>
      {
        notify( error, "warning" );
        const urlArr = window.location.href.split( "/" );
        const match = urlArr[urlArr.length - 1];
        if ( match !== "9" )
        {
          clearSelection();
        }
      },
    }
  );

  const [generateOtherReport] = useCreate(
    "generate-other-categories-report",
    {
      creation: { reportType: "other" },
    },
    {
      onSuccess: async ( response: { data: { id: string; csv: string } } ) =>
      {
        downloadCSV( response.data.csv, "report" );
        clearSelection();
      },
      onFailure: ( error: Error ) =>
      {
        notify( error, "warning" );
        clearSelection();
      },
    }
  );

  const [values, setValues] = React.useState( {
    currency: "",
  } );

  const handleSelectChange = (
    event: React.ChangeEvent<{ name: string; value: string }>
  ) =>
  {
    setCurrency( event.target.value );
    setReportType( "operationsActivity" );
    const { name, value } = event.target;
    setValues( { ...values, [name]: value } );
  };

  return (
    <TabbedShowLayout
      tabs={<TabbedShowLayoutTabs variant="scrollable" scrollButtons="auto" />}
    >
      <Tab label="Event Report" onClick={() => clearSelection()}>
        <Box textAlign="center" m={1} className={classes.topBox}>
          <Box className={classes.flexBox}>
            <Typography variant="h6">Select a Shopper</Typography>
            <PaginatedSelect
              label="Shopper"
              nameMappingKey="handle"
              onChange={(
                event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) =>
              {
                const eventValues = event.target.value.split( "-" );
                const id = eventValues[0];
                const name = eventValues[1];
                setSeller( { id, name } );
              }}
              selectedOption={seller}
              list={{
                resource: "users",
                nameMappingKey: "handle",
                perPage: paginatedSelectInputConfig.perPage,
                filter: { isSeller: true },
                sort: {
                  field: "sortOptions.handle",
                  order: "ASC",
                },
              }}
            />
          </Box>
          <Spacer />
          <Box className={classes.flexBox}>
            <Typography variant="h6">Select an Event</Typography>
            <PaginatedSelect
              key={seller.id}
              label="Event"
              nameMappingKey="name"
              selectedOption={event}
              disabled={!seller?.id}
              onChange={(
                event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) =>
              {
                const eventValues = event.target.value.split( "-" );
                const id = eventValues[0];
                const name = eventValues[1];
                setEvent( { id, name } );
              }}
              list={{
                resource: "events",
                nameMappingKey: "name",
                perPage: paginatedSelectInputConfig.perPage,
                filter: {
                  ...( seller.id && { userId: seller.id } ),
                },
              }}
            />
          </Box>
          <Spacer />
          <Box className={classes.flexBox}>
            <Button onClick={() => clearSelection()}>Clear Selection</Button>
            <Button onClick={() => create()} disabled={eventReportDisabled}>
              Generate Event Report
            </Button>
          </Box>
        </Box>
      </Tab>
      <Tab label="Shopper Report" onClick={() => clearSelection()}>
        <Box textAlign="center" m={1} className={classes.topBox}>
          <Box className={classes.flexBox}>
            <Typography variant="h6">Select a Shopper</Typography>
            <PaginatedSelect
              label="Shopper"
              nameMappingKey="handle"
              selectedOption={seller}
              onChange={(
                event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) =>
              {
                const eventValues = event.target.value.split( "-" );
                const id = eventValues[0];
                const name = eventValues[1];
                setSeller( { id, name } );
              }}
              list={{
                resource: "users",
                nameMappingKey: "handle",
                perPage: paginatedSelectInputConfig.perPage,
                filter: { isSeller: true },
                sort: {
                  field: "sortOptions.handle",
                  order: "ASC",
                },
              }}
            />
          </Box>
          <Spacer />
          <Box className={classes.flexBox}>
            <TextField
              id="datetime-local"
              label="Select Start Date for Report"
              type="datetime-local"
              defaultValue=""
              value={startDate}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(
                event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) =>
              {
                setStartDate( event.target.value );
                setEvent( {} as Option );
              }}
            />
          </Box>
          <Spacer />
          <Box className={classes.flexBox}>
            <TextField
              id="datetime-local"
              label="Select End Date for Report"
              type="datetime-local"
              defaultValue=""
              value={endDate}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(
                event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) =>
              {
                setEndDate( event.target.value );
                setEvent( {} as Option );
              }}
            />
          </Box>
          <Spacer />
          <Box className={classes.flexBox}>
            <Button onClick={() => clearSelection()}>Clear Selection</Button>
            <Button onClick={() => create()} disabled={shopperReportDisabled}>
              Generate Shopper Report
            </Button>
          </Box>
        </Box>
      </Tab>
      <Tab
        label="Microsoft Report"
        onClick={() =>
        {
          setReportType( "microsoft" );
          clearSelection();
        }}
      >
        <Box textAlign="center" m={1} className={classes.topBox}>
          <Box className={classes.flexBox}>
            <TextField
              id="datetime-local"
              label="Select Start Date for Report"
              type="datetime-local"
              defaultValue=""
              value={startDate}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(
                event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) =>
              {
                setReportType( "microsoft" );
                setStartDate( event.target.value );
                setEvent( {} as Option );
              }}
            />
          </Box>
          <Spacer />
          <Box className={classes.flexBox}>
            <TextField
              id="datetime-local"
              label="Select End Date for Report"
              type="datetime-local"
              defaultValue=""
              value={endDate}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(
                event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) =>
              {
                setReportType( "microsoft" );
                setEndDate( event.target.value );
                setEvent( {} as Option );
              }}
            />
          </Box>
          <Spacer />
          <Box className={classes.flexBox}>
            <Button onClick={() => clearSelection()}>Clear Selection</Button>
            <Button onClick={() => create()} disabled={microsoftReportDisabled}>
              Generate Microsoft Report
            </Button>
          </Box>
        </Box>
      </Tab>
      <Tab label="Reconciliation Report" onClick={() => clearSelection()}>
        <Box textAlign="center" m={1} className={classes.topBox}>
          <Box className={classes.flexBox}>
            <Typography variant="h6">Select a Shopper</Typography>
            <PaginatedSelect
              label="Shopper"
              nameMappingKey="handle"
              onChange={(
                event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) =>
              {
                const eventValues = event.target.value.split( "-" );
                const id = eventValues[0];
                const name = eventValues[1];
                setSeller( { id, name } );
                setReportType( "reconciliation" );
              }}
              selectedOption={seller}
              list={{
                resource: "users",
                nameMappingKey: "handle",
                perPage: paginatedSelectInputConfig.perPage,
                filter: { isSeller: true },
                sort: {
                  field: "sortOptions.handle",
                  order: "ASC",
                },
              }}
            />
          </Box>
          <Spacer />
          <Box className={classes.flexBox}>
            <Typography variant="h6">Select an Event</Typography>
            <PaginatedSelect
              key={seller.id}
              label="Event"
              nameMappingKey="name"
              selectedOption={event}
              disabled={!seller?.id}
              onChange={(
                event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) =>
              {
                const eventValues = event.target.value.split( "-" );
                const id = eventValues[0];
                const name = eventValues[1];
                setEvent( { id, name } );
              }}
              list={{
                resource: "events",
                nameMappingKey: "name",
                perPage: paginatedSelectInputConfig.perPage,
                filter: {
                  ...( seller.id && { userId: seller.id } ),
                },
              }}
            />
          </Box>
          <Spacer />
          <Box className={classes.flexBox}>
            <Button onClick={() => clearSelection()}>Clear Selection</Button>
            <Button
              onClick={() => create()}
              disabled={reconciliationReportDisabled}
            >
              Generate Reconciliation Report
            </Button>
          </Box>
        </Box>
      </Tab>

      <Tab
        label="Shopper brands Report"
        onClick={() =>
        {
          setReportType( "shopperBrands" );
          clearSelection();
        }}
      >
        <Box className={classes.flexBox}>
          <TextField
            id="datetime-local"
            label="Select Start Date for Report"
            type="datetime-local"
            defaultValue=""
            value={startDate}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(
              event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) =>
            {
              setStartDate( event.target.value );
              setReportType( "shopperBrands" );
            }}
          />
        </Box>
        <Box textAlign="center" m={1} className={classes.topBox}>
          <Box className={classes.flexBox}>
            <Button
              disabled={brandsReportDisabled}
              onClick={() =>
              {
                create();
              }}
            >
              Generate Shopper Brands Report
            </Button>
          </Box>
        </Box>
      </Tab>
      <Tab label="Other Categories Report" onClick={() => clearSelection()}>
        <Box textAlign="center" m={1} className={classes.topBox}>
          <Spacer />
          <Box className={classes.flexBox}>
            <Button onClick={() => generateOtherReport()}>
              Generate Other Categories Report
            </Button>
          </Box>
        </Box>
      </Tab>
      <Tab
        label="Operations Activity Report"
        onClick={() =>
        {
          setReportType( "operationsActivity" );
        }}
      >
        <Box className={classes.flexBox}>
          <Typography variant="h6">Select Currency:&nbsp;&nbsp; </Typography>
          <Select
            label="Currency"
            name="currency"
            value={values.currency.length > 0 ? values.currency : "Currency"}
            onChange={handleSelectChange}
            placeholder={"Currency"}
          >
            <MenuItem value={"Currency"} disabled>
              Currency
            </MenuItem>
            <MenuItem value={"cad"}>CAD</MenuItem>
            <MenuItem value={"usd"}>USD</MenuItem>
          </Select>
        </Box>
        <Box textAlign="center" m={1} className={classes.topBox}>
          <Box className={classes.flexBox}>
            <Button
              disabled={operationsActivityReportDisabled}
              onClick={() =>
              {
                create();
              }}
            >
              Generate Operations Activity Report
            </Button>
          </Box>
        </Box>
      </Tab>
      <Tab
        label="Coupon Usage Report"
        onClick={() =>
        {
          setReportType( "couponUsage" );
          clearSelection();
        }}
      >
        <Box textAlign="center" m={1} className={classes.topBox}>
          <Box className={classes.flexBox}>
            <Typography variant="h6">Select a Coupon</Typography>
            <PaginatedSelect
              label="Coupon"
              nameMappingKey="code"
              selectedOption={coupon}
              onChange={(
                event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) =>
              {
                const eventValues = event.target.value.split( "-" );
                const id = eventValues[0];
                const name = eventValues[1];
                setCoupon( { id, name } );
                setReportType( "couponUsage" );
              }}
              list={{
                resource: "coupons",
                nameMappingKey: "code",
                perPage: paginatedSelectInputConfig.perPage,
                filter: {},
                sort: {
                  field: "startDate",
                  order: "ASC",
                },
              }}
              options={[{ id: "all", name: "All" }]}
            />
          </Box>
          <Spacer />
          <Box className={classes.flexBox}>
            <TextField
              id="datetime-local"
              label="Select Start Date for Report"
              type="datetime-local"
              defaultValue=""
              value={startDate}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(
                event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) =>
              {
                setStartDate( event.target.value );
                setEvent( {} as Option );
              }}
            />
          </Box>
          <Spacer />
          <Box className={classes.flexBox}>
            <TextField
              id="datetime-local"
              label="Select End Date for Report"
              type="datetime-local"
              defaultValue=""
              value={endDate}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(
                event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) =>
              {
                setEndDate( event.target.value );
                setEvent( {} as Option );
              }}
            />
          </Box>
          <Spacer />
          <Box className={classes.flexBox}>
            <Button onClick={() => clearSelection()}>Clear Selection</Button>
            <Button
              onClick={() => create()}
              disabled={couponUsageReportDisabled}
            >
              Generate Coupon Usage Report
            </Button>
          </Box>
        </Box>
      </Tab>
      <Tab
        label="Coupon Report"
        onClick={() =>
        {
          setReportType( "coupons" );
          clearSelection();
        }}
      >
        <Box textAlign="center" m={1} className={classes.topBox}>
          <Box className={classes.flexBox}>
            <TextField
              id="datetime-local"
              label="Select Start Date for Report"
              type="datetime-local"
              defaultValue=""
              value={startDate}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(
                event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) =>
              {
                setStartDate( event.target.value );
                setEvent( {} as Option );
              }}
            />
          </Box>
          <Spacer />
          <Box className={classes.flexBox}>
            <TextField
              id="datetime-local"
              label="Select End Date for Report"
              type="datetime-local"
              defaultValue=""
              value={endDate}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(
                event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) =>
              {
                setEndDate( event.target.value );
                setEvent( {} as Option );
              }}
            />
          </Box>
          <Spacer />
          <Box className={classes.flexBox}>
            <Button onClick={() => clearSelection()}>Clear Selection</Button>
            <Button onClick={() => create()} disabled={couponsReportDisabled}>
              Generate Coupon Report
            </Button>
          </Box>
        </Box>
      </Tab>
      <Tab
        label="Order Report"
        onClick={() =>
        {
          setReportType( "orders" );
          clearSelection();
        }}
      >
        <Box textAlign="center" m={1} className={classes.topBox}>
          <Box className={classes.flexBox}>
            <TextField
              disabled={isLoading}
              id="datetime-local"
              label="Select Start Date for Report"
              type="datetime-local"
              defaultValue=""
              value={startDate}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(
                event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) =>
              {
                setStartDate( event.target.value );
                setEvent( {} as Option );
              }}
              onBlur={() =>
              {
                startDate && endDate && sendReq();
              }}
            />
          </Box>
          <Spacer />
          <Box className={classes.flexBox}>
            <TextField
              disabled={isLoading}
              id="datetime-local"
              label="Select End Date for Report"
              type="datetime-local"
              defaultValue=""
              value={endDate}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(
                event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) =>
              {
                setEndDate( event.target.value );
                setEvent( {} as Option );
              }}
              onBlur={() => startDate && endDate && sendReq()}
            />
          </Box>
          <Spacer />

          <Box className={classes.flexBox}>
            {isLoading && <CircularProgress />}
            {!isLoading && (
              <Select
                disabled={couponsReportDisabled}
                style={{ width: "200px" }}
                value={chosenPage}
                onChange={( event: React.ChangeEvent<{ value: unknown }> ) =>
                  setChosenPage( event.target.value as number )
                }
              >
                <MenuItem value=" " disabled>
                  Choose page
                </MenuItem>
                {ordersPages.map( ( item, index ) => (
                  <MenuItem key={index} value={item.value}>
                    {item.text}
                  </MenuItem>
                ) )}
              </Select>
            )}
          </Box>

          <Spacer />
          <Box className={classes.flexBox}>
            <Button onClick={() => clearSelection()}>Clear Selection</Button>
            <Button
              onClick={() => create()}
              disabled={ordersReportDisabled || isLoading}
            >
              Generate Order Report
            </Button>
          </Box>
        </Box>
      </Tab>
    </TabbedShowLayout>
  );
};

export const ReportList = ( properties: Record<string, never> ): JSX.Element => (
  <List {...properties} empty={<ReportGenerator />} title="Reports">
    <Datagrid />
  </List>
);
