import _ from "lodash";
import React from "react";
import { Button, Typography, Grid } from "@material-ui/core";
import { TextInput, SelectInput, usePermissions, useNotify } from "react-admin";
import { Form } from "react-final-form";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { USStates, CAProvinces, config } from "../../config";
import { ButtonStyles } from "../generics/button-styles";
import { authHeader } from "../../utils/auth-header";
import { fetchUtils } from "ra-core";
import { notifyMessage } from "../../utils/primitive";
const httpClient = fetchUtils.fetchJson;

interface AddFromAddressInput {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

type FromAddresses = { [x: string]: string }[];

const useStyles = makeStyles( ( theme: Theme ) =>
  createStyles( {
    formField: {
      margin: theme.spacing( 1, 2, 1, 0 ),
    },
  } )
);

const getStateChoices = ( data: { id: string; name: string }[] ) =>
  data.map( ( each ) => ( { id: each.name, name: each.name } ) );

const updateUserFromAddress = async ( parameters: {
  userId: string;
  fromAddresses: FromAddresses;
} ) =>
{
  const url = `${config.dataProviderRoot}/api/users/${parameters.userId}/update`;
  const options = {
    headers: await authHeader(),
    method: "post",
    body: JSON.stringify( { fromAddresses: parameters.fromAddresses } ),
  };
  return httpClient( url, options );
};

export const AddFromAddress = ( { setOpen }: AddFromAddressInput ) =>
{
  const classes = useStyles();
  const buttonClasses = ButtonStyles();
  const [country, setCountry] = React.useState( "" );
  const [firstNameAndLastNameRequired, setFirstNameAndLastNameRequired] =
    React.useState( true );
  const [companyRequired, setCompanyRequired] = React.useState( true );
  const { loaded, permissions } = usePermissions();
  const notify = useNotify();

  return loaded ? (
    <div>
      <Typography variant="h6" gutterBottom>
        {`"From" Address for Shipping`}
      </Typography>

      <Form
        id="post_create_form"
        onSubmit={( values: { [x: string]: string } ) =>
        {
          const userId = _.get( permissions, "user_id" ) as string;
          updateUserFromAddress( { userId, fromAddresses: [values] } )
            .then( () =>
            {
              notify( "From address added" );
              window.location.reload();
            } )
            .catch( ( error ) =>
            {
              notifyMessage( error, notify );
            } );
        }}
        render={( { handleSubmit } ) => (
          <form onSubmit={handleSubmit}>
            <Grid container justifyContent="space-between">
              <TextInput
                className={classes.formField}
                source="line1"
                label="Line 1"
                required
              />

              <TextInput
                className={classes.formField}
                source="line2"
                label="Line 2"
              />

              <TextInput
                className={classes.formField}
                source="city"
                label="City"
                required
              />
            </Grid>
            <Grid container justifyContent="space-between">
              <SelectInput
                source="country"
                label="Country"
                onChange={( event: React.ChangeEvent<HTMLTextAreaElement> ) =>
                  setCountry( event.target.value )
                }
                required
                choices={[
                  { id: "CA", name: "CA" },
                  { id: "US", name: "US" },
                ]}
              />
              <SelectInput
                source="state"
                label="State/Province"
                required
                disabled={_.isEmpty( country )}
                choices={
                  country
                    ? ( country === "US"
                      ? getStateChoices( USStates )
                      : getStateChoices( CAProvinces ) )
                    : []
                }
              />
              <TextInput
                className={classes.formField}
                source="postalCode"
                label="Postal Code"
                required
              />
            </Grid>
            <Grid container justifyContent="space-between">
              <TextInput
                className={classes.formField}
                source="phone"
                label="Phone"
                required
              />
            </Grid>
            <Grid container justifyContent="space-between">
              <Typography style={{ marginBottom: -5 }} variant="caption">
                {`You are only required to enter either a Company Name or your First Name/Last Name`}
              </Typography>
            </Grid>
            <Grid container justifyContent="space-between">
              <TextInput
                className={classes.formField}
                source="company"
                label="Company"
                required={companyRequired}
                onChange={( event: React.ChangeEvent<HTMLInputElement> ) =>
                {
                  if ( !_.isEmpty( event.target.value ) )
                  {
                    setCompanyRequired( true );
                    setFirstNameAndLastNameRequired( false );
                  }
                  else
                  {
                    setCompanyRequired( true );
                    setFirstNameAndLastNameRequired( true );
                  }
                }}
              />
              <TextInput
                className={classes.formField}
                source="firstname"
                label="First Name"
                required={firstNameAndLastNameRequired}
                onChange={( event: React.ChangeEvent<HTMLInputElement> ) =>
                {
                  if ( !_.isEmpty( event.target.value ) )
                  {
                    setFirstNameAndLastNameRequired( true );
                    setCompanyRequired( false );
                  }
                  else
                  {
                    setFirstNameAndLastNameRequired( true );
                    setCompanyRequired( true );
                  }
                }}
              />
              <TextInput
                className={classes.formField}
                source="lastname"
                label="Last Name"
                required={firstNameAndLastNameRequired}
                onChange={( event: React.ChangeEvent<HTMLInputElement> ) =>
                {
                  if ( !_.isEmpty( event.target.value ) )
                  {
                    setFirstNameAndLastNameRequired( true );
                    setCompanyRequired( false );
                  }
                  else
                  {
                    setFirstNameAndLastNameRequired( true );
                    setCompanyRequired( true );
                  }
                }}
              />
            </Grid>
            <Grid container justifyContent="center">
              <Button
                id="post_create_form"
                className={buttonClasses.saveButton}
                style={{
                  width: 100,
                  marginRight: 10,
                }}
                type="submit"
              >
                Save
              </Button>
              <Button
                className={buttonClasses.resetSortButton}
                onClick={() => setOpen( false )}
                style={{
                  width: 100,
                  marginLeft: 10,
                }}
              >
                close
              </Button>
            </Grid>
          </form>
        )}
      ></Form>
    </div>
  ) : null;
};
