import * as React from "react";
import { Order } from "src/ts/interfaces/order-interface";
import { SimpleShowLayout, Show, FunctionField } from "react-admin";
import { OrderDetail } from "./order/order-detail";
import { AuthContext } from "../contexts/auth-context";
import { PopupNotification } from "./popup-notification/popup-notification";

interface IShowProperties {
  id: string;
  [x: string]: unknown;
  record: Order;
}

export const OrderShow: React.FunctionComponent<IShowProperties> = (
  showProperties
) => (
  <Show {...showProperties} title={`ShopThing`}>
    <SimpleShowLayout>
      <FunctionField
        addLabel={false}
        render={( order: Order ) => (
          <AuthContext.Provider
            value={{ permissions: showProperties.permissions }}
          >
            <OrderDetail record={order} />
          </AuthContext.Provider>
        )}
      />
      <PopupNotification />
    </SimpleShowLayout>
  </Show>
);
