import * as React from "react";
import {
  ProductInventoryItem,
  CreateProductInventoryItem
} from "../../ts/interfaces/product-inventory-interface";
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceArrayInput,
  CheckboxGroupInput,
  SelectInput,
  ReferenceInput,
  FileInput,
  SimpleFormIterator,
  FormDataConsumer,
  ArrayInput,
  AddItemButton,
  RemoveItemButton,
  Toolbar,
  SaveButton
} from "react-admin";
import { Grid, makeStyles } from "@material-ui/core";
import { CategoriesSelectInput } from "./category-dropdowns";
import {
  required,
  number as numberValidator,
  useNotify,
  maxValue,
  minValue
} from "ra-core";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { getApp } from "firebase/app";
import { v4 } from "uuid";
import { MultiTypeFileField } from "../generics/multi-type-file-field";
import { useForm } from "react-final-form";
import { MediaType } from "src/ts/interfaces/product-interface";
import { BarcodeButton } from "./barcode-button";
import { ButtonStyles } from "../generics/button-styles";

interface IEditProperties {
  id: string;
  [x: string]: unknown;
  record: ProductInventoryItem;
}

interface CreateResponseEvent {
  data: {
    id: string;
  };
}

const useStyles = makeStyles( {
  clearRemoveButton: {
    "& .previews": {
      "& button": {
        display: "none",
      },
    },
  },
} );

const transform = ( data: CreateProductInventoryItem ) => ( {
  id: "",
  creation: {
    ...( data.brandId && { brandId: data.brandId } ),
    productName: data.productName,
    ...( data.productDescription && {
      productDescription: data.productDescription,
    } ),
    ...( data.tagIds && data.tagIds.length > 0 && { tagIds: data.tagIds } ),
    categoryPathIds: [
      [data.topLevelCategory, data.subCategory1, data.subCategory2]
        .filter( ( x ) => x )
        .join( "/" ),
    ],
    currency: data.currency,
    salePrice: Math.round( data.salePrice * 100 ), //change to Cent
    ...( data.regularPrice && {
      regularPrice: Math.round( data.regularPrice * 100 ),
    } ), //change to Cent
    taxRate: data.taxRate / 100, //get percentage
    skuOption: data.skuOption,
    skuNumber: v4(),
    location: data.location,
    media: data.media
      .filter( ( x ) => x?.storage )
      .map( ( value ) => ( {
        mediaHeight: value.storage.mediaHeight,
        mediaWidth: value.storage.mediaWidth,
        rawMediaPath: value.storage.rawMediaPath,
        mediaPath: value.storage.mediaPath,
        rawPosterPath: value.storage.rawPosterPath,
        type: value.storage.type,
        rawSmallPosterPath: value.storage.rawSmallPosterPath,
      } ) ),
  },
} );

export const ProductInventoryCreate: React.FunctionComponent<
  IEditProperties
> = ( properties: IEditProperties ) =>
{
  const classes = useStyles();
  const buttonClasses = ButtonStyles();
  const notify = useNotify();

  const [mediaDimensionsArray, setMediaDimensionsArray] = React.useState<
    { width: number; height: number; path: string }[]
  >( [] );

  const [mediaUploaded, setMediaUploaded] = React.useState<
    { path: string; uploaded: boolean }[]
  >( [] );

  const [newlyCreatedProductId, setNewlyCreatedProductId] = React.useState( " " );
  const [disableSaveButton, setDisableSaveButton] =
    React.useState<boolean>( false );

  const [disableBarCodeButton, setDisableBarCodeButton] = React.useState( true );

  const onSuccess = ( event: CreateResponseEvent ) =>
  {
    notify( "Product Inventory Item Created" );
    setNewlyCreatedProductId( event.data.id );
    setDisableSaveButton( true );
    setDisableBarCodeButton( false );
  };

  const onFailure = ( error: Error ) =>
  {
    setDisableBarCodeButton( true );
    notify(
      `Failed creating product inventory item - ${error.message}`,
      "warning"
    );
  };

  return (
    <Create
      {...properties}
      transform={transform}
      onSuccess={onSuccess}
      onFailure={onFailure}
      mutationMode={"pessimistic"}
    >
      <SimpleForm
        toolbar={
          <Toolbar>
            <SaveButton
              style={{ marginRight: 25 }}
              disabled={disableSaveButton}
            />
            <BarcodeButton
              disabled={disableBarCodeButton}
              productInventoryItemId={newlyCreatedProductId}
              styleClassName={buttonClasses.saveButton}
              redirect={true}
            />
          </Toolbar>
        }
      >
        <Grid container>
          <Grid item xs={12}>
            <h2>Create New Product Inventory Item</h2>
          </Grid>
        </Grid>
        <ReferenceInput reference="brands" label="Brand" source="brandId">
          <SelectInput validate={required()} />
        </ReferenceInput>
        <TextInput
          label="Product Name"
          source="productName"
          fullWidth
          validate={required()}
        />
        <TextInput
          label="Product Description"
          source="productDescription"
          fullWidth
        />
        <ReferenceArrayInput
          label="Tags"
          reference="get-tags"
          source="tagIds"
          fullWidth
        >
          <CheckboxGroupInput />
        </ReferenceArrayInput>
        <CategoriesSelectInput record={null} />
        <SelectInput
          label="Currency"
          choices={[
            { id: "usd", name: "usd" },
            { id: "cad", name: "cad" },
          ]}
          source="currency"
          validate={required()}
        />
        <TextInput
          label="Sale Price"
          source="salePrice"
          validate={[required(), numberValidator()]}
        />
        <TextInput
          label="Retail Price"
          source="regularPrice"
          validate={[numberValidator()]}
        />
        <TextInput
          label="Tax Rate 0 - 100 %"
          source="taxRate"
          validate={[
            numberValidator(),
            required(),
            minValue( 0, "Tax rate should be positive number" ),
            maxValue( 100, "Tax rate should be less than 100" ),
          ]}
        />
        <TextInput label="Option Name / Description" source="skuOption" />
        <ReferenceInput
          label="Location"
          source="location"
          reference="return-locations"
          validate={required()}
        >
          <SelectInput source="location" optionText="name" optionValue="id" />
        </ReferenceInput>
        <FormDataConsumer>
          {( {
            formData,
          }: {
            formData: { media: Record<string, unknown>[] };
          } ) => (
            <ArrayInput source="media" validate={required( " " )}>
              <SimpleFormIterator
                addButton={
                  formData?.media?.length >= 5 ? (
                    <></>
                  ) : (
                    <AddItemButton style={{ marginTop: 25 }} />
                  )
                }
                removeButton={
                  formData?.media?.length > 1 ? <RemoveItemButton /> : <></>
                }
              >
                <FormDataConsumer>
                  {( {
                    getSource,
                  }: {
                    getSource: ( source: string ) => string;
                    scopedFormData: CreateProductInventoryItem;
                  } ) =>
                  {
                    const form = useForm();

                    React.useEffect( () =>
                    {
                      const currentMedia: {
                        storage: MediaType & { preview: string };
                      }[] = form.getState().values.media;

                      const hasFilesUploading = currentMedia
                        .map(
                          ( media: {
                            storage: MediaType & { preview: string };
                          } ) =>
                          {
                            if (
                              media?.storage &&
                              media.storage.preview.startsWith( "blob" )
                            )
                            {
                              // Find media with blob (which means that a file was uploaded)
                              const foundPreview = mediaUploaded.filter(
                                ( value ) =>
                                  value.path === media.storage.preview &&
                                  value.uploaded === true
                              );

                              if ( foundPreview?.length === 0 )
                              {
                                return false;
                              }
                            }
                            return true;
                          }
                        )
                        .some( ( value: boolean ) => value === false );

                      setDisableSaveButton( hasFilesUploading );
                    }, [mediaUploaded] );

                    React.useEffect( () =>
                    {
                      const currentMedia = form.getState().values.media;

                      // Change is saved.  I don't want this.
                      form.change(
                        "media",
                        currentMedia.map( ( media: { storage: MediaType } ) =>
                        {
                          if ( media?.storage )
                          {
                            const foundDimensions = mediaDimensionsArray.filter(
                              ( value ) => value.path === media.storage.mediaPath
                            );
                            if ( foundDimensions?.length > 0 )
                            {
                              return {
                                storage: {
                                  ...media.storage,
                                  mediaWidth: foundDimensions[0].width,
                                  mediaHeight: foundDimensions[0].height,
                                },
                              };
                            }
                          }
                          return media;
                        } )
                      );
                    }, [mediaDimensionsArray] );

                    const parse = React.useCallback( ( file: File ) =>
                    {
                      if ( file )
                      {
                        try
                        {
                          let fileName = file.name;
                          if ( file.type.startsWith( "video" ) )
                          {
                            fileName = "video.mp4";
                          }
                          else if ( file.type.startsWith( "image" ) )
                          {
                            fileName = "poster-raw.jpg";
                          }

                          const basePath = `productMedia/${v4()}`;
                          const uploadPath = `${basePath}/${fileName}`;

                          const storeFile = async (
                            path: string,
                            previewPath: string
                          ) =>
                          {
                            const defaultApp = getApp();
                            const storage = getStorage( defaultApp );
                            const storageReference = ref( storage, path );

                            // Upload asynchronously so it doesn't fail the parse
                            uploadBytes( storageReference, file, {
                              customMetadata: {
                                isProductInventoryItemEdit: "true",
                                addWatermark: "true",
                              },
                            } )
                              .then( () =>
                              {
                                setMediaUploaded( ( values ) => [
                                  ...values,
                                  {
                                    path: previewPath,
                                    uploaded: true,
                                  },
                                ] );
                              } )
                              .catch( ( error ) =>
                              {
                                setMediaUploaded( ( values ) => [
                                  ...values,
                                  {
                                    path: previewPath,
                                    uploaded: false,
                                  },
                                ] );
                                notify(
                                  `Failed to upload Media! - ${error.message}`,
                                  "warning"
                                );
                              } );
                          };

                          // Obtain the blob for display in browser
                          const objectUrl = URL.createObjectURL( file );

                          // So this will just asynchronously trigger
                          if ( objectUrl )
                          {
                            storeFile( uploadPath, objectUrl );
                          }

                          // Obtain the dimensions
                          if ( file.type.startsWith( "video" ) )
                          {
                            const videoElement =
                              document.createElement( "video" );
                            videoElement.src = objectUrl;
                            videoElement.addEventListener(
                              "loadedmetadata",
                              function ()
                              {
                                setMediaDimensionsArray( ( values ) => [
                                  ...values,
                                  {
                                    width: this.videoWidth,
                                    height: this.videoHeight,
                                    path: uploadPath,
                                  },
                                ] );
                              }
                            );
                          }
                          else if ( file.type.startsWith( "image" ) )
                          {
                            const img = new Image();
                            img.src = objectUrl;
                            img.addEventListener( "load", () =>
                            {
                              setMediaDimensionsArray( ( values ) => [
                                ...values,
                                {
                                  width: img.width,
                                  height: img.height,
                                  path: uploadPath,
                                },
                              ] );
                            } );
                          }

                          return {
                            rawMediaPath: uploadPath,
                            mediaPath: uploadPath,
                            rawPosterPath: `${basePath}/poster-raw.jpg`,
                            rawSmallPosterPath: `${basePath}/small-poster-raw.jpg`,
                            type: file.type.split( "/" )[0],
                            preview: objectUrl,
                            // Need this to recalculate object url and prevent the old url from being removed
                            previewFile: file,
                          };
                        }
                        catch ( error )
                        {
                          console.log( "uploadFile error:", error );
                        }
                      }
                    }, [] );

                    return (
                      <>
                        <FileInput
                          validate={required()}
                          source={getSource( `storage` )}
                          parse={( image: File ) => parse( image )}
                          className={classes.clearRemoveButton}
                        >
                          <MultiTypeFileField
                            source="preview"
                            typeSource="type"
                            formMedia={form.getState()?.values?.media}
                          />
                        </FileInput>
                      </>
                    );
                  }}
                </FormDataConsumer>
              </SimpleFormIterator>
            </ArrayInput>
          )}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};
