import React from "react";
import cloneDeep from "lodash/cloneDeep";
import { useUpdate, useNotify, useRefresh } from "react-admin";
import { Button, Typography, Link } from "@material-ui/core";
import { Details } from "src/ts/interfaces/order-interface";
import { notifyMessage } from "../../utils/primitive";

interface IConvertPickup {
  orderId: string;
  logistics: ConvertPickupLogistics;
  redirect?: boolean;
  [x: string]: unknown;
}

interface IConvertSuccess {
  data: {
    status: string;
    link: string;
  };
}

interface ConvertPickupLogistics {
  details: Details;
  zone?: string;
}

export const ConvertPickup: React.FunctionComponent<IConvertPickup> = ( {
  orderId,
  logistics,
  redirect,
}: IConvertPickup ) =>
{
  const [enableConversion, setEnableConversion] = React.useState( true );
  const notify = useNotify();
  const refresh = useRefresh();

  const formattedLogistics = cloneDeep( logistics );
  if ( !formattedLogistics.details.address.company )
  {
    delete formattedLogistics.details.address.company;
  }
  if ( !formattedLogistics.details.address.line2 )
  {
    delete formattedLogistics.details.address.line2;
  }

  const [update, { loading, error }] = useUpdate(
    "potential-bundles",
    orderId,
    {
      logistics: formattedLogistics,
    },
    {},
    {
      onSuccess: ( response: IConvertSuccess ) =>
      {
        setEnableConversion( false );
        notify( `Successfully converted ${orderId} to delivery`, "success" );
        if ( redirect )
        {
          const fulfillmentPath = response.data.link.split( "#" )[1];
          const redirectUrl = `${window.location.origin}/#${fulfillmentPath}`;
          history.replaceState( {}, "", redirectUrl );
          history.go();
        }
        else
        {
          refresh();
        }
      },
    }
  );
  if ( error && !loading )
  {
    notifyMessage( error, notify );
  }

  return (
    <Button onClick={update} disabled={!enableConversion}>
      <Typography>
        <Link>Convert to Delivery</Link>
      </Typography>
    </Button>
  );
};
