import * as React from "react";

export interface AuthProperties {
  permissions: {
    exclusiveAddress?: string;
    admin?: boolean;
    partner?: boolean;
    seller?: boolean;
  };
}

export const AuthContext = React.createContext( { permissions: {} } );

export const useAuth = (): AuthProperties => React.useContext( AuthContext );
