import React from "react";
import { useUpdate } from "react-admin";
import { IconButton, Typography, Link } from "@material-ui/core";
import { PopupNotificationType } from "../popup-notification/popup-notification";
import { useBarcode } from "../../contexts/barcode-context";

interface ICloneButton {
  isDefaultPayment: boolean;
  cardId: string;
}

export const DefaultPaymentButton: React.FunctionComponent<ICloneButton> = (
  properties
) =>
{
  const { setNotification } = useBarcode();
  const [update, { loading }] = useUpdate(
    "cards",
    "",
    {
      creditCardId: properties.cardId,
    },
    {},
    {
      onSuccess: () =>
      {
        window.location.reload();
      },
      onFailure: ( error: Error ) =>
      {
        setNotification( {
          message: `${error}`,
          type: PopupNotificationType.error,
        } );
      },
    }
  );

  return (
    <>
      <IconButton
        color={"primary"}
        disabled={loading || properties.isDefaultPayment}
        disableRipple
        disableFocusRipple
        onClick={update}
      >
        <Typography>
          <Link>
            {properties.isDefaultPayment ? (
              <text style={{ color: "green" }}>Default</text>
            ) : (
              "Set as default"
            )}
          </Link>
        </Typography>
      </IconButton>
    </>
  );
};
