import * as React from "react";
import { AuthProvider, useAuthProvider } from "ra-core";
import {
  useLDClient,
  LDContext,
  LDClient
} from "launchdarkly-react-client-sdk";
import { useBarcode } from "./barcode-context";
export interface ObjectText {
  value: string;
  title: string;
}

const FirebaseAuthContext = React.createContext( {} );

export const useFirebaseAuth = () => React.useContext( FirebaseAuthContext );

interface ILaunchDarklyProvider {
  children: React.ReactChildren[];
}

const updateUserContext = async (
  authProvider: AuthProvider,
  userContext: LDContext,
  setUserContext: ( userContext: LDContext ) => void,
  ldClient: LDClient
) =>
{
  const authUser = await authProvider.getAuthUser();
  const jwtClaims = await authProvider.getJWTClaims();

  const newContext = {
    kind: "user",
    key: authUser.uid,
    email: authUser.email,
    anonymous: false,
    ...( jwtClaims.seller && {
      isShopper: jwtClaims.seller,
    } ),
    ...( jwtClaims.partner && {
      isPartner: jwtClaims.partner,
    } ),
    _meta: {
      privateAttributes: ["isShopper", "isPartner", "email", "anonymous"],
    },
  };

  // We can do a limited comparison between the two objects
  if ( JSON.stringify( userContext ) !== JSON.stringify( newContext ) )
  {
    await ldClient.identify( newContext, null, () =>
    {
      setUserContext( newContext );
    } );
  }
};

export const LaunchDarklyProvider: React.FunctionComponent = ( {
  children,
}: ILaunchDarklyProvider ): JSX.Element =>
{
  const { userContext, setUserContext } = useBarcode();
  const authProvider = useAuthProvider();
  const ldClient = useLDClient();

  React.useEffect( () =>
  {
    updateUserContext( authProvider, userContext, setUserContext, ldClient );
  }, [] );

  return (
    <FirebaseAuthContext.Provider value={{}}>
      <>{children}</>
    </FirebaseAuthContext.Provider>
  );
};
