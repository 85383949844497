import * as React from "react";
import { Box } from "@material-ui/core";
import { DataSquares } from "./data-squares";

export interface OrderItemData {
  totalOrderCount: number;
  totalItemCount: number;
}
export interface OpenOrderData {
  openOrderData: {
    pending: OrderItemData;
    disputed: OrderItemData;
    pendingToday: OrderItemData;
  };
}

export function OpenOrders( props: OpenOrderData ): JSX.Element
{
  return (
    <Box
      style={{
        border: "1px solid",
        borderRadius: "10px",
        margin: "10px 10px",
        padding: "15px 20px",
        borderColor: "#BBBBBB",
      }}
    >
      <h5
        style={{
          fontWeight: 900,
          fontSize: "24x",
          margin: 0,
          paddingLeft: "20px",
        }}
      >
        OPEN ORDERS
      </h5>
      <Box
        display="flex"
        flexDirection="row"
        style={{ width: "594px", justifyContent: "space-around" }}
      >
        <DataSquares
          title="PENDING TODAY"
          first={{
            label: "ORDERS",
            value: props.openOrderData.pendingToday.totalOrderCount,
          }}
          second={{
            label: "ITEMS",
            value: props.openOrderData.pendingToday.totalItemCount,
          }}
        />
        <DataSquares
          title="TOTAL PENDING"
          first={{
            label: "ORDERS",
            value: props.openOrderData.pending.totalOrderCount,
          }}
          second={{
            label: "ITEMS",
            value: props.openOrderData.pending.totalItemCount,
          }}
        />
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        style={{ width: "594px", justifyContent: "space-around" }}
      >
        <DataSquares
          title="TOTAL DISPUTED"
          first={{
            label: "ORDERS",
            value: props.openOrderData.disputed.totalOrderCount,
          }}
          second={{
            label: "ITEMS",
            value: props.openOrderData.disputed.totalItemCount,
          }}
        />
      </Box>
    </Box>
  );
}
