import React, { useEffect } from "react";
import { SaveOnlyToolbar } from "../toolbars/save-only";
import { Show, SimpleShowLayout, FunctionField } from "react-admin";
import { Box, Divider, Grid, Typography } from "@material-ui/core";
import { getFile } from "@shopthing-opn-shared/admin-dashboard";
import { getApp } from "firebase/app";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import {
  Announcement,
  AnnouncementMedia
} from "../../ts/interfaces/announcements";

const useStyles = makeStyles( () =>
  createStyles( {
    media: {
      maxWidth: "50%",
      maxHeight: "250px",
      marginBottom: "15px",
    },
    boxSpaceUnder: {
      marginBottom: "25px",
    },
  } )
);

const grayBoxProperties = {
  border: 2,
  bgcolor: "#f7f9fa",
  css: { borderColor: "#C5D1D9", borderRadius: "5px" },
  padding: 2,
  marginBottom: 2,
};

const AnnouncementTextField = ( {
  label,
  value,
}: {
  label: string;
  value: string | number;
} ) => (
  <Typography variant={"h6"}>
    <span
      style={{
        fontWeight: "bold",
      }}
    >
      {`${label}: `}
    </span>
    {value}
  </Typography>
);

export const AnnouncementShow: React.FunctionComponent = ( properties ) => (
  <Show {...properties} title="Announcement Details">
    <SimpleShowLayout toolbar={<SaveOnlyToolbar />}>
      <FunctionField
        addLabel={false}
        render={( announcement: Announcement ) => (
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Box paddingTop={2}>
                <Typography variant="h5" gutterBottom>
                  {`Announcement #${announcement.id}`}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <>
                <Box {...grayBoxProperties}>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                      <AnnouncementTextField
                        label="Name"
                        value={announcement.name}
                      />

                      <AnnouncementTextField
                        label="Title"
                        value={announcement.title || "N/A"}
                      />

                      <AnnouncementTextField
                        label="Subtitle"
                        value={announcement.subtitle || "N/A"}
                      />

                      <AnnouncementTextField
                        label="Start Date"
                        value={new Date(
                          announcement.startDate
                        ).toLocaleString()}
                      />

                      <AnnouncementTextField
                        label="End Date"
                        value={new Date( announcement.endDate ).toLocaleString()}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </>
            </Grid>

            <Grid item xs={12}>
              <Box {...grayBoxProperties}>
                <h3>Media</h3>

                {announcement.media.map( ( media ) => (
                  <AnnouncementMediaItem key={media.mediaPath} {...media} />
                ) )}
              </Box>
            </Grid>
          </Grid>
        )}
      />
    </SimpleShowLayout>
  </Show>
);

const AnnouncementMediaItem: React.FunctionComponent<AnnouncementMedia> = ( {
  isVideo,
  mediaPath,
  title,
  subtitle,
} ) =>
{
  const [media, setMedia] = React.useState( "" );

  const classes = useStyles();

  const app = getApp();

  async function loadFile( filePath: string, setter: ( value: string ) => void )
  {
    const posterUrl = await getFile( filePath, app );
    setter( posterUrl );
  }

  useEffect( () =>
  {
    if ( mediaPath )
    {
      loadFile( mediaPath, setMedia );
    }
  } );

  return (
    <div className={classes.boxSpaceUnder}>
      {isVideo ? (
        <>
          <video src={media} className={classes.media} controls />
        </>
      ) : (
        <img src={media} className={classes.media} alt="poster" />
      )}

      <AnnouncementTextField label="Title" value={title || "N/A"} />

      <AnnouncementTextField label="Subtitle" value={subtitle || "N/A"} />
      <br />
      <Divider />
    </div>
  );
};
