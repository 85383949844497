import * as React from "react";
import { usePermissions } from "react-admin";
import { BarcodeButton } from "./barcode-button";
import {
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import {
  BooleanRoles,
  extractAuthClaims,
  hasPermissions
} from "../../ts/interfaces/role-interface";
import { Item } from "../../ts/interfaces/order-interface";
import {
  MediaBox,
  MediaContainer,
  MediaItem
} from "../generics/media-container";

const useStyles = makeStyles( () =>
  createStyles( {
    table: {
      border: 0,
    },
    accordionSummary: {
      flexDirection: "row-reverse",
    },
  } )
);

interface IItemTable {
  item: Item;
  orderId: string;
  orderStatus: string;
  latestDisputeStatus: string;
  authentication?: {
    status: string;
  };
}

export const ItemTable = ( properties: IItemTable ): JSX.Element =>
{
  const { orderId, orderStatus, item, authentication, latestDisputeStatus } =
    properties;
  const classes = useStyles();
  const { loaded, permissions } = usePermissions();
  const roleClaims = extractAuthClaims( permissions );

  return (
    <>
      <Divider />
      <TableContainer>
        <Table aria-label="simple table" className={classes.table}>
          <TableHead>
            <TableRow className={classes.table}>
              <TableCell className={classes.table} align="left">
                Brand
              </TableCell>
              <TableCell className={classes.table} align="left">
                <Typography variant={"caption"}>Name / Description</Typography>
              </TableCell>
              {item.externalProductId && (
                <TableCell className={classes.table} align="left">
                  <Typography variant={"caption"}>Product ID</Typography>
                </TableCell>
              )}
              {item.description && (
                <TableCell className={classes.table} align="left">
                  <Typography variant={"caption"}>Option</Typography>
                </TableCell>
              )}
              <TableCell className={classes.table} align="left">
                <Typography variant={"caption"}>Quantity</Typography>
              </TableCell>
              {item.productTags?.length && (
                <>
                  <TableCell className={classes.table} align="left">
                    <Typography variant={"caption"}>Final Sale</Typography>
                  </TableCell>
                  <TableCell className={classes.table} align="left">
                    <Typography variant={"caption"}>Tags</Typography>
                  </TableCell>
                </>
              )}
              {item.productInventoryItemId && (
                <TableCell className={classes.table} align="left">
                  <Typography variant={"caption"}>
                    Is Product Inventory Item?
                  </Typography>
                </TableCell>
              )}
              {item.location && (
                <TableCell className={classes.table} align="left">
                  <Typography variant={"caption"}>Location</Typography>
                </TableCell>
              )}
              <TableCell className={classes.table} align="left"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className={classes.table} align="left">
                <Typography>{item.brandName}</Typography>
              </TableCell>
              <TableCell className={classes.table} align="left">
                <Typography>{`${item.productName}${
                  item.productDescription ? " - " + item.productDescription : ""
                }`}</Typography>
              </TableCell>
              {item.externalProductId && (
                <TableCell className={classes.table} align="left">
                  <Typography>{item.externalProductId}</Typography>
                </TableCell>
              )}
              {item.description && (
                <TableCell className={classes.table} align="left">
                  <Typography>{item.description}</Typography>
                </TableCell>
              )}
              <TableCell className={classes.table} align="left">
                <Typography>1</Typography>
              </TableCell>
              {item.productTags?.length && (
                <>
                  <TableCell className={classes.table} align="left">
                    <Typography>{item.isFinalSale ? "Yes" : "No"}</Typography>
                  </TableCell>
                  <TableCell className={classes.table} align="left">
                    <Typography>
                      {item.productTags?.map( ( tag: string ) => tag ).join( ", " )}
                    </Typography>
                  </TableCell>
                </>
              )}
              {item.productInventoryItemId && (
                <TableCell className={classes.table} align="left">
                  <Typography>
                    {item.productInventoryItemId ? "Yes" : "No"}
                  </Typography>
                </TableCell>
              )}
              {item.location && (
                <TableCell className={classes.table} align="left">
                  <Typography>{item.location}</Typography>
                </TableCell>
              )}
              {loaded &&
                hasPermissions(
                  [
                    BooleanRoles.CustomerService,
                    BooleanRoles.RefundCustomerService,
                    BooleanRoles.Fulfillment,
                    BooleanRoles.Admin,
                    BooleanRoles.Partner,
                    BooleanRoles.Seller,
                  ],
                  roleClaims
                ) &&
                orderStatus === "confirmed" && (
                <TableCell className={classes.table} align="left">
                  <BarcodeButton
                    orderId={orderId}
                    skuId={item.skuId}
                    authentication={authentication}
                    latestDisputeStatus={latestDisputeStatus}
                    {...properties}
                  />
                </TableCell>
              )}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Divider />
      <br />
      <MediaBox>
        <Grid>
          <Typography variant={"h6"}>
            <span
              style={{
                fontWeight: "bold",
              }}
            >
              Media:
            </span>
          </Typography>
          <br />
          <Grid container spacing={2}>
            {item.media.map( ( media, index ) =>
            {
              const type = media.type;
              const keyName = "mediaPath";
              return (
                <Grid item xs={3} key={index}>
                  <MediaContainer
                    keyName={keyName}
                    mediaItem={media as unknown as MediaItem}
                    type={type}
                  />
                </Grid>
              );
            } )}
          </Grid>
        </Grid>
      </MediaBox>
    </>
  );
};
