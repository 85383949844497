import * as React from "react";

export function useInterval( callback: () => void, delay: number )
{
  const savedCallback = React.useRef();
  // Remember the latest callback.
  React.useEffect( () =>
  {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    savedCallback.current = callback;
  }, [callback] );

  // Set up the interval.
  React.useEffect( () =>
  {
    // eslint-disable-next-line unicorn/consistent-function-scoping
    function tick()
    {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      savedCallback.current();
    }
    if ( delay !== null )
    {
      const id = setInterval( tick, delay );
      return () => clearInterval( id );
    }
  }, [delay] );
}
