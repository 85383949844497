import * as React from "react";
import { Box } from "@material-ui/core";
import { DataSquares } from "./data-squares";

interface ShopperData {
  handle: string;
  orderCount: number;
  itemCount: number;
}
interface OrderData {
  orderData: {
    totalOrderCount: number;
    totalItemCount: number;
    shoppers?: ShopperData[];
  };
  title: string;
}
export function OrderDataDisplay( props: OrderData ): JSX.Element
{
  const { orderData } = props;
  const displayTitle =
    props.title !== "PAID AND NOT CONFIRMED" ? (
      <h5 style={{ fontWeight: 900, fontSize: "24x" }}>{props.title}</h5>
    ) : (
      <h5 style={{ fontWeight: 900, fontSize: "24x" }}>
        PAID AND{" "}
        <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
          NOT
        </span>{" "}
        CONFIRMED
      </h5>
    );
  return (
    <Box
      style={{
        border: "1px solid",
        borderRadius: "10px",
        margin: "10px",
        padding: "15px 20px",
        borderColor: "#BBBBBB",
        minHeight: "834px",
      }}
    >
      <div style={{ fontWeight: 900, fontSize: "24x", width: "70%" }}>
        {displayTitle}
      </div>
      <Box display="flex">
        <DataSquares
          first={{ label: "Orders", value: orderData.totalOrderCount }}
          second={{ label: "Items", value: orderData.totalItemCount }}
        />
      </Box>
      <Box>
        <Box>
          <table style={{ width: "100%" }}>
            <tbody>
              <tr>
                <th style={{ width: "50%" }}></th>
                <th style={{ textAlign: "center" }}>Orders</th>
                <th style={{ textAlign: "center" }}>Items</th>
              </tr>
              {orderData.shoppers &&
                orderData.shoppers.map( ( shopperData, index ) => (
                  <tr
                    key={`${shopperData.handle}-${props.title}`}
                    style={{ background: index % 2 === 0 ? "#EFEFEF" : "" }}
                  >
                    <td style={{ textAlign: "left" }}>@{shopperData.handle}</td>
                    <td style={{ textAlign: "center" }}>
                      {shopperData.orderCount}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {shopperData.itemCount}
                    </td>
                  </tr>
                ) )}
            </tbody>
          </table>
        </Box>
      </Box>
    </Box>
  );
}
