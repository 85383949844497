import * as React from "react";
import {
  List,
  Datagrid,
  DeleteButton,
  FunctionField,
  useListContext,
  TopToolbar
} from "react-admin";

import { CreditCard } from "../../ts/interfaces/user-interface";
import { BasicPagination, perPage } from "../generics/pagination";
import { DefaultPaymentButton } from "./default-payment-button";

import DoneRoundedIcon from "@material-ui/icons/DoneRounded";
import CloseIcon from "@material-ui/icons/Close";
import { Typography } from "@material-ui/core";
import { UserContextProvider } from "../../contexts/user-context";
import { AddNewCardButton } from "./add-new-card";
import { PopupNotification } from "../popup-notification/popup-notification";

interface ListProperties {
  [key: string]: unknown;
}

interface ListActionProperties {
  filters?: React.ReactElement;
  [key: string]: unknown;
}

export const ListActions: React.FC<ListActionProperties> = () => (
  <TopToolbar>
    <UserContextProvider>
      <AddNewCardButton orderId="id123" />
    </UserContextProvider>
  </TopToolbar>
);

export const CardList = ( properties: ListProperties ): JSX.Element => (
  <List
    {...properties}
    pagination={
      <>
        <BasicPagination />
        <PopupNotification />
      </>
    }
    bulkActionButtons={false}
    exporter={false}
    actions={<ListActions />}
    perPage={perPage}
  >
    <Datagrid rowClick="show">
      <FunctionField
        label={"Card"}
        render={( record: CreditCard ) =>
          `**** **** **** ${record.last4} (${record.brand})`
        }
      />
      <FunctionField
        label={"Expiration date"}
        render={( record: CreditCard ) =>
          `${record.exp_month}/${record.exp_year}`
        }
      />
      <FunctionField
        label={"Subscription"}
        render={( record: CreditCard ) => (
          <Typography>
            {record.isShopperDefaultSubscriptionCardId ? (
              <DoneRoundedIcon fontSize="default" style={{ color: "green" }} />
            ) : (
              <CloseIcon fontSize="default" />
            )}
          </Typography>
        )}
      />
      <FunctionField
        render={( record: CreditCard ) => (
          <DefaultPaymentButton
            isDefaultPayment={record.isShopperDefaultSubscriptionCardId}
            cardId={record.source}
          />
        )}
      />
      <FunctionField
        render={( record: CreditCard ) =>
        {
          const { data } = useListContext();
          return (
            <DeleteButton
              mutationMode="pessimistic"
              disabled={Object.keys( data ).length <= 1}
              record={record}
              undoable={"false"}
              confirmTitle={"Delete Card"}
              confirmContent={`Are you sure you want to delete card *** ${record.last4} (${record.brand})?`}
            />
          );
        }}
      />
    </Datagrid>
  </List>
);
