import * as React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

import {
  TextInput,
  useNotify,
  Edit,
  FormDataConsumer,
  FileInput,
  SaveButton,
  Toolbar
} from "react-admin";

import AddIcon from "@material-ui/icons/AddCircleOutline";
import {
  ArrayInput,
  RemoveItemButton,
  SimpleFormIterator,
  DateTimeInput,
  SimpleForm,
  Button,
  BooleanInput
} from "ra-ui-materialui";
import { useForm } from "react-final-form";

import { Record as RaRecord, required } from "ra-core";
import { v4 } from "uuid";

import { getApp } from "firebase/app";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { Media, MultiTypeFileField } from "../generics/multi-type-file-field";
import {
  Announcement,
  AnnouncementKeys
} from "../../ts/interfaces/announcements";

interface IEditProperties {
  id: string;
  [x: string]: unknown;
  record: RaRecord;
}

interface MediaInput {
  storage: Media;
  title: string;
  subtitle: string;
  mediaPath: string;
  mediaPosterPath: string;
}

const useStyles = makeStyles( ( theme: Theme ) =>
  createStyles( {
    formField: {
      margin: theme.spacing( 1, 2, 1, 0 ),
    },
    clearRemoveButton: {
      "& .previews": {
        "& button": {
          display: "none",
        },
      },
    },
  } )
);

let initialValues: { record?: Partial<Announcement> } = {};

const transform = async ( data: RaRecord ) =>
{
  const initialValuesRecord = initialValues.record;
  console.log( "data", data );
  console.log( "initialValuesRecord", initialValuesRecord );
  const edit: Partial<Announcement> = {
    name: data.name,
    startDate: data.startDate,
    endDate: data.endDate,
    title: data.title || "",
    subtitle: data.subtitle || "",
    isOnboarding: data.isOnboarding,
    media: data.media.map( ( media: MediaInput ) => ( {
      title: media.title || "",
      subtitle: media.subtitle || "",
      mediaPath: media.storage.rawMediaPath || media.mediaPath,
      mediaPosterPath: media.storage.rawPosterPath || media.mediaPosterPath,
      isVideo: media.storage.type === "video",
    } ) ),
  };

  Object.keys( edit ).forEach( ( key: AnnouncementKeys ) =>
  {
    if ( initialValuesRecord[key] === edit[key] )
    {
      delete edit[key];
    }
  } );

  return edit;
};

export const AnnouncementEdit: React.FunctionComponent<IEditProperties> = (
  properties: IEditProperties
) =>
{
  const classes = useStyles();
  const notify = useNotify();

  const [startDate, setStartDate] = React.useState<Date | null>( null );
  const [endDate, setEndDate] = React.useState<Date | null>( null );

  const onFailure = ( error: Error ) =>
  {
    notify( `${error.message}`, "warning" );
  };

  const [mediaUploaded, setMediaUploaded] = React.useState<
    Record<string, boolean>
  >( {} );
  const [saveDisabledOnMediaLoad, setSaveDisabledOnMediaLoad] =
    React.useState<boolean>( false );

  React.useEffect( () =>
  {
    const hasFilesUploading = Object.values( mediaUploaded ).some(
      ( value: boolean ) => value === false
    );
    setSaveDisabledOnMediaLoad( hasFilesUploading );
  }, [mediaUploaded] );

  React.useEffect(
    () =>
      // eslint-disable-next-line unicorn/consistent-function-scoping
      () =>
      {
        initialValues = {};
      },
    []
  );

  return (
    <Edit
      transform={transform}
      onFailure={onFailure}
      undoable={false}
      {...properties}
    >
      <SimpleForm
        toolbar={
          <Toolbar {...properties}>
            <SaveButton disabled={saveDisabledOnMediaLoad} />
          </Toolbar>
        }
      >
        <BooleanInput
          label="Onboarding Announcement"
          source="isOnboarding"
          defaultValue={false}
        />

        <TextInput
          source="name"
          className={classes.formField}
          validate={required()}
        />

        <TextInput source="title" className={classes.formField} />

        <TextInput source="subtitle" className={classes.formField} />

        <DateTimeInput
          label="Start Date for Announcement"
          source="startDate"
          className={classes.formField}
          validate={required()}
          value={startDate}
          onBlur={( date ) =>
            setStartDate( date.target.value as React.SetStateAction<Date> )
          }
        />
        <DateTimeInput
          label="End Date for Announcement"
          source="endDate"
          className={classes.formField}
          validate={required()}
          value={endDate}
          onBlur={( date ) =>
            setEndDate( date.target.value as React.SetStateAction<Date> )
          }
        />

        <FormDataConsumer>
          {( {
            formData,
          }: {
            formData: { media: Record<string, unknown>[] };
          } ) =>
          {
            const form = useForm();
            return (
              <ArrayInput source="media" validate={required( " " )}>
                <SimpleFormIterator
                  addButton={
                    formData?.media?.length >= 5 ? (
                      <></>
                    ) : (
                      <Button
                        label="ra.action.add"
                        style={{ marginTop: 25 }}
                        onClick={() =>
                        {
                          const mediaItems = form.getState().values.media;
                          setTimeout( () =>
                          {
                            form.change(
                              "media",
                              mediaItems.map( ( item: string | undefined ) => item )
                            );
                          }, 10 );
                        }}
                      >
                        <AddIcon />
                      </Button>
                    )
                  }
                  removeButton={
                    formData?.media?.length > 1 ? <RemoveItemButton /> : <></>
                  }
                >
                  <FormDataConsumer>
                    {( {
                      getSource,
                      id,
                    }: {
                      getSource: ( source: string ) => string;
                      id: string;
                    } ) =>
                    {
                      const form = useForm();

                      if ( !initialValues.record )
                      {
                        Object.assign( initialValues, {
                          record: { ...form.getState().initialValues },
                        } );
                      }

                      const parse = React.useCallback( ( file: File ) =>
                      {
                        if ( file )
                        {
                          try
                          {
                            let fileName = file.name;
                            if ( file.type.startsWith( "video" ) )
                            {
                              fileName = "video.mp4";
                            }
                            else if ( file.type.startsWith( "image" ) )
                            {
                              fileName = "poster-raw.jpg";
                            }

                            const basePath = `announcements/${v4()}`;
                            const uploadPath = `${basePath}/${fileName}`;

                            const storeFile = async ( path: string ) =>
                            {
                              const defaultApp = getApp();
                              const storage = getStorage( defaultApp );
                              const storageReference = ref( storage, path );
                              // Upload asynchronously so it doesn't fail the parse
                              setMediaUploaded( ( before ) => ( {
                                ...before,
                                [id]: false,
                              } ) );
                              uploadBytes( storageReference, file, {
                                customMetadata: {
                                  isAnnouncementItem: "true",
                                },
                              } )
                                .then( () =>
                                {
                                  setMediaUploaded( ( before ) => ( {
                                    ...before,
                                    [id]: true,
                                  } ) );
                                } )
                                .catch( ( error ) =>
                                {
                                  setMediaUploaded( ( before ) => ( {
                                    ...before,
                                    [id]: false,
                                  } ) );
                                  notify(
                                    `Failed to upload Media! - ${error.message}`,
                                    "warning"
                                  );
                                } );
                            };

                            // Obtain the blob for display in browser
                            const objectUrl = URL.createObjectURL( file );

                            // So this will just asynchronously trigger
                            if ( objectUrl )
                            {
                              storeFile( uploadPath );
                            }

                            return {
                              rawMediaPath: uploadPath,
                              mediaPath: uploadPath,
                              rawPosterPath: `${basePath}/poster-raw.jpg`,
                              type: file.type.split( "/" )[0],
                              preview: objectUrl,
                              // Need this to recalculate object url and prevent the old url from being removed
                              previewFile: file,
                            };
                          }
                          catch ( error )
                          {
                            console.log( "uploadFile error:", error );
                          }
                        }
                      }, [] );

                      return (
                        <>
                          <FileInput
                            label=""
                            validate={required()}
                            source={getSource( `storage` )}
                            parse={( image: File ) => parse( image )}
                            className={classes.clearRemoveButton}
                          >
                            <MultiTypeFileField
                              source="preview"
                              typeSource="type"
                              formMedia={form.getState()?.values?.media}
                            />
                          </FileInput>
                          <TextInput
                            source={getSource( `title` )}
                            className={classes.formField}
                            label="Title"
                          />

                          <TextInput
                            source={getSource( `subtitle` )}
                            className={classes.formField}
                            label="Subtitle"
                          />
                        </>
                      );
                    }}
                  </FormDataConsumer>
                </SimpleFormIterator>
              </ArrayInput>
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};
