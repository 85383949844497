import {
  PotentialBundle,
  OrderDetails
} from "../../ts/interfaces/potential-bundle-interface";
import { BundleListShow } from "./bundle-expand";
import * as React from "react";
import {
  List,
  Datagrid,
  DateField,
  FunctionField,
  Pagination
} from "react-admin";
import { perPage } from "../generics/pagination";

interface IProperties {
  record?: PotentialBundle;
}
const PostPagination = ( properties: IProperties ) => (
  <Pagination rowsPerPageOptions={[100, 500, 2000]} {...properties} />
);

export const PotentialBundleList = (
  properties: PotentialBundle[]
): JSX.Element => (
  <List
    {...properties}
    pagination={<PostPagination />}
    bulkActionButtons={false}
    exporter={false}
    perPage={perPage}
  >
    <Datagrid rowClick="show" expand={BundleListShow}>
      <FunctionField
        label="Address"
        render={( bundle: PotentialBundle ) =>
        {
          const address = JSON.parse( bundle.addressHash );
          const formatAddress =
            `${address.line1} ${address.city} ${address.state}`.replace(
              /(^\w{1})|(\s+\w{1})/g,
              ( letter: string ) => letter.toUpperCase()
            );
          return formatAddress;
        }}
        sortable={false}
      />
      <DateField
        label="Oldest Order"
        source="oldestOrder.timestamp"
        showTime
        sortable={false}
      />
      <FunctionField
        label="Shoppers"
        render={( bundle: PotentialBundle ) =>
          [
            ...new Set(
              bundle.orderDetails.map(
                ( orderDetails: OrderDetails ) => orderDetails.sellerHandle
              )
            ),
          ].join( ", " )
        }
        sortable={false}
      />
      <FunctionField
        label="Customer"
        render={( bundle: PotentialBundle ) =>
        {
          const firstname = bundle.logistics.details.firstname;
          const lastname = bundle.logistics.details.lastname;
          return `${firstname} ${lastname}`;
        }}
      />
      <FunctionField
        label="# of Orders"
        render={( bundle: PotentialBundle ) => bundle.orderIds.length}
        sortable={false}
      />
    </Datagrid>
  </List>
);
