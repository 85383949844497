import * as React from "react";
import { SimpleShowLayout, Show, FunctionField } from "react-admin";
import { Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { SellerMessages } from "../../ts/interfaces/seller-messages-interface";
import { Spacer } from "../generics/spacer";

interface IShowProperties {
  id: string;
  [x: string]: unknown;
  record: SellerMessages;
}

const useStyles = makeStyles( () =>
  createStyles( {
    bolderRow: {
      fontSize: "1rem",
    },
    longRow: {
      margin: 1,
      overflowWrap: "break-word",
    },
  } )
);

const DateField = ( props: {
  date: Date;
  label: string;
  className?: string;
} ) => (
  <Grid container>
    <Typography variant={"h6"} {...props}>{`${props.label}`}</Typography>
    {props.date ? (
      <p style={{ padding: 2, margin: 1 }}>
        {new Date( props.date ).toLocaleString( "en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        } )}
      </p>
    ) : (
      ""
    )}
  </Grid>
);

const nameFormat = ( inputData: { firstName?: string; lastName?: string } ) =>
{
  const { firstName, lastName } = inputData;
  return (
    ( firstName || "" ) + ( firstName && lastName ? " " : "" ) + ( lastName || "" )
  );
};

const SellerMessagesDetailView = ( sellerMessages: SellerMessages ) =>
{
  const classes = useStyles();
  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={6} sm={6} md={6}>
          <Typography
            variant={"h6"}
            className={classes.bolderRow}
          >{`From: `}</Typography>
          <p className={classes.longRow}>{` ${nameFormat( {
            firstName: sellerMessages.from?.firstName,
            lastName: sellerMessages.from?.lastName,
          } )}`}</p>
          <Spacer />
          <Typography
            variant={"h6"}
            className={classes.bolderRow}
          >{`To: `}</Typography>
          <p className={classes.longRow}>
            {Array.isArray( sellerMessages.receiverHandles )
              ? sellerMessages.receiverHandles.join( " + " )
              : ""}
          </p>
          <Spacer />
          <Typography
            variant={"h6"}
            className={classes.bolderRow}
          >{`Subject line: `}</Typography>
          <p className={classes.longRow}>{sellerMessages.subject || ""}</p>
          <Spacer />
          <Typography
            variant={"h6"}
            className={classes.bolderRow}
          >{`Message: `}</Typography>
          <p className={classes.longRow}>{sellerMessages.message || ""}</p>
          <Spacer />
          <DateField
            label="Date sent: "
            date={sellerMessages.timestamps?.sent}
            className={classes.bolderRow}
          />
          <DateField
            label="Date created: "
            date={sellerMessages.timestamps?.created}
            className={classes.bolderRow}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export const SellerMessagesShow: React.FunctionComponent<IShowProperties> = (
  showProperties
) => (
  <Show {...showProperties} title={`ShopThing`}>
    <SimpleShowLayout>
      <FunctionField
        addLabel={false}
        render={( messages: SellerMessages ) => (
          <SellerMessagesDetailView {...messages} />
        )}
      />
    </SimpleShowLayout>
  </Show>
);
