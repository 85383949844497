import * as React from "react";
import { SelectInput, useGetList, ChoicesInputProps } from "react-admin";
import { useNotify, Record } from "ra-core";
import { TextFieldProps } from "ra-ui-materialui";

interface Option {
  id: string;
  name: string;
}
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
interface SelectInputProps<T> extends ChoicesInputProps<TextFieldProps> {
  data: T[];
  // If an entry is selected that would not be on the first page load, it needs to be provided manually so it can be appended to the list of options
  initialEntry?: Option;
  source: string;
  label?: string;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  // Passed to `useGetList` method to query for the data in the select input
  list: {
    resource: string;
    perPage: number;
    // Used if you want a different field as source for the label in the select display
    nameMappingKey?: string;
    // If you need to do something with the value of the nameMapping key onChange
    storageFunction?: ( id: string, value: unknown ) => void;
    filter: { [key: string]: unknown };
    sort?: {
      field: string;
      order: "ASC" | "DESC";
    };
  };
}

export const PaginatedSelectInput = <T extends Record>(
  properties: SelectInputProps<T>
): JSX.Element =>
{
  const { initialEntry, list, source, label, onChange, ...rest } = properties;
  const [init, setInit] = React.useState( false );
  const [options, setOptions] = React.useState<Option[]>( [] );
  const [fetching, setFetching] = React.useState( true );
  const [page, setPage] = React.useState( 1 );
  const [hasMore, setHasMore] = React.useState( true );
  const notify = useNotify();
  const initialId = initialEntry?.id || "";

  if ( options.length === 0 && initialId && !init )
  {
    setInit( true );
    setOptions( [initialEntry] );
  }

  const { data, loaded, ids, error } = useGetList(
    list.resource,
    { page, perPage: list.perPage },
    list.sort,
    list.filter
  );
  // After we've loaded in more data append it to the <SelectInput> options
  if ( loaded && fetching && hasMore )
  {
    setFetching( false );
    // If we provided an initialEntry already, remove it from the list of data retrieved from the API to prevent duplicates
    const newOptions = Object.values( data )
      .filter( ( data: T ) => data.id !== initialId )
      .map( ( data: T ) => ( {
        id: data.id as string,
        name: data[list.nameMappingKey || "id"],
      } ) );

    const oldOptions = options;
    // If the initialEntry isn't in the list of options already, append it to the start.
    // This scenario can occur when navigating back to this page from another one due to how react-admin preserves the filter's options.
    if ( !init && initialId )
    {
      oldOptions.unshift( initialEntry );
      setInit( true );
    }
    setOptions( oldOptions.concat( newOptions ) );

    // Increment the pagination page
    setPage( page + 1 );
    if ( ids.length === 0 )
    {
      setHasMore( false );
    }
  }
  if ( error )
  {
    notify( `Error loading ${source} filter ${error.message}`, "error" );
  }

  const scrollHandler = ( event: React.UIEvent<HTMLDivElement, UIEvent> ) =>
  {
    const target = event.target as HTMLElement;
    const currentScrollHeight = target.scrollHeight - target.scrollTop;

    const scrollViewHeight = target.clientHeight;
    const scrollThreshold = 100;

    // If we've reached near the end of the scroll, load the next page of data
    if (
      currentScrollHeight - scrollViewHeight <= scrollThreshold &&
      !fetching
    )
    {
      setFetching( true );
    }
  };

  const onChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) =>
  {
    if ( list.storageFunction )
    {
      const target = event.target.value;
      const value =
        options.filter( ( option ) => option.id === target )[0]?.name || target;
      list.storageFunction( target, value );
    }
    if ( onChange )
    {
      onChange( event );
    }
  };

  return (
    <SelectInput
      source={source}
      label={label}
      choices={options}
      onScrollCapture={scrollHandler}
      onChange={onChangeHandler}
      {...rest}
    />
  );
};
