import * as React from "react";
import { AppBar as MuiAppBar, useNotify, useGetOne } from "react-admin";
import { AppBarProps as MuiAppBarProperties } from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { ClipboardInput } from "@shopthing-opn-shared/admin-dashboard";
import { useBarcode } from "../contexts/barcode-context";
import { resetHistory } from "../utils/reset-history";
import { notifyMessage } from "../utils/primitive";
const useStyles = makeStyles( {
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  spacer: {
    flex: 1,
  },
} );

const BundleSearch = (): JSX.Element =>
{
  /* eslint-disable unicorn/consistent-function-scoping */
  const bundleLookup = async () =>
  {
    let retrievedBundledOrderId = "";
    const { payload, fulfillmentDispatch, setBarcode } = useBarcode();
    const { orderId, fulfillmentId } = payload;
    await useGetOne( "potential-bundles-for-order", orderId, {
      onSuccess: ( response: { data: { bundleId: string } } ) =>
      {
        retrievedBundledOrderId = response.data.bundleId;
        const bundleDestination = `${window.location.origin}/#/potential-bundles/${response.data.bundleId}/show`;
        if ( window.location.href !== bundleDestination )
        {
          history.replaceState(
            {
              orderId,
              fulfillmentId,
              bundledOrderId: retrievedBundledOrderId,
            },
            "",
            bundleDestination
          );
          history.go();
        }
        else
        {
          fulfillmentDispatch( {
            orderId,
            fulfillmentId: `${fulfillmentId}`,
            bundledOrderId: retrievedBundledOrderId,
          } );
          setBarcode( "", "", "" );
        }
      },
      onFailure: () =>
      {
        history.replaceState(
          {},
          "",
          `${window.location.origin}#/orders/${orderId}/show`
        );
        history.go();
      },
    } );
    const bundleDestination = `${window.location.origin}/#/potential-bundles/${retrievedBundledOrderId}/show`;
    if (
      retrievedBundledOrderId.length > 0 &&
      window.location.href !== bundleDestination
    )
    {
      setBarcode( "", "", "" );
    }
  };

  bundleLookup();

  return null;
};

export const AppBar: React.FunctionComponent<MuiAppBarProperties> = (
  properties: MuiAppBarProperties
) =>
{
  const [input, setInput] = React.useState( "" );
  const notify = useNotify();
  const classes = useStyles();
  const { setBarcode, orderId, toggleLabel } = useBarcode();
  return (
    <MuiAppBar {...properties}>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      <ClipboardInput
        onChange={( event ) => setInput( event.target.value )}
        value={input}
        onFocus={() => toggleLabel( false )}
        onKeyUp={async ( event: React.KeyboardEvent ) =>
        {
          let confirmedOrderId,
            confirmedFulfillmentId,
            confirmedBundledOrderId,
            confirmedProductInventoryItemId;
          try
          {
            setBarcode( "", "", "" );
            const clipboardPayload = input;
            const {
              orderId,
              fulfillmentId,
              bundledOrderId,
              productInventoryItemId,
            } = JSON.parse( clipboardPayload );
            confirmedOrderId = orderId || "";
            confirmedFulfillmentId = fulfillmentId || "";
            confirmedBundledOrderId = bundledOrderId || "";
            confirmedProductInventoryItemId = productInventoryItemId || "";
          }
          catch ( error )
          {
            if ( event.keyCode === 13 )
            {
              notifyMessage( error, notify );
            }
          }

          console.log( confirmedProductInventoryItemId );
          if ( confirmedBundledOrderId?.length > 0 )
          {
            const bundledOrderDestination = `${window.location.origin}/#/bundled-orders/${confirmedBundledOrderId}/show`;
            if ( window.location.href !== bundledOrderDestination )
            {
              history.replaceState( {}, "", bundledOrderDestination );
              history.go();
              setInput( "" );
              return;
            }
            else
            {
              setInput( "" );
            }
          }
          else if ( confirmedOrderId?.length > 0 )
          {
            setBarcode(
              confirmedOrderId,
              confirmedFulfillmentId,
              confirmedBundledOrderId
            );
            resetHistory();
            setInput( "" );
          }
          else if ( confirmedProductInventoryItemId?.length > 0 )
          {
            const productInventoryItemDestination = `${window.location.origin}/#/product-inventory-items/${confirmedProductInventoryItemId}/show`;
            if ( window.location.href !== productInventoryItemDestination )
            {
              history.replaceState( {}, "", productInventoryItemDestination );
              history.go();
              setInput( "" );
              return;
            }
            else
            {
              setInput( "" );
            }
          }
        }}
        pasteEventHandler={() => null}
      />
      {orderId.length > 0 && <BundleSearch />}
      <span className={classes.spacer} />
    </MuiAppBar>
  );
};
