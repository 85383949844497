import * as React from "react";
import _ from "lodash";

const isValidUrl = ( url: string ) =>
{
  try
  {
    new URL( url );
    return true;
  }
  catch ( error: unknown )
  {
    return false;
  }
};

export interface Media {
  posterPath: string;
  posterUrl?: string;

  mediaHeight: string;
  mediaWidth: string;
  rawMediaPath: string;
  mediaPath: string;
  rawPosterPath: string;
  type: string;
  rawSmallPosterPath: string;

  mediaUrl?: string;
  preview: string;
  previewFile?: File;
}

export const MultiTypeFileField = ( props: {
  record?: Media;
  source: string;
  typeSource: string;
  maxWidth?: string;
  formMedia?: {
    storage: Media;
  }[];
} ) =>
{
  let record = props.record;
  // Find record from current form state
  if ( props.formMedia )
  {
    const media = props.formMedia.filter(
      ( media: { storage: { mediaPath: string } } ) =>
        media?.storage && props.record.mediaPath === media.storage.mediaPath
    );
    if ( media?.length > 0 )
    {
      record = media[0].storage;
    }
  }

  const type = _.get( record, props.typeSource );
  let preview = _.get( record, props.source ) as string;
  const previewFile: File = record?.previewFile as File;
  if ( previewFile && type === "image" )
  {
    preview = URL.createObjectURL( previewFile );
  }

  if ( !preview )
  {
    return <></>;
  }

  const validUrl = isValidUrl( preview );
  const styleProperties: React.CSSProperties = {
    margin: 10,
    ...( props.maxWidth && { maxWidth: props.maxWidth } ),
  };

  if ( validUrl && type === "video" )
  {
    return (
      <>
        <video
          src={preview}
          controls
          height="240"
          style={styleProperties}
        ></video>
      </>
    );
  }

  if ( validUrl && type === "image" )
  {
    return (
      <>
        <img
          title={record["mediaPath"]}
          alt={record["mediaPath"]}
          src={preview}
          height="240"
          style={styleProperties}
        />
      </>
    );
  }

  // Show link to file
  return (
    <>
      <div style={{ margin: 10 }}>
        <a
          href={preview}
          title={record["mediaPath"]}
          target="_blank"
          rel="noreferrer"
        >
          {record["mediaPath"]}
        </a>
      </div>
    </>
  );
};
