import * as React from "react";

import { SelectInput, TextInput, required } from "react-admin";
import { Typography, Grid } from "@material-ui/core";
import { Customer } from "../../../ts/interfaces/user-interface";
import { USStates, ShopThingAddressUS } from "../../../config";

export const StripeIssuingFields: React.FunctionComponent<{
  record?: Customer;
}> = ( { record }: { record?: Customer } ) =>
{
  const validateRequired = required( "Field Required" );

  return record?.isSeller ? (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            {`Cardholder Information`}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextInput
            fullWidth={true}
            source="ccName"
            label="Name"
            validate={validateRequired}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectInput
            fullWidth={true}
            source="ccTypeOfCard"
            label="Type of Card"
            disabled
            required
            choices={[{ id: "physical", name: "Physical" }]}
            initialValue="physical"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectInput
            fullWidth={true}
            source="ccType"
            label="Type"
            disabled
            required
            choices={[{ id: "company", name: "Company" }]}
            initialValue="company"
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            {`Company Information`}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextInput
            fullWidth={true}
            source="ccTaxId"
            label="Tax Id (Business ID)"
            validate={validateRequired}
            initialValue="87-1246256"
          />
        </Grid>

        {/* Billing */}
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            {`Billing Address`}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextInput
            fullWidth={true}
            source="ccBillLine1"
            label="Address Line 1"
            validate={validateRequired}
            initialValue={ShopThingAddressUS.line1}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            fullWidth={true}
            source="ccBillLine2"
            label="Address Line 2"
            initialValue={ShopThingAddressUS.line2 || ""}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            fullWidth={true}
            source="ccBillCity"
            label="City"
            validate={validateRequired}
            initialValue={ShopThingAddressUS.city}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            fullWidth={true}
            source="ccBillPostalCode"
            label="Postal Code / Zip Code"
            validate={validateRequired}
            initialValue={ShopThingAddressUS.postalCode}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectInput
            fullWidth={true}
            source="ccBillState"
            label="State/Province"
            required
            choices={USStates}
            initialValue={ShopThingAddressUS.state.toLowerCase()}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectInput
            fullWidth={true}
            source="ccBillCountry"
            label="Country"
            required
            choices={[{ id: "US", name: "US" }]}
            initialValue={ShopThingAddressUS.country}
          />
        </Grid>

        {/* shipping */}
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            {`Shipping Address`}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">
            Please read the following documentation regarding Shipping Method
            and Type:{" "}
            <a
              href="https://stripe.com/docs/issuing/cards/physical#shipping-your-cards"
              target="_blank"
              rel="noreferrer"
            >
              https://stripe.com/docs/issuing/cards/physical#shipping-your-cards
            </a>
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectInput
            fullWidth={true}
            source="ccShipMethod"
            label="Shipping Method"
            required
            choices={[
              { id: "standard", name: "Standard" },
              { id: "express", name: "Express" },
              { id: "priority", name: "Priority" },
            ]}
            initialValue="standard"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectInput
            fullWidth={true}
            source="ccShipType"
            label="Shipping Type"
            required
            choices={[
              { id: "individual", name: "Individual" },
              { id: "bulk", name: "Bulk" },
            ]}
            initialValue="individual"
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            fullWidth={true}
            source="ccShipName"
            label="Name"
            validate={validateRequired}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            fullWidth={true}
            source="ccShipLine1"
            label="Address Line 1"
            validate={validateRequired}
            initialValue={ShopThingAddressUS.line1}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            fullWidth={true}
            source="ccShipLine2"
            label="Address Line 2"
            initialValue={ShopThingAddressUS.line2 || ""}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            fullWidth={true}
            source="ccShipCity"
            label="City"
            validate={validateRequired}
            initialValue={ShopThingAddressUS.city}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            fullWidth={true}
            source="ccShipPostalCode"
            label="Postal Code / Zip Code"
            validate={validateRequired}
            initialValue={ShopThingAddressUS.postalCode}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectInput
            fullWidth={true}
            source="ccShipState"
            label="State/Province"
            required
            choices={USStates}
            initialValue={ShopThingAddressUS.state.toLowerCase()}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectInput
            fullWidth={true}
            source="ccShipCountry"
            label="Country"
            required
            choices={[{ id: "US", name: "US" }]}
            initialValue={ShopThingAddressUS.country}
          />
        </Grid>
      </Grid>
    </>
  ) : null;
};
