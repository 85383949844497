import * as React from "react";
import _ from "lodash";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Event } from "../../ts/interfaces/event-interface";
import {
  NumberInput,
  Edit,
  SimpleForm,
  TextInput,
  DateTimeInput,
  FormDataConsumer,
  useNotify,
  SaveButton,
  RadioButtonGroupInput,
  Toolbar,
  ReferenceInput,
  SelectInput,
  BooleanInput
} from "react-admin";
import { Record } from "ra-core";
import { Spacer } from "../generics/spacer";

interface IShowProperties {
  [x: string]: unknown;
  record: Event;
}

const useStyles = makeStyles( ( theme: Theme ) =>
  createStyles( {
    formField: {
      margin: theme.spacing( 1, 2, 1, 0 ),
    },
  } )
);

export const EventConditionalToolbar: React.FunctionComponent<
  IShowProperties
> = ( properties: IShowProperties ) => (
  <Toolbar {...properties}>{<SaveButton />}</Toolbar>
);

const transform = ( data: Record ) =>
  _( {} )
    .assign( {
      isClosetSale: data.isClosetSale,
      partnership: data.partnership,
    } )
    .assign(
      new Date( data.startDate ) >= new Date() && {
        exclusiveEvent: data.exclusiveEvent,
        earlyAccess: data.earlyAccess,
        earlyAccessDelayMinutes: data.earlyAccessDelayMinutes,
      }
    )
    .assign(
      data.isPinned !== _.get( initiateEventData, "event.isPinned" ) && {
        isPinned: data.isPinned,
      }
    )
    .value();

const initiateEventData: { event?: Event } = {};

export const EventEdit: React.FunctionComponent<IShowProperties> = (
  showProperties
) =>
{
  const notify = useNotify();
  const onSuccess = () =>
  {
    notify( "Event Updated" );
    setTimeout( () =>
    {
      window.location.reload();
    }, 1500 );
  };

  const onFailure = ( error: Error ) =>
  {
    notify( `Failed updating event  ${error.message}`, "warning" );
  };

  const classes = useStyles();
  return (
    <Edit
      transform={transform}
      undoable={false}
      onSuccess={onSuccess}
      onFailure={onFailure}
      {...showProperties}
    >
      <SimpleForm
        toolbar={<EventConditionalToolbar record={showProperties.record} />}
      >
        <FormDataConsumer>
          {( data: { record: Event } ) =>
          {
            Object.assign( initiateEventData, { event: data.record } );
          }}
        </FormDataConsumer>
        <>
          <h2>Event Details</h2>
          <TextInput disabled source="id" className={classes.formField} />
          <DateTimeInput
            disabled
            label="Start Date"
            source="startDate"
            className={classes.formField}
          />
          <DateTimeInput
            disabled
            label="End Date"
            source="endDate"
            className={classes.formField}
          />
          <ReferenceInput
            label="Partner"
            name="partnership"
            source="partnership"
            reference="partnerships"
          >
            <SelectInput optionText="name" resettable />
          </ReferenceInput>
          <FormDataConsumer>
            {( { formData }: { formData: Event } ) => (
              <BooleanInput
                label="Pin Event"
                source="isPinned"
                defaultValue={false}
                disabled={
                  new Date( formData.endDate ) <= new Date() ||
                  formData.isUsedInCollaboration
                }
              />
            )}
          </FormDataConsumer>

          <FormDataConsumer>
            {( { formData }: { formData: Event } ) => (
              <BooleanInput
                label="Closet Sale Event"
                source="isClosetSale"
                defaultValue={false}
                disabled={formData.isRepeated}
              />
            )}
          </FormDataConsumer>
        </>
        <Spacer />
        <FormDataConsumer>
          {( { formData }: { formData: Event } ) =>
          {
            let initialCheckedValue = formData.eventType || "none";
            if ( formData.earlyAccess && initialCheckedValue === "none" )
            {
              initialCheckedValue = "earlyAccess";
            }
            else if (
              formData.exclusiveEvent &&
              initialCheckedValue === "none"
            )
            {
              initialCheckedValue = "exclusiveEvent";
            }

            if ( formData.eventType === "none" )
            {
              formData.earlyAccess = false;
              formData.exclusiveEvent = false;
            }
            else if ( formData.eventType === "earlyAccess" )
            {
              formData.earlyAccess = true;
              formData.exclusiveEvent = false;
            }
            else if ( formData.eventType === "exclusiveEvent" )
            {
              formData.earlyAccess = false;
              formData.exclusiveEvent = true;
            }
            return (
              <>
                <h2>Event Access Details</h2>
                <RadioButtonGroupInput
                  source="eventType"
                  initialValue={initialCheckedValue}
                  disabled={new Date( formData.startDate ) < new Date()}
                  choices={[
                    { id: "earlyAccess", name: "Early Access" },
                    { id: "exclusiveEvent", name: "Exclusive event" },
                    { id: "none", name: "None" },
                  ]}
                />
                <br />
                {formData.earlyAccess ? (
                  <NumberInput
                    source="earlyAccessDelayMinutes"
                    label="Delay in Minutes"
                    disabled={new Date( formData.startDate ) < new Date()}
                    min={1}
                    defaultValue={30}
                  ></NumberInput>
                ) : null}
              </>
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};
