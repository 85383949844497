import { Persistence } from "@shopthing-opn-shared/admin-dashboard";
import { Address } from "src/ts/interfaces/order-interface";

export const config = {
  dataProviderRoot: ` ${process.env.API_ROOT}`,
  firebase: {
    apiKey: `${process.env.FIREBASE_API_KEY}`,
    authDomain: `${process.env.FIREBASE_AUTH_DOMAIN}`,
    databaseURL: `${process.env.FIREBASE_DATABASEURL}`,
    projectId: `${process.env.FIREBASE_PROJECT}`,
    storageBucket: `${process.env.FIREBASE_STORAGEBUCKET}`,
    messageSenderId: `${process.env.FIREBASE_MESSAGE_SENDER_ID}`,
    persistence: "local" as Persistence.local,
  },
  dashboards: { realTimeOrderStatus: { refreshDelayMs: 30000 } },
  stripe: {
    publishableKey: process.env.STRIPE_PUBLISHABLE_KEY,
  },
  stripeUS: {
    publishableKey: process.env.US_STRIPE_PUBLISHABLE_KEY,
  },
  launchDarkly: {
    clientSideID: process.env.LAUNCHDARKLY_CLIENT_SIDE_ID,
  },
};

export const USStates = [
  { id: "al", name: "Alabama" },
  { id: "ak", name: "Alaska" },
  { id: "az", name: "Arizona" },
  { id: "ar", name: "Arkansas" },
  { id: "ca", name: "California" },
  { id: "co", name: "Colorado" },
  { id: "ct", name: "Connecticut" },
  { id: "de", name: "Delaware" },
  { id: "fl", name: "Florida" },
  { id: "ga", name: "Georgia" },
  { id: "hi", name: "Hawaii" },
  { id: "id", name: "Idaho" },
  { id: "il", name: "Illinois" },
  { id: "in", name: "Indiana" },
  { id: "ia", name: "Iowa" },
  { id: "ks", name: "Kansas" },
  { id: "ky", name: "Kentucky" },
  { id: "la", name: "Louisiana" },
  { id: "me", name: "Maine" },
  { id: "md", name: "Maryland" },
  { id: "ma", name: "Massachusetts" },
  { id: "mi", name: "Michigan" },
  { id: "mn", name: "Minnesota" },
  { id: "ms", name: "Mississippi" },
  { id: "mo", name: "Missouri" },
  { id: "mt", name: "Montana" },
  { id: "ne", name: "Nebraska" },
  { id: "nv", name: "Nevada" },
  { id: "nh", name: "New Hampshire" },
  { id: "nj", name: "New Jersey" },
  { id: "nm", name: "New Mexico" },
  { id: "ny", name: "New York" },
  { id: "nc", name: "North Carolina" },
  { id: "nd", name: "North Dakota" },
  { id: "oh", name: "Ohio" },
  { id: "ok", name: "Oklahoma" },
  { id: "or", name: "Oregon" },
  { id: "pa", name: "Pennsylvania" },
  { id: "ri", name: "Rhode Island" },
  { id: "sc", name: "South Carolina" },
  { id: "sd", name: "South Dakota" },
  { id: "tn", name: "Tennessee" },
  { id: "tx", name: "Texas" },
  { id: "ut", name: "Utah" },
  { id: "vt", name: "Vermont" },
  { id: "va", name: "Virginia" },
  { id: "wa", name: "Washington" },
  { id: "wv", name: "West Virginia" },
  { id: "wi", name: "Wisconsin" },
  { id: "wy", name: "Wyoming" },
  { id: "dc", name: "District of Columbia" },
];

export const CAProvinces = [
  { id: "ab", name: "Alberta" },
  { id: "bc", name: "British Columbia" },
  { id: "mb", name: "Manitoba" },
  { id: "nb", name: "New Brunswick" },
  { id: "nl", name: "Newfoundland and Labrador" },
  { id: "nt", name: "Northwest Territories" },
  { id: "ns", name: "Nova Scotia" },
  { id: "nu", name: "Nunavut" },
  { id: "on", name: "Ontario" },
  { id: "pe", name: "Prince Edward Island" },
  { id: "qc", name: "Quebec" },
  { id: "sk", name: "Saskatchewan" },
  { id: "yt", name: "Yukon" },
];

export const ShopThingAddress = {
  firstname: "",
  lastname: "",
  company: "ST (CAN)",
  line1: "130 Merton St",
  line2: "Unit 100",
  city: "Toronto",
  state: "Ontario",
  postalCode: "M4S 3G9",
  country: "CA",
  phone: "(647) 670-0501",
} as Address;

export const ShopThingAddressUS = {
  firstname: "",
  lastname: "",
  company: "ST (USA)",
  line1: "65 Railroad Avenue",
  line2: "Suite 102-B",
  city: "Ridgefield",
  state: "NJ",
  postalCode: "07657",
  country: "US",
  phone: "(647) 670-0501",
} as Address;
