import _ from "lodash";
import React from "react";
import { IconButton, Typography, Link } from "@material-ui/core";
import { CustomModal } from "@shopthing-opn-shared/admin-dashboard";
import { CreditCardForm } from "./credit-card-input";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { config } from "../../config";
import { useUserContext } from "../../contexts/user-context";

const stripePromise = loadStripe( config.stripe.publishableKey );
const stripeUsPromise = loadStripe( config.stripeUS.publishableKey );

interface AddNewCardButton {
  orderId: string;
}

enum accountSource {
  us = "US",
  ca = "CA",
}

export const AddNewCardButton: React.FunctionComponent<
  AddNewCardButton
> = () =>
{
  const { stripeAccountSource } = useUserContext();
  const elementStripe =
    _.upperCase( stripeAccountSource ) === accountSource.ca
      ? stripePromise
      : stripeUsPromise;

  const [open, setOpen] = React.useState( false );
  const handleOpen = () =>
  {
    setOpen( true );
  };

  const handleClose = () =>
  {
    setOpen( false );
  };

  const body = (
    <div>
      <Elements stripe={elementStripe}>
        <CreditCardForm />
      </Elements>
    </div>
  );

  return _.isEmpty( stripeAccountSource ) ? null : (
    <>
      <IconButton
        onClick={handleOpen}
        color={"primary"}
        disableRipple
        disableFocusRipple
      >
        <Typography>
          <Link>ADD NEW CARD</Link>
        </Typography>
      </IconButton>
      <CustomModal open={open} onClose={handleClose}>
        {body}
      </CustomModal>
    </>
  );
};
