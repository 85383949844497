import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button
} from "@material-ui/core";
import { useRedirect } from "react-admin";

export const SettingsList = (): JSX.Element =>
{
  const redirect = useRedirect();

  const handleCustomRedirect = ( page: string ) =>
  {
    redirect( `/settings/${page}` ); // Redirect to a custom page
  };

  //list of settings - avoid calling API
  const settings = [
    { id: 1, name: "Main", page: "main" },
    { id: 2, name: "Shipping labels", page: "shipping-labels" },
  ];

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {settings.map( ( setting ) => (
            <TableRow key={setting.id} hover>
              <TableCell>
                <Button
                  onClick={() => handleCustomRedirect( setting.page )}
                  variant="contained"
                >
                  {setting.name}
                </Button>
              </TableCell>
            </TableRow>
          ) )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
