import * as React from "react";
import { useGetOne } from "react-admin";
import { Typography, Box, Button } from "@material-ui/core";
import { ReturnShippingPurchase } from "./return-shipping-purchase";
import { resetHistory } from "../../../utils/reset-history";
import { Spacer } from "../../generics/spacer";
import { useBarcode } from "../../../contexts/barcode-context";
import { PopupNotificationType } from "../../popup-notification/popup-notification";
import { ShipmentData } from "../../../ts/interfaces/shipping-interface";

interface IShippingRates {
  requestData: unknown;
  fulfillmentId: string;
  orderId: string;
  currentRate: { shipmentId: string; rate: ReturnShippingRate };
  setCurrentRate: React.Dispatch<
    React.SetStateAction<{ shipmentId: string; rate: ReturnShippingRate }>
  >;
}

export interface ReturnShippingRate {
  carrier: string;
  currency: string;
  deliverDate: string;
  deliveryDays: number;
  deliveryGuarantee?: boolean | null;
  estDeliveryDays: number;
  id: string;
  rate: string;
  service: string;
  shipmentId: string;
}

export const ReturnShippingRates = (
  properties: IShippingRates
): JSX.Element =>
{
  const { requestData, fulfillmentId, orderId } = properties;
  const { setNotification } = useBarcode();
  const [rates, setRates] = React.useState( [] );
  const [shipment, setShipment] = React.useState( null );

  const { loading } = useGetOne(
    "return-shipping-labels",
    { requestData },
    {
      onSuccess: ( response: {
        data: {
          id: string;
          rates: ReturnShippingRate[];
          shipmentData?: ShipmentData;
        };
      } ) =>
      {
        setRates( response.data.rates );
        setShipment( response.data.shipmentData );
        if ( response.data.rates.length === 0 )
        {
          setNotification( {
            message: "No shipping methods found",
            type: PopupNotificationType.error,
          } );
        }
      },
      onFailure: () =>
      {
        setTimeout( () =>
        {
          resetHistory();
          history.go();
        }, 2000 );
      },
    }
  );

  const currentLabel = Object.keys( properties.currentRate ).length > 0;

  return (
    <>
      <Typography variant="h5" gutterBottom>
        {`Shipping Methods`}
      </Typography>
      {!currentLabel &&
        !loading &&
        rates.length > 0 &&
        rates.map( ( rate: ReturnShippingRate ) => (
          <div key={rate.id}>
            <Box display={"flex"} alignItems={"center"}>
              <Typography>
                {rate.carrier} {rate.service}
                {rate.estDeliveryDays && " - Delivers in "}
                {rate.estDeliveryDays && `${rate.estDeliveryDays} days`}
              </Typography>
              <div>&nbsp;&nbsp;</div>
              <Button
                color={"secondary"}
                variant={"outlined"}
                onClick={() =>
                {
                  const shipmentId = rate.shipmentId;
                  properties.setCurrentRate( { shipmentId, rate } );
                }}
              >
                {rate.currency}
                {" $"}
                {rate.rate}
              </Button>
            </Box>
            <Spacer />
          </div>
        ) )}
      {currentLabel && (
        <ReturnShippingPurchase
          shippingId={properties.currentRate.rate.shipmentId}
          fulfillmentId={fulfillmentId}
          orderId={orderId}
          rateId={properties.currentRate.rate.id}
          rate={properties.currentRate.rate}
          shipmentData={shipment}
        />
      )}
    </>
  );
};
