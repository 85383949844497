import React from "react";
import { Order } from "src/ts/interfaces/order-interface";
import NewWindow from "react-new-window";

interface IPackingSlipProperties {
  order: Order;
}
export const PickupPackingSlip = (
  properties: IPackingSlipProperties
): JSX.Element =>
{
  const { order } = properties;
  const item = order.cart.items[0];
  const image = item.media[0].productPoster;
  const brand = item.brandName;
  const productName = item.productName;
  const size = item.description;
  const skuid = item.skuId;
  const quantity = 1;
  return (
    <NewWindow
      key={order.id}
      name={`Bundled Order ${order.id}`}
      features={{ width: 700, height: 800 }}
      copyStyles={true}
    >
      <div className="container">
        <div className="row">
          <div className="col-8 valign-center">
            <br />
            <h4>ShopThing Packaging Slip</h4>
          </div>
          <div className="col-4">
            <img
              src="https://static1.squarespace.com/static/5cfdb3c88438ae000157a60e/t/5d015726350842000160d98d/1603719698581/?format=1500w"
              height="100px"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-8 valign-center">
            <h4>{"Hand off to:"}</h4>
            <br />
            {order.customer.firstname} {order.customer.lastname}
            <br />
            {order.customer.phone ? `${order.customer.phone}` : ""}
            <br />
          </div>
          <div className="col-4" style={{ width: "281.42px" }}>
            <br />
            <div style={{ paddingLeft: "88.705px" }} id="qrcode"></div>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col">
            <h4>Items Purchased:</h4>
            <br />
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col" colSpan={2}>
                    Item
                  </th>
                  <th scope="col">Quantity</th>
                </tr>
              </thead>
              <tbody>
                <tr key={order.id}>
                  <th scope="row">1</th>
                  <td>
                    <img
                      style={{ objectFit: "cover" }}
                      width={50}
                      height={50}
                      src={`${image}`}
                    />
                  </td>
                  <td>
                    <strong>
                      {brand} {productName}
                    </strong>
                    <br />
                    {size} {skuid}
                  </td>
                  <td>{quantity}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <>
          Email us at{" "}
          <a href="mailto: hello@shopthing.com">hello@shopthing.com</a> for any
          enquiries about your order.
        </>
      </div>
    </NewWindow>
  );
};
