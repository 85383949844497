import * as React from "react";

import {
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Divider
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import { Fulfillments, Order } from "src/ts/interfaces/order-interface";
import { ImageButton } from "../potential-bundles/image-button";
import { Spacer } from "../generics/spacer";

const useStyles = makeStyles( () =>
  createStyles( {
    table: {
      border: 0,
    },
  } )
);

interface IBundledOrdersTable {
  orders: Order[];
  bundleId: string;
  [x: string]: unknown;
}

export const BundledOrderTable = (
  properties: IBundledOrdersTable
): JSX.Element =>
{
  const { bundleId, orders } = properties;

  const classes = useStyles();

  return (
    <>
      <>
        <Divider />
        <TableContainer>
          {orders.map( ( order, orderDetailIndex ) =>
          {
            const orderId = order.id;

            return (
              <div key={`${bundleId}-${orderId}-${orderDetailIndex}`}>
                <Spacer />
                <Typography>Order {orderId}</Typography>
                <Table aria-label="simple table" className={classes.table}>
                  <TableHead>
                    <TableRow className={classes.table}>
                      <TableCell className={classes.table} align="left">
                        <Typography variant={"caption"}>Seller</Typography>
                      </TableCell>
                      <TableCell className={classes.table} align="left">
                        <Typography variant={"caption"}>Brand</Typography>
                      </TableCell>
                      <TableCell className={classes.table} align="left">
                        <Typography variant={"caption"}>Product</Typography>
                      </TableCell>
                      <TableCell className={classes.table} align="left">
                        <Typography variant={"caption"}>Size</Typography>
                      </TableCell>
                      <TableCell className={classes.table} align="left">
                        <Typography variant={"caption"}>SKU</Typography>
                      </TableCell>
                      <TableCell className={classes.table} align="center">
                        <Typography variant={"caption"}>Quantity</Typography>
                      </TableCell>
                      {order?.logistics.delivery && (
                        <TableCell className={classes.table} align="center">
                          <Typography variant={"caption"}>
                            Billing Match
                          </Typography>
                        </TableCell>
                      )}
                      <TableCell className={classes.table} align="center">
                        <Typography variant={"caption"}>Type</Typography>
                      </TableCell>
                      <TableCell className={classes.table} align="center">
                        <ImageButton
                          imageUrl={order.cart.items[0].media[0].productPoster}
                        />
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell className={classes.table} align="left">
                        <Typography align="left">
                          {order.seller.handle}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.table} align="left">
                        <Typography align="left">
                          {order.cart.items[0].brandName}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.table} align="left">
                        <Typography align="left">
                          {order.cart.items[0].productName}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.table} align="left">
                        <Typography align="left">
                          {order.cart.items[0].description}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.table} align="left">
                        <Typography align="left">
                          {order.cart.items[0].skuId}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.table} align="center">
                        <Typography align="center">
                          {order.cart.items[0].quantity}
                        </Typography>
                      </TableCell>
                      {order.logistics.delivery && (
                        <TableCell className={classes.table} align="center">
                          {order.billingMatch && <CheckIcon />}
                        </TableCell>
                      )}
                      <TableCell className={classes.table} align="center">
                        <Typography align="center">
                          {order.logistics.delivery
                            ? order.logistics.type +
                              " - " +
                              order.logistics.option
                            : order.logistics.type}
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={classes.table}
                        align="center"
                      ></TableCell>
                    </TableRow>
                  </TableBody>
                </Table>

                {order.fulfillments &&
                  Object.values( order.fulfillments ).map(
                    ( fulfillment: Fulfillments, fulfillmentIndex ) => (
                      <div
                        key={`${bundleId}-${orderId}-${orderDetailIndex}-${fulfillmentIndex}`}
                      >
                        <Table
                          aria-label="simple table"
                          className={classes.table}
                        >
                          <TableHead>
                            <TableRow className={classes.table}>
                              <TableCell className={classes.table} align="left">
                                <Typography variant={"caption"}>
                                  Fulfillment ID
                                </Typography>
                              </TableCell>
                              <TableCell className={classes.table} align="left">
                                <Typography variant={"caption"}>
                                  Sku ID
                                </Typography>
                              </TableCell>
                              <TableCell className={classes.table} align="left">
                                <Typography variant={"caption"}>
                                  Quantity
                                </Typography>
                              </TableCell>
                              <TableCell className={classes.table} align="left">
                                <Typography variant={"caption"}>
                                  Location
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell className={classes.table} align="left">
                                <Typography align="left">
                                  {fulfillment.externalId}
                                </Typography>
                              </TableCell>
                              <TableCell className={classes.table} align="left">
                                <Typography align="left">
                                  {fulfillment.items[0].skuId}
                                </Typography>
                              </TableCell>
                              <TableCell className={classes.table} align="left">
                                <Typography align="left">
                                  {fulfillment.items[0].quantity}
                                </Typography>
                              </TableCell>
                              <TableCell className={classes.table} align="left">
                                <Typography align="left">
                                  {fulfillment.location || ""}
                                </Typography>
                              </TableCell>
                              <TableCell
                                className={classes.table}
                                align="left"
                              ></TableCell>
                              <TableCell className={classes.table} align="left">
                                <Typography align="left"></Typography>
                              </TableCell>
                              <TableCell
                                className={classes.table}
                                align="center"
                              ></TableCell>
                              <TableCell
                                className={classes.table}
                                align="center"
                              ></TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </div>
                    )
                  )}
                <Divider />
              </div>
            );
          } )}
        </TableContainer>
      </>
    </>
  );
};
