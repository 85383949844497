import * as React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { getFile } from "@shopthing-opn-shared/admin-dashboard";

import {
  Edit,
  FormTab,
  TabbedForm,
  TextInput,
  BooleanInput,
  useRefresh,
  useNotify,
  ReferenceManyField,
  Datagrid,
  TextField,
  useUpdate,
  BooleanField,
  Button
} from "react-admin";

import { Record } from "ra-core";
import { SaveOnlyToolbar } from "../toolbars/save-only";
import { Editorial, FeedEntryType } from "../../ts/interfaces/event-interface";
import { BasicPagination } from "../generics/pagination";
import { TrailerUpload } from "../generics/trailer-media";
import { getApp } from "firebase/app";
import { notifyMessage } from "../../utils/primitive";
interface IEditProperties {
  id: string;
  [x: string]: unknown;
  record: Editorial;
}

const useStyles = makeStyles( ( theme: Theme ) =>
  createStyles( {
    formField: {
      margin: theme.spacing( 1, 2, 1, 0 ),
    },
    media: {
      maxWidth: "100%",
      maxHeight: "500px",
    },
  } )
);

interface IRemoveButton {
  editorialId: string;
  record: Record;
}
interface IAddButton {
  editorialId: string;
  editorialProductIds: string[];
  record: Record;
}

const RemoveFromEditorialButton: React.FunctionComponent<IRemoveButton> = (
  properties
) =>
{
  const notify = useNotify();
  const [update] = useUpdate(
    "editorial-removal",
    properties.editorialId,
    {
      productIds: [properties.record?.id],
    },
    {},
    {
      onSuccess: () =>
      {
        window.location.reload();
      },
      onFailure: ( error: Error ) =>
      {
        notifyMessage( error, notify );
      },
    }
  );

  return <Button onClick={update} label="Remove From Editorial" />;
};

const AddToEditorialButton: React.FunctionComponent<IAddButton> = (
  properties
) =>
{
  const notify = useNotify();
  const [update] = useUpdate(
    "editorial-addition",
    properties.editorialId,
    {
      productIds: [properties.record?.id],
    },
    {},
    {
      onSuccess: () =>
      {
        window.location.reload();
      },
      onFailure: ( error: Error ) =>
      {
        notifyMessage( error, notify );
      },
    }
  );

  if ( properties.editorialProductIds?.includes( String( properties.record?.id ) ) )
  {
    return <Button disabled label="Already in Editorial" />;
  }

  return <Button onClick={update} label="Add to Editorial" />;
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const EditorialForm: React.FC = ( { record }: { record: Editorial } ) =>
{
  const classes = useStyles();
  const app = getApp();

  const [poster, setPoster] = React.useState( "" );
  const [trailer, setTrailer] = React.useState( "" );
  React.useEffect( () =>
  {
    if ( record?.trailerPosterPath )
    {
      loadFile( record.trailerPosterPath, setPoster );
    }
    if ( record?.trailerPath )
    {
      loadFile( record.trailerPath, setTrailer );
    }
  } );

  async function loadFile( filePath: string, setter: ( value: string ) => void )
  {
    const posterUrl = await getFile( filePath, app );
    setter( posterUrl );
  }

  return (
    <React.Fragment>
      <TextInput disabled source="id" className={classes.formField} />
      <TextInput source="name" className={classes.formField} />
      <BooleanInput source="isActive" className={classes.formField} />
      <div>
        <h2>Editorial Trailer</h2>
        {trailer ? (
          <video src={trailer} className={classes.media} controls />
        ) : null}
      </div>
      <div>
        <h2>Editorial Preview Poster</h2>
        {poster ? <img src={poster} className={classes.media} /> : null}
      </div>
    </React.Fragment>
  );
};

const transform = async ( data: Record ) =>
{
  const update = {
    id: data.id,
    updates: {
      name: data.name,
      isActive: data.isActive,
      trailerPosterPath: await Promise.resolve( data.trailerPosterPath ),
      trailerPath: await Promise.resolve( data.trailerPath ),
    },
  };

  return update;
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const AddProductToEditorial: React.FC = ( properties: { record: Editorial } ) =>
{
  const editorialId = properties.record.id;
  const editorialProductIds = properties.record.productIds;
  return (
    <ReferenceManyField
      reference="products"
      target="id"
      filter={{}}
      fullWidth
      pagination={<BasicPagination />}
      {...properties}
    >
      <Datagrid>
        <TextField label="Product Id" source="id" sortable={false} />
        <TextField label="Brand Name" source="brandName" sortable={false} />
        <TextField label="Product Name" source="productName" sortable={false} />
        <BooleanField label="Is Active" source="isActivated" sortable={false} />
        <BooleanField
          label="Is Published"
          source="isPublished"
          sortable={false}
        />
        <TextField label="Shopper" source="sellerHandle" sortable={false} />
        <TextField label="Seller Id" source="sellerId" sortable={false} />
        <AddToEditorialButton
          editorialId={editorialId}
          editorialProductIds={editorialProductIds}
          record={properties.record}
        />
      </Datagrid>
    </ReferenceManyField>
  );
};

export const EditorialEdit: React.FunctionComponent<IEditProperties> = (
  properties: IEditProperties
) =>
{
  const refresh = useRefresh();
  const notify = useNotify();
  const onSuccess = () =>
  {
    notify( "Editorial Updated" );
    refresh();
  };

  const onFailure = ( error: Error ) =>
  {
    notify( `Failed updating editorial  ${error.message}`, "warning" );
  };

  return (
    <Edit
      undoable={false}
      transform={transform}
      onSuccess={onSuccess}
      onFailure={onFailure}
      {...properties}
    >
      <TabbedForm toolbar={<SaveOnlyToolbar />}>
        <FormTab label="Editorial">
          <EditorialForm />
        </FormTab>
        <FormTab label="Media">
          <TrailerUpload
            uploadPath={`editorials/${
              properties.id
            }/${new Date().toISOString()}`}
            label="Editorial"
          />
        </FormTab>
        <FormTab label="Products">
          <ReferenceManyField
            reference="event-products"
            target="id"
            filter={{ type: FeedEntryType.editorial }}
            fullWidth
            pagination={<BasicPagination />}
          >
            <Datagrid>
              <TextField sortable={false} label="Product Id" source="id" />
              <TextField
                sortable={false}
                label="Brand Name"
                source="brandName"
              />
              <TextField
                sortable={false}
                label="Product Name"
                source="productName"
              />
              <BooleanField
                sortable={false}
                label="Is Active"
                source="isActivated"
              />
              <BooleanField
                sortable={false}
                label="Is Published"
                source="isPublished"
              />
              <TextField
                sortable={false}
                label="Shopper"
                source="sellerHandle"
              />
              <TextField sortable={false} label="Seller Id" source="sellerId" />
              <RemoveFromEditorialButton
                editorialId={properties.id}
                record={properties.record}
              />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
        <FormTab label="Add Product To Editorial">
          <AddProductToEditorial />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
