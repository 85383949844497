import * as React from "react";
import { useGetList, useNotify } from "react-admin";
import { Select, MenuItem } from "@material-ui/core";

export interface Option {
  id: string;
  [name: string]: string;
}
interface SelectProps {
  nameMappingKey: string;
  label?: string;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  selectedOption: Option;
  options?: Option[];
  // Passed to `useGetList` method to query for the data in the select input
  disabled?: boolean;
  list: {
    resource: string;
    perPage: number;
    // Used if you want a different field as source for the label in the select display
    nameMappingKey?: string;
    // If you need to do something with the value of the nameMapping key onChange
    filter: { [key: string]: unknown };
    sort?: {
      field: string;
      order: "ASC" | "DESC";
    };
  };
}

export const PaginatedSelect = ( properties: SelectProps ): JSX.Element =>
{
  const {
    options: initialOptions = [],
    list,
    label,
    nameMappingKey,
    onChange,
    selectedOption,
    disabled,
    ...rest
  } = properties;
  const [options, setOptions] = React.useState<Option[]>( [...initialOptions] );
  const [fetching, setFetching] = React.useState( true );
  const [page, setPage] = React.useState( 1 );
  const [hasMore, setHasMore] = React.useState( true );
  const notify = useNotify();

  const { data, loaded, ids, error } = useGetList(
    list.resource,
    { page, perPage: list.perPage },
    list.sort,
    list.filter
  );
  // After we've loaded in more data append it to the <SelectInput> options
  if ( loaded && fetching && hasMore )
  {
    setFetching( false );
    const newOptions = Object.values( data ).map( ( data: Option ) => ( {
      id: data.id as string,
      name: data[nameMappingKey],
    } ) );

    const oldOptions = options;

    setOptions( oldOptions.concat( newOptions ) );

    // Increment the pagination page
    setPage( page + 1 );
    if ( ids.length === 0 )
    {
      setHasMore( false );
    }
  }
  if ( error )
  {
    notify( `Error loading ${list.resource} list ${error.message}`, "error" );
  }

  const scrollHandler = ( event: React.UIEvent<HTMLDivElement, UIEvent> ) =>
  {
    const target = event.target as HTMLElement;
    const currentScrollHeight = target.scrollHeight - target.scrollTop;

    const scrollViewHeight = target.clientHeight;
    const scrollThreshold = 100;

    // If we've reached near the end of the scroll, load the next page of data
    if (
      currentScrollHeight - scrollViewHeight <= scrollThreshold &&
      !fetching
    )
    {
      setFetching( true );
    }
  };

  const onChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) =>
  {
    if ( onChange )
    {
      onChange( event );
    }
  };

  return (
    <Select
      label={label}
      onScrollCapture={scrollHandler}
      onChange={onChangeHandler}
      style={{ minWidth: "250px" }}
      name={selectedOption?.name}
      renderValue={() => selectedOption?.name}
      disabled={disabled}
      value={selectedOption.id || ""}
      {...rest}
    >
      {options.map( ( option: Option ) => (
        // Passing both option values concatenated because we're limited to one user defined property on the event
        <MenuItem key={option.id} value={`${option.id}-${option.name}`}>
          {option.name}
        </MenuItem>
      ) )}
    </Select>
  );
};
