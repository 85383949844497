export const amountValidator = ( value: string ): string =>
{
  try
  {
    const amount = Number( value );
    if ( isNaN( amount ) )
    {
      return "Amount must be a number";
    }

    const split = value.split( "." );
    if ( split.length !== 2 || split[1].length !== 2 )
    {
      return "Please enter a valid amount in the format of 50.00";
    }

    if ( amount <= 0 )
    {
      return "Amount must be a positive number";
    }

    return "";
  }
  catch ( error )
  {
    return error.message;
  }
};

export const description = ( value: string ): string =>
{
  if ( value.length <= 0 )
  {
    return "Description must be non-empty";
  }

  return "";
};

export const requiredString = ( value: string, key: string ): string =>
{
  if ( value.length <= 0 )
  {
    return `${key} is required`;
  }

  return "";
};
