import * as React from "react";
import _ from "lodash";
import { SelectInput, useGetOne, useNotify } from "react-admin";
import { Address, Order } from "src/ts/interfaces/order-interface";
import { Form, Field } from "react-final-form";
import { FinalFormField } from "../../generics/final-form-field";
import {
  AuthenticationShippingRate,
  AuthenticationShippingRates
} from "./authentication-shipping-rates";
import {
  Typography,
  Box,
  Checkbox,
  FormControlLabel,
  Button,
  Grid,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  TableContainer,
  TextField
} from "@material-ui/core";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import { resetHistory } from "../../../utils/reset-history";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Seller } from "src/ts/interfaces/user-interface";
import { ShopThingAddress, ShopThingAddressUS } from "../../../config";
import { ButtonStyles } from "../../generics/button-styles";
import { Spacer } from "../../generics/spacer";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { ORIGIN_COUNTRIES } from "../../../constants/origin-countries";

const useStyles = makeStyles( ( theme: Theme ) =>
  createStyles( {
    root: {
      "& .MuiTextField-root": {
        margin: theme.spacing( 1 ),
        width: "45ch",
      },
    },
    fullWidth: {
      "& .MuiTextField-root": {
        margin: theme.spacing( 1 ),
        width: "92ch",
      },
    },
    orginCountry: {
      "& .MuiTextField-root": {
        width: "100%",
        "margin-bottom": "0px",
      },
    },
    nowrapHeader: {
      whiteSpace: "normal",
      [theme.breakpoints.up( "md" )]: {
        whiteSpace: "nowrap",
      },
    },
  } )
);

export enum authenticationShippingDirection {
  shopthingToSeller = "shopthing-to-seller",
  sellerToShopthing = "seller-to-shopthing",
}

interface IAuthenticationShippingLabel {
  orderData?: Order;
  direction: authenticationShippingDirection;
}

interface CustomsItem {
  description: string;
  quantity: number;
  tariff: string;
  value: number;
  originCountry?: string;
  weight: number;
}

interface RatesNumerals {
  [Symbol.iterator](): IterableIterator<number>;
  [x: string]: number;
}
interface RatesStrings {
  [Symbol.iterator](): IterableIterator<string>;
  [x: string]: string;
}
interface RatesAddress {
  [Symbol.iterator](): IterableIterator<Address>;
  [x: string]: Address;
}

interface AddressChoices {
  id: string;
  name: string;
}

type RatesForm = RatesNumerals | RatesStrings | RatesAddress;

const addressChoices = ( toAddresses: Address[] ) =>
{
  const result = [] as AddressChoices[];
  toAddresses.forEach( ( address ) =>
  {
    result.push( {
      id: JSON.stringify( address ),
      name: `${address.line1}, ${address.state}, ${address.postalCode}${
        address.company ? `, ${address.company}` : ""
      }`,
    } );
  } );

  return result;
};

const pickRequiredAddressData = ( address: Address ) =>
  _.pick( address, [
    "firstname",
    "lastname",
    "phone",
    "postalCode",
    "city",
    "state",
    "country",
    "line1",
    "line2",
    "company",
  ] );

const fedexPredefinedPackageFedexUsChoices = () =>
{
  const choices = [
    "FedExEnvelope",
    "FedExBox",
    "FedExPak",
    "FedExTube",
    "FedEx10kgBox",
    "FedEx25kgBox",
    "FedExSmallBox",
    "FedExMediumBox",
    "FedExLargeBox",
    "FedExExtraLargeBox",
  ];
  const result = choices.map( ( choice ) => ( {
    id: choice.toLowerCase(),
    name: choice,
  } ) );
  return result;
};

const deliveryConfirmationChoices = () =>
{
  const choices = [
    {
      id: "ADULT_SIGNATURE",
      name: "ADULT_SIGNATURE",
    },
    {
      id: "SIGNATURE",
      name: "SIGNATURE",
    },
    {
      id: "NO_SIGNATURE",
      name: "NO_SIGNATURE",
    },
  ];
  return choices;
};

const boxSizeChoices = () =>
{
  const choices = [
    {
      id: "5x5x5",
      name: "5x5x5",
    },
    {
      id: "8x8x4",
      name: "8x8x4",
    },
    {
      id: "14x10x6",
      name: "14x10x6",
    },
    {
      id: "16x12x6",
      name: "16x12x6",
    },
    {
      id: "20x14x6",
      name: "20x14x6",
    },
    {
      id: "31x16x9",
      name: "31x16x9",
    },
    {
      id: "12x8x1",
      name: "12x8x1",
    },
    {
      id: "6x11x1",
      name: "6x11x1",
    },
    {
      id: "14x20x1",
      name: "14x20x1",
    },
    {
      id: "19x14x1",
      name: "19x14x1",
    },
    {
      id: "19x24x1",
      name: "19x24x1",
    },
  ];
  return choices;
};

const weightChoices = () =>
{
  const choices = [
    {
      name: "16 oz = 1 lb",
      id: "16",
    },
    {
      name: "32 oz = 2 lbs",
      id: "32",
    },
    {
      name: "48 oz = 3 lbs",
      id: "48",
    },
    {
      name: "64 oz = 4 lbs",
      id: "64",
    },
    {
      name: "80 oz = 5 lbs",
      id: "80",
    },
    {
      name: "96 oz = 6 lbs",
      id: "96",
    },
    {
      name: "112 oz = 7 lbs",
      id: "112",
    },
    {
      name: "128 oz = 8 lbs",
      id: "128",
    },
    {
      name: "144 oz = 9 lbs",
      id: "144",
    },
    {
      name: "160 oz = 10 lbs",
      id: "160",
    },
  ];
  return choices;
};

const tariffChoices = [
  {
    txt: "Apparel",
    value: "4203.10",
  },
  {
    txt: "Pre-loved Apparel and Accessories",
    value: "6309.00.00",
  },
  {
    txt: "Leather Bags and Wallets",
    value: "4202.11.00",
  },
  {
    txt: "Non-leather Bags and Wallets",
    value: "4202.12",
  },
  {
    txt: "Precious Jewelry",
    value: "7113.19.50",
  },
  {
    txt: "Non-precious Jewelry",
    value: "7177.11.00",
  },
  {
    txt: "Leather Shoes",
    value: "6404.11.20",
  },
  {
    txt: "Non-leather Shoes",
    value: "640420",
  },
  {
    txt: "Skincare/Makeup",
    value: "3304.20.00",
  },
];

const generateRedErrorMsg = ( msg: string ) => (
  <div style={{ color: "red" }}> {msg} </div>
);

function CustomsInfoInput( props: {
  originCurrency: string;
  initialValues: CustomsItem[];
} )
{
  const classes = useStyles();
  const ldClient = useLDClient();
  const useCustomsItemOriginCountry = ldClient.variation(
    "useCustomsItemOriginCountry",
    false
  );

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="justify" component="th" scope="row">
              Quantity
            </TableCell>
            <TableCell align="justify" component="th" scope="row">
              Description
            </TableCell>
            <TableCell align="justify" component="th" scope="row">
              {`Value (${props.originCurrency})`}
            </TableCell>
            {useCustomsItemOriginCountry && (
              <TableCell
                align="left"
                component="th"
                scope="row"
                className={classes.nowrapHeader}
              >
                Product’s Country of Origin*
              </TableCell>
            )}
            <TableCell align="justify" component="th" scope="row">
              Weight (oz)
            </TableCell>
            <TableCell align="justify" component="th" scope="row">
              Tariff
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <FieldArray
            name="customs_items"
            {...props}
            initialValue={props.initialValues}
          >
            {( { fields } ) =>
              fields.map( ( name, index ) => (
                <TableRow key={name}>
                  <TableCell scope="row">
                    <Field
                      name={`${name}.quantity`}
                      component={FinalFormField}
                      placeholder="quantity"
                      style={{ width: "100%" }}
                    />
                  </TableCell>
                  <TableCell scope="row">
                    <Field
                      name={`${name}.description`}
                      component={FinalFormField}
                      placeholder="Description"
                      style={{ width: "100%" }}
                    />
                  </TableCell>
                  <TableCell scope="row">
                    <Field
                      name={`${name}.value`}
                      component={FinalFormField}
                      placeholder="Value"
                      style={{ width: "100%" }}
                    />
                  </TableCell>
                  {useCustomsItemOriginCountry && (
                    <TableCell scope="row" className={classes.orginCountry}>
                      <SelectInput
                        key={`${name}.originCountry`}
                        name={`${name}.originCountry`}
                        label="Product’s Country of Origin"
                        choices={ORIGIN_COUNTRIES}
                        optionValue="value"
                        defaultValue={props.initialValues[index].originCountry}
                        source={`${name}.originCountry`}
                      />
                    </TableCell>
                  )}
                  <TableCell scope="row">
                    <Field
                      name={`${name}.weight`}
                      component={FinalFormField}
                      placeholder="Weight (oz)"
                      style={{ width: "100%" }}
                      defaultValue={0}
                    />
                  </TableCell>
                  <TableCell scope="row">
                    <Field
                      name={`${name}.tariff`}
                      placeholder="Tariff"
                      style={{ width: "100%" }}
                    >
                      {( { input, meta } ) => (
                        <TextField
                          error={meta.submitError ? true : false}
                          helperText={meta.submitError || meta.error}
                          variant="outlined"
                          placeholder="Enter / Select"
                          style={{ width: "100%" }}
                          size={"small"}
                          label="Enter / Select"
                          InputProps={{
                            endAdornment: (
                              <datalist id="tariffList">
                                {tariffChoices.map( ( choice ) => (
                                  <option
                                    key={choice.value}
                                    value={choice.value}
                                  >
                                    {choice.txt}
                                  </option>
                                ) )}
                              </datalist>
                            ),
                            inputProps: {
                              ...input,
                              list: "tariffList",
                            },
                          }}
                        />
                      )}
                    </Field>
                  </TableCell>
                </TableRow>
              ) )
            }
          </FieldArray>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

// This component is using for generating shipping label for both shipping
// From seller to ShopThing center and other way around
export const AuthenticationShippingLabel: React.FunctionComponent<
  IAuthenticationShippingLabel
> = ( properties: IAuthenticationShippingLabel ): JSX.Element =>
{
  const ldClient = useLDClient();
  const useCustomsItemOriginCountry = ldClient.variation(
    "useCustomsItemOriginCountry",
    false
  );

  const notify = useNotify();
  const { orderData, direction } = properties;
  const classes = useStyles();
  const [toAddresses, setToAddresses] = React.useState( [] as Address[] );
  const [toAddress, setToAddress] = React.useState( {} as Address );
  const [fromAddresses, setFromAddresses] = React.useState( [] as Address[] );
  const [fromAddress, setFromAddress] = React.useState( {} as Address );

  const [customsItemRequest, setCustomsItemRequest] = React.useState(
    [] as { tariff: string }[]
  );

  const [isIntl, setIntl] = React.useState( false );
  const [labelCurrency, setLabelCurrency] = React.useState( "CAD" );
  const [rateRequest, setRateRequest] = React.useState( {} );
  const [showRates, setShowRates] = React.useState( false );
  const initialBundleCustomsItems: {
    description: string;
    quantity: number;
    value: number;
    weight: number | null;
    skuId: string;
    originCountry?: string;
  }[] = [
    {
      description: orderData.cart.items[0].productName,
      quantity: orderData.cart.items[0].quantity,
      value: Number( orderData.totals?.subtotal || 0 ) / 100,
      weight: null,
      skuId: orderData.cart.items[0].skuId,
      originCountry: useCustomsItemOriginCountry ? "IT" : undefined,
    },
  ];

  const [initialValues, setInitialValues] = React.useState( {
    quantity: 1,
    value: Number( orderData.totals?.subtotal || 0 ) / 100,
    description: orderData.cart.items[0].description,
    originCurrency: orderData.specifications.currency,
    customs: false,
    deliveryConfirmation: undefined,
    customs_items: initialBundleCustomsItems,
  } as { [x: string]: unknown } );
  const [currentRate, setCurrentRate] = React.useState(
    {} as { shipmentId: string; rate: AuthenticationShippingRate }
  );

  const { loading } = useGetOne( "authenticatedUser", orderData.seller.id, {
    onSuccess: ( response: { data: Seller } ) =>
    {
      const sellerAddressesValues =
        response.data?.fromAddresses?.length > 0
          ? response.data.fromAddresses
          : [];

      if ( sellerAddressesValues.length <= 0 )
      {
        notify( "Seller does not have from address", "warning" );
        setTimeout( () =>
        {
          resetHistory();
          history.go();
        }, 2000 );
      }

      const refinedSellerAddresses = sellerAddressesValues.map( ( address ) =>
        pickRequiredAddressData( address )
      );

      const shopthingAddresses = [ShopThingAddress, ShopThingAddressUS];

      if ( direction === authenticationShippingDirection.sellerToShopthing )
      {
        setFromAddresses( refinedSellerAddresses );
        const defaultFromAddress =
          refinedSellerAddresses[refinedSellerAddresses.length - 1];
        setFromAddress( defaultFromAddress );

        setToAddresses( shopthingAddresses );
        const defaultToAddress =
          shopthingAddresses[shopthingAddresses.length - 1];
        setToAddress( defaultToAddress );

        setIntl(
          defaultFromAddress.country !== defaultToAddress.country ? true : false
        );
      }
      else if (
        direction === authenticationShippingDirection.shopthingToSeller
      )
      {
        setToAddresses( refinedSellerAddresses );
        const defaultFromAddress =
          refinedSellerAddresses[refinedSellerAddresses.length - 1];
        setToAddress( defaultFromAddress );

        setFromAddresses( shopthingAddresses );
        const defaultToAddress =
          shopthingAddresses[shopthingAddresses.length - 1];
        setFromAddress( defaultToAddress );

        setIntl(
          defaultFromAddress.country !== defaultToAddress.country ? true : false
        );
      }

      setLabelCurrency(
        refinedSellerAddresses[refinedSellerAddresses.length - 1].country ===
          "CA"
          ? "cad"
          : "usd"
      );
    },
  } );

  const handleToAddressChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) =>
  {
    const toAddressValue = JSON.parse( event.target.value ) as Address;
    setToAddress( toAddressValue );
    setIntl(
      toAddressValue.country.toLowerCase() !== fromAddress.country.toLowerCase()
        ? true
        : false
    );

    if ( direction === authenticationShippingDirection.shopthingToSeller )
    {
      setLabelCurrency( toAddressValue.country === "CA" ? "cad" : "usd" );
    }
  };

  const handleFromAddressChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) =>
  {
    const fromAddressValue = JSON.parse( event.target.value ) as Address;
    setFromAddress( fromAddressValue );
    setIntl(
      fromAddressValue.country.toLowerCase() !== toAddress.country.toLowerCase()
        ? true
        : false
    );

    if ( direction === authenticationShippingDirection.sellerToShopthing )
    {
      setLabelCurrency( fromAddressValue.country === "CA" ? "cad" : "usd" );
    }
  };

  const handleCheckbox = () =>
  {
    setIntl( !isIntl );
  };

  const buttonClasses = ButtonStyles();

  return (
    <>
      {showRates && !loading && (
        <>
          <Typography variant="h5" gutterBottom>
            {`Shipping Label Options: `}
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12}>
              <Box
                border={2}
                bgcolor={"#f7f9fa"}
                css={{ borderColor: "#C5D1D9" }}
                padding={2}
              >
                <Grid container>
                  {initialValues.deliveryConfirmation && (
                    <Grid item xs={12} sm={12} md={12}>
                      <Typography
                        variant={"h6"}
                      >{`Delivery Confirmation: ${initialValues.deliveryConfirmation}`}</Typography>
                    </Grid>
                  )}
                  {
                    // fromAddress.country?.toLowerCase() === "us" &&
                    initialValues.predefinedPackageFedexUs ? (
                      <>
                        <Grid item xs={12} sm={12} md={6}>
                          <Typography
                            variant={"h6"}
                          >{`Predefined Package: ${initialValues.predefinedPackageFedexUs}`}</Typography>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item xs={12} sm={12} md={6}>
                          <Typography
                            variant={"h6"}
                          >{`Length (inches): ${initialValues.length}`}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <Typography
                            variant={"h6"}
                          >{`Width (inches): ${initialValues.width}`}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <Typography
                            variant={"h6"}
                          >{`Height (inches): ${initialValues.height}`}</Typography>
                        </Grid>
                      </>
                    )
                  }
                  <Grid item xs={12} sm={12} md={6}>
                    <Typography
                      variant={"h6"}
                    >{`Weight (oz): ${initialValues.weight}`}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Typography
                      variant={"h6"}
                    >{`Quantity: ${initialValues.quantity}`}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Typography
                      variant={"h6"}
                    >{`Value (${initialValues.originCurrency}): ${initialValues.value}`}</Typography>
                  </Grid>
                  {initialValues.description ? (
                    <Grid item xs={12} sm={12} md={12}>
                      <Typography
                        variant={"h6"}
                      >{`Description: ${initialValues.description}`}</Typography>
                    </Grid>
                  ) : null}
                  {isIntl ? (
                    <Grid item xs={12} sm={12} md={12}>
                      <Typography variant={"h6"}>{`Tariff: ${customsItemRequest
                        .map( ( item ) => item.tariff )
                        .join( ", " )}`}</Typography>
                    </Grid>
                  ) : null}
                  {Object.keys( currentRate ).length === 0 ? (
                    <Grid item xs={12} sm={12} md={12} alignItems={"flex-end"}>
                      <Spacer />
                      <Button
                        className={buttonClasses.saveButton}
                        onClick={() =>
                        {
                          setShowRates( false );
                        }}
                      >
                        Change Shipping Rates
                      </Button>
                    </Grid>
                  ) : null}
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <Spacer />
          <AuthenticationShippingRates
            requestData={rateRequest}
            orderId={orderData.id}
            currentRate={currentRate}
            setCurrentRate={setCurrentRate}
            direction={direction}
          />
        </>
      )}
      {!showRates && !loading && (
        <>
          <Typography variant="h5" gutterBottom>
            {`Shipping Label Creation`}
          </Typography>
          {!isIntl && (
            <FormControlLabel
              control={<Checkbox checked={isIntl} onClick={handleCheckbox} />}
              label="International Shipping"
            />
          )}
          <Form
            keepDirtyOnReinitialize
            onSubmit={( values: { [x: string]: unknown } ) =>
            {
              const requiredFields = [
                "length",
                "width",
                "height",
                "weight",
                "customs_items",
              ];
              const errors: { [x: string]: string } = {};
              for ( const field of requiredFields )
              {
                if ( !values[field] )
                {
                  errors[field] = "Field Required";
                }
                if ( field === "customs_items" && isIntl )
                {
                  const customsRequiredFields = [
                    "quantity",
                    "description",
                    "tariff",
                    "value",
                    "weight",
                  ];

                  if ( useCustomsItemOriginCountry )
                  {
                    customsRequiredFields.push( "originCountry" );
                  }

                  for ( const customsField of customsRequiredFields )
                  {
                    if ( Array.isArray( values.customs_items ) )
                    {
                      values.customs_items.map( ( thisItem, i ) =>
                      {
                        if ( !thisItem[customsField] )
                        {
                          _.set(
                            errors,
                            `customs_items[${i}].${customsField}`,
                            "Field required"
                          );
                        }
                      } );
                    }
                  }
                }
              }
              if ( Object.keys( errors ).length > 0 )
              {
                return errors;
              }
              const requestData: { [x: string]: unknown } = {
                direction,
                orderId: orderData.id,
                length: values.length,
                width: values.width,
                height: values.height,
                weight: values.weight,
                quantity: values.quantity,
                deliveryConfirmation: values.deliveryConfirmation || "",
                predefinedPackageFedexUs: values.predefinedPackageFedexUs,
                originCountry:
                  direction ===
                  authenticationShippingDirection.sellerToShopthing
                    ? fromAddress.country
                    : toAddress.country,
                currency: labelCurrency,
              };
              if ( isIntl )
              {
                requestData.items = values.customs_items;
                setCustomsItemRequest( values.customs_items as CustomsItem[] );
              }

              if ( fromAddress.country?.toLowerCase() === "us" )
              {
                Object.assign( requestData, {
                  predefinedPackageFedexUs: values.predefinedPackageFedexUs,
                } );
              }

              if ( values.address as string )
              {
                const address = JSON.parse( values.address as string ) as Address;
                requestData.address = address;
                requestData.country = address.country;
              }

              if ( values.fromAddress as string )
              {
                const fromAddress = JSON.parse(
                  values.fromAddress as string
                ) as Address;
                requestData.fromAddress = fromAddress;
                requestData.originCountry = fromAddress.country;
              }

              if ( isIntl )
              {
                requestData.hasCustoms = isIntl;
                requestData.description = values.description;
                requestData.value = values.value;
                requestData.tariff = values.tariff;
              }

              setRateRequest( requestData );
              setShowRates( true );
              setInitialValues( values );
            }}
            initialValues={initialValues}
            mutators={{
              ...arrayMutators,
              handleDimensionOnChange: ( args, state, utils ) =>
              {
                const dimensions = _.get( args, "[0].target.value", "" ).split(
                  "x"
                ) as string[];
                if ( dimensions.length === 3 )
                {
                  utils.changeValue( state, "length", () => dimensions[0] );
                  utils.changeValue( state, "width", () => dimensions[1] );
                  utils.changeValue( state, "height", () => dimensions[2] );
                }
                else
                {
                  utils.changeValue( state, "length", () => null );
                  utils.changeValue( state, "width", () => null );
                  utils.changeValue( state, "height", () => null );
                }
              },
              handWeightOnChange: ( args, state, utils ) =>
              {
                const weight = _.get( args, "[0].target.value", "" );
                if ( _.isNil( weight ) || _.isEmpty( weight ) )
                {
                  utils.changeValue( state, "weight", () => null );
                  // As items in the customs are not dynamic and determine on initiation
                  // the length of the initial values always shows the number of orders in bundle
                  if (
                    _.get(
                      state,
                      "formState.initialValues.customs_items.length"
                    ) === 1
                  )
                  {
                    utils.changeValue(
                      state,
                      "customs_items[0].weight",
                      () => null
                    );
                  }
                }
                else
                {
                  utils.changeValue( state, "weight", () => weight );

                  if (
                    _.get(
                      state,
                      "formState.initialValues.customs_items.length"
                    ) === 1
                  )
                  {
                    utils.changeValue(
                      state,
                      "customs_items[0].weight",
                      () => weight
                    );
                  }
                }
              },
            }}
            validate={( values: RatesForm ) =>
            {
              const numbersFields = ["length", "width", "height", "weight"];
              const errors: { [x: string]: string } = {};
              // TS wouldn't allow a standard for const [key,value] iteration here
              const valueArray = Object.values( values );
              Object.keys( values ).forEach( ( key, index ) =>
              {
                if ( isNaN( valueArray[index] ) && numbersFields.includes( key ) )
                {
                  errors[key] = "Number Value Required";
                }
              } );

              // Validate customs items array
              const customsItems = values["customs_items"];
              if ( customsItems && Array.isArray( customsItems ) && isIntl )
              {
                customsItems.forEach( ( item, i ) =>
                {
                  if (
                    !_.isNil( item["description"] ) &&
                    _.get( item["description"], "length", 0 ) > 45
                  )
                  {
                    _.set(
                      errors,
                      `customs_items[${i}].description`,
                      generateRedErrorMsg( "Please Shorten The Description" )
                    );
                  }

                  if (
                    !_.isNil( item["weight"] ) &&
                    isNaN( Number( item["weight"] ) )
                  )
                  {
                    _.set(
                      errors,
                      `customs_items[${i}].weight`,
                      generateRedErrorMsg( "Number Value Required" )
                    );
                  }
                } );
              }

              return errors;
            }}
            render={( { handleSubmit, submitting, initialValues, form } ) => (
              <form className={classes.root} onSubmit={handleSubmit}>
                <Box width={"100%"} paddingX={6} paddingY={2}>
                  <Typography variant={"h5"}>Shipping</Typography>
                  {toAddresses.length === 0 ? null : (
                    <SelectInput
                      label="To Address"
                      source="address"
                      choices={addressChoices( toAddresses )}
                      onChange={handleToAddressChange}
                      allowEmpty={false}
                      defaultValue={JSON.stringify(
                        toAddresses[toAddresses.length - 1]
                      )}
                    />
                  )}
                  {fromAddresses.length === 0 ? null : (
                    <SelectInput
                      label="From Address"
                      source="fromAddress"
                      choices={addressChoices( fromAddresses )}
                      onChange={handleFromAddressChange}
                      allowEmpty={false}
                      defaultValue={JSON.stringify(
                        fromAddresses[fromAddresses.length - 1]
                      )}
                    />
                  )}
                  <Box component="div" display="block">
                    <SelectInput
                      label="Predefined Package Fedex Us"
                      source="predefinedPackageFedexUs"
                      choices={fedexPredefinedPackageFedexUsChoices()}
                      optionValue="name"
                      allowEmpty={true}
                    />
                  </Box>
                  <Box component="div" display="block">
                    <SelectInput
                      label="Delivery Confirmation Options"
                      source="deliveryConfirmation"
                      choices={deliveryConfirmationChoices()}
                      optionValue="id"
                      allowEmpty={true}
                    />
                  </Box>
                  <Box component="div" display="block">
                    <Typography style={{ marginBottom: -5 }} variant="caption">
                      {`Please select your package dimensions from the dropdown below or manually enter it.`}
                    </Typography>
                  </Box>
                  <Box component="div" display="block">
                    <SelectInput
                      label="Package Dimensions L x W x H"
                      source="dimensionDropDown"
                      choices={boxSizeChoices()}
                      optionValue="id"
                      allowEmpty={true}
                      onChange={form.mutators.handleDimensionOnChange}
                    />
                  </Box>
                  <Box component="div" display="block">
                    <Typography style={{ marginBottom: -5 }} variant="caption">
                      {`Please select the weight of your package from the dropdown below or manually enter it.`}
                    </Typography>
                  </Box>
                  <Box component="div" display="block">
                    <SelectInput
                      label="Package Weight"
                      source="weightDropDown"
                      choices={weightChoices()}
                      optionValue="id"
                      allowEmpty={true}
                      onChange={form.mutators.handWeightOnChange}
                    />
                  </Box>
                  <Box component="div" display="block">
                    <Field<number>
                      name="length"
                      component={FinalFormField}
                      label="Length (inches)"
                    />
                    <Field<number>
                      name="width"
                      component={FinalFormField}
                      label="Width (inches)"
                    />
                  </Box>
                  <Box component="div" display="block">
                    <Field<number>
                      name="height"
                      component={FinalFormField}
                      label="Height (inches)"
                    />
                    <Field<number>
                      name="weight"
                      component={FinalFormField}
                      label="Package weight (oz)"
                    />
                  </Box>
                  {isIntl && (
                    <>
                      <Spacer key="total-spacer-1" />
                      <Typography variant={"h5"} key="total-spacer-2">
                        Customs
                      </Typography>
                      <CustomsInfoInput
                        initialValues={initialValues.customs_items}
                        originCurrency={initialValues.originCurrency}
                      />
                      {useCustomsItemOriginCountry && (
                        <React.Fragment>
                          <Typography variant={"caption"} key="total-spacer-2">
                            *Please select the country of origin accurately to
                            ensure correct customs processing. The country of
                            origin refers to where the product was manufactured
                            or produced. If you can’t find the exact option,
                            please select the country most commonly associated
                            with this product.
                          </Typography>
                          <br />
                        </React.Fragment>
                      )}
                    </>
                  )}

                  <Button
                    color={"primary"}
                    type="submit"
                    disabled={submitting}
                    style={{ marginTop: 5 }}
                  >
                    Get Shipping Rates
                  </Button>
                </Box>
              </form>
            )}
          />
        </>
      )}
    </>
  );
};
