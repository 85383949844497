import React from "react";
import {
  IconButton,
  Card,
  CardMedia,
  createStyles,
  makeStyles
} from "@material-ui/core";
import { CustomModal } from "@shopthing-opn-shared/admin-dashboard";
import PhotoIcon from "@material-ui/icons/Photo";
interface IImageButton {
  imageUrl: string;
  [x: string]: unknown;
}

const useStyles = makeStyles( () =>
  createStyles( {
    root: {
      height: "100%",
      width: "100%",
      padding: "auto",
    },
    media: {
      height: "100%",
      width: "auto",
      backgroundSize: "contain",
    },
  } )
);

export const ImageButton: React.FunctionComponent<IImageButton> = ( {
  imageUrl,
}: IImageButton ) =>
{
  const [open, setOpen] = React.useState( false );
  const classes = useStyles();

  const handleOpen = () =>
  {
    setOpen( true );
  };

  const handleClose = () =>
  {
    setOpen( false );
  };

  const body = (
    <>
      <Card className={classes.root}>
        <CardMedia className={classes.media} image={imageUrl} />
      </Card>
    </>
  );

  return (
    <>
      <IconButton
        onClick={handleOpen}
        color={"primary"}
        disableRipple
        disableFocusRipple
      >
        <PhotoIcon />
      </IconButton>
      <CustomModal
        open={open}
        onClose={handleClose}
        style={{ width: "80%", height: "80%" }}
      >
        {body}
      </CustomModal>
    </>
  );
};
