import * as React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import get from "lodash/get";
import { FunctionField } from "react-admin";
import { FieldProps } from "ra-ui-materialui";
import { Record } from "ra-core";

const useStyles = makeStyles( {
  threeDaysPlus: {
    color: "red",
    fontWeight: "bold",
  },
} );

/**
 * Number of Days in Paid is calcualted by the following:
 *
 *  Number of Days in Paid = Current Date - Date Paid
 *
 * @param paid - Paid Date
 * @returns
 */
export const getNumOfDaysInPaid = (
  paid: Date | undefined
): number | undefined =>
{
  // Fallback on no value
  if ( !paid )
  {
    return undefined;
  }

  const MS_PER_DAY = 1000 * 60 * 60 * 24;

  const startDate = new Date( paid );
  const endDate = new Date();

  return Math.floor( ( endDate.getTime() - startDate.getTime() ) / MS_PER_DAY );
};

/**
 * Return Function Field to display value
 *
 * @param props
 * @returns
 */
export const DaysInPaidField: React.FC<FieldProps<Record>> = ( props: {
  record: Record;
} ) =>
{
  const classes = useStyles();

  const { record } = props;
  const paidDate = get( record, "timestamps.paid" );

  const numOfDaysInPaid = getNumOfDaysInPaid( paidDate );

  const newRecord: { status: string; numOfDaysInPaid: number } = {
    status: record.status,
    numOfDaysInPaid,
  };

  return (
    <FunctionField
      record={newRecord}
      className={classnames( {
        [classes.threeDaysPlus]: numOfDaysInPaid >= 3,
      } )}
      render={( record: { status: string; numOfDaysInPaid: number } ) =>
        record.numOfDaysInPaid != null && record.status === "paid"
          ? record.numOfDaysInPaid
          : ""
      }
    />
  );
};

DaysInPaidField.propTypes = {
  label: PropTypes.string,
  addLabel: PropTypes.bool,
};

DaysInPaidField.defaultProps = {
  addLabel: true,
  sortable: true,
};
