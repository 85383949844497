import { Editorial } from "../../ts/interfaces/event-interface";
import * as React from "react";
import { SelectInput, Filter, List, Datagrid, TextField } from "react-admin";
import { BasicPagination, perPage } from "../generics/pagination";

const PostFilter: React.FC<{ isActive?: boolean }> = ( properties ) => (
  <Filter {...properties}>
    <SelectInput
      source="isActive"
      label="Is Active"
      choices={[
        { id: true, name: "Active" },
        { id: false, name: "Inactive" },
      ]}
    />
  </Filter>
);

export const EditorialList = ( properties: Editorial[] ): JSX.Element => (
  <List
    {...properties}
    pagination={<BasicPagination />}
    filters={<PostFilter />}
    bulkActionButtons={false}
    exporter={false}
    sort={undefined}
    perPage={perPage}
  >
    <Datagrid rowClick="edit">
      <TextField label="ID" source="id" sortable={false} />
      <TextField label="Name" source="name" sortable={false} />
      <TextField label="Is Active" source="isActive" sortable={false} />
    </Datagrid>
  </List>
);
