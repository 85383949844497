import React from "react";
import { useUpdate, useNotify } from "react-admin";
import { Grid, FormControl, FormLabel, Button, Input } from "@material-ui/core";
import { Spacer } from "./../generics/spacer";
import { CustomModal } from "@shopthing-opn-shared/admin-dashboard";
import { ButtonStyles } from "../generics/button-styles";
import { notifyMessage } from "../../utils/primitive";

interface IRefundButton {
  orderId: string;
  latestDisputeStatus?: string;
}

export const ResolveDisputeButton: React.FunctionComponent<IRefundButton> = (
  properties
) =>
{
  const buttonClasses = ButtonStyles();
  const [open, setOpen] = React.useState( false );
  const [note, setNote] = React.useState( " " );

  const notify = useNotify();
  const [resolveDispute, { loading }] = useUpdate(
    "resolve-dispute",
    properties.orderId,
    {
      note,
    },
    {},
    {
      onSuccess: () =>
      {
        if ( properties.latestDisputeStatus === "fraudulent" )
        {
          notify( "Early Fraud Warning resolved" );
        }
        else
        {
          notify( "Dispute resolved" );
        }

        window.location.reload();
      },
      onFailure: ( error: Error ) =>
      {
        notifyMessage( error, notify );
      },
    }
  );

  const handleOpen = () =>
  {
    setOpen( true );
  };

  const handleClose = () =>
  {
    setOpen( false );
  };

  const inputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) =>
  {
    setNote( event.target.value );
  };

  const body = (
    <div>
      <Spacer />
      <Grid style={{ minWidth: "500px" }} container spacing={0}>
        <FormLabel>Note</FormLabel>
        <FormControl fullWidth={true}>
          <Grid xs={12}>
            <Input
              name="note"
              placeholder={note}
              multiline={true}
              rows="6"
              aria-describedby="my-helper-text"
              type="text"
              fullWidth={true}
              disableUnderline={true}
              required={true}
              onChange={inputChange}
              style={{
                borderStyle: "solid",
                borderWidth: "thin",
                borderColor: "grey",
              }}
            />
          </Grid>
        </FormControl>
      </Grid>
      <Spacer />
      <Button onClick={resolveDispute} disabled={note === " " || !note}>
        Resolve
      </Button>
    </div>
  );

  const buttonText =
    properties.latestDisputeStatus === "fraudulent"
      ? "Resolve Fraudulent Order"
      : "Resolve Dispute";

  return (
    <>
      <Button
        onClick={handleOpen}
        className={buttonClasses.saveButton}
        disabled={loading}
        disableRipple
        disableFocusRipple
      >
        {buttonText}
      </Button>
      <CustomModal open={open} onClose={handleClose}>
        {body}
      </CustomModal>
    </>
  );
};
