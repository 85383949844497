import * as React from "react";
import _ from "lodash";

import {
  Edit,
  useNotify,
  TextField,
  NumberField,
  SimpleForm,
  Labeled,
  SelectInput,
  usePermissions,
  TextInput,
  SimpleFormIterator,
  ArrayInput,
  useUpdate,
  TabbedShowLayout,
  Tab,
  SimpleShowLayout,
  Show,
  Datagrid,
  Pagination,
  ReferenceManyField,
  DateField,
  FunctionField,
  ReferenceInput,
  useRecordContext
} from "react-admin";

import {
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  Button
} from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Record, useMutation } from "ra-core";
import { CustomModal } from "@shopthing-opn-shared/admin-dashboard";

import { SaveOnlyToolbar } from "../toolbars/save-only";
import { TrueFalseIcon } from "../generics/true-false-icon";
import {
  Customer,
  MembershipSubscription,
  SellerPermittedEventTypes,
  SellerTransferTypes
} from "../../ts/interfaces/user-interface";
import {
  FulfillmentType,
  SellerIdentityType
} from "../../ts/interfaces/shared";
import {
  extractAuthClaims,
  hasPermissions,
  BooleanRoles
} from "../../ts/interfaces/role-interface";
import { ButtonStyles } from "../generics/button-styles";
import { StripeIssuingFields } from "./card-issuing/issue-card";
import { CardDetailsView } from "./card-issuing/display-card";
import { IssueToolbar } from "./card-issuing/issue-button";
import { Spacer } from "../generics/spacer";

import { formatPrice } from "../../utils/primitive";
import { MultiSelectCheckbox } from "../multi-select-checkbox/multi-select-checkbox";
import { PartnerCarriers } from "./partner-carriers";

interface IEditProperties {
  id: string;
  [x: string]: unknown;
  record: Customer;
}

const useStyles = makeStyles( ( theme: Theme ) =>
  createStyles( {
    formField: {
      margin: theme.spacing( 1, 2, 1, 0 ),
    },
    customWith: {
      width: "210px",
    },
    button: {
      marginLeft: "5px",
      border: "1px solid black",
    },
  } )
);

const useIteratorStyle = makeStyles( () => ( {
  root: {
    width: "100%",
  },
  form: {
    display: "flex",
    flexWrap: "wrap",
  },
  line: {
    marginRight: "5px",
  },
} ) );

const transformCCIssuing = ( data: Record ) =>
{
  const update: {
    id: string | number;
    issueCreditCard: boolean;
    updates: {
      [key: string]: unknown;
    };
  } = {
    id: data.id,
    issueCreditCard: true,
    updates: {
      name: data.ccName,
      taxId: data.ccTaxId,
      cardType: data.ccTypeOfCard,
      email: data.email,
      cardBillingAddress: {
        line1: data.ccBillLine1,
        line2: data.ccBillLine2,
        city: data.ccBillCity,
        postalCode: data.ccBillPostalCode,
        state: data.ccBillState,
        country: data.ccBillCountry,
      },
      cardShippingAddress: {
        name: data.ccShipName,
        line1: data.ccShipLine1,
        line2: data.ccShipLine2,
        city: data.ccShipCity,
        postalCode: data.ccShipPostalCode,
        state: data.ccShipState,
        country: data.ccShipCountry,
        shippingMethod: data.ccShipMethod,
        shippingType: data.ccShipType,
      },
    },
  };

  return update;
};

interface SellerFieldsProps {
  record: Customer;
  onPermittedEventTypesChange: ( selected: SellerPermittedEventTypes[] ) => void;
}
const SellerFields: React.FC<SellerFieldsProps> = ( {
  record,
  onPermittedEventTypesChange,
  ...rest
} ) =>
{
  const classes = useStyles();

  const iteratorClasses = useIteratorStyle();
  const { loaded, permissions } = usePermissions();
  const roleClaims = extractAuthClaims( permissions );

  const permittedEventTypeOptionName = {
    [SellerPermittedEventTypes.closetSale]: "Marketplace",
    [SellerPermittedEventTypes.regular]: "Events",
  };

  return record?.isSeller ? (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            {`Seller Details`}
          </Typography>
        </Grid>
        <Grid item xs={"auto"}>
          <Labeled label="Handle">
            <TextField
              label="Handle"
              source="handle"
              record={record}
              {...rest}
            />
          </Labeled>
        </Grid>
        <Grid item xs={"auto"}>
          <Labeled label="Phone">
            <TextField
              label="Phone"
              source="sellerPhone"
              record={record}
              {...rest}
            />
          </Labeled>
        </Grid>
        {loaded && hasPermissions( [BooleanRoles.Admin], roleClaims ) ? (
          <>
            <Grid item xs={"auto"}>
              <Labeled label="Market">
                <ReferenceInput
                  label="Shopper Markets"
                  name="Shopper Market"
                  source="shopperMarket"
                  reference="shopper-markets"
                  allowEmpty={true}
                  emptyValue={``}
                >
                  <SelectInput optionText="name" />
                </ReferenceInput>
              </Labeled>
            </Grid>
            <Grid item xs={"auto"}>
              <Labeled label="Seller Type">
                <SelectInput
                  label="Seller Type"
                  source="sellerType"
                  choices={[
                    { id: "Seller", name: "Seller" },
                    { id: "ShopThing", name: "ShopThing" },
                    { id: "Partner", name: "Partner" },
                  ]}
                />
              </Labeled>
            </Grid>
            <Grid item xs={"auto"}>
              <Labeled label="Seller Identity">
                <SelectInput
                  label="Seller Identity Type"
                  source="sellerIdentityType"
                  record={record}
                  {...rest}
                  choices={[
                    { id: SellerIdentityType.retailer, name: "Retailer" },
                    { id: SellerIdentityType.partner, name: "Partner" },
                  ]}
                  className={classes.formField}
                  allowEmpty={true}
                  emptyValue={``}
                />
              </Labeled>
            </Grid>
            <Grid item xs={"auto"}>
              <Labeled label="Fulfillment Type">
                <SelectInput
                  source="fulfillmentType"
                  record={record}
                  {...rest}
                  choices={[
                    { id: FulfillmentType.seller, name: "Seller" },
                    { id: FulfillmentType.shopthing, name: "ShopThing" },
                    { id: FulfillmentType.partner, name: "Partner" },
                  ]}
                  className={classes.formField}
                />
              </Labeled>
            </Grid>
            {record.permittedEventTypes &&
              record.permittedEventTypes.length && (
              <Grid item xs={"auto"}>
                <Labeled label="Posts">
                  <MultiSelectCheckbox
                    value={record.permittedEventTypes || []}
                    options={[
                      {
                        id: SellerPermittedEventTypes.closetSale,
                        name: permittedEventTypeOptionName[
                          SellerPermittedEventTypes.closetSale
                        ],
                      },
                      {
                        id: SellerPermittedEventTypes.regular,
                        name: permittedEventTypeOptionName[
                          SellerPermittedEventTypes.regular
                        ],
                      },
                    ]}
                    onChange={( selected: SellerPermittedEventTypes[] ) =>
                    {
                      onPermittedEventTypesChange( selected );
                    }}
                    renderSelectedValues={permittedEventTypeOptionName}
                  />
                </Labeled>
              </Grid>
            )}
            {record.transferType && (
              <Grid item xs="auto">
                <Labeled label="Earnings">
                  <SelectInput
                    source="transferType"
                    record={record}
                    {...rest}
                    choices={[
                      {
                        id: record.transferType,
                        name: `Transfer on ${
                          record.transferType === SellerTransferTypes.onDelivery
                            ? "delivery"
                            : "fulfillment"
                        }`,
                      },
                    ]}
                    value={record.transferType}
                    disabled
                    className={classes.customWith}
                  />
                </Labeled>
              </Grid>
            )}
          </>
        ) : null}
        <Grid item xs={"auto"}>
          <Labeled label="# of Followers">
            <NumberField
              label="Followers"
              source="followerCount"
              emptyText="0"
              record={record}
              {...rest}
            />
          </Labeled>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            {`"From" Addresses for Shipping`}
          </Typography>
        </Grid>
        <ArrayInput source="fromAddresses" label="">
          <SimpleFormIterator classes={iteratorClasses}>
            <TextInput
              className={classes.formField}
              source="line1"
              label="Line 1"
              required
            />
            <TextInput
              className={classes.formField}
              source="line2"
              label="Line 2"
            />
            <TextInput
              className={classes.formField}
              source="city"
              label="City"
              required
            />
            <TextInput
              className={classes.formField}
              source="state"
              label="State/Province"
              required
            />
            <SelectInput
              className={classes.formField}
              source="country"
              label="Country"
              required
              choices={[
                { id: "CA", name: "Canada" },
                { id: "US", name: "US" },
              ]}
            />
            <TextInput
              className={classes.formField}
              source="postalCode"
              label="Postal Code"
              required
            />
            <TextInput
              className={classes.formField}
              source="phone"
              label="Phone"
              required
            />
            <TextInput
              className={classes.formField}
              source="company"
              label="Company"
            />
            <TextInput
              className={classes.formField}
              source="firstname"
              label="First Name"
            />
            <TextInput
              className={classes.formField}
              source="lastname"
              label="Last Name"
            />
          </SimpleFormIterator>
        </ArrayInput>
      </Grid>
    </>
  ) : null;
};

interface DetailProperties {
  record?: Customer;
  [x: string]: unknown;
}

export const CustomerFields: React.FunctionComponent<DetailProperties> = (
  detailProperties: DetailProperties
) =>
{
  const { record } = detailProperties;
  const { permissions } = usePermissions();
  const classes = useStyles();
  const buttonClasses = ButtonStyles();
  const notify = useNotify();

  const [isUpdating, setIsUpdating] = React.useState( false );
  const [approveCustomerUpdate] = useUpdate(
    "manual-review",
    record.id,
    {},
    {},
    {
      onSuccess: () =>
      {
        notify( "Customer Approved!" );
        window.location.href = `/#/manual-review`;
      },
      onFailure: ( error: Error ) =>
      {
        notify(
          `An error occurred, please try again later ${error.message}`,
          "warning"
        );
        setIsUpdating( false );
      },
    }
  );

  const approveCustomer = () =>
  {
    setIsUpdating( true );
    approveCustomerUpdate();
  };

  const [saveDeactivateShopper, { loading, loaded: loaded2, data, error }] =
    useMutation();
  const [isUpdatingDeactivateShopper, setIsUpdatingDeactivateShopper] =
    React.useState( false );

  const deactivateShopper = () =>
  {
    setIsUpdatingDeactivateShopper( true );
  };

  const handleDeactivateShopper = async () =>
  {
    saveDeactivateShopper( {
      type: "deactivateShopper",
      resource: "users",
      payload: { id: record.id },
    } );
  };

  const cancelDeactivateShopper = () =>
  {
    setIsUpdatingDeactivateShopper( false );
  };

  React.useEffect( () =>
  {
    if ( error )
    {
      notify(
        `An error occurred, please try again later ${error.message}`,
        "warning"
      );
    }
    else if ( data )
    {
      notify( "Shopper Deactivated!" );
      setIsUpdatingDeactivateShopper( false );
    }
  }, [loaded2] );

  return (
    <Card>
      <CardContent>
        <CustomModal open={isUpdating} onClose={() => null}>
          <h2>Granting Approval to Customer</h2>
          <div>
            We are currently granting approval for this customer and all of
            their orders. This may take a while. Please do not close this page.
          </div>
          <div>
            After this is complete you will be redirected to back to the manual
            order review queue.
          </div>
        </CustomModal>
        <CustomModal open={isUpdatingDeactivateShopper} onClose={() => null}>
          <h2>Deactivate Seller</h2>
          <div>Are you sure you would like to deactivate this seller?</div>
          <br />
          <Button
            className={[buttonClasses.saveButton, classes.button].join( " " )}
            disabled={loading}
            onClick={handleDeactivateShopper}
          >
            Yes
          </Button>
          <Button
            className={[classes.button].join( " " )}
            disabled={loading}
            onClick={cancelDeactivateShopper}
          >
            No
          </Button>
        </CustomModal>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="h5" gutterBottom>
              {`User ${record.id}`}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="h6" gutterBottom>
              {`User Details`}
            </Typography>
            <Box
              border={2}
              bgcolor={"#f7f9fa"}
              css={{ borderColor: "#C5D1D9" }}
              padding={2}
              marginLeft={1}
            >
              <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography
                    variant={"h6"}
                  >{`First Name: ${record.firstname}`}</Typography>
                  <Typography
                    variant={"h6"}
                  >{`Last Name: ${record.lastname}`}</Typography>
                  <Typography
                    variant={"h6"}
                  >{`Email: ${record.email}`}</Typography>
                  <Typography variant={"h6"}>{`Phone: ${
                    record.phone || ""
                  }`}</Typography>
                  {record.instagramHandle && (
                    <Typography
                      variant={"h6"}
                    >{`Instagram Handle: ${record.instagramHandle}`}</Typography>
                  )}
                  <Typography variant={"h6"}>
                    {`Passed Fulfillment Review: `}
                    <TrueFalseIcon value={record.passedFulfillmentReview} />
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box paddingTop={2} marginLeft={1} textAlign="left">
              {hasPermissions(
                [BooleanRoles.Admin, BooleanRoles.RefundCustomerService],
                permissions
              ) &&
                !record.passedFulfillmentReview && (
                <Button
                  className={[buttonClasses.saveButton, classes.button].join(
                    " "
                  )}
                  disabled={isUpdating}
                  onClick={approveCustomer}
                >
                    Approve Customer
                </Button>
              )}
              {hasPermissions( [BooleanRoles.Admin], permissions ) && (
                <Button
                  className={[buttonClasses.saveButton, classes.button].join(
                    " "
                  )}
                  disabled={
                    isUpdatingDeactivateShopper ||
                    !record.isSellerActive ||
                    ( data && !data.isSellerActive )
                  }
                  onClick={deactivateShopper}
                >
                  Deactivate Seller
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export interface CustomViewProps {
  properties?: {
    [x: string]: unknown;
  };
  record?: Customer;
  parentProps?: IEditProperties;
}

export const CustomCardIssuingTab: React.FC<CustomViewProps> = (
  properties: CustomViewProps
) =>
{
  const parentProps: IEditProperties = properties.parentProps;
  const record: Customer = properties.record;

  const notify = useNotify();
  const onSuccess = () =>
  {
    notify( "User Updated" );
    window.location.reload();
  };

  const onFailure = ( error: Error ) =>
  {
    notify( `Failed updating user  ${error.message}`, "warning" );
  };

  return record?.isSeller && record?.stripeAccountSource === "US" ? (
    record?.issuedCreditCard !== true ? (
      <Edit
        undoable={false}
        transform={transformCCIssuing}
        onSuccess={onSuccess}
        onFailure={onFailure}
        {...parentProps}
      >
        <SimpleForm toolbar={<IssueToolbar />}>
          <StripeIssuingFields />
        </SimpleForm>
      </Edit>
    ) : (
      <CardDetailsView record={record} />
    )
  ) : (
    <>
      <Box>&nbsp;</Box>
      <Typography gutterBottom={true}>
        Sorry, this Shopper is currently ineligible for Stripe Credit Card
        Issuing.
      </Typography>
    </>
  );
};

export const CustomEmptyDisplay: React.FC = () => (
  <Typography>
    This user is neither currently nor have they ever been a member.
  </Typography>
);

// Keep it here as it will be available on transform func.
let permittedEventTypes: SellerPermittedEventTypes[] = [];
const onPermittedEventTypesChange = ( selected: SellerPermittedEventTypes[] ) =>
{
  permittedEventTypes = selected;
};
export const CustomTabbedShowLayout: React.FC<CustomViewProps> = ( properties: {
  [x: string]: unknown;
  record: Customer;
  parentProps: IEditProperties;
} ) =>
{
  const { loaded, permissions } = usePermissions();
  const roleClaims = extractAuthClaims( permissions );
  const isPartner = hasPermissions( [BooleanRoles.Partner], roleClaims );
  const isAdmin = hasPermissions( [BooleanRoles.Admin], roleClaims );
  const record = useRecordContext();

  const notify = useNotify();
  const onSuccess = () =>
  {
    notify( "User Updated" );
    window.location.reload();
  };

  const onFailure = ( error: Error ) =>
  {
    notify( `Failed updating user  ${error.message}`, "warning" );
  };

  const transform = ( data: Record ) =>
  {
    const fromAddresses = data.fromAddresses as { [key: string]: unknown }[];
    const filteredFromAddresses = fromAddresses
      ? fromAddresses.map( ( value ) => _.omitBy( value, _.isNil ) )
      : [];

    const update: {
      id: string | number;
      updates: {
        [key: string]: unknown;
      };
    } = {
      id: data.id,
      updates: {
        fromAddresses: filteredFromAddresses || [],
        ...( data.sellerType && { sellerType: data.sellerType } ),
        ...( permittedEventTypes &&
          permittedEventTypes.length && { permittedEventTypes } ),
      },
    };

    if ( isPartner === false && data.fulfillmentType )
    {
      update.updates.fulfillmentType = data.fulfillmentType;
    }

    if ( isAdmin )
    {
      update.updates.shopperMarket = data.shopperMarket || "";
      update.updates.sellerIdentityType = data.sellerIdentityType || "";
    }

    return update;
  };

  const { parentProps, ...obtainedProps } = properties;

  const isSeller = properties.record?.isSeller || false;
  return loaded ? (
    <TabbedShowLayout {...obtainedProps}>
      {isSeller ? (
        <Tab label="Seller Details">
          <Edit
            undoable={false}
            transform={transform}
            onSuccess={onSuccess}
            onFailure={onFailure}
            {...parentProps}
          >
            <SimpleForm toolbar={<SaveOnlyToolbar />}>
              <SellerFields
                record={record}
                {...{
                  ...properties,
                  onPermittedEventTypesChange,
                }}
              />
            </SimpleForm>
          </Edit>
        </Tab>
      ) : null}
      {isSeller && hasPermissions( [BooleanRoles.Admin], roleClaims ) ? (
        <Tab label="Credit Card Details">
          <CustomCardIssuingTab parentProps={parentProps} />
        </Tab>
      ) : null}
      <Tab label="ShopThing VIP Membership">
        <ReferenceManyField
          reference="membership"
          target="id"
          filter={{}}
          pagination={<Pagination limit={<CustomEmptyDisplay />} />}
          label=""
        >
          {/* Style as necessary */}
          <Datagrid style={{ tableLayout: "fixed" }}>
            <TextField
              label="Membership Status"
              source="membershipStatus"
              sortable={false}
            />
            <DateField
              label="Member Since"
              source="memberSince"
              sortable={false}
              locales="en-US"
              options={{
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
              }}
              showTime={true}
            />
            <DateField
              label="Membership Renewal Date"
              source="memberRenewalDate"
              sortable={false}
              locales="en-US"
              options={{
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
              }}
              showTime={true}
            />
            <TextField label="Type" source="type" sortable={false} />
            <FunctionField
              label="Price"
              render={( membership: MembershipSubscription ) =>
                `${formatPrice(
                  membership.price.toString(),
                  membership.currency
                )}`
              }
              sortable={false}
            />
            <DateField
              label="Expiry date"
              source="expiryDate"
              sortable={false}
              locales="en-US"
              options={{
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
              }}
              showTime={true}
            />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      {isAdmin &&
      properties.record?.fulfillmentType === FulfillmentType.partner ? (
          <Tab label="Carrier Details">
            <PartnerCarriers parentProps={parentProps} />
          </Tab>
        ) : null}
    </TabbedShowLayout>
  ) : null;
};

export const UserEdit: React.FunctionComponent<IEditProperties> = (
  properties: IEditProperties
) =>
{
  const { loaded } = usePermissions();
  return loaded ? (
    <Show {...properties}>
      <SimpleShowLayout>
        <CustomerFields />
        <Spacer />
        <CustomTabbedShowLayout parentProps={properties} />
      </SimpleShowLayout>
    </Show>
  ) : null;
};
