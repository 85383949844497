import * as React from "react";
import { SaveButton, Toolbar } from "react-admin";

export const SaveOnlyToolbar: React.FunctionComponent<unknown> = (
  properties: Record<string, never>
) => (
  <Toolbar {...properties}>
    <SaveButton />
  </Toolbar>
);
