import { Main } from "./main";
import ReactDOM from "react-dom";
import React from "react";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import { config } from "./config";

export class App
{
  public async render(): Promise<void>
  {
    // This will wait for the app to start
    const LDProvider = await asyncWithLDProvider( {
      clientSideID: config.launchDarkly.clientSideID,
    } );

    // Render the LDProvider with the Main app as a child
    ReactDOM.render(
      React.createElement(
        LDProvider,
        null,
        React.createElement( Main, { app: this } )
      ),
      document.querySelector( "#app" ) || document.createElement( "div" )
    );
  }
}

new App().render();
