import React from "react";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles( () => ( {
  error: {
    backgroundColor: "#f15d5d",
  },
  warning: {
    backgroundColor: "#e0d56c",
  },
  success: {
    backgroundColor: "#6fce60",
  },
  closeIcon: {
    position: "absolute",
    right: 16,
    top: 5,
    cursor: "pointer",
  },
  notificationBox: {
    minWidth: 500,
    minHeight: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "3px",
  },
  message: {
    marginTop: "5px",
    textAlign: "center",
    flexGrow: 1,
  },
} ) );

export enum NotifyTypes {
  success = "success",
  error = "error",
  warning = "warning",
}

interface NotifyProps {
  open: boolean;
  message: string;
  onClose: () => void;
  type?: NotifyTypes;
}

export const Notify: React.FC<NotifyProps> = ( {
  open,
  message,
  onClose,
  type,
} ) =>
{
  const classes = useStyles();
  return (
    <Dialog open={open}>
      <DialogContent className={`${classes[type]} ${classes.notificationBox}`}>
        <DialogTitle>
          <CloseIcon
            onClick={onClose}
            className={classes.closeIcon}
          ></CloseIcon>
        </DialogTitle>
        <DialogContent className={classes.message}>{message}</DialogContent>
      </DialogContent>
    </Dialog>
  );
};
