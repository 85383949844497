import * as React from "react";
import { useDataProvider, useNotify } from "react-admin";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid
} from "@material-ui/core";
import {
  ChatMessage,
  ChatMessageListProps,
  MessageType
} from "../../ts/interfaces/chat-interface";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles( ( theme ) => ( {
  messageContainer: {
    marginBottom: theme.spacing( 1 ),
    borderBottom: "1px solid #CCCCCC",
    paddingBottom: "40px",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "flex-start",
  },
  sender: {
    fontSize: "14px",
    textAlign: "left",
  },
  sentAt: {
    fontSize: "14px",
    textAlign: "right",
  },
  messageText: {
    fontSize: "14px",
    textAlign: "left",
  },
  attachment: {
    textAlign: "center",
  },
  image: {
    maxHeight: "400px",
    verticalAlign: "top",
  },
  video: {
    width: "250px",
    height: "300px",
    verticalAlign: "top",
  },
} ) );

export const ChatMessageList: React.FunctionComponent<ChatMessageListProps> = ( {
  chatId,
  seller,
  customer,
} ) =>
{
  const [openPopup, setOpenPopup] = React.useState( false );
  const [chatMessages, setChatMessages] = React.useState<ChatMessage[]>( [] );
  const [offset, setOffset] = React.useState( 0 );
  const [hasMoreMessages, setHasMoreMessages] = React.useState( true );
  const limit = 100;
  const notifyMessageText = "There are no chat messages on this chat.";
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const classes = useStyles();

  const fetchChatMessages = ( newOffset: number ) =>
  {
    dataProvider
      .chatMessages( "chat-reports", { id: chatId, offset: newOffset, limit } )
      .then( ( { data }: { data: ChatMessage[] } ) =>
      {
        if ( data.length === 0 )
        {
          setHasMoreMessages( false );
          notify( notifyMessageText );
        }
        setChatMessages( ( prevMessages ) => [...prevMessages, ...data] );
      } );
  };

  const handlePopupOpen = () =>
  {
    setOpenPopup( true );
    fetchChatMessages( offset );
  };

  const handleClosePopup = () =>
  {
    setOpenPopup( false );
    setHasMoreMessages( true );
    setOffset( 0 );
    setChatMessages( [] );
  };

  const handleLoadMore = () =>
  {
    if ( !hasMoreMessages )
    {
      notify( notifyMessageText );
      return;
    }

    const newOffset = offset + limit;
    setOffset( newOffset );
    fetchChatMessages( newOffset );
  };

  return (
    <div>
      <Button variant="contained" onClick={handlePopupOpen}>
        Show Messages
      </Button>
      <Dialog
        open={openPopup}
        onClose={handleClosePopup}
        maxWidth="lg"
        fullWidth={true}
      >
        <DialogTitle>Chat Messages</DialogTitle>
        <DialogContent dividers>
          <Box>
            {chatMessages.map( ( message ) =>
            {
              const isSellerMessage = message.senderId === seller.id;
              const sender = isSellerMessage ? seller : customer;
              const isMessageTypeAttachment =
                message.messageType === MessageType.attachment;
              return (
                <Grid
                  container
                  className={classes.messageContainer}
                  key={message.id}
                >
                  <Grid item xs={3} className={classes.sender}>
                    {!sender.firstname && !sender.lastname
                      ? "N/A"
                      : `${sender.firstname} ${sender.lastname.charAt( 0 )}.`}
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    className={
                      !isMessageTypeAttachment
                        ? classes.messageText
                        : classes.attachment
                    }
                  >
                    {isMessageTypeAttachment ? (
                      <>
                        {message.media.map( ( mediaItem, mediaIndex ) =>
                        {
                          const isImage =
                            mediaItem.contentType &&
                            mediaItem.contentType.startsWith( "image" );
                          return (
                            <div key={mediaIndex}>
                              {isImage ? (
                                <img
                                  src={mediaItem.mediaUrl}
                                  alt="Image"
                                  className={classes.image}
                                />
                              ) : (
                                <video className={classes.video} controls>
                                  <source
                                    src={mediaItem.mediaUrl}
                                    type="video/mp4"
                                  />
                                </video>
                              )}
                            </div>
                          );
                        } )}
                      </>
                    ) : (
                      message.messageText
                    )}
                  </Grid>
                  <Grid item xs={3} className={classes.sentAt}>
                    {new Date( message.sentAt as unknown as Date ).toLocaleString(
                      "en-US",
                      {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        second: "numeric",
                      }
                    )}
                  </Grid>
                </Grid>
              );
            } )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePopup} color="primary">
            Close
          </Button>
          <Button onClick={handleLoadMore} color="primary">
            Load More
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
