import React from "react";
import { Box, Grid, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import { useBarcode } from "../../contexts/barcode-context";
import { resetHistory } from "../../utils/reset-history";
const useStyles = makeStyles( () => ( {
  error: {
    backgroundColor: "#f15d5d",
  },
  warning: {
    backgroundColor: "#e0d56c",
  },
  success: {
    backgroundColor: "#6fce60",
  },
  notificationBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "1px",
    borderRadius: "4px",
  },
  message: {
    marginLeft: "5px",
  },
} ) );

export enum PopupNotificationType {
  error = "error",
  warning = "warning",
  success = "success",
}

export interface INotification {
  message: string;
  type: PopupNotificationType;
  refresh?: boolean;
}

export const PopupNotification = () =>
{
  const { notification, setNotification } = useBarcode();
  const classes = useStyles();
  if ( !notification )
  {
    return null;
  }
  const { message, type, refresh } = notification as INotification;

  const handleCloseNotification = () =>
  {
    setNotification( null );
    if ( refresh )
    {
      resetHistory();
      history.go();
    }
  };

  return (
    <Box>
      <Grid container justify="center">
        <Grid item xs={4}>
          <Box className={`${classes[type]} ${classes.notificationBox}`}>
            <span className={classes.message}>{message}</span>
            <IconButton onClick={handleCloseNotification}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
