import * as React from "react";
import _ from "lodash";
import {
  SimpleForm,
  useNotify,
  useRefresh,
  Create,
  FileInput,
  FileField
} from "react-admin";
import { Grid, Typography } from "@material-ui/core";
import { Record } from "ra-core";
import { Spacer } from "../../generics/spacer";

const MAX_FILE_SIZE = 400 * 1000;

interface IEditProperties {
  id: string;
  [x: string]: unknown;
}

const convertFileToBase64 = ( file: File ) =>
  new Promise( ( resolve, reject ) =>
  {
    const filReader = new FileReader();
    filReader.addEventListener( "load", () =>
      resolve( ( filReader.result as string ).split( "," ).pop() )
    );
    filReader.addEventListener( "error", reject );
    filReader.readAsDataURL( file );
  } );

const fileInputValidate = ( inputFile: Record ) =>
{
  const fileSize = _.get( inputFile, "rawFile.size" );
  const fileType = _.get( inputFile, "rawFile.type" );
  if ( _.isNil( inputFile ) || _.isNil( fileSize ) || _.isNil( fileType ) )
  {
    return "CSV file required";
  }

  if ( fileSize > MAX_FILE_SIZE )
  {
    return `File size should be less than ${MAX_FILE_SIZE / 1000} KB`;
  }

  if ( fileType !== "text/csv" )
  {
    return "Only CSV file accepted";
  }
};

const transform = async ( data: Record ) =>
{
  const rawFile = _.get( data, "csvInput.rawFile" );
  let base64File = null;
  if ( rawFile )
  {
    base64File = await convertFileToBase64( rawFile );
  }

  const create: Record = {
    id: "",
    creation: {
      ...( base64File && { csvInput: base64File } ),
    },
  };

  return create;
};

export const ManualOrdersCreate: React.FunctionComponent<IEditProperties> = (
  properties: IEditProperties
) =>
{
  const notify = useNotify();
  const refresh = useRefresh();

  const onFailure = ( error: Error ) =>
  {
    notify( `${error.message}`, "warning" );
    refresh();
  };

  const onSuccess = () =>
  {
    notify( "Orders created" );
    window.location.reload();
  };

  return (
    <Create
      transform={transform}
      onFailure={onFailure}
      undoable={false}
      onSuccess={onSuccess}
      {...properties}
    >
      <SimpleForm>
        <Grid container>
          <Grid item xs={12} sm={12} md={12}>
            <Typography variant={"h5"}>Add CSV file of orders</Typography>
          </Grid>
          <Spacer />
          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="body1">
              Maximum number of order is 500
            </Typography>
            <Typography variant="body1">
              Maximum file size is {MAX_FILE_SIZE / 1000} KB
            </Typography>
            <Typography variant="body1">
              The time format is UTC iso format
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <FileInput
              label="File input"
              source="csvInput"
              multiple={false}
              accept="text/csv"
              validate={fileInputValidate}
            >
              <FileField source="src" title="title" download={false} />
            </FileInput>
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};
