import * as React from "react";
import {
  Datagrid,
  DateField,
  Filter,
  FunctionField,
  List,
  SelectInput,
  TextField,
  useListContext
} from "react-admin";
import { FilterProps } from "ra-ui-materialui";
import { Settings } from "../../ts/interfaces/settings-interface";
import { BasicPagination, perPage } from "../generics/pagination";
import {
  ChatReportData,
  ReporterType
} from "../../ts/interfaces/chat-interface";

const ChatReportStatusFilter: React.FC = ( properties: FilterProps ) => (
  <Filter {...properties}>
    <SelectInput
      source="chatReportStatus"
      label="Reported Chats"
      alwaysOn
      allowEmpty={false}
      choices={[
        { id: "pending", name: "Pending" },
        { id: "approved", name: "Approved" },
        { id: "rejected", name: "Rejected" },
      ]}
    />
  </Filter>
);

const ListChatReportsView: React.FC = () =>
{
  const { loading, ids, filterValues } = useListContext();
  return loading ? (
    <></>
  ) : ( ids.length === 0 ? (
    <div style={{ padding: "0 20px" }}>
      <br />
      <h3 style={{ textTransform: "capitalize" }}>
        No {filterValues.chatReportStatus} Reported Chats
      </h3>
      <br />
    </div>
  ) : (
    <Datagrid
      rowClick={( _id: string, _: string, report: ChatReportData ) =>
        `chat-reports/${report.id}/show`
      }
    >
      <TextField label="Chat ID" source="chatId" sortable={false} />
      <TextField
        label="Product Name"
        source="product.productName"
        sortable={false}
      />
      <FunctionField
        label="Reported by"
        render={( report: ChatReportData ) =>
          `${report.isSellerReporter ? "Seller" : "Customer"}`
        }
        sortable={false}
      />

      <FunctionField
        label="Reporter"
        render={( report: ChatReportData ) =>
        {
          const key = report.isSellerReporter
            ? ReporterType.seller
            : ReporterType.customer;
          return !report[key].firstname && !report[key].lastname
            ? "N/A"
            : `${report[key].firstname} ${report[key].lastname}`;
        }}
        sortable={false}
      />
      <FunctionField
        label="Reporter Email"
        render={( report: ChatReportData ) =>
        {
          const key = report.isSellerReporter
            ? ReporterType.seller
            : ReporterType.customer;
          return `${report[key].email || "N/A"}`;
        }}
        sortable={false}
      />
      <FunctionField
        label="Reporter ID"
        render={( report: ChatReportData ) =>
        {
          const key = report.isSellerReporter
            ? ReporterType.seller
            : ReporterType.customer;
          return report[key].id;
        }}
        sortable={false}
      />
      <FunctionField
        label="Handle"
        render={( report: ChatReportData ) =>
          `${report.isSellerReporter ? report.seller.handle : "N/A"}`
        }
        sortable={false}
      />
      <DateField
        label="Reported Date"
        source="timestamps.created"
        showTime
        sortable={false}
      />
      <TextField
        label="Conversation Report Count"
        source="reportedCount"
        sortable={false}
      />
    </Datagrid>
  ) );
};

export const ChatReportsList = ( properties: Settings[] ): JSX.Element => (
  <List
    {...properties}
    pagination={<BasicPagination hideWhenEmpty />}
    title="Reported Chats"
    bulkActionButtons={false}
    exporter={false}
    filters={<ChatReportStatusFilter />}
    filterDefaultValues={{ chatReportStatus: "pending" }}
    perPage={perPage}
  >
    <ListChatReportsView />
  </List>
);
