import React from "react";
import {
  TextInput,
  SimpleForm,
  SelectInput,
  useUpdate,
  useNotify,
  usePermissions
} from "react-admin";
import { Button } from "@material-ui/core";
import { CustomModal } from "@shopthing-opn-shared/admin-dashboard";
import { makeStyles } from "@material-ui/core/styles";
import { minValue, number as numberValidator, regex } from "ra-core";
import { ButtonStyles } from "../generics/button-styles";
import { notifyMessage } from "../../utils/primitive";

interface IProductAuthenticationButton {
  id: string;
  action: string;
  [key: string]: unknown;
}

const useStyles = makeStyles( {
  customTextArea: {
    "& .MuiFilledInput-root": {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      backgroundColor: "inherit",
      borderStyle: "solid",
      borderWidth: "thin",
      borderColor: "grey",
    },
  },
} );

export const ProductAuthenticationAction: React.FunctionComponent<
  IProductAuthenticationButton
> = ( properties ) =>
{
  const [open, setOpen] = React.useState( false );
  const [currency, setCurrency] = React.useState( "cad" );
  const [price, setPrice] = React.useState( null );
  const [note, setNote] = React.useState( null );
  const [hasFormError, setHasFormError] = React.useState( false );

  const classes = useStyles();
  const notify = useNotify();

  const [updateAction, { loading }] = useUpdate(
    "order-authentication",
    properties.id,
    {
      status: properties.action,
      note,
      currency,
      price: price * 100,
    },
    {},
    {
      onSuccess: () =>
      {
        notify( "Success" );
        window.location.reload();
      },
      onFailure: ( error: Error ) =>
      {
        notifyMessage( error, notify );
      },
    }
  );

  const handleOpen = () =>
  {
    setOpen( true );
  };

  const handleClose = () =>
  {
    setNote( null );
    setCurrency( "cad" );
    setPrice( null );
    setOpen( false );
  };

  const noteInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) =>
  {
    setNote( event.target.value );
  };

  const currencyChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) =>
  {
    setCurrency( event.target.value );
  };

  const amountChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) =>
  {
    setPrice( event.target.value );
  };

  const customNumberValidator = ( value: unknown, values: unknown ) =>
  {
    const error = numberValidator( "Amount must be a number." )( value, values );
    if ( error )
    {
      setHasFormError( true );
      return error;
    }
    setHasFormError( false );
    return error;
  };

  const customMinValueValidator = ( value: unknown, values: unknown ) =>
  {
    const error = minValue( 0, "Minimum amount must be 0." )( value, values );
    if ( error )
    {
      setHasFormError( true );
      return error;
    }
    setHasFormError( false );
    return error;
  };

  const customRegexValidator = ( value: unknown, values: unknown ) =>
  {
    const error = regex(
      /^\d+(\.\d{1,2})?$/,
      "Amount can only contain up to two digits for cents."
    )( value, values );
    if ( error )
    {
      setHasFormError( true );
      return error;
    }
    setHasFormError( false );
    return error;
  };

  const { loaded } = usePermissions();
  const body = loaded ? (
    <div>
      <SimpleForm toolbar={<></>}>
        <TextInput
          source="note"
          name="note"
          label="Notes"
          multiline={true}
          fullWidth={true}
          resettable={false}
          onChange={noteInputChange}
          rows="5"
          className={classes.customTextArea}
          required
        />
        <SelectInput
          source="currency"
          choices={[
            { id: "cad", name: "CAD" },
            { id: "usd", name: "USD" },
          ]}
          onChange={currencyChange}
          required
        />
        <TextInput
          source="authenticationAmount"
          name="authenticationAmount"
          label="Authentication Price"
          onChange={amountChange}
          required
          validate={[
            customNumberValidator,
            customMinValueValidator,
            customRegexValidator,
          ]}
        />
      </SimpleForm>
      <Button
        onClick={updateAction}
        disabled={!note || !price || loading || hasFormError}
      >
        {properties.action === "authenticated"
          ? "Authenticate Item"
          : "Counterfeit Item"}
      </Button>
    </div>
  ) : null;

  const buttonClasses = ButtonStyles();

  return (
    <>
      <Button
        className={buttonClasses.saveButton}
        disabled={loading}
        disableRipple
        disableFocusRipple
        onClick={handleOpen}
      >
        {properties.action === "authenticated"
          ? "Authenticate Item"
          : "Counterfeit Item"}
      </Button>
      <CustomModal open={open} onClose={handleClose}>
        {body}
      </CustomModal>
    </>
  );
};
