import * as React from "react";
import {
  Create,
  SelectArrayInput,
  SimpleForm,
  TextInput,
  Toolbar,
  useDataProvider
} from "react-admin";
import { Button, Grid } from "@material-ui/core";
import { required } from "ra-core";
import { makeStyles } from "@material-ui/core/styles";
import { ButtonStyles } from "../generics/button-styles";
import { Notify, NotifyTypes } from "../popup-notification/notify";

enum SellerType {
  shopthing = "ShopThing",
  seller = "Seller",
  partner = "Partner",
}

const useStyles = makeStyles( {
  customTextArea: {
    "& .MuiFilledInput-root": {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      backgroundColor: "inherit",
      borderStyle: "solid",
      borderWidth: "thin",
      borderColor: "grey",
    },
  },
  button: {
    marginLeft: "5px",
    border: "1px solid black",
  },
  minWidthSelectArrayInput: {
    minWidth: "280px",
  },
} );

export const SellerMessageCreate: React.FunctionComponent = ( properties ) =>
{
  const dataProvider = useDataProvider();
  const classes = useStyles();
  const buttonClasses = ButtonStyles();

  const [disableSaveButton, setDisableSaveButton] =
    React.useState<boolean>( true );
  const [formValues, setFormValues] = React.useState( {
    subject: "",
    message: "",
    receiverIds: "",
    sellerTypes: [],
  } );

  const [notification, setNotification] = React.useState<{
    open: boolean;
    message: string;
    type: NotifyTypes;
  }>( { open: false, message: "", type: NotifyTypes.success } );

  const handleChange = ( event: React.ChangeEvent<HTMLInputElement> ) =>
  {
    const { name, value } = event.target;
    setFormValues( ( prevValues ) => ( { ...prevValues, [name]: value } ) );
  };

  const handleSubmit = async ( event: React.FormEvent ) =>
  {
    event.preventDefault();
    setDisableSaveButton( true );
    dataProvider
      .createSellerMessage( "seller-messages", {
        subject: formValues.subject,
        message: formValues.message,
        sellerTypes: formValues.sellerTypes,
        receiverIds: formValues.receiverIds
          .split( "\n" )
          .map( ( id ) => id.trim() )
          .filter( ( id ) => id !== "" ),
      } )
      .then( () =>
      {
        setNotification( {
          open: true,
          message: "Message successfully sent to the shoppers.",
          type: NotifyTypes.success,
        } );
      } )
      .catch( ( error: Error ) =>
      {
        setNotification( {
          open: true,
          message: error.message,
          type: NotifyTypes.error,
        } );
      } );
  };

  const handleCloseNotification = () =>
  {
    setNotification( ( prev ) => ( { ...prev, open: false } ) );
    const redirectUrl = `${window.location.origin}/#/seller-messages`;
    history.replaceState( {}, "", redirectUrl );
    history.go();
  };

  React.useEffect( () =>
  {
    const { subject, message, receiverIds } = formValues;
    setDisableSaveButton(
      !( subject && message && ( receiverIds || formValues.sellerTypes.length ) )
    );
  }, [formValues] );

  return (
    <Create {...properties} mutationMode={"pessimistic"}>
      <>
        <SimpleForm
          toolbar={
            <Toolbar>
              <Button
                className={[buttonClasses.saveButton, classes.button].join( " " )}
                disabled={disableSaveButton}
                onClick={handleSubmit}
              >
                Send
              </Button>
            </Toolbar>
          }
        >
          <Grid container>
            <Grid item xs={12}>
              <h2>Create Concierge Messages</h2>
            </Grid>
          </Grid>
          <SelectArrayInput
            className={classes.minWidthSelectArrayInput}
            label="Seller Type"
            name="sellerTypes"
            choices={Object.values( SellerType ).map( ( sellerType ) => ( {
              id: sellerType,
              name: sellerType,
            } ) )}
            onChange={handleChange}
            value={formValues.sellerTypes}
          />
          <TextInput
            name="receiverIds"
            label="To"
            multiline={true}
            fullWidth={true}
            validate={false}
            onChange={handleChange}
            value={formValues.receiverIds}
            rows={10}
            className={classes.customTextArea}
          />
          <TextInput
            label="Subject"
            name="subject"
            validate={required()}
            onChange={handleChange}
            value={formValues.subject}
            fullWidth
          />
          <TextInput
            name="message"
            label="Message"
            multiline={true}
            fullWidth={true}
            validate={required()}
            onChange={handleChange}
            value={formValues.message}
            rows={6}
            className={classes.customTextArea}
          />
        </SimpleForm>
        <Notify
          open={notification.open}
          message={notification.message}
          onClose={handleCloseNotification}
          type={notification.type}
        />
      </>
    </Create>
  );
};
