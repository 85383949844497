import React, { useEffect } from "react";
import { useUpdate, useNotify, useRefresh, usePermissions } from "react-admin";
import { IconButton, Typography, Link, Button } from "@material-ui/core";
import { Item, Order } from "src/ts/interfaces/order-interface";
import {
  CustomModal,
  DymoContext
} from "@shopthing-opn-shared/admin-dashboard";
import { Spacer } from "../generics/spacer";
import { PrintBarcodeLabelButton } from "./product-barcode-label";
import {
  extractAuthClaims,
  hasPermissions,
  BooleanRoles
} from "../../ts/interfaces/role-interface";
import { useBarcode } from "../../contexts/barcode-context";
import { PopupNotificationType } from "../popup-notification/popup-notification";
import { notifyMessage } from "../../utils/primitive";

interface IBarcodeButton {
  orderId: string;
  skuId: string;
  authentication?: {
    status: string;
  };
  [x: string]: unknown;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  latestDisputeStatus?: string;
}

interface UpdateResponse {
  data: Order;
}

const ModalBody = ( { orderId, skuId, setOpen }: IBarcodeButton ) =>
{
  const refresh = useRefresh();
  const notify = useNotify();
  const [fulfillmentData, setFulfillmentData] = React.useState( {
    fulfillmentId: "",
    item: {} as Item,
    productInventoryItemId: "",
  } );
  const [update, { loading, error }] = useUpdate(
    "orders-create-fulfillment",
    orderId,
    {
      items: [
        {
          skuId,
          quantity: 1,
        },
      ],
    },
    {},
    {
      onSuccess: ( response: UpdateResponse ) =>
      {
        const item = response.data.cart.items[0];
        const fulfillmentIds = response.data.fulfillmentIds;
        //Temporary fix until we can add verification of newly added fulfillmentId in response
        const fulfillmentId = fulfillmentIds[fulfillmentIds.length - 1];
        setFulfillmentData( {
          fulfillmentId,
          item,
          productInventoryItemId:
            response.data.fulfillments[fulfillmentId].productInventoryItemId,
        } );
      },
    }
  );

  useEffect( () =>
  {
    if ( error )
    {
      notifyMessage( error, notify );
    }
  }, [error] );

  // call the api for order fulfillments
  useEffect( () =>
  {
    update();
  }, [] );

  const { printLabel } = React.useContext( DymoContext );
  const handleConfirm = () =>
  {
    printLabel( [
      { title: "CATEGORY", value: fulfillmentData.item.brandName || "" },
      { title: "TITLE", value: fulfillmentData.item.productName || "" },
      { title: "DESC", value: fulfillmentData.item.description || "" },
      {
        title: "qrcode",
        value: JSON.stringify( {
          fulfillmentId: fulfillmentData.fulfillmentId,
          orderId,
          productInventoryItemId: fulfillmentData.productInventoryItemId,
        } ),
      },
    ] );
    setOpen( false );
    refresh();
  };
  return (
    <>
      <Typography>Generate Barcode Label for Fulfillment</Typography>
      <Spacer />
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Button disabled={loading} onClick={handleConfirm}>
          Print (using Dymo)
        </Button>
        <PrintBarcodeLabelButton
          fulfillmentId={fulfillmentData.fulfillmentId}
          orderId={orderId}
          item={fulfillmentData.item}
          setOpen={setOpen}
          loading={loading}
          productInventoryItemId={fulfillmentData.productInventoryItemId}
        />
      </div>
    </>
  );
};

const GenerateBarcodeForAdmin: React.FunctionComponent<IBarcodeButton> = ( {
  orderId,
  skuId,
}: IBarcodeButton ) =>
{
  const [open, setOpen] = React.useState( false );
  const handleOpen = () =>
  {
    setOpen( true );
  };

  const handleClose = () =>
  {
    setOpen( false );
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        color={"primary"}
        disableRipple
        disableFocusRipple
      >
        <Typography>
          <Link>Generate Barcode</Link>
        </Typography>
      </IconButton>

      <CustomModal open={open} onClose={handleClose}>
        <ModalBody orderId={orderId} skuId={skuId} setOpen={setOpen} />
      </CustomModal>
    </>
  );
};

const GenerateBarcodeForSeller: React.FunctionComponent<IBarcodeButton> = ( {
  orderId,
  skuId,
}: IBarcodeButton ) =>
{
  const notify = useNotify();
  const { setNotification } = useBarcode();
  const [update, { error }] = useUpdate(
    "orders-create-fulfillment",
    orderId,
    {
      items: [
        {
          skuId,
          quantity: 1,
        },
      ],
    },
    {},
    {
      onSuccess: () =>
      {
        notify( "Barcode generated" );
        window.location.reload();
      },
    }
  );

  useEffect( () =>
  {
    if ( error )
    {
      setNotification( {
        message: error.message,
        type: PopupNotificationType.error,
      } );
    }
  }, [error] );

  return (
    <Button onClick={update}>
      <Link>Generate Barcode</Link>
    </Button>
  );
};

export const BarcodeButton: React.FunctionComponent<IBarcodeButton> = ( {
  orderId,
  skuId,
  authentication,
  latestDisputeStatus,
}: IBarcodeButton ) =>
{
  const { loaded, permissions } = usePermissions();
  const roleClaims = extractAuthClaims( permissions );

  const authenticationStatusText =
    authentication?.status === "pending" ? (
      <Typography>Authentication is Pending</Typography>
    ) : ( authentication?.status === "rejected" ? (
      <Typography>Item(s) are counterfeit</Typography>
    ) : (
      <></>
    ) );

  return (
    <>
      {loaded &&
        ( () =>
        {
          if ( latestDisputeStatus === "fraudulent" )
          {
            return <Typography>Order is marked as fraudulent</Typography>;
          }

          if (
            !authentication?.status ||
            authentication.status === "authenticated"
          )
          {
            if (
              hasPermissions(
                [
                  BooleanRoles.Admin,
                  BooleanRoles.RefundCustomerService,
                  BooleanRoles.Partner,
                ],
                roleClaims
              )
            )
            {
              return (
                <GenerateBarcodeForAdmin orderId={orderId} skuId={skuId} />
              );
            }
            else
            {
              return (
                <GenerateBarcodeForSeller orderId={orderId} skuId={skuId} />
              );
            }
          }
          return authenticationStatusText;
        } )()}
    </>
  );
};
