import * as React from "react";
import {
  Card,
  CardContent,
  Box,
  Grid,
  Typography,
  Link
} from "@material-ui/core";
import DoneRoundedIcon from "@material-ui/icons/DoneRounded";
import CloseIcon from "@material-ui/icons/Close";
import { Event as EventInterface } from "../../ts/interfaces/event-interface";
import { MediaContainer, MediaItem } from "../generics/media-container";
import {
  BooleanRoles,
  hasPermissions
} from "../../ts/interfaces/role-interface";
import { usePermissions } from "ra-core";

interface DetailProperties {
  record?: EventInterface;
  [x: string]: unknown;
}

export const EventDetail: React.FunctionComponent<DetailProperties> = (
  detailProperties: DetailProperties
) =>
{
  const { record } = detailProperties;

  const { permissions } = usePermissions();
  const isAdmin = hasPermissions(
    [
      BooleanRoles.Admin,
      BooleanRoles.Fulfillment,
      BooleanRoles.CustomerService,
      BooleanRoles.FulfillmentReadOnly,
      BooleanRoles.RefundCustomerService,
    ],
    permissions
  );

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Typography variant="h5" gutterBottom>
              {`Event ${record.id}`}
            </Typography>
            <Box
              border={2}
              bgcolor={"#f7f9fa"}
              css={{ borderColor: "#C5D1D9" }}
              padding={2}
            >
              <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography
                    variant={"h6"}
                  >{`Name: ${record.name}`}</Typography>
                  <Typography variant={"h6"}>{`Start Date: ${new Date(
                    record.startDate
                  ).toLocaleString()}`}</Typography>
                  <Typography variant={"h6"}>{`End Date: ${new Date(
                    record.endDate
                  ).toLocaleString()}`}</Typography>
                  <Typography variant={"h6"}>
                    Shopper:
                    <Link href={`/#/users/${record.userId}`}>
                      {`${record.userId}`}
                    </Link>
                  </Typography>
                  {record.partnership && (
                    <Typography
                      variant={"h6"}
                    >{`Partner: ${record.partnership}`}</Typography>
                  )}
                  {isAdmin && (
                    <Typography variant={"h6"}>
                      Is Pinned Event:
                      {record.isPinned ? (
                        <DoneRoundedIcon fontSize="default" />
                      ) : (
                        <CloseIcon />
                      )}
                    </Typography>
                  )}
                  {record.isClosetSale && (
                    <Typography variant={"h6"}>
                      Event Type: Closet Sale
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={4}>
            <Typography variant="h5" gutterBottom>
              {"Event Membership details"}
            </Typography>
            <Box
              border={2}
              bgcolor={"#f7f9fa"}
              css={{ borderColor: "#C5D1D9" }}
              padding={2}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant={"h6"}>
                    Exclusive:
                    {record.exclusiveEvent ? (
                      <DoneRoundedIcon fontSize="default" />
                    ) : (
                      <CloseIcon />
                    )}
                  </Typography>
                  <Typography variant={"h6"}>
                    Early Access:
                    {record.earlyAccess ? (
                      <DoneRoundedIcon fontSize="default" />
                    ) : (
                      <CloseIcon />
                    )}
                  </Typography>
                  {record.earlyAccessDelayMinutes &&
                  record.earlyAccess === true ? (
                      <Typography variant={"h6"}>
                      Delay: {record.earlyAccessDelayMinutes} minutes
                      </Typography>
                    ) : null}
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              Event Trailer
            </Typography>
            <Box
              border={2}
              bgcolor={"#f7f9fa"}
              css={{ borderColor: "#C5D1D9" }}
              padding={2}
            >
              <Grid container>
                <MediaContainer
                  keyName="trailerPath"
                  mediaItem={record as unknown as MediaItem}
                  type="video"
                />
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
