import * as React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

import {
  FormTab,
  TabbedForm,
  TextInput,
  useNotify,
  Datagrid,
  TextField,
  Create,
  FunctionField,
  List,
  FormDataConsumer,
  FileInput,
  BooleanInput
} from "react-admin";
import RemoveCircle from "@material-ui/icons/RemoveCircle";

import { useForm } from "react-final-form";

import { Record, required } from "ra-core";
import Checkbox from "@material-ui/core/Checkbox";
import { v4 } from "uuid";

import { CollaborationEvent, Event } from "../../ts/interfaces/event-interface";
import { BasicPagination } from "../generics/pagination";
import { DateField, DateTimeInput } from "ra-ui-materialui";
import { getApp } from "firebase/app";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { IconButton } from "@material-ui/core";

interface IEditProperties {
  id: string;
  [x: string]: unknown;
  record: CollaborationEvent;
}

const useStyles = makeStyles( ( theme: Theme ) =>
  createStyles( {
    formField: {
      margin: theme.spacing( 1, 2, 1, 0 ),
    },
  } )
);

const formLinkIndicator = "form-link-event-";

/**
 * Due to the nature of react admin, it is not possible to directly modify the parent form state
 * from within a nested list view. Instead pass down a checkbox with access to the form controlller
 * which can update the form's state when a checkbox is toggled.
 */
const SelectEvent = ( properties: { record: Event } ) =>
{
  const form = useForm();

  return (
    <Checkbox
      disabled={properties.record.isUsedInCollaboration}
      onChange={( event ) =>
      {
        const isChecked = event.target.checked;
        form.change( `${formLinkIndicator}${properties.record.id}`, isChecked );
      }}
    />
  );
};

const ClosetSaleButton = ( props: { onChange: () => void } ) =>
{
  const form = useForm();
  const notify = useNotify();

  return (
    <div style={{ display: "flex" }}>
      <BooleanInput
        label="Closet Sale Event"
        defaultValue={false}
        source="isClosetSale"
        onChange={() =>
        {
          props.onChange();
          const keys = Object.keys( form.getState().values );

          let removed = false;

          keys.forEach( ( key ) =>
          {
            if ( key.startsWith( formLinkIndicator ) )
            {
              form.change( key, false );
              removed = true;
            }
          } );

          if ( removed )
          {
            notify(
              "Switching the collaboration event type unselected the events you had picked before."
            );
          }
        }}
      />
    </div>
  );
};

export const CollaborationEventCreate: React.FunctionComponent<
  IEditProperties
> = ( properties: IEditProperties ) =>
{
  const classes = useStyles();
  const notify = useNotify();

  const [startDate, setStartDate] = React.useState<Date | null>( null );
  const [endDate, setEndDate] = React.useState<Date | null>( null );
  const [isClosetSale, setIsClosetSale] = React.useState<boolean>( false );

  const onFailure = ( error: Error ) =>
  {
    notify( `${error.message}`, "warning" );
  };

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const transform = async ( data: Record ) =>
  {
    const eventIds = new Set<string>();

    // Extract the custom form fields set by the checkboxes and concatentate
    // them into an array of events in the collaboration event
    for ( const [name, value] of Object.entries( data ) )
    {
      if ( name.startsWith( formLinkIndicator ) && value === true )
      {
        eventIds.add( name.split( formLinkIndicator )[1] );
      }
    }

    const events = [...eventIds];

    const create: Record = {
      id: "",
      creation: {
        name: data.name,
        eventIds: events.length > 0 ? events : undefined,
        trailerPath: data.trailerPath,
        trailerPosterPath: data.trailerPosterPath,
        startDate: data.startDate,
        endDate: data.endDate,
        isClosetSale: data.isClosetSale,
      },
    };

    return create;
  };

  const [trailerPath, setTrailerPath] = React.useState<string | null>( null );

  return (
    <Create transform={transform} onFailure={onFailure} {...properties}>
      <TabbedForm>
        <FormTab label="Collaboration Event Details">
          <TextInput
            source="name"
            className={classes.formField}
            validate={required()}
          />

          <ClosetSaleButton
            onChange={() => setIsClosetSale( ( value ) => !value )}
          />

          <DateTimeInput
            label="Start Date for Collaboration Event"
            source="startDate"
            className={classes.formField}
            validate={required()}
            value={startDate}
            onBlur={( date ) =>
              setStartDate( date.target.value as React.SetStateAction<Date> )
            }
          />
          <DateTimeInput
            label="End Date for Collaboration Event"
            source="endDate"
            className={classes.formField}
            validate={required()}
            value={endDate}
            onBlur={( date ) =>
              setEndDate( date.target.value as React.SetStateAction<Date> )
            }
          />
          <FormDataConsumer>
            {() =>
            {
              const form = useForm();

              const parse = React.useCallback( ( file: File ) =>
              {
                if ( file )
                {
                  try
                  {
                    let fileName = file.name;
                    if ( file.type.startsWith( "video" ) )
                    {
                      fileName = "video.mp4";
                    }
                    else if ( file.type.startsWith( "image" ) )
                    {
                      fileName = "poster-raw.jpg";
                    }

                    const basePath = `collaboration-events/${v4()}`;
                    const uploadPath = `${basePath}/${fileName}`;

                    const storeFile = async ( path: string ) =>
                    {
                      const defaultApp = getApp();
                      const storage = getStorage( defaultApp );
                      const storageReference = ref( storage, path );

                      // Upload asynchronously so it doesn't fail the parse
                      uploadBytes( storageReference, file, {
                        customMetadata: {
                          isCollaborationItem: "true",
                          addWatermark: "true",
                        },
                      } )
                        .then( () =>
                        {
                          console.log( "Media Uploaded" );

                          form.change( "trailerPath", uploadPath );
                          form.change(
                            "trailerPosterPath",
                            `${basePath}/poster-raw.jpg`
                          );
                          setTrailerPath( file.name );
                        } )
                        .catch( ( error ) =>
                        {
                          notify(
                            `Failed to upload Media! - ${error.message}`,
                            "warning"
                          );
                        } );
                    };

                    // Obtain the blob for display in browser
                    const objectUrl = URL.createObjectURL( file );

                    // So this will just asynchronously trigger
                    if ( objectUrl )
                    {
                      storeFile( uploadPath );
                    }
                  }
                  catch ( error )
                  {
                    console.log( "uploadFile error:", error );
                  }
                }
              }, [] );

              return (
                <>
                  <FileInput
                    label="Collaboration Event Trailer"
                    parse={( image: File ) => parse( image )}
                    accept="video/*"
                  />
                  {trailerPath && (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <IconButton
                        onClick={() =>
                        {
                          setTrailerPath( null );
                          form.change( "trailerPath", "" );
                          form.change( "trailerPosterPath", "" );
                        }}
                      >
                        <RemoveCircle style={{ color: "#f44336" }} />
                      </IconButton>
                      {trailerPath}
                    </div>
                  )}
                </>
              );
            }}
          </FormDataConsumer>
        </FormTab>
        <FormTab
          label="Add Events to Collaboration"
          disabled={!startDate || !endDate}
        >
          <List
            title=" "
            resource="recommendation-override"
            exporter={false}
            target="id"
            filter={{ startDate, endDate, isClosetSale }}
            pagination={<BasicPagination />}
            bulkActionButtons={false}
          >
            <Datagrid>
              <FunctionField
                label="Add Event"
                render={( event: Event ) => <SelectEvent record={event} />}
              />
              <TextField label="Event Id" source="id" sortable={false} />
              <TextField label="Name" source="name" sortable={false} />
              <DateField
                label="Start Date"
                source="startDate"
                sortable={false}
                showTime
              />
              <DateField
                label="End Date"
                source="endDate"
                sortable={false}
                showTime
              />
              <TextField label="Partnership" source="partnership" />
              <TextField label="Shopper ID" source="userId" />
              <TextField label="Shopper Handle" source="sellerHandle" />
            </Datagrid>
          </List>
        </FormTab>
      </TabbedForm>
    </Create>
  );
};
