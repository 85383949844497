import { FileUploader } from "@shopthing-opn-shared/admin-dashboard";
import * as React from "react";
import { Create, SimpleForm, DateInput } from "react-admin";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { DataGrid as MuiDataGrid } from "@material-ui/data-grid";
import { Record } from "ra-core";
import { getApp } from "firebase/app";

const secondClosetFolder = "secondCloset";

const useStyles = makeStyles( () =>
  createStyles( {
    clickable: {
      cursor: "pointer",
    },
  } )
);

const transform = async ( data: Record ) =>
{
  const create: Record = {
    id: "",
    creation: {
      name: data.name,
      fulfillmentDate: data.fulfillmentDate,
      fulfillmentCsv: await Promise.resolve( data.fulfillmentCsv ),
    },
  };

  return create;
};
interface FulfillmentResult {
  id: string;
  result: "Error" | "Fulfilled";
  message?: string;
}

const ResultList = ( properties: { data: FulfillmentResult[] } ) =>
{
  const classes = useStyles();
  const rows = properties.data.map( ( data ) => ( {
    id: data.id,
    status: data.result,
    message: data.message || "",
  } ) );

  const columns = [
    { field: "id", headerName: "Order Id", sortable: false, width: 300 },
    { field: "status", headerName: "Status", sortable: false, width: 200 },
    { field: "message", headerName: "Message", sortable: false, width: 600 },
  ];
  return (
    <div style={{ minHeight: 800, height: "100%", width: "100%" }}>
      <MuiDataGrid
        rows={rows}
        columns={columns}
        className={classes.clickable}
        onCellDoubleClick={( elem ) =>
        {
          window.location.href = `/#/orders/${elem.id}/show`;
        }}
      />
    </div>
  );
};

export const ClosetCreate = ( properties: {
  [key: string]: never;
} ): JSX.Element =>
{
  // const classes = useStyles();
  const dateString = new Date().toISOString();
  const folder = "fulfillment";
  const [results, setResults] = React.useState( [] as FulfillmentResult[] );
  const app = getApp();

  const onSuccess = async ( result: Record ) =>
  {
    setResults( result.data.data );
  };

  return (
    <Create
      {...properties}
      transform={transform}
      onSuccess={onSuccess}
      title="Second Closet Fulfillment"
    >
      {results.length === 0 ? (
        <SimpleForm>
          <DateInput source="fulfillmentDate" label="Fulfillment Date" />
          <FileUploader
            source="fulfillmentCsv"
            uploadPath={`${secondClosetFolder}/${folder}/${dateString}-csv`}
            inputProperties={{
              accept: "text/csv",
              label: "Upload Fulfillment CSV",
            }}
            app={app}
          />
        </SimpleForm>
      ) : (
        <ResultList data={results} />
      )}
    </Create>
  );
};
