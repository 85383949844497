import React from "react";
import {
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
  makeStyles
} from "@material-ui/core";
import { useNotify } from "react-admin";

const useStyles = makeStyles( () => ( {
  select: {
    marginTop: "8px",
    height: "48px",
    width: "180px",
    backgroundColor: "#f2f2f2",
    borderRadius: "8px",
    transition: "background-color 0.3s",
    "&:hover": {
      backgroundColor: "#e0e0e0",
    },
  },
} ) );

interface MultiSelectCheckboxProps {
  value: string[];
  renderSelectedValues?: {
    [key: string]: string;
  };
  options: { id: string; name: string }[];
  onChange: ( selected: string[] ) => void;
}

export const MultiSelectCheckbox: React.FC<MultiSelectCheckboxProps> = ( {
  value,
  options,
  onChange,
  renderSelectedValues,
} ) =>
{
  const notify = useNotify();
  const classes = useStyles();

  const [selected, setSelected] = React.useState<string[]>( value );

  React.useEffect( () =>
  {
    setSelected( value );
  }, [value] );

  const handleChange = ( event: React.ChangeEvent<{ value: unknown }> ) =>
  {
    const { value: selectedValues } = event.target;
    if ( Array.isArray( selectedValues ) )
    {
      if ( selectedValues.length === 0 )
      {
        notify( "At least one option must be selected", "warning" );
      }
      else
      {
        setSelected( selectedValues as string[] );
        onChange( selectedValues as string[] );
      }
    }
  };

  return (
    <FormControl fullWidth>
      <Select
        className={classes.select}
        multiple
        value={selected}
        onChange={handleChange}
        renderValue={( selected ) =>
        {
          if ( !renderSelectedValues )
          {
            return ( selected as string[] ).join( ", " );
          }
          else
          {
            return ( selected as string[] )
              .map( ( value ) => renderSelectedValues[value] )
              .join( ", " );
          }
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
      >
        {options.map( ( option ) => (
          <MenuItem key={option.id} value={option.id}>
            <Checkbox checked={selected.includes( option.id )} />
            <ListItemText primary={option.name} />
          </MenuItem>
        ) )}
      </Select>
    </FormControl>
  );
};
