import { useDataProvider, usePermissions } from "react-admin";
import * as React from "react";
import { useEffect, useState } from "react";
import { ReturnHistoryData } from "../ts/interfaces/order-interface";
import { Spacer } from "./generics/spacer";
import {
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import {
  extractAuthClaims,
  hasPermissions,
  BooleanRoles
} from "../ts/interfaces/role-interface";
import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles( () =>
  createStyles( {
    fullWidth: {
      width: "100%",
    },
    table: {},
  } )
);

interface IReturnHistoryProperties {
  id: string;
  targetResource: string;
}

export const ReturnHistoryTable: React.FunctionComponent<
  IReturnHistoryProperties
> = ( properties: IReturnHistoryProperties ) =>
{
  const { permissions } = usePermissions();
  const roleClaims = extractAuthClaims( permissions );
  const dataProvider = useDataProvider();
  const [returnHistory, setReturnHistory] = useState( [] );
  const classes = useStyles();
  useEffect( () =>
  {
    dataProvider
      .getReturnHistory( properties.targetResource, { id: properties.id } )
      .then( ( { data }: { data: ReturnHistoryData[] } ) =>
      {
        setReturnHistory( data );
      } );
  }, [] );
  return (
    <>
      <Spacer />
      <Typography variant={"h5"}>Return History</Typography>
      <Spacer />
      <TableContainer>
        <Table aria-label="simple table" className={classes.table}>
          <TableHead>
            <TableRow className={classes.table}>
              <TableCell className={classes.table} width={250} align="left">
                <Typography variant={"caption"}>Return Status</Typography>
              </TableCell>
              <TableCell className={classes.table} width={250} align="left">
                <Typography variant={"caption"}>
                  Product Inventory Id
                </Typography>
              </TableCell>
              <TableCell className={classes.table} width={250} align="left">
                <Typography variant={"caption"}>Author</Typography>
              </TableCell>
              <TableCell className={classes.table} align="left">
                <Typography variant={"caption"}>Date</Typography>
              </TableCell>
              <TableCell className={classes.table} width={250} align="left">
                <Typography variant={"caption"}>Reason</Typography>
              </TableCell>
              <TableCell className={classes.table} width={280} align="left">
                <Typography variant={"caption"}>Note</Typography>
              </TableCell>
              <TableCell className={classes.table} width={280} align="left">
                <Typography variant={"caption"}>Location</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {returnHistory.map( ( entry ) => (
              <TableRow key={entry.id}>
                <TableCell className={classes.table} align="left">
                  {entry.returnStatus}
                </TableCell>
                <TableCell>
                  {hasPermissions(
                    [
                      BooleanRoles.Admin,
                      BooleanRoles.CustomerService,
                      BooleanRoles.Fulfillment,
                    ],
                    roleClaims
                  ) ? (
                      <Link
                        href={`/#/product-inventory-items/${entry.productInventoryItemId}/show`}
                      >
                        {entry.productInventoryItemId}
                      </Link>
                    ) : (
                      entry.productInventoryItemId
                    )}
                </TableCell>
                <TableCell>{entry.author}</TableCell>
                <TableCell className={classes.table} align="left">
                  {entry.timestamp
                    ? new Date( entry.timestamp ).toLocaleString( "en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      second: "numeric",
                    } )
                    : " "}
                </TableCell>
                <TableCell className={classes.table} align="left">
                  {entry.reason}
                </TableCell>
                <TableCell className={classes.table} align="left">
                  {entry.note}
                </TableCell>
                <TableCell className={classes.table} align="left">
                  {entry.location}
                </TableCell>
              </TableRow>
            ) )}
          </TableBody>
        </Table>
      </TableContainer>
      <Spacer />
    </>
  );
};
