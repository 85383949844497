import React from "react";
import { useDataProvider, useUpdate } from "react-admin";
import {
  Box,
  Button,
  Checkbox,
  createStyles,
  FormControl,
  FormLabel,
  Grid,
  Input,
  InputAdornment,
  makeStyles,
  TextField,
  Theme
} from "@material-ui/core";
import { Spacer } from "../generics/spacer";
import { CustomModal } from "@shopthing-opn-shared/admin-dashboard";
import { ButtonStyles } from "../generics/button-styles";
import { amountValidator } from "../../utils/form-validators";
import { isEmpty } from "lodash";
import { useNotification } from "../custom-notification/notification";

interface IResolveFailedTransferButton {
  orderId: string;
}

export interface FailedTransferData {
  id: string;
  reason: string;
  type: string;
  amount?: string;
  currency?: string;
  notes?: string;
  author?: string;
  resolved?: boolean;
}

const useCustomStyles = makeStyles( ( theme: Theme ) =>
  createStyles( {
    textfield: {
      width: "100%",
      textAlign: "center",
    },
    checkboxRoot: {
      color: "#3f51b5",
      "&$checked": {
        color: "#3f51b5",
      },
    },
    checkboxChild: {
      color: "#3f51b5",
      marginLeft: "13px",
      "&$checked": {
        color: "#3f51b5",
      },
    },
    checked: {},
    headerCell: {
      fontWeight: "bold",
      padding: theme.spacing( 1 ),
    },
    row: {
      borderBottom: "1px solid #ccc",
      padding: theme.spacing( 1, 0 ),
    },
    modalActions: {
      marginTop: theme.spacing( 2 ),
      display: "flex",
      justifyContent: "flex-end",
      "& > *": {
        marginLeft: theme.spacing( 1 ),
      },
    },
  } )
);

interface CompleteTransferResponse {
  data: {
    errors: string[];
  };
}

export const ResolveFailedTransferButton: React.FC<
  IResolveFailedTransferButton
> = ( { orderId } ) =>
{
  const buttonClasses = ButtonStyles();
  const classes = useCustomStyles();
  const [open, setOpen] = React.useState( false );
  const [disabledButton, setDisabledButton] = React.useState( false );
  const [confirmOpen, setConfirmOpen] = React.useState( false );
  const [note, setNote] = React.useState( " " );
  const [failedTransfers, setFailedTransfers] = React.useState<
    FailedTransferData[]
  >( [] );
  const [selectedRows, setSelectedRows] = React.useState<number[]>( [] );
  const [formErrors, setFormErrors] = React.useState<{
    [index: number]: string;
  }>( {} );

  const { showNotification } = useNotification();

  const dataProvider = useDataProvider();

  React.useEffect( () =>
  {
    dataProvider
      .getFailedTransferHistory( "orders", { id: orderId } )
      .then( ( { data }: { data: FailedTransferData[] } ) =>
      {
        setFailedTransfers(
          data
            .filter(
              ( el ) =>
                !["transferCompleted", "retry", "addedNote"].includes(
                  el.type
                ) && !el.resolved
            )
            .map( ( item ) =>
            {
              const fp = parseInt( item.amount, 10 ) / 100;
              item.amount = new Intl.NumberFormat( "en-US", {
                useGrouping: true,
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              } ).format( fp );
              return item;
            } )
        );
        setSelectedRows( [] );
      } );
  }, [open] );

  const [resolveFailedTransfer, { loading }] = useUpdate(
    "resolve-failed-transfer",
    orderId,
    {
      note,
      ...( !isEmpty( selectedRows )
        ? {
          transfers: selectedRows.map( ( index ) =>
          {
            const { type, amount, currency, id } = failedTransfers[index];
            return {
              type,
              amount: parseFloat( amount.replace( /,/g, "" ) ) * 100,
              currency: currency.toUpperCase(),
              id,
            };
          } ),
        }
        : {} ),
    },
    {},
    {
      onSuccess: ( response: CompleteTransferResponse ) =>
      {
        setOpen( false );

        const errors = response?.data?.errors || [];
        if ( !isEmpty( errors ) )
        {
          handleClose();
          showNotification( {
            message: `There were error(s): ${errors.join( " ,  " )}`,
            type: "error",
          } );
          setSelectedRows( [] );
          setNote( "" );
          return;
        }

        setSelectedRows( [] );
        setNote( "" );
        showNotification( {
          message: "Complete Transfer",
          type: "notify",
          timeout: 2000,
          onClose: () => window.location.reload(),
        } );
      },
      onFailure: ( error: Error ) =>
      {
        setOpen( false );
        setSelectedRows( [] );
        setNote( "" );
        setDisabledButton( false );
        showNotification( {
          message: `Transfer fail message: ${error.message}`,
          type: "error",
        } );
      },
    }
  );

  const handleOpen = () => setOpen( true );
  const handleClose = () =>
  {
    setSelectedRows( [] );
    setNote( "" );
    setOpen( false );
  };

  const handleConfirmClose = () => setConfirmOpen( false );

  const inputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) =>
  {
    setNote( event.target.value );
  };

  const toggleSelectRow = ( index: number ) =>
    setSelectedRows( ( prev ) =>
      prev.includes( index ) ? prev.filter( ( i ) => i !== index ) : [...prev, index]
    );

  const allSelected =
    failedTransfers.length > 0 &&
    selectedRows.length === failedTransfers.length;

  const handleSelectAll = ( event: React.ChangeEvent<HTMLInputElement> ) =>
  {
    setSelectedRows(
      event.target.checked ? failedTransfers.map( ( _, idx ) => idx ) : []
    );
  };

  const handleAmountChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    index: number
  ) =>
  {
    const { value } = event.target;
    const error = amountValidator( value );
    setFormErrors( ( prev ) => ( { ...prev, [index]: error || "" } ) );

    setFailedTransfers( ( prev ) =>
    {
      const newTransfers = [...prev];
      newTransfers[index] = { ...newTransfers[index], amount: value };
      return newTransfers;
    } );
  };

  const handleResolve = () =>
  {
    if ( selectedRows.length === 0 )
    {
      setOpen( false );
      setConfirmOpen( true );
    }
    else
    {
      setDisabledButton( true );
      resolveFailedTransfer();
    }
  };

  const confirmResolve = () =>
  {
    setConfirmOpen( false );
    setDisabledButton( true );
    resolveFailedTransfer();
  };

  const mainBody = (
    <div>
      <Grid
        container
        spacing={1}
        style={{ maxWidth: "1000px", maxHeight: 750, paddingTop: 10 }}
      >
        <Grid
          container
          item
          xs={12}
          alignItems="center"
          className={classes.row}
        >
          <Grid item xs={1}>
            <Checkbox
              classes={{ root: classes.checkboxRoot, checked: classes.checked }}
              checked={allSelected}
              onChange={handleSelectAll}
              color="primary"
            />
          </Grid>
          <Grid item xs={5} className={classes.headerCell}>
            Reason
          </Grid>
          <Grid item xs={2} className={classes.headerCell}>
            Type
          </Grid>
          <Grid item xs={2} className={classes.headerCell}>
            Currency
          </Grid>
          <Grid item xs={2} className={classes.headerCell}>
            Amount
          </Grid>
        </Grid>

        <Box style={{ maxHeight: 400, overflowY: "auto", width: "100%" }}>
          {failedTransfers.map( ( transfer, index ) => (
            <Grid
              container
              item
              xs={12}
              alignItems="center"
              key={index}
              className={classes.row}
            >
              <Grid item xs={1}>
                <Checkbox
                  classes={{
                    root: classes.checkboxChild,
                    checked: classes.checked,
                  }}
                  color="primary"
                  checked={selectedRows.includes( index )}
                  onChange={() => toggleSelectRow( index )}
                />
              </Grid>
              <Grid item xs={5}>
                {transfer.reason}
              </Grid>
              <Grid item xs={2}>
                {transfer.type}
              </Grid>
              <Grid item xs={2}>
                {transfer.currency}
              </Grid>
              <Grid item xs={2}>
                <TextField
                  name="amount"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  onChange={( e ) => handleAmountChange( e, index )}
                  value={transfer.amount || ""}
                  error={Boolean( formErrors[index] )}
                  helperText={formErrors[index]}
                  className={classes.textfield}
                  type="text"
                />
              </Grid>
            </Grid>
          ) )}
        </Box>

        <Spacer />
        <Grid item xs={12}>
          <FormLabel>Note</FormLabel>
          <FormControl fullWidth>
            <Input
              name="note"
              placeholder="Enter note"
              multiline={true}
              rows="6"
              aria-describedby="my-helper-text"
              type="text"
              fullWidth={true}
              disableUnderline={true}
              required={true}
              onChange={inputChange}
              style={{
                borderStyle: "solid",
                borderWidth: "thin",
                borderColor: "grey",
              }}
            />
          </FormControl>
        </Grid>
        <Spacer />
        <Grid item xs={12}>
          <Button
            onClick={handleResolve}
            disabled={disabledButton || note.trim() === ""}
            variant="contained"
            color="primary"
          >
            {selectedRows.length > 0 ? "Complete Transfer" : "Resolve"}
          </Button>
        </Grid>
      </Grid>
    </div>
  );

  const confirmBody = (
    <div>
      <Spacer />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          Are you sure you want to resolve the failed transfer(s) without a
          transfer?
        </Grid>
        <Grid item xs={12} className={classes.modalActions}>
          <Button variant="contained" onClick={confirmResolve} color="primary">
            Yes Resolve
          </Button>
          <Button variant="outlined" onClick={handleConfirmClose}>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            No Don't Resolve
          </Button>
        </Grid>
      </Grid>
    </div>
  );

  return (
    <>
      <Button
        onClick={handleOpen}
        className={buttonClasses.saveButton}
        disabled={loading}
        disableRipple
        disableFocusRipple
      >
        Complete Transfer
      </Button>
      <CustomModal open={open} onClose={handleClose}>
        {mainBody}
      </CustomModal>
      <CustomModal open={confirmOpen} onClose={handleConfirmClose}>
        {confirmBody}
      </CustomModal>
    </>
  );
};
