import React from "react";
import { useUpdate, useNotify } from "react-admin";
import { Button } from "@material-ui/core";
import { resetHistory } from "../../utils/reset-history";
import { notifyMessage } from "../../utils/primitive";

interface IFulfillPickup {
  orderId: string;
  orderLatestDisputeStatus?: string;
  orderDisputeResolved?: boolean;
  [x: string]: unknown;
}

export const FulfillPickup: React.FunctionComponent<IFulfillPickup> = ( {
  orderId,
  orderLatestDisputeStatus,
  orderDisputeResolved,
}: IFulfillPickup ) =>
{
  const notify = useNotify();
  const [fulfilled, setFulfilled] = React.useState( false );
  const [update, { loading, error }] = useUpdate(
    "pickup-orders",
    orderId,
    {
      orderId,
      customerNotes: "Thank you for shopping with ShopThing",
    },
    {},
    {
      onSuccess: () =>
      {
        setFulfilled( true );
        notify( `Sucessfully fulfilled ${orderId}`, "success" );
        setTimeout( () =>
        {
          resetHistory();
          history.go();
        }, 2000 );
      },
    }
  );
  if ( error && !loading )
  {
    notifyMessage( error, notify );
  }

  return (
    <Button
      onClick={update}
      disabled={
        fulfilled ||
        loading ||
        ( orderLatestDisputeStatus &&
          ( typeof orderDisputeResolved !== "boolean" ||
            orderDisputeResolved === false ) )
      }
    >
      Fulfill Pickup
    </Button>
  );
};
