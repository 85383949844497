import React, { useEffect, useState } from "react";
import { getApp } from "firebase/app";
import { getFile } from "@shopthing-opn-shared/admin-dashboard";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

export interface MediaItem {
  mediaPath: string;
  rawSmallPosterPath: string;
  trailerPath: string;
}
interface MediaContainerProps {
  mediaItem: MediaItem;
  keyName: keyof MediaItem;
  type: string;
  maxWidth?: string;
}

const useStyles = makeStyles( {
  container: {
    height: 240,
    marginLeft: "20px",
  },
  image: {
    height: "100%",
    cursor: "pointer",
    maxWidth: ( props: { maxWidth?: string } ) =>
      props.maxWidth ? `${props.maxWidth}` : "unset",
  },
  video: {
    height: "100%",
    maxWidth: ( props: { maxWidth?: string } ) =>
      props.maxWidth ? `${props.maxWidth}` : "unset",
  },
} );

export const MediaContainer: React.FC<MediaContainerProps> = ( {
  mediaItem,
  keyName,
  type,
  maxWidth,
} ) =>
{
  const [fileUrl, setFileUrl] = useState<string>( "" );
  const app = getApp();
  const classes = useStyles( { maxWidth } );

  useEffect( () =>
  {
    if ( mediaItem && keyName )
    {
      const filePath = mediaItem[keyName];

      if ( type === "image" )
      {
        loadFile( filePath );
        return;
      }

      // this is for future maybe wil be needed
      if (
        filePath &&
        type === "video" &&
        ( filePath.endsWith( ".mp4" ) || filePath.endsWith( ".mov" ) )
      )
      {
        loadFile( filePath );
      }
    }
  }, [mediaItem, keyName, type] );

  const loadFile = async ( filePath: string ) =>
  {
    const url = await getFile( filePath, app );
    setFileUrl( url );
  };

  return (
    <Box className={classes.container}>
      {type === "video" && fileUrl && (
        <video src={fileUrl} controls className={classes.video} />
      )}
      {type === "image" && fileUrl && (
        <img src={fileUrl} alt="File" className={classes.image} />
      )}
    </Box>
  );
};

export const MediaBox = ( props: { children: React.ReactChild } ) => (
  <Box
    border={2}
    bgcolor={"#f7f9fa"}
    padding={2}
    margin={2}
    borderRadius="5px"
    borderColor="#C5D1D9"
    height="90%"
  >
    {props.children}
  </Box>
);
