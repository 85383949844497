import * as React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { getFile } from "@shopthing-opn-shared/admin-dashboard";

import {
  Edit,
  FormTab,
  TabbedForm,
  TextInput,
  useRefresh,
  useNotify,
  ReferenceManyField,
  Datagrid,
  TextField,
  useUpdate,
  Button,
  List,
  FormDataConsumer,
  BooleanInput,
  FileInput,
  FunctionField
} from "react-admin";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { useForm } from "react-final-form";
import { Record } from "ra-core";
import { SaveOnlyToolbar } from "../toolbars/save-only";
import { CollaborationEvent, Event } from "../../ts/interfaces/event-interface";
import { BasicPagination } from "../generics/pagination";
import { getApp } from "firebase/app";
import { DateField, DateTimeInput } from "ra-ui-materialui";
import { v4 } from "uuid";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { IconButton, Link } from "@material-ui/core";
import { notifyMessage } from "../../utils/primitive";

interface IEditProperties {
  id: string;
  [x: string]: unknown;
  record: CollaborationEvent;
}

const useStyles = makeStyles( ( theme: Theme ) =>
  createStyles( {
    formField: {
      margin: theme.spacing( 1, 2, 1, 0 ),
    },
    media: {
      maxWidth: "100%",
      maxHeight: "500px",
    },
    content: {
      overflowX: "auto",
    },
    table: {
      minWidth: 1500,
    },
  } )
);

interface IRemoveButton {
  collaborationEventId: string;
  record: Record;
}
interface IAddButton {
  collaborationEventId: string;
  collaborationEventEventIds: string[];
  record: Record;
}

const RemoveFromCollaborationButton: React.FunctionComponent<IRemoveButton> = (
  properties
) =>
{
  const notify = useNotify();
  const [update] = useUpdate(
    "collaboration-events",
    properties.collaborationEventId,
    {
      deletedEventIds: [properties.record?.id],
    },
    {},
    {
      onSuccess: () =>
      {
        window.location.reload();
      },
      onFailure: ( error: Error ) =>
      {
        notifyMessage( error, notify );
      },
    }
  );

  return <Button onClick={update} label="Remove From Collaboration" />;
};

const AddToCollaborationButton: React.FunctionComponent<IAddButton> = (
  properties
) =>
{
  const notify = useNotify();
  const [update] = useUpdate(
    "collaboration-events",
    properties.collaborationEventId,
    {
      addedEventIds: [properties.record?.id],
    },
    {},
    {
      onSuccess: () =>
      {
        window.location.reload();
      },
      onFailure: ( error: Error ) =>
      {
        notifyMessage( error, notify );
      },
    }
  );

  if (
    properties.collaborationEventEventIds?.includes(
      String( properties.record?.id )
    )
  )
  {
    return <Button disabled label="Already in Collaboration" />;
  }

  if ( properties.record?.isUsedInCollaboration )
  {
    return <Button disabled label="Already used in other Collaboration" />;
  }

  return <Button onClick={update} label="Add to Collaboration" />;
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const CollaborationForm: React.FC = ( {
  record,
}: {
  record: CollaborationEvent;
} ) =>
{
  const classes = useStyles();
  const app = getApp();

  const [poster, setPoster] = React.useState( "" );
  const [trailer, setTrailer] = React.useState( "" );
  React.useEffect( () =>
  {
    if ( record?.trailerPosterPath )
    {
      loadFile( record.trailerPosterPath, setPoster );
    }
    if ( record?.trailerPath )
    {
      loadFile( record.trailerPath, setTrailer );
    }
  } );

  async function loadFile( filePath: string, setter: ( value: string ) => void )
  {
    const posterUrl = await getFile( filePath, app );
    setter( posterUrl );
  }

  return (
    <React.Fragment>
      <TextInput disabled source="id" className={classes.formField} />
      <TextInput source="name" className={classes.formField} />
      <FormDataConsumer>
        {( { formData }: { formData: Event } ) => (
          <div style={{ display: "flex" }}>
            <BooleanInput
              label="Pin Event"
              source="isPinned"
              defaultValue={false}
              disabled={new Date( formData.endDate ) <= new Date()}
            />
          </div>
        )}
      </FormDataConsumer>

      <div style={{ display: "flex" }}>
        <BooleanInput
          label="Closet Sale Event"
          source="isClosetSale"
          disabled
        />
      </div>

      <DateTimeInput
        label="Start Date"
        source="startDate"
        className={classes.formField}
      />
      <DateTimeInput
        label="End Date"
        source="endDate"
        className={classes.formField}
      />
      <div>
        <h2>Collaboration Event Trailer</h2>
        {trailer ? (
          <video src={trailer} className={classes.media} controls />
        ) : null}
      </div>
      <div>
        <h2>Collaboration Event Preview Poster</h2>
        {poster ? <img src={poster} className={classes.media} /> : null}
      </div>
    </React.Fragment>
  );
};

const dataWithCollaboration: { collaboration?: CollaborationEvent } = {};

const transform = ( data: Record ) =>
{
  const { collaboration } = dataWithCollaboration;

  const update = {
    name: data.name,
    isPinned: data.isPinned,
    trailerPath: data.trailerPath,
    trailerPosterPath: data.trailerPosterPath,
    startDate: data.startDate,
    endDate: data.endDate,
  };

  if ( update.name === collaboration?.name )
  {
    delete update.name;
  }

  // eslint-disable-next-line eqeqeq
  if ( update.isPinned == collaboration?.isPinned )
  {
    delete update.isPinned;
  }

  if ( update.trailerPath === collaboration?.trailerPath )
  {
    delete update.trailerPath;
  }

  if ( update.trailerPosterPath === collaboration?.trailerPosterPath )
  {
    delete update.trailerPosterPath;
  }

  if ( update.startDate === collaboration?.startDate )
  {
    delete update.startDate;
  }

  if ( update.endDate === collaboration?.endDate )
  {
    delete update.endDate;
  }

  return update;
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const AddEventsToCollaboration: React.FC = ( properties: {
  record: CollaborationEvent;
} ) =>
{
  const collaborationEventId = properties.record.id;
  const collaborationEventEventIds = properties.record.eventIds;
  const classes = useStyles();

  return (
    <div className={classes.content}>
      <List
        {...properties}
        title=" "
        resource="recommendation-override"
        target="id"
        filter={{
          startDate: properties.record?.startDate,
          endDate: properties.record?.endDate,
          isClosetSale: properties.record?.isClosetSale,
        }}
        fullWidth
        pagination={<BasicPagination />}
        exporter={false}
        bulkActionButtons={false}
      >
        <Datagrid classes={{ table: classes.table }}>
          <FunctionField
            label="Event Id"
            render={( record: { id: string } ) => (
              <Link href={`/#/events/${record.id}/show`}>{record.id}</Link>
            )}
            sortable={false}
          />
          <TextField label="Name" source="name" sortable={false} />
          <DateField
            label="Start Date"
            source="startDate"
            sortable={false}
            showTime
          />
          <DateField
            label="End Date"
            source="endDate"
            sortable={false}
            showTime
          />
          <TextField label="Partnership" source="partnership" />
          <TextField label="Shopper ID" source="userId" />
          <TextField label="Shopper Handle" source="sellerHandle" />
          <AddToCollaborationButton
            collaborationEventId={collaborationEventId}
            collaborationEventEventIds={collaborationEventEventIds}
            record={properties.record}
          />
        </Datagrid>
      </List>
    </div>
  );
};

export const CollaborationEventEdit: React.FunctionComponent<
  IEditProperties
> = ( properties: IEditProperties ) =>
{
  const [trailerPath, setTrailerPath] = React.useState<string | null>( null );

  const refresh = useRefresh();
  const notify = useNotify();
  const onSuccess = () =>
  {
    notify( "Collaboration Event Updated" );
    refresh();
    setTrailerPath( null );
  };

  const onFailure = ( error: Error ) =>
  {
    notify( `Failed updating collaboration event  ${error.message}`, "warning" );
  };

  return (
    <Edit
      undoable={false}
      transform={transform}
      onSuccess={onSuccess}
      onFailure={onFailure}
      {...properties}
    >
      <TabbedForm toolbar={<SaveOnlyToolbar />}>
        <FormTab label="Collaboration Event Details">
          <CollaborationForm />
        </FormTab>
        <FormTab label="Media">
          <FormDataConsumer>
            {( data: { record: CollaborationEvent } ) =>
            {
              dataWithCollaboration.collaboration = data.record;

              const form = useForm();

              const parse = React.useCallback( ( file: File ) =>
              {
                if ( file )
                {
                  try
                  {
                    let fileName = file.name;
                    if ( file.type.startsWith( "video" ) )
                    {
                      fileName = "video.mp4";
                    }
                    else if ( file.type.startsWith( "image" ) )
                    {
                      fileName = "poster-raw.jpg";
                    }

                    const basePath = `collaboration-events/${v4()}`;
                    const uploadPath = `${basePath}/${fileName}`;

                    const storeFile = async ( path: string ) =>
                    {
                      const defaultApp = getApp();
                      const storage = getStorage( defaultApp );
                      const storageReference = ref( storage, path );

                      // Upload asynchronously so it doesn't fail the parse
                      uploadBytes( storageReference, file, {
                        customMetadata: {
                          isCollaborationItem: "true",
                          addWatermark: "true",
                        },
                      } )
                        .then( () =>
                        {
                          console.log( "Media Uploaded" );

                          form.change( "trailerPath", uploadPath );
                          form.change(
                            "trailerPosterPath",
                            `${basePath}/poster-raw.jpg`
                          );
                          setTrailerPath( file.name );
                        } )
                        .catch( ( error ) =>
                        {
                          notify(
                            `Failed to upload Media! - ${error.message}`,
                            "warning"
                          );
                        } );
                    };

                    // Obtain the blob for display in browser
                    const objectUrl = URL.createObjectURL( file );

                    // So this will just asynchronously trigger
                    if ( objectUrl )
                    {
                      storeFile( uploadPath );
                    }
                  }
                  catch ( error )
                  {
                    console.log( "uploadFile error:", error );
                  }
                }
              }, [] );

              return (
                <>
                  <FileInput
                    label="Collaboration Event Trailer"
                    parse={( image: File ) => parse( image )}
                    accept="video/*"
                  />
                  {trailerPath && (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <IconButton
                        onClick={() =>
                        {
                          setTrailerPath( null );
                          form.change( "trailerPath", "" );
                          form.change( "trailerPosterPath", "" );
                        }}
                      >
                        <RemoveCircle style={{ color: "#f44336" }} />
                      </IconButton>
                      {trailerPath}
                    </div>
                  )}
                </>
              );
            }}
          </FormDataConsumer>
        </FormTab>
        <FormTab label="Events in Collaboration">
          <ReferenceManyField
            reference="collaboration-event-events"
            target="id"
            filter={{}}
            fullWidth
            pagination={<BasicPagination />}
          >
            <Datagrid>
              <FunctionField
                label="Event Id"
                render={( record: { id: string } ) => (
                  <Link href={`/#/events/${record.id}/show`}>{record.id}</Link>
                )}
                sortable={false}
              />
              <TextField label="Name" source="name" sortable={false} />
              <DateField
                label="Start Date"
                source="startDate"
                sortable={false}
                showTime
              />
              <DateField
                label="End Date"
                source="endDate"
                sortable={false}
                showTime
              />
              <TextField label="Partnership" source="partnership" />
              <TextField label="Shopper ID" source="userId" />
              <TextField label="Shopper Handle" source="handle" />
              <RemoveFromCollaborationButton
                collaborationEventId={properties.id}
                record={properties.record}
              />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
        <FormTab label="Add Events to Collaboration">
          <AddEventsToCollaboration />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
