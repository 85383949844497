import { Box } from "@material-ui/core";
import * as React from "react";

interface DataSquareData {
  label?: string;
  value: number;
  background?: string;
}

function DataSquare( props: DataSquareData )
{
  const bgColor = props.background === "dark" ? "#D9D9D9" : "#EEEEEE";
  return (
    <Box
      style={{
        background: bgColor,
        width: "128px",
        height: "75px",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <p style={{ fontWeight: 900, fontSize: "16px", margin: 0 }}>
        {props.label}
      </p>
      <p style={{ fontWeight: 900, fontSize: "30px", margin: 0 }}>
        {props.value}
      </p>
    </Box>
  );
}

export function DataSquares( props: {
  first: DataSquareData;
  second?: DataSquareData;
  title?: string;
} ): JSX.Element
{
  return (
    <Box style={{ margin: "10px 5px" }}>
      <Box style={{ fontWeight: 900, fontSize: "16px" }}>
        {props.title && (
          <p style={{ fontWeight: 900, fontSize: "24x", margin: 0 }}>
            {props.title}
          </p>
        )}
      </Box>
      <Box display="flex" style={{ margin: 0 }}>
        <DataSquare
          label={props.first.label}
          value={props.first.value}
          background="dark"
        />
        <DataSquare label={props.second.label} value={props.second.value} />
      </Box>
    </Box>
  );
}
