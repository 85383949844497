import * as React from "react";
import {
  Grid,
  FormLabel,
  Input,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Button
} from "@material-ui/core";
import {
  useUpdate,
  useNotify,
  Toolbar,
  FormDataConsumer,
  FileInput
} from "react-admin";
import { Spacer } from "../generics/spacer";
import { noteData } from "../../ts/interfaces/order-interface";
import { notifyMessage } from "../../utils/primitive";
import {
  AddItemButton,
  ArrayInput,
  RemoveItemButton,
  SimpleForm,
  SimpleFormIterator
} from "ra-ui-materialui";
import { required } from "ra-core";
import { useForm } from "react-final-form";
import { v4 } from "uuid";
import { getApp } from "firebase/app";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { MultiTypeFileField } from "../generics/multi-type-file-field";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  MediaBox,
  MediaContainer,
  MediaItem
} from "../generics/media-container";

export interface IStorageMedia {
  mediaPath: string;
  mediaPosterPath?: string;
  rawPosterPath: string;
  type: string;
}

interface MediaStorage {
  storage: IStorageMedia;
}

const useStyles = makeStyles( ( theme: Theme ) =>
  createStyles( {
    formField: {
      margin: theme.spacing( 1, 2, 1, 0 ),
    },
    clearRemoveButton: {
      "& .previews": {
        "& button": {
          display: "none",
        },
      },
    },
    customSimpleForm: {
      width: "100%",
      margin: "0 auto",
      marginBottom: theme.spacing( 2 ),
    },
    noteInput: {
      width: "100%",
    },
    simpleFormIterator: {
      width: "62rem",
    },
  } )
);

const NoteBox = ( props: { id: string } ) =>
{
  const notify = useNotify();
  const [note, setNote] = React.useState( " " );
  const classes = useStyles();
  const [medias, setMedias] = React.useState<
    Omit<IStorageMedia, "rawPosterPath">[]
  >( [] );

  const [mediaUploaded, setMediaUploaded] = React.useState<
    Record<string, boolean>
  >( {} );
  const [disableSaveButton, setDisableSaveButton] =
    React.useState<boolean>( true );

  const [addNotes] = useUpdate(
    "add-note-to-order",
    props.id,
    {
      task: "add",
      note,
      orderId: props.id,
      medias,
    },
    {},
    {
      onSuccess: () =>
      {
        notify( "Note Saved" );
        window.location.reload();
      },
      onFailure: ( error: Error ) =>
      {
        notifyMessage( error, notify );
        setDisableSaveButton( false );
      },
    }
  );

  const inputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) =>
  {
    setNote( event.target.value );
  };

  React.useEffect( () =>
  {
    const hasNote = note.trim().length > 0;
    const hasFilesUploaded = Object.values( mediaUploaded ).some(
      ( value: boolean ) => value === true
    );
    setDisableSaveButton( !( hasNote || hasFilesUploaded ) );
  }, [note, mediaUploaded] );

  const handleSaveButtonClick = () =>
  {
    setDisableSaveButton( true );
    addNotes();
  };

  return (
    <Grid container>
      <FormLabel>Note</FormLabel>
      <SimpleForm
        className={classes.customSimpleForm}
        toolbar={
          <Toolbar>
            <Button
              onClick={handleSaveButtonClick}
              disabled={disableSaveButton}
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          </Toolbar>
        }
      >
        <Grid xs={12} className={classes.noteInput}>
          <Input
            name="note"
            placeholder={note}
            multiline={true}
            rows="5"
            aria-describedby="my-helper-text"
            type="text"
            fullWidth={true}
            disableUnderline={true}
            required={true}
            onChange={inputChange}
            style={{
              borderStyle: "solid",
              borderWidth: "thin",
              borderColor: "grey",
            }}
          />
        </Grid>
        <Grid xs={12}>
          <FormDataConsumer className={classes.noteInput}>
            {( { formData }: { formData: { media: MediaStorage[] } } ) => (
              <ArrayInput source="media">
                <SimpleFormIterator
                  className={classes.simpleFormIterator}
                  addButton={
                    formData?.media?.length >= 5 ? (
                      <></>
                    ) : (
                      <AddItemButton style={{ marginTop: 25 }} />
                    )
                  }
                  removeButton={
                    formData?.media?.length > 1 ? <RemoveItemButton /> : <></>
                  }
                >
                  <FormDataConsumer>
                    {( {
                      getSource,
                      id,
                    }: {
                      getSource: ( source: string ) => string;
                      id: string;
                    } ) =>
                    {
                      const form = useForm();
                      const parse = React.useCallback( ( file: File ) =>
                      {
                        if ( file )
                        {
                          try
                          {
                            let fileName = file.name;
                            if ( file.type.startsWith( "video" ) )
                            {
                              fileName = "video.mp4";
                            }
                            else if ( file.type.startsWith( "image" ) )
                            {
                              fileName = "poster-raw.jpg";
                            }

                            const generateId = v4();
                            const basePath = `notes/${generateId}`;
                            const uploadPath = `${basePath}/${fileName}`;

                            const storeFile = async ( path: string ) =>
                            {
                              const defaultApp = getApp();
                              const storage = getStorage( defaultApp );
                              const storageReference = ref( storage, path );

                              // Upload asynchronously so it doesn't fail the parse
                              setMediaUploaded( ( before ) => ( {
                                ...before,
                                [id]: false,
                              } ) );
                              uploadBytes( storageReference, file, {
                                customMetadata: {
                                  isNoteItem: "true",
                                },
                              } )
                                .then( () =>
                                {
                                  setMediaUploaded( ( before ) => ( {
                                    ...before,
                                    [id]: true,
                                  } ) );
                                } )
                                .catch( ( error ) =>
                                {
                                  setMediaUploaded( ( before ) => ( {
                                    ...before,
                                    [id]: false,
                                  } ) );
                                  notify(
                                    `Failed to upload Media! - ${error.message}`,
                                    "warning"
                                  );
                                } );
                            };

                            const objectUrl = URL.createObjectURL( file );

                            // So this will just asynchronously trigger
                            if ( objectUrl )
                            {
                              storeFile( uploadPath );
                            }

                            return {
                              rawMediaPath: uploadPath,
                              mediaPath: uploadPath,
                              rawPosterPath: `${basePath}/poster-raw.jpg`,
                              type: file.type.split( "/" )[0],
                              preview: objectUrl,
                              previewFile: file,
                            };
                          }
                          catch ( error )
                          {
                            console.log( "uploadFile error:", error );
                          }
                        }
                      }, [] );

                      React.useEffect( () =>
                      {
                        if ( formData?.media && formData?.media.length )
                        {
                          const currentMedias = [];
                          for ( const mediaItem of formData.media )
                          {
                            if ( !mediaItem )
                            {
                              continue;
                            }
                            currentMedias.push( {
                              mediaPath: mediaItem.storage.mediaPath,
                              mediaPosterPath: mediaItem.storage.rawPosterPath,
                              type: mediaItem.storage.type,
                            } );
                          }
                          setDisableSaveButton(
                            currentMedias.length === 0 &&
                              formData.media.length === 1 &&
                              note.trim().length > 0
                              ? false
                              : formData.media.length > currentMedias.length
                          );
                          setMedias( currentMedias );
                        }
                      }, [formData?.media] );

                      return (
                        <>
                          <FileInput
                            label=""
                            validate={required()}
                            source={getSource( `storage` )}
                            parse={( image: File ) => parse( image )}
                            className={classes.clearRemoveButton}
                          >
                            <MultiTypeFileField
                              source="preview"
                              typeSource="type"
                              maxWidth={"80%"}
                              formMedia={form.getState()?.values?.media}
                            />
                          </FileInput>
                        </>
                      );
                    }}
                  </FormDataConsumer>
                </SimpleFormIterator>
              </ArrayInput>
            )}
          </FormDataConsumer>
        </Grid>
      </SimpleForm>
    </Grid>
  );
};

const NotesTable = ( data: { notesData: noteData[] } ) => (
  <TableContainer>
    <Table aria-label="note-table">
      <TableHead>
        <TableRow>
          <TableCell align="left">
            <Typography align="left" noWrap={true}>
              Created On
            </Typography>
          </TableCell>
          <TableCell align="left">
            <Typography align="left">Author</Typography>
          </TableCell>
          <TableCell align="left">
            <Typography>Note</Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      {data.notesData.length > 0 ? (
        <TableBody>
          {data.notesData.map( ( noteData ) => (
            <>
              <TableRow key={noteData.id}>
                <TableCell width={250} align="left">
                  <Typography align="left" noWrap={true}>
                    {noteData.timestamps.created
                      ? new Date( noteData.timestamps.created ).toLocaleString(
                        "en-US",
                        {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        }
                      )
                      : ""}
                    <br />
                    {noteData.timestamps.created
                      ? new Date( noteData.timestamps.created ).toLocaleString(
                        "en-US",
                        {
                          hour: "numeric",
                          minute: "numeric",
                          second: "numeric",
                        }
                      )
                      : ""}
                  </Typography>
                </TableCell>
                <TableCell width={250} align="left">
                  <Typography align="left" noWrap={true}>
                    {noteData.author}
                  </Typography>
                </TableCell>
                <TableCell align="left">{noteData.note}</TableCell>
              </TableRow>
              {noteData.medias && noteData.medias.length > 0 && (
                <TableRow key={`${noteData.id}-media`}>
                  <TableCell colSpan={3}>
                    <MediaBox>
                      <Grid container spacing={2}>
                        {noteData.medias.map( ( media ) => (
                          <Grid item xs={2} key={media.mediaPath}>
                            <MediaContainer
                              keyName="mediaPath"
                              maxWidth={`${Math.round(
                                65 / noteData.medias.length
                              )}rem`}
                              mediaItem={media as unknown as MediaItem}
                              type={media.type}
                            />
                          </Grid>
                        ) )}
                      </Grid>
                    </MediaBox>
                  </TableCell>
                </TableRow>
              )}
            </>
          ) )}
        </TableBody>
      ) : (
        <caption>There is no notes related to this order</caption>
      )}
    </Table>
  </TableContainer>
);

export const Notes = ( data: {
  notesData: noteData[];
  id: string;
} ): JSX.Element => (
  <React.Fragment>
    <NoteBox id={data.id} />
    <Spacer />
    <NotesTable notesData={data.notesData} />
  </React.Fragment>
);
