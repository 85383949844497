import * as React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useShowController } from "react-admin";
import { PotentialBundle } from "src/ts/interfaces/potential-bundle-interface";
import { BundleDetailTable } from "./bundle-detail-table";

export interface FieldProperties {
  record?: PotentialBundle;
  [x: string]: unknown;
}
export const BundleListShow = ( properties: FieldProperties ): JSX.Element =>
{
  const { record } = useShowController( properties );
  const classes = useStyles();

  if ( !record )
  {
    return null;
  }
  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography variant={"h6"} key={1}>
          Order Details:
        </Typography>
        <BundleDetailTable orderDetails={record.orderDetails} />
      </CardContent>
    </Card>
  );
};

const useStyles = makeStyles( {
  root: { width: "100%", margin: "auto" },
  spacer: { height: 20 },
  invoices: { margin: "10px 0" },
} );
