import { useDataProvider } from "react-admin";
import * as React from "react";
import { useEffect, useState } from "react";
import { ReturnHistoryData } from "../../ts/interfaces/order-interface";
import { Spacer } from "../generics/spacer";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles( () =>
  createStyles( {
    fullWidth: {
      width: "100%",
    },
    table: {},
  } )
);

interface DeliveryHistoryProperties {
  id: string;
  targetResource: string;
}

export const DeliveryHistoryTable: React.FunctionComponent<
  DeliveryHistoryProperties
> = ( properties: DeliveryHistoryProperties ) =>
{
  const dataProvider = useDataProvider();
  const [deliveryHistory, setDeliveryHistory] = useState( [] );
  const classes = useStyles();

  useEffect( () =>
  {
    dataProvider
      .getDeliveryHistory( properties.targetResource, { id: properties.id } )
      .then( ( { data }: { data: ReturnHistoryData[] } ) =>
      {
        setDeliveryHistory( data );
      } );
  }, [] );

  return (
    <>
      <Spacer />
      <Typography variant={"h5"}>Delivery History</Typography>
      <Spacer />
      <TableContainer>
        <Table aria-label="simple table" className={classes.table}>
          <TableHead>
            <TableRow className={classes.table}>
              <TableCell className={classes.table} width={250} align="left">
                <Typography variant={"caption"}>Update date</Typography>
              </TableCell>
              <TableCell className={classes.table} width={250} align="left">
                <Typography variant={"caption"}>Status</Typography>
              </TableCell>
              <TableCell className={classes.table} width={250} align="left">
                <Typography variant={"caption"}>Carrier</Typography>
              </TableCell>
              <TableCell className={classes.table} align="left">
                <Typography variant={"caption"}>
                  tracking number / code
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {deliveryHistory.map( ( entry ) => (
              <TableRow key={entry.id}>
                <TableCell className={classes.table} align="left">
                  {entry.updateTimestamp
                    ? new Date( entry.updateTimestamp ).toLocaleString( "en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      second: "numeric",
                    } )
                    : " "}
                </TableCell>
                <TableCell className={classes.table} align="left">
                  {entry.status}
                </TableCell>
                <TableCell className={classes.table} align="left">
                  {entry.carrier}
                </TableCell>
                <TableCell className={classes.table} align="left">
                  {entry.trackingCode}
                </TableCell>
              </TableRow>
            ) )}
          </TableBody>
        </Table>
      </TableContainer>
      <Spacer />
    </>
  );
};
