import * as React from "react";
import { Box } from "@material-ui/core";
import { DataSquares } from "./data-squares";
import { OrderItemData } from "./open-orders";
interface ShippingHistoryData {
  shippedToday: OrderItemData;
  shippedYesterday: OrderItemData;
  shippedLastWeek: OrderItemData;
  shippedLastMonth: OrderItemData;
  shippedLastQuarter: OrderItemData;
  shippedYtd: OrderItemData;
  shippedWtd: OrderItemData;
}

export function ShippingHistory( props: {
  shippingHistory: ShippingHistoryData;
} ): JSX.Element
{
  return (
    <Box
      style={{
        border: "1px solid",
        borderRadius: "10px",
        margin: "10px",
        padding: "15px 20px",
        borderColor: "#BBBBBB",
      }}
    >
      <h5
        style={{
          fontWeight: 900,
          fontSize: "24x",
          margin: 0,
          paddingLeft: "20px",
        }}
      >
        SHIPPING HISTORY
      </h5>
      <Box
        display="flex"
        flexDirection="row"
        style={{
          width: "594px",
          flexWrap: "wrap",
          justifyContent: "space-around",
        }}
      >
        <DataSquares
          first={{
            label: "ORDERS",
            value: props.shippingHistory.shippedToday.totalOrderCount,
          }}
          second={{
            label: "ITEMS",
            value: props.shippingHistory.shippedToday.totalItemCount,
          }}
          title="SHIPPED TODAY"
        />
        <DataSquares
          first={{
            label: "ORDERS",
            value: props.shippingHistory.shippedYesterday.totalOrderCount,
          }}
          second={{
            label: "ITEMS",
            value: props.shippingHistory.shippedYesterday.totalItemCount,
          }}
          title="SHIPPED YESTERDAY"
        />
        <DataSquares
          first={{
            label: "ORDERS",
            value: props.shippingHistory.shippedWtd.totalOrderCount,
          }}
          second={{
            label: "ITEMS",
            value: props.shippingHistory.shippedWtd.totalItemCount,
          }}
          title="SHIPPED WTD"
        />
        <DataSquares
          first={{
            label: "ORDERS",
            value: props.shippingHistory.shippedLastWeek.totalOrderCount,
          }}
          second={{
            label: "ITEMS",
            value: props.shippingHistory.shippedLastWeek.totalItemCount,
          }}
          title="SHIPPED LAST WEEK"
        />
        <DataSquares
          first={{
            label: "ORDERS",
            value: props.shippingHistory.shippedLastMonth.totalOrderCount,
          }}
          second={{
            label: "ITEMS",
            value: props.shippingHistory.shippedLastMonth.totalItemCount,
          }}
          title="SHIPPED LAST MONTH"
        />
        <DataSquares
          first={{
            label: "ORDERS",
            value: props.shippingHistory.shippedLastQuarter.totalOrderCount,
          }}
          second={{
            label: "ITEMS",
            value: props.shippingHistory.shippedLastQuarter.totalItemCount,
          }}
          title="SHIPPED LAST QUARTER"
        />
        <DataSquares
          first={{
            label: "ORDERS",
            value: props.shippingHistory.shippedYtd.totalOrderCount,
          }}
          second={{
            label: "ITEMS",
            value: props.shippingHistory.shippedYtd.totalItemCount,
          }}
          title="SHIPPED YTD"
        />
      </Box>
    </Box>
  );
}
