import * as React from "react";
import { DateField } from "ra-ui-materialui";
import { List, Datagrid, TextField } from "react-admin";

import { Event } from "../../ts/interfaces/event-interface";
import { BasicPagination, perPage } from "../generics/pagination";

export const RecommendationOverrideList = (
  properties: Event[]
): JSX.Element => (
  <List
    {...properties}
    pagination={<BasicPagination />}
    bulkActionButtons={false}
    exporter={false}
    sort={{ field: "startDate", order: "DESC" }}
    perPage={perPage}
  >
    <Datagrid
      rowClick={( id: string ) => `/recommendation-override-products/${id}`}
    >
      <TextField label="Event ID" source="id" sortable={false} />
      <TextField label="Name" source="name" sortable={false} />
      <DateField
        label="Start Date"
        source="startDate"
        showTime
        sortable={false}
      />
      <DateField label="End Date" source="endDate" showTime sortable={false} />
      <TextField label="Shopper ID" source="userId" sortable={false} />
      <TextField
        label="Shopper Handle"
        source="sellerHandle"
        sortable={false}
      />
      <TextField
        label="Products Overriden"
        source="productOverridenCount"
        sortable={false}
      />
    </Datagrid>
  </List>
);
