import _ from "lodash";
import React, { useState } from "react";
import { Grid } from "@material-ui/core";

import { useCreate, useNotify } from "react-admin";
import {
  useStripe,
  useElements,
  AddressElement,
  CardExpiryElement,
  CardCvcElement,
  CardNumberElement
} from "@stripe/react-stripe-js";

import {
  StripeCardExpiryElementChangeEvent,
  StripeAddressElementChangeEvent,
  StripeCardCvcElementChangeEvent,
  StripeCardNumberElementChangeEvent
} from "@stripe/stripe-js";
import { notifyMessage } from "../../utils/primitive";

export const CreditCardForm = () =>
{
  const elements = useElements();
  const stripe = useStripe();
  const notify = useNotify();
  const [create] = useCreate();

  const [getError, setError] = useState(
    "Please fill out all the required fields to proceed"
  );
  const [hasError, setHasError] = useState( false );
  const [isSubmitting, setSubmit] = useState( false );
  const [getAddress, setAddress] = useState( null );
  const [cardNumberComplete, setCardNumberStatus] = useState( false );
  const [cardExpiryElementComplete, setCardExpiryElementStatus] =
    useState( false );
  const [cardCvcElementComplete, setCardCvcElementStatus] = useState( false );
  const [addressElementComplete, setAddressElementCompleteStatus] =
    useState( false );

  const handleCardNumberElement = (
    event: StripeCardNumberElementChangeEvent
  ) =>
  {
    if ( event.complete )
    {
      setHasError( false );
      setCardNumberStatus( true );
    }
    else
    {
      setCardNumberStatus( false );
    }
  };

  const handleCardExpiryElement = (
    event: StripeCardExpiryElementChangeEvent
  ) =>
  {
    if ( event.complete )
    {
      setHasError( false );
      setCardExpiryElementStatus( true );
    }
    else
    {
      setCardExpiryElementStatus( false );
    }
  };

  const handleCardCvcElement = ( event: StripeCardCvcElementChangeEvent ) =>
  {
    if ( event.complete )
    {
      setHasError( false );
      setCardCvcElementStatus( true );
    }
    else
    {
      setCardCvcElementStatus( false );
    }
  };

  const handleAddressElement = ( event: StripeAddressElementChangeEvent ) =>
  {
    if ( event.complete )
    {
      setHasError( false );
      setAddress( event.value.address );
      setAddressElementCompleteStatus( true );
    }
    else
    {
      setAddressElementCompleteStatus( false );
    }
  };

  const handleSubmit = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) =>
  {
    event.preventDefault();
    if (
      !cardNumberComplete ||
      !cardExpiryElementComplete ||
      !cardCvcElementComplete ||
      !addressElementComplete ||
      hasError ||
      isSubmitting
    )
    {
      return;
    }

    setSubmit( true );

    if ( !stripe || !elements )
    {
      setHasError( true );
      setError( "Please try again" );
      setSubmit( false );
      return;
    }

    const stripeAddress = getAddress;
    if ( _.isNull( stripeAddress ) )
    {
      setHasError( true );
      setError( "Address is not set correctly!" );
      setSubmit( false );
      return;
    }
    const address = generateAdditionalData( stripeAddress );

    const cardElement = elements.getElement( CardNumberElement );
    const tokenObj = await stripe.createToken( cardElement, address );
    if ( tokenObj.error )
    {
      setHasError( true );
      setError( "Address is not set correctly!" );
      setSubmit( false );
      return;
    }

    // Call /api/sellers/add-credit-card
    await create(
      "cards",
      {
        id: "",
        creation: {
          stripeToken: tokenObj.token.id,
        },
      },
      {
        onSuccess: async () =>
        {
          setSubmit( false );
          notify( "Card successfully added" );
          window.location.reload();
        },
        onFailure: ( error: Error ) =>
        {
          setSubmit( false );
          notifyMessage( error, notify );
        },
      }
    );
  };

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <div
            style={{
              backgroundColor: "white",
              padding: 12,
              borderRadius: 5,
              marginBottom: 25,
              marginTop: 25,
            }}
          >
            <CardNumberElement onChange={handleCardNumberElement} />
          </div>
        </Grid>
        <Grid item xs={3}>
          <div
            style={{
              backgroundColor: "white",
              padding: 12,
              borderRadius: 5,
              marginBottom: 25,
              marginTop: 25,
            }}
          >
            <CardExpiryElement onChange={handleCardExpiryElement} />
          </div>
        </Grid>
        <Grid item xs={3}>
          <div
            style={{
              backgroundColor: "white",
              padding: 12,
              borderRadius: 5,
              marginBottom: 25,
              marginTop: 25,
            }}
          >
            <CardCvcElement onChange={handleCardCvcElement} />
          </div>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <AddressElement
          options={{ mode: "shipping" }}
          onChange={handleAddressElement}
        />
      </Grid>

      <Grid item xs={12}>
        <div style={{ marginTop: 15 }}>
          {cardNumberComplete &&
          cardExpiryElementComplete &&
          cardCvcElementComplete &&
          addressElementComplete &&
          !hasError ? (
              <button onClick={handleSubmit} style={{ borderRadius: 5 }}>
              Add Card
              </button>
            ) : (
              <div style={{ color: "red", fontSize: 12 }}>{getError}</div>
            )}
        </div>
      </Grid>
    </Grid>
  );
};

interface StripeAddress {
  city: string;
  country: string;
  line1: string;
  line2?: string;
  postal_code: string;
  state: string;
}

function generateAdditionalData( addressData: StripeAddress )
{
  return _( {} )
    .assign( {
      address_line1: _.get( addressData, "line1" ),
      address_city: _.get( addressData, "city" ),
      address_state: _.get( addressData, "state" ),
      address_zip: _.get( addressData, "postal_code" ),
      address_country: _.get( addressData, "country" ),
    } )
    .assign(
      _.isNil( _.get( addressData, "line2" ) )
        ? {}
        : { address_line2: _.get( addressData, "line2" ) }
    )
    .value();
}
