import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  FunctionField,
  useListContext,
  TopToolbar,
  Button,
  SelectInput,
  Filter
} from "react-admin";
import { FilterProps } from "ra-ui-materialui";
import { Order } from "../../ts/interfaces/order-interface";
import { Settings } from "../../ts/interfaces/settings-interface";
import { BasicPagination, perPage } from "../generics/pagination";
import { ButtonStyles } from "../generics/button-styles";
import { formatPrice } from "../../utils/primitive";

const OrderTypeFilter: React.FC = ( properties: FilterProps ) =>
{
  const { isLoading, filterValues, hideFilter } = useListContext();

  const isInitiatedAcceptedFilter =
    filterValues.orderType === "initiated/accepted";

  if ( !isInitiatedAcceptedFilter )
  {
    hideFilter( "sellerType" );
  }

  return (
    <Filter {...properties}>
      <SelectInput
        source="orderType"
        label="Order Type"
        alwaysOn
        allowEmpty={false}
        choices={[
          { id: "mismatch", name: "Mismatched" },
          { id: "dispute", name: "Disputed" },
          { id: "initiated/accepted", name: "Initiated/Accepted" },
          { id: "toBeAuthenticated", name: "Pending Authentication" },
          { id: "transferFailure", name: "Transfer Failure" },
        ]}
      />
      {!isLoading && isInitiatedAcceptedFilter && (
        <SelectInput
          source="sellerType"
          label="Seller Type"
          alwaysOn
          allowEmpty={true}
          choices={[
            { id: "ShopThing", name: "ShopThing" },
            { id: "Seller", name: "Seller" },
          ]}
        />
      )}
    </Filter>
  );
};

export const ListActions: React.FC = () =>
{
  const { setSort, currentSort } = useListContext();
  const buttonClasses = ButtonStyles();

  return (
    <TopToolbar>
      <Button
        onClick={() =>
        {
          if (
            currentSort.field !== "timestamps.paid" ||
            currentSort.order.toUpperCase() !== "DESC"
          )
          {
            setSort( "timestamps.paid", "DESC" );
          }
        }}
        label="Reset Sort"
        variant="contained"
        color="primary"
        className={buttonClasses.resetSortButton}
      />
    </TopToolbar>
  );
};

const ListView: React.FC = () =>
{
  const { isLoading, filterValues } = useListContext();
  const isInitiatedAcceptedFilter =
    filterValues.orderType === "initiated/accepted";
  const isToBeAuthenticatedFilter =
    filterValues.orderType === "toBeAuthenticated";
  const isTransferFailure = filterValues.orderType === "transferFailure";
  const isMismatch = filterValues.orderType === "mismatch";
  const orderDetailsPageUrl = ( id: string ) =>
    `orders/${id}/show${isMismatch ? `?type=${filterValues.orderType}` : ""}`;

  if ( isLoading )
  {
    return <></>;
  }
  else if ( isInitiatedAcceptedFilter )
  {
    return (
      <Datagrid rowClick={( id: string ) => orderDetailsPageUrl( id )}>
        <TextField
          label="Return Status"
          source="returns.returnStatus"
          sortable={false}
        />
        <TextField label="Order ID" source="id" sortable={false} />
        <FunctionField
          label="VIP"
          render={( order: Order ) =>
            `${
              typeof order.customer.vip === "boolean"
                ? ( order.customer.vip === true
                  ? "Yes"
                  : "No" )
                : "N/A"
            }`
          }
          source="customer.vip"
          sortable={false}
        />

        <FunctionField
          label="Customer"
          render={( order: Order ) =>
            `${order.customer.firstname} ${order.customer.lastname}`
          }
          sortable={false}
        />

        <TextField
          label="Customer Email"
          source="customer.email"
          sortable={false}
        />
        <TextField label="Shopper" source="seller.handle" sortable={false} />
        <TextField label="Seller Type" source="sellerType" sortable={false} />
        <TextField label="Fulfilled By" source="fulfilledBy" sortable={false} />
        <DateField
          label="Date return was initiated"
          source="returns.timestamps.initiated"
          showTime
          sortable={false}
        />
        <TextField
          label="Location"
          source="returns.returnLocation"
          sortable={false}
        />
      </Datagrid>
    );
  }
  else if ( isToBeAuthenticatedFilter )
  {
    return (
      <Datagrid rowClick={( id: string ) => orderDetailsPageUrl( id )}>
        <TextField label="Order ID" source="id" sortable={false} />
        <TextField label="Shopper" source="seller.handle" sortable={false} />
        <FunctionField
          label="Total"
          render={( order: Order ) =>
            `${formatPrice(
              order.totals.totalPay,
              order.specifications.currency
            )}`
          }
          sortable={false}
        />
        <TextField label="Seller Type" source="sellerType" sortable={false} />
        <TextField label="Fulfilled By" source="fulfilledBy" sortable={false} />
        <DateField
          label="Date order was confirmed"
          source="timestamps.confirmed"
          showTime
          sortable={false}
        />
      </Datagrid>
    );
  }
  else if ( isTransferFailure )
  {
    return (
      <Datagrid rowClick={( id: string ) => orderDetailsPageUrl( id )}>
        <TextField label="Order ID" source="id" sortable={false} />
        <TextField label="Shopper" source="seller.handle" sortable={false} />
        <TextField label="Seller Type" source="sellerType" sortable={false} />
        <DateField
          label="Transfer Failed Date"
          source="timestamps.latestTransferFailure"
          showTime
          sortable={false}
        />
      </Datagrid>
    );
  }
  else
  {
    return (
      <Datagrid rowClick={( id: string ) => orderDetailsPageUrl( id )}>
        <TextField label="Order ID" source="id" sortable={false} />
        <FunctionField
          label="VIP"
          render={( order: Order ) =>
            `${
              typeof order.customer.vip === "boolean"
                ? ( order.customer.vip === true
                  ? "Yes"
                  : "No" )
                : "N/A"
            }`
          }
          source="customer.vip"
          sortable={false}
        />
        <FunctionField
          label="Customer"
          render={( order: Order ) =>
            `${order.customer.firstname} ${order.customer.lastname}`
          }
          sortable={false}
        />
        <TextField
          label="Customer Email"
          source="customer.email"
          sortable={false}
        />
        <TextField label="Shopper" source="seller.handle" sortable={false} />
        <TextField label="Seller Type" source="sellerType" sortable={false} />
        <TextField label="Fulfilled By" source="fulfilledBy" sortable={false} />
        {isMismatch && (
          <FunctionField
            label="Contacted"
            render={( order: Order ) =>
              `${order.customerContacted === true ? "Yes" : "No"}`
            }
            source="order.customerContacted"
            sortable={false}
          />
        )}
      </Datagrid>
    );
  }
};

export const ManualReviewList = ( properties: Settings[] ): JSX.Element => (
  <List
    {...properties}
    pagination={<BasicPagination />}
    title="Manual Review"
    actions={<ListActions />}
    bulkActionButtons={false}
    exporter={false}
    filters={<OrderTypeFilter />}
    filterDefaultValues={{ orderType: "mismatch" }}
    sort={{ field: "timestamps.paid", order: "DESC" }}
    perPage={perPage}
  >
    <ListView />
  </List>
);
