import React, {
  useEffect,
  createContext,
  useState,
  useContext,
  ReactNode
} from "react";

import ReactDOM from "react-dom";

import { makeStyles, createStyles } from "@material-ui/core/styles";

type notificationType = "error" | "notify";

interface ShowNotification {
  message: string;
  type: notificationType;
  onClose?: () => void;
  timeout?: number;
}

interface INotificationComponent extends ShowNotification {
  closeNotification: () => void;
}

interface INotificationContext {
  showNotification: ( data: ShowNotification ) => void;
}

interface INotificationProvider {
  children: ReactNode;
}

const useStyles = makeStyles( () =>
  createStyles( {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "#000000",
      opacity: 0.5,
      zIndex: 999,
    },
    container: {
      position: "fixed",
      bottom: "20px",
      left: "50%",
      transform: "translate(-50%)",
      backgroundColor: "#333",
      color: "#fff",
      padding: "20px 40px",
      borderRadius: "8px",
      zIndex: 1000,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    },
    containerWarning: {
      backgroundColor: "#f15d5d",
    },
    message: {
      fontSize: "16px",
    },
    closeButton: {
      position: "absolute",
      top: "10px",
      right: "10px",
      background: "transparent",
      border: "none",
      color: "#fff",
      fontSize: "18px",
      cursor: "pointer",
    },
  } )
);

const NotificationComponent: React.FC<INotificationComponent> = ( {
  closeNotification,
  message,
  type,
  onClose,
  timeout,
} ) =>
{
  const classes = useStyles();
  const [notificationDOMNode, setNotificationDOMNode] = useState<
    HTMLElement | Element | null
  >( null );
  const [timer, setTimer] = useState<NodeJS.Timeout | null>( null );

  const containerClass =
    type === "notify"
      ? classes.container
      : classes.container + " " + classes.containerWarning;

  useEffect( () =>
  {
    let root = document.querySelector( "#notification-root" );

    if ( !root )
    {
      root = document.createElement( "div" );
      root.setAttribute( "id", "notification-root" );
      document.body.appendChild( root );
    }

    setNotificationDOMNode( root );

    // cleanup on unmount
    return () =>
    {
      if ( root && root.childElementCount === 0 )
      {
        root.remove();
      }
    };
  }, [] );

  useEffect( () =>
  {
    if ( timeout )
    {
      const timerId = setTimeout( () =>
      {
        handleClose();
      }, timeout );

      setTimer( timerId );

      // Cleanup timer on component unmount
      return () => clearTimeout( timerId );
    }
  }, [timeout] );

  const handleClose = () =>
  {
    closeNotification();

    if ( onClose )
    {
      onClose(); // Use call back on close if exist
    }

    if ( timer )
    {
      clearTimeout( timer );
    }
  };

  if ( !notificationDOMNode )
  {
    return null; // When root not exist do not render anything
  }

  return ReactDOM.createPortal(
    <React.Fragment>
      <div className={classes.overlay} />
      <div className={containerClass}>
        <div className={classes.message}>
          {message}
          {!timeout && (
            <button className={classes.closeButton} onClick={handleClose}>
              X
            </button>
          )}
        </div>
      </div>
    </React.Fragment>,
    notificationDOMNode
  );
};

const NotificationContext = createContext<INotificationContext | undefined>(
  undefined
);

export const NotificationProvider: React.FC<INotificationProvider> = ( {
  children,
} ) =>
{
  const [notificationData, setNotificationData] =
    useState<ShowNotification | null>( null );

  const showNotification = ( data: ShowNotification ) =>
  {
    setNotificationData( {
      message: data.message,
      type: data.type,
      onClose: data.onClose,
      timeout: data.timeout,
    } );
  };

  const closeNotification = () =>
  {
    setNotificationData( null );
  };

  return (
    <NotificationContext.Provider value={{ showNotification }}>
      {children}
      {notificationData && (
        <NotificationComponent
          closeNotification={closeNotification}
          message={notificationData.message}
          type={notificationData.type}
          onClose={notificationData.onClose}
          timeout={notificationData.timeout}
        />
      )}
    </NotificationContext.Provider>
  );
};

// Custom hook to use notification context
export const useNotification = () =>
{
  const context = useContext( NotificationContext );
  if ( !context )
  {
    throw new Error( "Use useNotification inside NotificationProvider!" );
  }
  return context;
};
