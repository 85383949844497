import React from "react";
import { useUpdate, useNotify } from "react-admin";
import {
  IconButton,
  Typography,
  Link,
  TextField,
  Button,
  InputAdornment,
  Select,
  MenuItem
} from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { amountValidator, description } from "../../utils/form-validators";
import { Customer } from "../../ts/interfaces/user-interface";
import { Confirmation } from "./charge-confirmation";
import { CustomModal } from "@shopthing-opn-shared/admin-dashboard";
import { Spacer } from "../generics/spacer";
import { notifyMessage } from "../../utils/primitive";

interface IUserReconcile {
  record?: Customer;
}

interface EventTarget {
  name: string;
  value: string;
  description?: string;
  currency?: string;
}

const useStyles = makeStyles( ( theme: Theme ) =>
  createStyles( {
    chargeInput: {
      position: "absolute",
      width: 500,
      backgroundColor: "#f2f2f2",
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing( 2, 4, 3 ),
      textAlign: "center",
    },
    currentLayout: {
      display: "flex",
      flexDirection: "row",
    },
    amount: {
      width: "80%",
    },
    description: {
      width: "100%",
    },
    select: {
      width: "15%",
    },
  } )
);

export const ReconcileButton: React.FunctionComponent<IUserReconcile> = (
  property: IUserReconcile
) =>
{
  const { id, firstname } = property.record;
  const [open, setOpen] = React.useState( false );
  const [values, setValues] = React.useState( {
    amount: 0,
    description: "",
    currency: "cad",
  } );
  const [confirmationText, setConfirmationText] = React.useState( "" );
  const [showConfirmation, setShowConfirmation] = React.useState( false );
  const [formError, setError] = React.useState( {
    amount: "",
    description: "Please enter a non empty description",
  } );
  const [touched, setTouched] = React.useState( false );

  const notify = useNotify();
  const [update, { loading }] = useUpdate(
    "reconciliation",
    id,
    {
      sellerId: id,
      amount: Math.floor( values.amount * 100 ),
      description: values.description,
      currency: values.currency,
    },
    {},
    {
      onSuccess: () =>
      {
        window.location.reload();
      },
      onFailure: ( error: Error ) =>
      {
        notifyMessage( error, notify );
      },
    }
  );
  const classes = useStyles();
  const validators: { [key: string]: ( value: string ) => string } = {
    amount: amountValidator,
    description,
  };
  const handleOpen = () =>
  {
    setOpen( true );
  };
  const handleClose = () =>
  {
    setOpen( false );
  };
  const handleInputChange = ( event: { target: EventTarget } ) =>
  {
    setTouched( true );
    const { name, value } = event.target;
    setValues( { ...values, [name]: value } );
    const error = validators[name]( value );
    setError( { ...formError, [name]: error } );
  };
  const handleCurrencyChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) =>
  {
    setValues( { ...values, currency: event.target.value } );
  };
  const handleSubmit = () =>
  {
    setOpen( false );
    setShowConfirmation( true );
    const currencyString = values.currency.toUpperCase();
    setConfirmationText(
      `Please confirm $${values.amount} ${currencyString} as the value to charge ${firstname}`
    );
  };

  const handleConfirmationClose = () =>
  {
    setOpen( true );
    setShowConfirmation( false );
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        color={"primary"}
        disabled={loading}
        disableRipple
        disableFocusRipple
      >
        <Typography>
          <Link>Reconcile</Link>
        </Typography>
      </IconButton>
      <CustomModal open={open} onClose={handleClose}>
        <>
          <div className={classes.currentLayout}>
            <TextField
              name="amount"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              onChange={handleInputChange}
              value={values.amount}
              error={Boolean( formError.amount )}
              helperText={formError.amount}
              className={classes.amount}
              type="text"
              label={`Enter amount to charge (${values.currency.toUpperCase()})`}
            />
            <Select
              onChange={handleCurrencyChange}
              value={values.currency}
              className={classes.select}
            >
              <MenuItem value="cad">CAD</MenuItem>
              <MenuItem value="usd">USD</MenuItem>
            </Select>
          </div>

          <Spacer />
          <TextField
            name="description"
            onChange={handleInputChange}
            value={values.description}
            error={Boolean( formError.description )}
            helperText={formError.description}
            className={classes.description}
            type="text"
            label="Please enter a description for the charge"
          />
          <Spacer />
          <Button
            onClick={handleSubmit}
            disabled={
              !touched || Boolean( formError.amount || formError.description )
            }
          >
            Charge
          </Button>
        </>
      </CustomModal>
      <Confirmation
        open={showConfirmation}
        disabled={loading}
        title="Confirm charge"
        text={confirmationText}
        confirmText="Confirm"
        rejectText="Cancel"
        onReject={handleConfirmationClose}
        onClose={handleConfirmationClose}
        onAgree={update}
      />
    </>
  );
};
