export const formatPrice = ( price: string, currency: string ): string =>
{
  const currencyDisplayed = currency.toUpperCase().slice( 0, 2 );
  const fp = parseInt( price, 10 ) / 100;
  const m = new Intl.NumberFormat( "en-US", {
    useGrouping: true,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  } ).format( fp );

  return `${currencyDisplayed} $${m}`;
};

export const formatPriceWithoutCurrency = ( price: string ): string =>
{
  const fp = parseInt( price, 10 ) / 100;
  const m = new Intl.NumberFormat( "en-US", {
    useGrouping: true,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  } ).format( fp );

  return `$${m}`;
};

export const getAge = ( created: Date ): number =>
{
  const MS_PER_DAY = 1000 * 60 * 60 * 24;
  const startDate = new Date( created ).getTime();
  const endDate = new Date().getTime();
  return Math.floor( ( endDate - startDate ) / MS_PER_DAY );
};

export const notifyMessage = (
  error: Error,
  notify: ( message: string, type: string ) => void
) =>
{
  if ( typeof error === "string" )
  {
    error = JSON.parse( error );
  }
  notify( error.message, "warning" );
};
