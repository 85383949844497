import { ProductInventoryItemStatus } from "../../ts/interfaces/product-inventory-interface";
import {
  ReferenceInput,
  SelectInput,
  SimpleForm,
  useMutation,
  useNotify,
  useRefresh
} from "react-admin";
import * as React from "react";
import { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { Box, Button } from "@material-ui/core";

interface InputEvent {
  target: EventTarget;
}

interface EventTarget {
  name: string;
  value: string;
}

export function AddProductInventoryToEvent( props: {
  productInventoryItemId: string;
  itemStatus: ProductInventoryItemStatus;
  currency: string;
} ): JSX.Element
{
  const refresh = useRefresh();
  const [values, setValues] = React.useState( { id: "" } );
  const [addToEvent, { loading, loaded, data, error }] = useMutation();
  const [dialogState, setDialogState] = useState( false );
  const [touched, setTouched] = React.useState( false );
  const notify = useNotify();
  useEffect( () =>
  {
    if ( data )
    {
      notify( "Product added to event." );
      setDialogState( false );
      refresh();
    }
    else if ( error )
    {
      notify( `${error.body.message}`, "warning" );
    }
  }, [loaded, data, error] );
  const handleInputChange = ( event: InputEvent ) =>
  {
    setTouched( true );
    const { name, value }: { name: string; value: string } = event.target;
    setValues( { ...values, [name]: value } );
  };

  const handleSubmit = async () =>
  {
    await addToEvent( {
      type: "addToEvent",
      resource: "product-inventory-items",
      payload: { id: props.productInventoryItemId, eventId: values.id },
    } );
  };
  if ( props.itemStatus === ProductInventoryItemStatus.Available )
  {
    return (
      <>
        <Dialog
          open={dialogState}
          onClose={() =>
          {
            setDialogState( false );
          }}
        >
          <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
            Add product to an event
          </DialogTitle>
          <DialogContent>
            <SimpleForm toolbar={<></>}>
              <ReferenceInput
                label="Event name"
                source="id"
                reference="events-live-and-upcoming"
                filter={{ currency: props.currency }}
                onChange={handleInputChange}
                // Todo:  fix this
                perPage={10000}
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
            </SimpleForm>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                width: "fit-content",
                justifyContent: "center",
                margin: "auto",
              }}
            >
              <Button disabled={!touched || loading} onClick={handleSubmit}>
                ADD PRODUCT
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
        <Button
          color="primary"
          onClick={() =>
          {
            setDialogState( true );
          }}
        >
          Add to Event
        </Button>
      </>
    );
  }
  return <></>;
}

export function RemoveProductInventoryFomEvent( props: {
  productInventoryItemId: string;
  itemStatus: ProductInventoryItemStatus;
  eventName: string;
} ): JSX.Element
{
  const refresh = useRefresh();
  const [removeFromEvent, { loading, loaded, data, error }] = useMutation();
  const [dialogState, setDialogState] = useState( false );
  const notify = useNotify();
  useEffect( () =>
  {
    if ( data )
    {
      notify( "Product removed from event." );
      setDialogState( false );
      refresh();
    }
    else if ( error )
    {
      notify( `${error.body.message}`, "warning" );
    }
  }, [loaded, data, error] );
  const handleSubmit = async () =>
  {
    await removeFromEvent( {
      type: "removeFromEvent",
      resource: "product-inventory-items",
      payload: { id: props.productInventoryItemId },
    } );
  };
  if ( props.itemStatus === ProductInventoryItemStatus.InEvent )
  {
    return (
      <>
        <Dialog
          open={dialogState}
          onClose={() =>
          {
            setDialogState( false );
          }}
        >
          <DialogTitle id="alert-dialog-title">
            Are you sure you want to remove this product from the shopper’s
            event?
          </DialogTitle>
          <DialogContent style={{ display: "flex", justifyContent: "center" }}>
            <Button disabled={loading} onClick={handleSubmit}>
              Yes
            </Button>
            <Button
              onClick={() =>
              {
                setDialogState( false );
              }}
            >
              No
            </Button>
          </DialogContent>
        </Dialog>
        <Button
          color="primary"
          onClick={() =>
          {
            setDialogState( true );
          }}
        >
          Remove from event
        </Button>
      </>
    );
  }
  return <></>;
}

export function RemoveProductInventory( props: {
  productInventoryItemId: string;
  itemStatus: ProductInventoryItemStatus;
} ): JSX.Element
{
  const refresh = useRefresh();
  const [releaseProduct, { loading, loaded, data, error }] = useMutation();
  const [dialogState, setDialogState] = useState( false );
  const notify = useNotify();
  useEffect( () =>
  {
    if ( data )
    {
      notify( "Product is released." );
      setDialogState( false );
      refresh();
    }
    else if ( error )
    {
      notify( `${error.body.message}`, "warning" );
    }
  }, [loaded, data, error] );
  const handleSubmit = async () =>
  {
    await releaseProduct( {
      type: "releaseProduct",
      resource: "product-inventory-items",
      payload: { id: props.productInventoryItemId },
    } );
  };
  if ( props.itemStatus === ProductInventoryItemStatus.Available )
  {
    return (
      <>
        <Dialog
          open={dialogState}
          onClose={() =>
          {
            setDialogState( false );
          }}
        >
          <DialogTitle id="alert-dialog-title">
            Are you sure you want to remove this product from Product Inventory?
          </DialogTitle>
          <DialogContent style={{ display: "flex", justifyContent: "center" }}>
            <Button disabled={loading} onClick={handleSubmit}>
              Yes
            </Button>
            <Button
              onClick={() =>
              {
                setDialogState( false );
              }}
            >
              No
            </Button>
          </DialogContent>
        </Dialog>
        <Button
          color="primary"
          onClick={() =>
          {
            setDialogState( true );
          }}
        >
          Remove from Product Inventory
        </Button>
      </>
    );
  }
  return <></>;
}
