import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  FunctionField,
  Filter,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
  usePermissions,
  useListContext,
  TopToolbar,
  CreateButton,
  Button,
  sanitizeListRestProps,
  BooleanField
} from "react-admin";
import { FilterProps } from "ra-ui-materialui";
import { Chip, Grid } from "@material-ui/core";

import {
  extractAuthClaims,
  UserClaims,
  BooleanRoles,
  hasPermissions
} from "../ts/interfaces/role-interface";
import { BasicPagination, perPage } from "./generics/pagination";
import { Order } from "../ts/interfaces/order-interface";
import { PaginatedSelectInput } from "./generics/paginated-select-input";
import { paginatedSelectInputConfig } from "./component-config";
import {
  makeLocalStorageKey,
  StoragePage,
  StorageType
} from "../utils/local-storage";
import { formatPrice } from "../utils/primitive";
import { Customer } from "../ts/interfaces/user-interface";
import { DaysToFulfillField } from "./order/days-to-fulfill-field";
import { DaysInPaidField } from "./order/days-in-paid-field";
import { ButtonStyles } from "./generics/button-styles";
import { useEffect } from "react";

const optionRenderer = ( choice: Customer ) =>
  `${choice?.firstname || ""} ${choice?.lastname || ""} ${
    choice?.email ? `(${choice.email})` : ""
  }`;

const shouldRenderSuggestions = ( value: string ) => value.trim().length > 2;

const setShopper = ( id: string, value: string ) =>
  localStorage.setItem(
    makeLocalStorageKey( StoragePage.orderList, StorageType.seller, id ),
    value
  );

const setEvent = ( id: string, value: string ) =>
  localStorage.setItem(
    makeLocalStorageKey( StoragePage.orderList, StorageType.event, id ),
    value
  );

const PostFilter: React.FC = (
  properties: FilterProps & {
    data?: Order[];
    filterValues?: Order;
    displayedFilters: { [key: string]: boolean };
    setFilters?: ( filter: { [key: string]: unknown } ) => void;
    resetSort: () => void;
  }
) =>
{
  const { permissions } = usePermissions();
  const roleClaims = extractAuthClaims( permissions );
  const hasFilterPermissions = hasPermissions(
    [
      BooleanRoles.Fulfillment,
      BooleanRoles.FulfillmentReadOnly,
      BooleanRoles.CustomerService,
      BooleanRoles.RefundCustomerService,
      BooleanRoles.Admin,
    ],
    roleClaims
  );

  const [sellerId, setSellerId] = React.useState(
    properties.filterValues?.seller?.id || ""
  );

  const eventId = properties.filterValues?.seller?.event || "";
  const [initialEvent, setInitialEvent] = React.useState(
    eventId
      ? {
        id: eventId,
        name:
            localStorage.getItem(
              makeLocalStorageKey(
                StoragePage.orderList,
                StorageType.event,
                eventId
              )
            ) || eventId,
      }
      : null
  );

  let initialSellerValue;
  if ( sellerId )
  {
    if (
      !properties.displayedFilters ||
      !properties.displayedFilters["seller.id"]
    )
    {
      initialSellerValue = null;
      setSellerId( "" );
      setInitialEvent(
        eventId
          ? {
            id: eventId,
            name:
                localStorage.getItem(
                  makeLocalStorageKey(
                    StoragePage.orderList,
                    StorageType.event,
                    eventId
                  )
                ) || eventId,
          }
          : null
      );
    }
    else
    {
      initialSellerValue = {
        id: sellerId,
        name:
          localStorage.getItem(
            makeLocalStorageKey(
              StoragePage.orderList,
              StorageType.seller,
              sellerId
            )
          ) || sellerId,
      };
    }
  }

  const isFulfilledOrRefundedStatus = ["fulfilled", "refunded"].includes(
    properties.filterValues?.status
  );
  useEffect( () =>
  {
    if (
      properties.filterValues?.timestamps?.confirmed &&
      properties.resetSort
    )
    {
      properties.resetSort();
    }
  }, [properties.filterValues] );
  return (
    <Filter {...properties}>
      <SelectInput
        source="status"
        choices={[
          { id: "paid", name: "paid" },
          { id: "pending", name: "pending" },
          { id: "confirmed", name: "confirmed" },
          { id: "fulfilled", name: "fulfilled" },
          { id: "refunded", name: "refunded" },
        ]}
      />
      {hasFilterPermissions && (
        <ReferenceInput
          label="Customer Name"
          source="customer.id"
          reference="buyers"
          filterToQuery={( searchText: string ) => ( {
            customerName: searchText,
          } )}
        >
          <AutocompleteInput
            optionValue="id"
            optionText={optionRenderer}
            choices={[]}
            matchSuggestion={() => true}
            shouldRenderSuggestions={shouldRenderSuggestions}
            resettable={true}
            clearAlwaysVisible={true}
          />
        </ReferenceInput>
      )}

      {hasFilterPermissions && (
        <PaginatedSelectInput
          source="seller.id"
          data={properties.data}
          initialEntry={initialSellerValue}
          label="Shopper"
          onChange={(
            event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) =>
          {
            // Clear the event filter when the seller changes
            const filters = {
              ...properties.filterValues,
              seller: {
                id: event.target.value,
              },
            };
            properties.setFilters( filters );
            setInitialEvent( null );
            setSellerId( event.target.value );
          }}
          list={{
            resource: "users",
            nameMappingKey: "handle",
            perPage: paginatedSelectInputConfig.perPage,
            filter: { isSeller: true },
            sort: {
              field: "sortOptions.handle",
              order: "ASC",
            },
            storageFunction: setShopper,
          }}
        />
      )}
      <PaginatedSelectInput
        key={sellerId}
        source="seller.event"
        data={properties.data}
        initialEntry={initialEvent}
        label="Event"
        list={{
          resource: "events",
          nameMappingKey: "name",
          perPage: paginatedSelectInputConfig.perPage,
          filter: {
            ...( sellerId && { userId: sellerId } ),
          },
          storageFunction: setEvent,
        }}
      />
      {!isFulfilledOrRefundedStatus && hasFilterPermissions && (
        <SelectInput
          label="Days to Fulfillment"
          source="timestamps.confirmed"
          choices={[
            {
              id: "5",
              name: "5 days or more",
              value: "5",
            },
            {
              id: "10",
              name: "10 days or more",
              value: "10",
            },
            {
              id: "15",
              name: "15 days or more",
              value: "15",
            },
          ]}
          optionText="name"
          optionValue="value"
        />
      )}
      {( hasFilterPermissions ||
        hasPermissions( [BooleanRoles.Seller], roleClaims ) ) && (
        <SelectInput
          label="Currency"
          source="specifications.currency"
          choices={[
            {
              id: "CAD",
              name: "CAD",
              value: "cad",
            },
            {
              id: "USD",
              name: "USD",
              value: "usd",
            },
          ]}
          optionText="name"
          optionValue="value"
        />
      )}
    </Filter>
  );
};
interface ListProperties {
  permissions: UserClaims;
  [key: string]: unknown;
}

interface ListActionProperties {
  filters?: React.ReactElement;
  [key: string]: unknown;
}

export const ListActions: React.FC<ListActionProperties> = ( props ) =>
{
  const { permissions } = usePermissions();
  const roleClaims = extractAuthClaims( permissions );
  // eslint-disable-next-line react/prop-types
  const { filters, ...rest } = props;
  const {
    resource,
    displayedFilters,
    filterValues,
    showFilter,
    setSort,
    currentSort,
  } = useListContext();

  const buttonClasses = ButtonStyles();
  const resetSort = () =>
  {
    if (
      currentSort.field !== "timestamps.created" ||
      currentSort.order.toUpperCase() !== "DESC"
    )
    {
      setSort( "timestamps.created", "DESC" );
    }
  };
  return (
    <TopToolbar {...sanitizeListRestProps( rest )}>
      {filters &&
        React.cloneElement( filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
          resetSort,
          currentSort,
        } )}
      <Button
        onClick={resetSort}
        label="Reset Sort"
        variant="contained"
        color="primary"
        className={buttonClasses.resetSortButton}
      />
      {hasPermissions( [BooleanRoles.Admin], roleClaims ) && <CreateButton />}
    </TopToolbar>
  );
};

export const OrderList = ( properties: ListProperties ): JSX.Element => (
  <List
    {...properties}
    pagination={<BasicPagination />}
    filters={<PostFilter />}
    actions={<ListActions />}
    bulkActionButtons={false}
    exporter={false}
    perPage={perPage}
    sort={{ field: "timestamps.created", order: "DESC" }}
  >
    <Datagrid rowClick="show">
      <TextField label="Order ID" source="id" sortable={false} />
      <BooleanField
        label="Is Product Inventory?"
        source="hasProductInventoryItem"
        sortable={false}
      />
      <TextField
        label="Product Inventory Location"
        source="location"
        sortable={false}
      />
      <TextField
        label="Shipping Method"
        source="logistics.option"
        sortable={true}
      />
      <TextField label="Event ID" source="seller.event" sortable={false} />
      <FunctionField
        label="VIP"
        render={( order: Order ) =>
          `${
            typeof order.customer.vip === "boolean"
              ? ( order.customer.vip === true
                ? "Yes"
                : "No" )
              : "N/A"
          }`
        }
        source="customer.vip"
        sortable={false}
      />
      <FunctionField
        label="Customer"
        render={( order: Order ) =>
          `${order.customer.firstname} ${order.customer.lastname}`
        }
        sortable={false}
      />
      <TextField
        label="Brand"
        source="cart.items[0].brandName"
        sortable={true}
      />
      <FunctionField
        label="Product"
        render={( order: Order ) =>
          `${order.cart.items[0].productName}${
            order.cart.items[0].description
              ? ` - ${order.cart.items[0].description}`
              : ""
          }`
        }
        sortable={false}
      />
      <TextField
        label="Product ID"
        source="cart.items[0].externalProductId"
        sortable={false}
      />
      -{" "}
      <FunctionField
        label="Total"
        render={( order: Order ) =>
          `${formatPrice( order.totals.totalPay, order.specifications.currency )}`
        }
        sortable={false}
      />{" "}
      - <TextField label="Shopper" source="seller.handle" sortable={false} />
      <DateField
        label="Created At"
        source="timestamps.created"
        showTime
        sortable={true}
      />
      <DateField
        label="Paid At"
        source="timestamps.paid"
        showTime
        sortable={false}
      />
      <DaysInPaidField
        source="timestamps.paid"
        label="# of Days in Paid"
        sortByOrder="ASC"
        sortable={true}
      />
      <DaysToFulfillField label="# of Days to Fulfill" />
      <FunctionField
        label="Status"
        render={( order: Order ) =>
          order.latestDisputeStatus &&
          ( typeof order.disputeResolved !== "boolean" ||
            order.disputeResolved === false ) ? (
              <Grid container spacing={1} wrap="nowrap">
                <Grid item>
                  <Chip label={order.status} />
                </Grid>
                <Grid item>
                  <Chip
                    style={{ color: "red" }}
                    label={
                      order.latestDisputeStatus === "fraudulent"
                        ? "fraudulent"
                        : `disputed`
                    }
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={1} wrap="nowrap">
                <Grid item>
                  <Chip
                    label={
                      order.latestDisputeStatus === "lost" &&
                    order.disputeResolved === true
                        ? "Dispute Lost"
                        : order.status
                    }
                  />
                </Grid>
              </Grid>
            )
        }
        sortable={false}
      />
    </Datagrid>
  </List>
);
