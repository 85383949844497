import * as React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

import {
  Edit,
  FormTab,
  TabbedForm,
  TextInput,
  useNotify,
  useRedirect
} from "react-admin";

import { Record } from "ra-core";
import { SaveOnlyToolbar } from "../toolbars/save-only";
import { Product } from "../../ts/interfaces/product-interface";
import { PopupNotificationType } from "../popup-notification/popup-notification";

interface IEditProperties {
  id: string;
  [x: string]: unknown;
  record: Product;
}

const useStyles = makeStyles( ( theme: Theme ) =>
  createStyles( {
    formField: {
      margin: theme.spacing( 1, 2, 1, 0 ),
    },
  } )
);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const EventProductEditForm: React.FC = () =>
{
  const classes = useStyles();

  return (
    <React.Fragment>
      <TextInput
        source="externalProductId"
        label="Product ID"
        className={classes.formField}
      />
    </React.Fragment>
  );
};

export const EventProductEdit: React.FunctionComponent<IEditProperties> = (
  properties: IEditProperties
) =>
{
  const redirect = useRedirect();
  let eventId = "";
  const notify = useNotify();

  const onSuccess = () =>
  {
    notify( "Product Updated" );
    redirect( `/events/${eventId}/show` );
  };

  const transform = async ( data: Record ) =>
  {
    eventId = data.eventId;
    const update = {
      externalProductId: data.externalProductId || "",
    };

    return update;
  };

  const onFailure = ( error: Error ) =>
  {
    if ( error.name !== "TypeError" )
    {
      notify(
        `Failed updating product  ${error.message}`,
        PopupNotificationType.error
      );
    }
    else
    {
      notify( "Product Updated" );
      redirect( `/events/${eventId}/show` );
    }
  };

  return (
    <Edit
      undoable={false}
      transform={transform}
      onSuccess={onSuccess}
      onFailure={onFailure}
      {...properties}
    >
      <TabbedForm toolbar={<SaveOnlyToolbar />}>
        <FormTab label="Product">
          <EventProductEditForm />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
