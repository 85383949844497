import * as React from "react";
import { BooleanFieldProps } from "ra-ui-materialui";
import { BooleanField } from "react-admin";

// TODO After this has been merged into the shared package, import it instead of using this
export const TruthyBooleanField: React.FC<BooleanFieldProps> = (
  properties: BooleanFieldProps
) =>
{
  const { record, source, ...rest } = properties;
  record[source] = !!record[source];
  return <BooleanField record={record} source={source} {...rest} />;
};
