import * as React from "react";
import { INotification } from "../components/popup-notification/popup-notification";
import { LDContext } from "launchdarkly-react-client-sdk";

export interface ObjectText {
  value: string;
  title: string;
}
interface Payload {
  orderId: string;
  fulfillmentId: string;
  bundledOrderId: string;
}

interface Action {
  id?: string;
  reset?: boolean;
}
interface FulfillmentAction {
  fulfillmentId?: string;
  bundledOrderId?: string;
  orderId?: keyof Record<string, State>;
  reset?: boolean;
}

interface State {
  checkedIds: string[];
}

const BarcodeContext = React.createContext( {
  setBarcode: (
    orderId: string,
    fulfillmentId: string,
    bundledOrderId: string
  ) =>
  {
    orderId;
    fulfillmentId;
    bundledOrderId;
  },
  orderId: "",
  fulfillmentId: "",
  bundledOrderId: "",
  orderState: { checkedIds: [] },
  orderDispatch: ( action: Action ) =>
  {
    action;
  },
  fulfillmentState: {} as Record<string, State>,
  fulfillmentDispatch: ( action: FulfillmentAction ) =>
  {
    action;
  },
  payload: {} as Payload,
  toggleLabel: ( showLabel: boolean ) =>
  {
    showLabel;
  },
  displayLabel: false as boolean,
  setNotification: ( notification: INotification ) =>
  {
    notification;
  },
  notification: null,
  setUserContext: ( userContext: LDContext ) =>
  {
    userContext;
  },
  userContext: null,
} );

export const useBarcode = () => React.useContext( BarcodeContext );

const initialState: State = { checkedIds: [] };
const initialFulfillmentState: Record<string, State> = {};

const reducer = ( state: State, action: Action ) =>
{
  if ( action.reset === true )
  {
    return {
      ...initialState,
    };
  }
  if ( state.checkedIds.includes( action.id ) )
  {
    return {
      ...state,
      checkedIds: state.checkedIds.filter( ( id ) => id !== action.id ),
    };
  }

  return {
    ...state,
    checkedIds: [...state.checkedIds, action.id],
  };
};

const fulfillmentReducer = (
  state: Record<string, State>,
  action: FulfillmentAction
) =>
{
  if ( action.reset === true )
  {
    return {
      ...initialFulfillmentState,
    };
  }

  if (
    state &&
    Object.prototype.hasOwnProperty.call( state, action.orderId ) &&
    !state[action.orderId].checkedIds.includes( action.fulfillmentId )
  )
  {
    return {
      ...state,
      [action.orderId]: {
        checkedIds: [...state[action.orderId].checkedIds, action.fulfillmentId],
      },
    };
  }

  if (
    state &&
    !state[action.orderId]?.checkedIds.includes( action.fulfillmentId )
  )
  {
    return {
      ...state,
      [action.orderId]: { checkedIds: [action.fulfillmentId] },
    };
  }

  return {
    ...state,
  };
};

interface IBarcodeProvider {
  children: React.ReactChildren[];
}

export const BarcodeProvider: React.FunctionComponent = ( {
  children,
}: IBarcodeProvider ): JSX.Element =>
{
  const [userContext, setUserContext] = React.useState( {} );
  const [orderId, setOrderId] = React.useState( "" );
  const [payload, setPayload] = React.useState( {} as Payload );
  const [notification, setNotification] = React.useState( null );
  const [fulfillmentId, setFulfillmentId] = React.useState( "" );
  const [bundledOrderId, setBundledOrderId] = React.useState( "" );
  const [displayLabel, toggleLabel] = React.useState( false );
  const [orderState, orderDispatch] = React.useReducer( reducer, initialState );
  const [fulfillmentState, fulfillmentDispatch] = React.useReducer(
    fulfillmentReducer,
    initialFulfillmentState
  );
  const setBarcode = (
    incomingOrderId: string,
    incomingFulfillmentId: string,
    incomingBundledOrderId: string
  ) =>
  {
    setFulfillmentId( incomingFulfillmentId );
    setBundledOrderId( incomingBundledOrderId );
    setOrderId( incomingOrderId );

    setPayload( {
      orderId: incomingOrderId,
      fulfillmentId: incomingFulfillmentId,
      bundledOrderId: incomingBundledOrderId,
    } );
  };
  return (
    <BarcodeContext.Provider
      value={{
        setBarcode,
        toggleLabel,
        displayLabel,
        orderId,
        fulfillmentId,
        bundledOrderId,
        orderState,
        orderDispatch,
        fulfillmentState,
        fulfillmentDispatch,
        payload,
        setNotification,
        notification,
        setUserContext,
        userContext,
      }}
    >
      <>{children}</>
    </BarcodeContext.Provider>
  );
};
