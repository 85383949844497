import { Timestamps } from "./shared";
export enum ProductInventoryItemStatus {
  Unavailable = "unavailable",
  Initiated = "initiated-return",
  Available = "available",
  InEvent = "in-event",
  Resold = "resold",
}

export interface Media {
  posterPath: string;
  posterUrl?: string;

  mediaHeight: string;
  mediaWidth: string;
  rawMediaPath: string;
  mediaPath: string;
  rawPosterPath: string;
  type: string;
  rawSmallPosterPath: string;

  mediaUrl?: string;
}

export interface ProductInventoryItemAssignedEvent {
  eventId: string;
  eventName: string;
  shopperHandle: string;
  shopperId: string;
}

export interface CreateProductInventoryItem extends ProductInventoryItem {
  tagIds: string[];
  topLevelCategory?: string;
  subCategory1?: string;
  subCategory2?: string;
  taxRate: number;
}

export interface UpdateProductInventoryItem extends ProductInventoryItem {
  tagIds?: string[];
  topLevelCategory?: string;
  subCategory1?: string;
  subCategory2?: string;
  salePriceUpdate?: number;
  regularPriceUpdate?: number;
}

interface MediaInput {
  storage: Media;
}

export interface ProductInventoryItem {
  currentAssignedEvent?: ProductInventoryItemAssignedEvent;
  currency: "cad" | "usd";
  id: string;
  status: ProductInventoryItemStatus;
  brandId: string;
  brandName: string;
  productName: string;
  productDescription: string;
  categoryPathIds: string[];
  categoryPathNames: string[];
  tagNames?: string[];
  salePrice: number;
  regularPrice?: number;
  media: MediaInput[];
  skuOption: string;
  skuNumber: string;
  location?: string;
  currentOrderId?: string;
  orderIds?: string[];
  currentProductId?: string;
  productIds?: string[];
  numOfTimesSold: number;
  numOfTimesAddedToEvent: number;
  timestamps: Timestamps;
  manuallyCreated?: boolean;
  age: number;
  formattedSalePrice: string;
  seller?: {
    id: string;
    handle: string;
  };
}

export interface ProductInventoryItemsListData {
  id: string;
  brandName: string;
  productName: string;
  regularPrice?: number;
  salePrice: number;
  status: ProductInventoryItemStatus;
  numOfTimesSold: number;
  currency: string;
  location?: string;
  timestamps: Timestamps;
  skuOption?: string;
  tagNames?: string[];
}
