import * as React from "react";

import get from "lodash/get";
import { useUpdate, useNotify } from "react-admin";

import {
  Typography,
  Grid,
  Card,
  CardContent,
  Box,
  Button
} from "@material-ui/core";
import { Customer } from "../../../ts/interfaces/user-interface";
import { Spacer } from "../../generics/spacer";
import { ButtonStyles } from "../../generics/button-styles";

interface CustomTextFieldProps {
  label: string;
  source?: string;
  value?: string;
  record?: Customer;
  currency?: boolean;
}

export const CustomTextField: React.FunctionComponent<CustomTextFieldProps> = (
  props: CustomTextFieldProps
) =>
{
  const { source, value, label, record, currency } = props;
  const displayValue = value ? value : ( source ? get( record, source ) : undefined );
  return displayValue ? (
    <Typography variant="body1">
      {label}:{" "}
      {currency === true
        ? ( displayValue / 100 ).toLocaleString( "en-US", {
          style: "currency",
          currency: "USD",
        } )
        : displayValue}
    </Typography>
  ) : null;
};

export const CardDetailsView: React.FC<{ record?: Customer }> = ( {
  record,
}: {
  record: Customer;
} ) =>
{
  // For use with Activate / Deactivate Cards
  const buttonClasses = ButtonStyles();
  const notify = useNotify();
  const [isUpdating, setIsUpdating] = React.useState( false );
  const [updateIssuedCard] = useUpdate(
    "update-issued-card",
    record?.id,
    {
      status: record?.cardDetails?.status === "active" ? "inactive" : "active",
    },
    {},
    {
      onSuccess: () =>
      {
        const message =
          record?.cardDetails?.status === "active"
            ? "Credit Card Deactivated"
            : "Credit Card Activated";
        notify( message );
        window.location.reload();
      },
      onFailure: ( error: Error ) =>
      {
        notify(
          `An error occurred, please try again later ${error.message}`,
          "warning"
        );
        setIsUpdating( false );
      },
    }
  );

  const activateCard = () =>
  {
    setIsUpdating( true );
    updateIssuedCard();
  };

  return record?.isSeller ? (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            {`Cardholder Information`}
          </Typography>
          <Card>
            <CardContent>
              <CustomTextField
                label="Name"
                source="cardDetails.cardholder.name"
                record={record}
              />
              <CustomTextField
                label="Email"
                source="cardDetails.cardholder.email"
                record={record}
              />
              <CustomTextField
                label="Phone Number"
                source="cardDetails.cardholder.phone_number"
                record={record}
              />
              <CustomTextField
                label="Type of Card"
                source="cardDetails.type"
                record={record}
              />
              <CustomTextField
                label="Type"
                source="cardDetails.cardholder.type"
                record={record}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            {`Billing Address`}
          </Typography>
          <Card>
            <CardContent>
              <CustomTextField
                label="Address Line 1"
                source="cardDetails.cardholder.billing.address.line1"
                record={record}
              />
              <CustomTextField
                label="Address Line 2"
                source="cardDetails.cardholder.billing.address.line2"
                record={record}
              />
              <CustomTextField
                label="City"
                source="cardDetails.cardholder.billing.address.city"
                record={record}
              />
              <CustomTextField
                label="Postal Code/Zip Code"
                source="cardDetails.cardholder.billing.address.postal_code"
                record={record}
              />
              <CustomTextField
                label="State/Province"
                source="cardDetails.cardholder.billing.address.state"
                record={record}
              />
              <CustomTextField
                label="Country"
                source="cardDetails.cardholder.billing.address.country"
                record={record}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            {`Shipping Address`}
          </Typography>
          <Card>
            <CardContent>
              <CustomTextField
                label="Address Line 1"
                source="cardDetails.shipping.address.line1"
                record={record}
              />
              <CustomTextField
                label="Address Line 2"
                source="cardDetails.shipping.address.line2"
                record={record}
              />
              <CustomTextField
                label="City"
                source="cardDetails.shipping.address.city"
                record={record}
              />
              <CustomTextField
                label="Postal Code/Zip Code"
                source="cardDetails.shipping.address.postal_code"
                record={record}
              />
              <CustomTextField
                label="State/Province"
                source="cardDetails.shipping.address.state"
                record={record}
              />
              <CustomTextField
                label="Country"
                source="cardDetails.shipping.address.country"
                record={record}
              />
              <Spacer />
              <CustomTextField
                label="Service"
                source="cardDetails.shipping.service"
                record={record}
              />
              <CustomTextField
                label="Shipping Type"
                source="cardDetails.shipping.type"
                record={record}
              />
              <CustomTextField
                label="Carrier"
                source="cardDetails.shipping.carrier"
                record={record}
              />
              <CustomTextField
                label="Tracking #"
                source="cardDetails.shipping.tracking_number"
                record={record}
              />
              <CustomTextField
                label="Status"
                source="cardDetails.shipping.status"
                record={record}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            {`Card Details`}
          </Typography>
          <Card>
            <CardContent>
              <CustomTextField
                label="Cardholder Name"
                source="cardDetails.cardholder.name"
                record={record}
              />
              <CustomTextField
                label="Expiry"
                value={`${record.cardDetails?.exp_month}/${record.cardDetails?.exp_year}`}
              />
              <CustomTextField
                label="Cardholder Number"
                value={`********${record.cardDetails?.last4}`}
              />
              <Spacer />
              <CustomTextField
                label="Status"
                source="cardDetails.status"
                record={record}
              />
              <CustomTextField
                label="Current Amount"
                source="cardDetails.spending_controls.spending_limits[0].amount"
                currency={true}
                record={record}
              />
              <CustomTextField
                label="Spending Limit Interval"
                source="cardDetails.spending_controls.spending_limits[0].interval"
                record={record}
              />

              <Box paddingTop={2} marginLeft={1} textAlign="left">
                <Button
                  className={buttonClasses.saveButton}
                  disabled={isUpdating}
                  onClick={activateCard}
                >
                  {record?.cardDetails?.status === "active"
                    ? "Deactivate Card"
                    : "Activate Card"}
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  ) : null;
};
