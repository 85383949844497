import React from "react";
import TextField from "@material-ui/core/TextField";

export interface FieldState<FieldValue> {
  active?: boolean;
  blur: () => void;
  change: ( value: FieldValue | undefined ) => void;
  data?: AnyObject;
  dirty?: boolean;
  dirtySinceLastSubmit?: boolean;
  error?: unknown;
  focus: () => void;
  initial?: FieldValue;
  invalid?: boolean;
  length?: number;
  modified?: boolean;
  modifiedSinceLastSubmit?: boolean;
  name: string;
  pristine?: boolean;
  submitError?: unknown;
  submitFailed?: boolean;
  submitSucceeded?: boolean;
  submitting?: boolean;
  touched?: boolean;
  valid?: boolean;
  validating?: boolean;
  value?: FieldValue;
  visited?: boolean;
}

interface AnyObject {
  [key: string]: unknown;
}

interface FieldInputProperties<FieldValue, T extends HTMLElement = HTMLElement>
  extends AnyObject {
  name: string;
  onBlur: ( event?: React.FocusEvent<T> ) => void;
  onChange: ( event: React.ChangeEvent<T> | any ) => void;
  onFocus: ( event?: React.FocusEvent<T> ) => void;
  type?: string;
  value: FieldValue;
  checked?: boolean;
  multiple?: boolean;
}

export type FieldMetaState<FieldValue> = Pick<
  FieldState<FieldValue>,
  Exclude<
    keyof FieldState<FieldValue>,
    "blur" | "change" | "focus" | "name" | "value"
  >
>;

export interface FieldRenderProperties<
  FieldValue,
  T extends HTMLElement = HTMLElement
> {
  input: FieldInputProperties<FieldValue, T>;
  meta: FieldMetaState<FieldValue>;
  [otherProp: string]: unknown;
}

export const FinalFormField: React.FC<FieldRenderProperties<
  any,
  HTMLElement
>> = ( {
  input: { name, onChange, value, ...restInput },
  meta,
  ...rest
} ): JSX.Element => (
  <TextField
    {...rest}
    name={name}
    helperText={meta.error && meta.touched ? meta.error : meta.submitError}
    error={meta.submitError && meta.touched}
    inputProps={restInput}
    onChange={onChange}
    value={value}
  />
);
