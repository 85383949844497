import { fetchUtils, DataProvider, GetListParams, UpdateParams } from "ra-core";
import { BooleanRoles, hasPermissions } from "../ts/interfaces/role-interface";
import { authHeader, getParsedToken } from "../utils/auth-header";
import { getApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { Media } from "../ts/interfaces/product-inventory-interface";
import { getFile } from "@shopthing-opn-shared/admin-dashboard";
import {
  FilterInputSourceName,
  PinnedOptionsChoices
} from "../components/events/list";

const resourceMap = {
  settings: {
    id: () => null,
    list: () => "/api/settings/",
    getOne: () => "/api/settings",
  },
  orders: {
    id: () => "orderId",
    list: () => "/api/admin-v2/orders/list",
    create: () => "/api/order-v2/manual-create",
    sellerList: () => `/api/seller/orders/list`,
    getOne: () => "/api/admin/orders/retrieve",
    sellerGetOne: () => "/api/seller/orders/retrieve",
    getReturnHistory: ( id: string ) => `/api/order-v2/${id}/return-history`,
    getDeliveryHistory: ( id: string ) => `/api/order-v2/${id}/delivery-history`,
    getFailedTransferHistory: ( id: string ) =>
      `/api/order-v2/${id}/failed-transfer-history`,
  },
  "order-authentication": {
    id: () => `orderAuthentication`,
    update: ( id: string ) => `/api/admin-v2/orders/${id}/authentication`,
  },
  events: {
    id: () => null,
    list: () => "/api/events/list",
    getOne: ( id: string ) => `/api/events/${id}/retrieve`,
    update: ( id: string ) => `/api/events/${id}/update`,
  },
  "event-product-edit": {
    id: () => null,
    getOne: () => `/api/admin/products/retrieve`,
    update: ( id: string ) => `/api/products/${id}`,
  },
  "events-live-and-upcoming": {
    id: () => null,
    list: () => "/api/events/live-and-upcoming/list",
  },
  users: {
    id: () => null,
    list: () => "/api/admin-v2/list-users",
    getOne: ( id: string ) => `/api/users/${id}/retrieve`,
    deactivateShopper: ( id: string ) => `/api/sellers/${id}/deactivate`,
  },
  coupons: {
    id: () => null,
    list: () => "/api/admin-v2/list-coupons",
  },
  membership: {
    id: () => null,
    getManyReference: ( id: string ) =>
      `/api/users/${id}/membership-subscriptions`,
  },
  buyers: {
    id: () => null,
    list: () => "/api/admin-v2/users/search-by-name",
    getOne: () => null,
  },
  authenticatedUser: {
    id: () => null,
    list: () => "/api/admin-v2/list-users",
    getOne: ( id: string ) => `/api/users/${id}/retrieve`,
  },
  reconciliation: {
    id: () => null,
    list: () => "/api/admin-v2/list-users",
  },
  "event-products": {
    id: () => null,
    getManyReference: ( id: string ) => `/api/events/${id}/products`,
  },
  editorials: {
    id: () => null,
    list: () => "/api/editorials/list",
    getOne: ( id: string ) => `/api/editorials/${id}/retrieve`,
    create: () => "/api/editorials/create",
  },
  "collaboration-events": {
    id: () => null,
    getOne: ( id: string ) => `/api/collaboration-events/${id}/retrieve`,
    list: () => "/api/collaboration-events/list",
    create: () => "/api/collaboration-events/create",
    delete: ( id: string ) => `/api/collaboration-events/${id}/delete`,
  },
  "collaboration-event-events": {
    id: () => null,
    getManyReference: ( id: string ) => `/api/collaboration-events/${id}/events`,
  },
  products: {
    id: () => null,
    getManyReference: () => "/api/admin/products/list",
  },
  "products-clone": {
    id: () => null,
    update: () => `/api/admin-v2/orders/clone-for-resale`,
  },
  "potential-bundles": {
    id: () => "null",
    getOne: ( id: string ) => `/api/fulfillment/potential-bundles/${id}/retrieve`,
    list: () => "/api/fulfillment/potential-bundles/list",
    update: ( id: string ) => `/api/order-v2/${id}/convert-pickup-to-delivery`,
  },
  "potential-bundles-for-order": {
    id: () => "null",
    getOne: ( id: string ) =>
      `/api/order-v2/${id}/get-potential-bundles-for-order/`,
  },
  "pickup-orders": {
    id: () => "orderId",
    getOne: () => "/api/admin/orders/retrieve",
    list: () => "/api/fulfillment/pickup-orders/list",
    update: () => "/api/seller/orders/fulfill",
  },
  "fulfilled-orders": {
    id: () => "orderId",
    list: () => "/api/fulfillment/fulfilled-orders/list",
    getOne: () => "/api/admin/orders/retrieve",
    sellerGetOne: () => "/api/seller/orders/retrieve",
  },
  "bundled-orders": {
    id: () => "orderBundleId",
    list: () => "/api/bundledOrders/list",
    create: () => "/api/order-v2/create-bundle",
    update: () => "/api/order-v2/fulfill",
    getOne: () => "",
  },
  "manual-review": {
    id: () => null,
    list: () => "/api/admin-v2/orders/list",
    sellerList: () => `/api/seller/manual-review/list`,
    getOne: () => "/api/admin/orders/retrieve",
    update: ( id: string ) => `/api/users/${id}/pass-fulfillment-review`,
  },
  "order-review": {
    id: () => null,
    list: () => "/api/reviews/list",
    getOne: ( id: string ) => `/api/reviews/${id}`,
    update: ( id: string ) => `/api/reviews/${id}`,
  },
  "seller-messages": {
    id: () => null,
    getOne: ( id: string ) => `/api/chat/seller-messages/${id}`,
    list: () => "/api/chat/list-seller-messages",
    createSellerMessage: () => "/api/chat/message",
  },
  "chat-reports": {
    id: () => null,
    list: () => "/api/chat/reports",
    getOne: ( id: string ) => `/api/chat/reports/${id}`,
    update: ( id: string ) => `/api/chat/reports/${id}`,
    chatMessages: ( id: string ) => `/api/chat/${id}/messages-list`,
  },
  "bundled-orders-proxy": {
    id: () => null,
    getOne: ( id: string ) => `/api/bundledOrders/${id}/list-orders`,
  },
  "shipping-labels": {
    id: () => null,
    getOne: () => "/api/admin/orders/shipping/label/rates",
    create: () => "/api/admin/orders/shipping/label/create",
  },
  "return-shipping-labels": {
    id: () => null,
    getOne: () => "/api/admin/orders/shipping/return-label/rates",
    create: () => "/api/admin/orders/shipping/return-label/create",
  },
  "authentication-shipping-labels": {
    id: () => null,
    getOne: () => "/api/admin/orders/shipping/authentication-label/rates",
    create: () => "/api/admin/orders/shipping/authentication-label/create",
  },
  "second-closet": {
    id: () => null,
    list: () => null,
    create: () => "/api/second-closet/fulfill",
  },
  reports: {
    id: () => null,
    list: () => null,
    create: () => "/api/admin-v2/report",
  },
  "generate-other-categories-report": {
    id: () => null,
    list: () => null,
    create: () => "/api/report/categories",
  },
  dashboards: {
    id: () => null,
    realTimeOrderStatus: () => "/api/admin-v2/real-time-order-status",
  },
  "recommendation-override": {
    id: () => null,
    list: () => "/api/events/live-and-upcoming/list",
  },
  "recommendation-override-products": {
    id: () => null,
    list: ( id: string ) => `/api/events/live-and-upcoming/${id}/products`,
    getManyReference: ( id: string ) =>
      `/api/events/live-and-upcoming/${id}/products`,
  },
  "product-inventory-items": {
    id: () => null,
    list: () => "/api/admin/product-inventory-items/list-items",
    getOne: ( id: string ) => `/api/admin/product-inventory-items/${id}/retrieve`,
    addToEvent: ( id: string ) => `/api/admin/product-inventory-items/${id}`,
    removeFromEvent: ( id: string ) => `/api/admin/product-inventory-items/${id}`,
    releaseProduct: ( id: string ) => `/api/admin/product-inventory-items/${id}`,
    getEditHistory: ( id: string ) =>
      `/api/admin/product-inventory-items/${id}/retrieve/edit-history`,
    update: ( id: string ) => `/api/admin/product-inventory-items/${id}/edit`,
    create: () => "/api/admin/product-inventory-items/create",
  },
  "return-reasons": {
    id: () => null,
    list: ( type: string ) => `/api/return-reasons/${type}`,
    getManyReference: ( type: string ) => `/api/return-reasons/${type}`,
  },
  "shopper-markets": {
    id: () => null,
    list: () => `/api/shopper-markets`,
  },
  partnerships: {
    id: () => null,
    list: () => `/api/event-partnerships`,
  },
  "get-tags": {
    id: () => null,
    list: () => `/api/products/tags`,
  },
  "return-locations": {
    id: () => null,
    list: () => `/api/return-locations`,
  },
  categories: {
    id: () => null,
    list: () => "/api/products/categories",
  },
  brands: {
    id: () => null,
    list: () => "/api/products/brands",
  },
  cards: {
    id: () => null,
    create: () => "/api/sellers/add-credit-card",
    list: ( id: string ) => `/api/users/${id}/list-credit-cards`,
    delete: ( id: string ) => `/api/users/${id}/delete-credit-card`,
  },
  "events-rank": {
    id: () => null,
    list: () => `/api/admin-v2/list-events-to-rank`,
    //Due to use of the create view
    create: () => `/api/admin-v2/update-events-rank`,
  },
  announcements: {
    id: () => null,
    list: () => "/api/announcements/list",
    create: () => "/api/announcements/create",
    getOne: ( id ) => `/api/announcements/${id}/retrieve`,
    update: ( id ) => `/api/announcements/${id}/update`,
  },
  signedUrl: {
    id: () => null,
    getSignedUrl: () => "/api/filestore/get-signed-url",
  },
} as {
  [key: string]: {
    id: () => string | null;
    [key: string]: ( argument0?: string ) => string;
  };
};

const updateMap = {
  "orders-create-fulfillment": ( id: string ) =>
    `/api/order-v2/${id}/fulfillments`,
  "orders-update-fulfillment": ( id: string ) =>
  {
    const compositeId = id.split( "-" );
    const orderId = compositeId[0];
    const fulfillmentId = compositeId[1];
    return `/api/order-v2/${orderId}/fulfillments/${fulfillmentId}`;
  },
  "bundled-orders": () => "/api/order-v2/fulfill",
  "potential-bundles": ( id: string ) =>
    `/api/order-v2/${id}/convert-pickup-to-delivery`,
  "pickup-orders": () => "/api/seller/orders/fulfill",
  "orders-refund": () => `/api/admin/orders/refund`,
  "orders-fulfill": () => "/api/seller/orders/fulfill",
  orders: () => "/api/order/update",
  users: ( id: string ) => `/api/users/${id}/update`,
  issueCreditCard: ( id: string ) => `/api/users/${id}/issuing/card/create`,
  reconciliation: ( id: string ) => `/api/stripe/accounts/users/${id}/transfers`,
  editorials: ( id: string ) => `/api/editorials/${id}/update`,
  "collaboration-events": ( id: string ) =>
    `/api/collaboration-events/${id}/update`,
  "editorial-removal": ( id: string ) => `/api/editorials/${id}/remove-products`,
  "editorial-addition": ( id: string ) => `/api/editorials/${id}/add-products`,
  settings: ( id: string ) => `/api/settings/${id}/update`,
  events: ( id: string ) => `/api/events/${id}/update`,
  manifests: () => "/api/fulfillment/manifest/create",
  "partner-manifests": () => "/api/fulfillment/partner/manifest/create",
  "manual-review": ( id: string ) => `/api/users/${id}/pass-fulfillment-review`,
  "order-review": ( id: string ) => `/api/reviews/${id}`,
  "chat-reports": ( id: string ) => `/api/chat/reports/${id}`,
  "update-issued-card": ( id: string ) => `/api/users/${id}/issuing/card/update`,
  "add-note-to-order": () => "/api/order/update/note",
  "resolve-dispute": ( id: string ) =>
    `/api/admin-v2/order/${id}/resolve-dispute`,
  "resolve-failed-transfer": ( id: string ) =>
    `/api/admin-v2/order/${id}/complete-transfer-failure`,
  "add-note-to-transfer-failure": ( id: string ) =>
    `/api/admin-v2/order/${id}/transfer-failure-note`,
  "products-clone": () => `/api/admin-v2/orders/clone-for-resale`,
  "recommendation-override-products": () =>
    `/api/admin-v2/override-rec-products`,
  "initiate-return": ( id: string ) => `/api/order-v2/${id}/initiate-return`,
  "resolve-return": ( id: string ) => `/api/order-v2/${id}/resolve-return`,
  return: ( id: string ) => `/api/admin/product-inventory-items/${id}`,
  cards: () => "/api/sellers/update-shopper-subscription-payment",
  "product-inventory-items": ( id: string ) =>
    `/api/admin/product-inventory-items/${id}/edit`,
  "event-product-edit": ( id: string ) => `/api/products/${id}`,
  announcements: ( id: string ) => `/api/announcements/${id}/update`,
  "order-authentication": ( id: string ) =>
    `/api/admin-v2/orders/${id}/authentication`,
} as { [key: string]: ( argument0: string ) => string };

interface Sort {
  querySortValue: string;
  querySortDirection: string;
}

interface ListParameterBody {
  id: string;
  // Note this can be extended as necessary
  additionalParameters: {
    [key: string]: string | number | boolean | Sort[];
  };
}

interface Query {
  name: string;
  operator: "==" | "<" | ">" | "<=" | ">=" | "in" | "array-contains";
  value: unknown;
}

function flattenFilters( name: string, value: unknown ): Query[]
{
  // If we're checking a primitive or non-dictionary object return the value as is. Note this is not an exhaustive list so it may need to be updated with new checks
  if (
    !value ||
    typeof value !== "object" ||
    Array.isArray( value ) ||
    value.constructor !== Object
  )
  {
    return [{ name, operator: "==", value }];
  }

  const entries = Object.entries( value );
  let filters: Query[] = [];
  for ( const [key, value] of entries )
  {
    filters = filters.concat( flattenFilters( `${name}.${key}`, value ) );
  }

  return filters;
}

async function handleError<T>( error: { status: number }, callback: () => T )
{
  if ( error.status === 403 || error.status === 401 )
  {
    const defaultApp = getApp();
    const defaultAuth = getAuth( defaultApp );
    if ( localStorage.getItem( "usertoken" ) && defaultAuth.currentUser )
    {
      const newToken = await defaultAuth.currentUser.getIdToken( true );
      if ( newToken )
      {
        localStorage.setItem( "usertoken", newToken );
        return callback();
      }
    }
  }
  throw error;
}

export const dataProvider = (
  apiUrl: string,
  httpClient = fetchUtils.fetchJson
): DataProvider => ( {
  async getList( resource, parameters: GetListParams & ListParameterBody )
  {
    try
    {
      let url = `${apiUrl}${resourceMap[resource].list()}`;
      const { page, perPage } = parameters.pagination;
      const filters = parameters.filter;
      const sortDirection = parameters.sort?.order.toLowerCase();
      let sortField = parameters.sort?.field;
      let query: Query[] = [];

      for ( let [name, value] of Object.entries( filters ) )
      {
        if ( name === FilterInputSourceName.pinned )
        {
          name = "isPinned";
          value = value === PinnedOptionsChoices.yes;
        }

        const queryValue = flattenFilters( name, value );
        query = query.concat( queryValue );
      }

      const additionalParameters = parameters.additionalParameters || {};

      if ( resourceMap[resource].list() === null )
      {
        return {
          data: [],
          total: 0,
        };
      }
      else if ( resource === "reconciliation" )
      {
        additionalParameters.isSeller = true;
      }
      else if ( resource === "editorials" )
      {
        query = filters;
      }
      else if ( resource === "users" )
      {
        query = [];
        additionalParameters.isSeller =
          filters.isSeller != null ? filters.isSeller : true;

        if ( filters.hasMessagesFromShopThing === true )
        {
          query.push( {
            name: "hasMessagesFromShopThing",
            operator: "==",
            value: true,
          } );
        }

        // sort seller by sign up date
        if ( additionalParameters.isSeller === true )
        {
          additionalParameters.sortArray = [
            {
              querySortValue: sortField || "timestamps.created",
              querySortDirection: sortDirection || "desc",
            },
          ];
        }
      }
      else if ( resource === "buyers" )
      {
        query = filters;
      }
      else if ( resource === "events" )
      {
        query = query.map( ( item ) =>
        {
          if ( item.name === "currentDate" )
          {
            return {
              name: "startDate",
              operator: "<=",
              value: filters.currentDate,
            };
          }
          else
          {
            return item;
          }
        } );
      }
      else if ( resource === "recommendation-override" )
      {
        additionalParameters.isRecommendationOverride = true;

        const startDate = query.find( ( item ) => item.name === "startDate" );
        const endDate = query.find( ( item ) => item.name === "endDate" );
        const isClosetSale = query.find( ( item ) => item.name === "isClosetSale" );

        if ( startDate && endDate )
        {
          query = query.filter(
            ( item ) =>
              item.name !== "startDate" &&
              item.name !== "endDate" &&
              item.name !== "isClosetSale"
          );

          additionalParameters.startDate = startDate.value as string;
          additionalParameters.endDate = endDate.value as string;
          additionalParameters.isClosetSale = isClosetSale.value as boolean;
        }
      }
      else if ( resource.startsWith( "return-reasons" ) )
      {
        if ( parameters.filter.type )
        {
          url = `${apiUrl}${resourceMap[resource].getManyReference(
            String( parameters.filter.type )
          )}`;

          const options = {
            headers: await authHeader(),
            method: "get",
          };

          const { json } = await httpClient( url, options );
          return {
            data: ( json.data as string[] ).map( ( reason ) => ( {
              id: reason,
              name: reason,
            } ) ),
            total: 1,
          };
        }
        else
        {
          return {
            data: [],
            total: 1,
          };
        }
      }
      else if ( resource.startsWith( "recommendation-override-products" ) )
      {
        if ( parameters.filter.id )
        {
          url = `${apiUrl}${resourceMap[resource].list(
            String( parameters.filter.id )
          )}`;

          const options = {
            headers: await authHeader(),
            method: "post",
            body: JSON.stringify( {
              limit: perPage,
              offset: ( page - 1 ) * perPage,
            } ),
          };

          const { json } = await httpClient( url, options );
          return {
            data: json.data.products,
            total: ( page - 1 ) * perPage + json.data.products.length + 1, // total number is unknown from firestore
          };
        }
        else
        {
          return {
            data: [],
            total: 1,
          };
        }
      }
      else if ( resource === "orders" )
      {
        let isSellerList = false;

        const parsedToken = await getParsedToken();
        // If the requesting user is missing permissions to view all orders, only display their own
        if (
          !hasPermissions(
            [
              BooleanRoles.FulfillmentReadOnly,
              BooleanRoles.Fulfillment,
              BooleanRoles.CustomerService,
              BooleanRoles.RefundCustomerService,
              BooleanRoles.Admin,
            ],
            parsedToken
          )
        )
        {
          isSellerList = true;
          url = `${apiUrl}${resourceMap[resource].sellerList()}`;
          query = null;
          if ( filters?.status )
          {
            additionalParameters.filter = filters.status;
          }
          if ( filters?.seller?.event )
          {
            additionalParameters.eventId = filters.seller.event;
          }
          if ( filters?.specifications?.currency )
          {
            additionalParameters.currency = filters.specifications.currency;
          }
        }

        if ( sortField === "cart.items[0].brandName" )
        {
          sortField = "sortOptions.product.brandName";
        }

        if ( sortDirection || sortField )
        {
          additionalParameters.sortArray = [
            {
              querySortValue: sortField || "timestamps.created",
              querySortDirection: sortDirection || "desc",
            },
          ];

          if (
            sortField === "customer.vip" ||
            sortField === "sortOptions.product.brandName" ||
            sortField === "logistics.option"
          )
          {
            // Add secondary sort
            additionalParameters.sortArray.push( {
              querySortValue: "timestamps.created",
              querySortDirection: "desc",
            } );
          }

          if ( sortField === "timestamps.paid" )
          {
            // If status does not exist add a default filter. Otherwise use the existing value.
            const statusFilter: Query[] = query.filter(
              ( item: Query ) => item.name === "status"
            );

            let isNotPaidStatus = false;
            if ( statusFilter.length === 0 )
            {
              query.push( {
                name: "status",
                operator: "==",
                value: "paid",
              } );
            }
            else
            {
              // Expect only a single status filter selected
              const statusValue = statusFilter[0].value;
              if ( Array.isArray( statusValue ) )
              {
                isNotPaidStatus =
                  statusValue.includes( "fulfilled" ) ||
                  statusValue.includes( "refunded" ) ||
                  statusValue.includes( "confirmed" ) ||
                  statusValue.includes( "pending" ) ||
                  statusValue.includes( "created" );
              }
              else if ( typeof statusValue === "string" )
              {
                isNotPaidStatus = [
                  "fulfilled",
                  "refunded",
                  "confirmed",
                  "pending",
                  "created",
                ].includes( statusValue );
              }
            }

            if ( isNotPaidStatus )
            {
              // override if # of days in paid is requested
              query[0].value = "paid";
            }
          }
        }
        // Add custom filter for confirmed
        if ( filters?.timestamps?.confirmed )
        {
          const currentDate = new Date();
          const pastDate = currentDate.setDate(
            currentDate.getDate() - filters.timestamps.confirmed
          );

          // If status does not exist add a default filter.  Otherwise use the existing value.
          const statusFilter: Query[] = query.filter(
            ( item: Query ) => item.name === "status"
          );

          let isFulfilledOrRefundedStatus = false;
          if ( statusFilter.length === 0 )
          {
            query.push( {
              name: "status",
              operator: "in",
              value: ["confirmed", "pending"],
            } );
          }
          else
          {
            // Expect only a single status filter selected
            const statusValue = statusFilter[0].value;
            if ( Array.isArray( statusValue ) )
            {
              isFulfilledOrRefundedStatus =
                statusValue.includes( "fulfilled" ) ||
                statusValue.includes( "refunded" );
            }
            else if ( typeof statusValue === "string" )
            {
              isFulfilledOrRefundedStatus = ["fulfilled", "refunded"].includes(
                statusValue
              );
            }
          }

          query = query
            // Remove timestamps.confirmed if the status is fulfilled or refunded
            .filter(
              ( item ) =>
                !isFulfilledOrRefundedStatus ||
                item.name !== "timestamps.confirmed"
            )
            // Override the flattened confirmed timestamp value
            .map( ( item ) =>
            {
              if ( item.name === "timestamps.confirmed" )
              {
                return {
                  name: "timestamps.confirmed",
                  operator: "<=",
                  value: pastDate,
                };
              }
              else
              {
                return item;
              }
            } );

          // Using an inequality filter requires the first sort to be the inequality (in this case confirmed time)
          additionalParameters.sortValue = "timestamps.confirmed";
          additionalParameters.sortArray = [
            {
              querySortValue: "timestamps.confirmed",
              querySortDirection: "desc",
            },
          ];
        }

        if (
          isSellerList &&
          typeof additionalParameters.sortArray === "object" &&
          additionalParameters.sortArray.length > 0
        )
        {
          // Assumption here is that it's a single sort
          additionalParameters.sortDirection =
            additionalParameters.sortArray[0].querySortDirection;
          additionalParameters.sortValue =
            additionalParameters.sortArray[0].querySortValue;
          delete additionalParameters.sortArray;
        }
      }
      else if ( resource === "settings" )
      {
        // Settings always returns exactly 1 document (main settings)
        query = null;
        const options = {
          headers: await authHeader(),
          method: "post",
        };

        const { json } = await httpClient( url, options );
        return {
          data: [{ id: "main", data: json.data }],
          total: 1,
        };
      }
      else if ( resource === "bundled-orders" )
      {
        const body = {
          limit: perPage,
          offset: ( page - 1 ) * perPage,
          ...filters,
        };
        const options = {
          headers: await authHeader(),
          method: "post",
          body: JSON.stringify( body ),
        };
        const { json } = await httpClient( url, options );
        return {
          data: json.data.bundledOrders,
          total: ( page - 1 ) * perPage + json.data.bundledOrders.length + 1, // total number is unknown from firestore
        };
      }
      else if ( resource === "manual-review" )
      {
        const parsedToken = await getParsedToken();
        // If the requesting user is missing permissions to view all orders, only display their own
        if (
          !hasPermissions(
            [
              BooleanRoles.FulfillmentReadOnly,
              BooleanRoles.Fulfillment,
              BooleanRoles.CustomerService,
              BooleanRoles.RefundCustomerService,
              BooleanRoles.Admin,
            ],
            parsedToken
          ) &&
          hasPermissions(
            [BooleanRoles.Seller, BooleanRoles.Partner],
            parsedToken
          )
        )
        {
          url = `${apiUrl}${resourceMap[resource].sellerList()}`;
          const body = {
            limit: perPage,
            offset: ( page - 1 ) * perPage,
            query: [
              {
                name: "returns.status",
                operator: "array-contains-any",
                value: ["initiated"],
              },
            ],
            sortArray: [
              {
                querySortValue: "timestamps.paid",
                querySortDirection: "desc",
              },
            ],
          };

          const options = {
            headers: await authHeader(),
            method: "post",
            body: JSON.stringify( body ),
          };
          const { json } = await httpClient( url, options );
          return {
            data: json.data,
            total:
              json.data.length > 0
                ? ( page - 1 ) * perPage + json.data.length + 1
                : ( page - 1 ) * perPage + json.data.length, // total number is unknown from firestore
          };
        }
        else
        {
          const orderType = filters.orderType;

          let body: {
            type?: string;
            limit: number;
            offset: number;
            query: {
              name: string;
              operator: string;
              value: boolean | string | string[];
            }[];
            sortArray: { querySortValue: string; querySortDirection: string }[];
          } = {
            limit: perPage,
            offset: ( page - 1 ) * perPage,
            query: [
              {
                name: "billingAndShippingMatch",
                operator: "==",
                value: false,
              },
              {
                name: "status",
                operator: "in",
                value: ["confirmed", "pending"],
              },
              {
                name: "customer.passedFulfillmentReview",
                operator: "==",
                value: false,
              },
            ],
            sortArray: [
              {
                querySortValue: sortField || "timestamps.paid",
                querySortDirection: sortDirection || "desc",
              },
            ],
          };

          if ( orderType === "dispute" )
          {
            body = {
              limit: perPage,
              offset: ( page - 1 ) * perPage,
              query: [
                {
                  name: "latestDisputeStatus",
                  operator: "in",
                  value: [
                    "warning_needs_response",
                    "warning_under_review",
                    "warning_closed",
                    "needs_response",
                    "under_review",
                    "charge_refunded",
                    "fraudulent",
                    "won",
                    "lost",
                  ],
                },
                { name: "disputeResolved", operator: "==", value: false },
              ],
              sortArray: [
                {
                  querySortValue: sortField || "timestamps.paid",
                  querySortDirection: sortDirection || "desc",
                },
              ],
            };
          }

          if ( orderType === "initiated/accepted" )
          {
            body = {
              limit: perPage,
              offset: ( page - 1 ) * perPage,
              query: [
                {
                  name: "returns.status",
                  operator: "array-contains-any",
                  value: ["initiated", "accepted"],
                },
              ],
              sortArray: [
                {
                  querySortValue: "returns.status",
                  querySortDirection: "asc",
                },
              ],
            };

            if ( filters.sellerType )
            {
              body.query.push( {
                name: "sellerType",
                operator: "==",
                value: filters.sellerType,
              } );
            }
          }

          if ( orderType === "toBeAuthenticated" )
          {
            body = {
              type: orderType,
              limit: perPage,
              offset: ( page - 1 ) * perPage,
              query: [
                {
                  name: "authentication.status",
                  operator: "in",
                  value: ["pending", "rejected"],
                },
                {
                  name: "status",
                  operator: "==",
                  value: "confirmed",
                },
              ],
              sortArray: [
                {
                  querySortValue: "timestamps.confirmed",
                  querySortDirection: "desc",
                },
              ],
            };
          }

          if ( orderType === "transferFailure" )
          {
            body = {
              type: orderType,
              limit: perPage,
              offset: ( page - 1 ) * perPage,
              query: [
                {
                  name: "hasTransferFailure",
                  operator: "==",
                  value: true,
                },
              ],
              sortArray: [
                {
                  querySortValue: "timestamps.latestTransferFailure",
                  querySortDirection: "desc",
                },
              ],
            };
          }

          if ( sortField === "customer.vip" )
          {
            // Add secondary sort
            body.sortArray.push( {
              querySortValue: "timestamps.paid",
              querySortDirection: "desc",
            } );
          }

          const options = {
            headers: await authHeader(),
            method: "post",
            body: JSON.stringify( body ),
          };
          const { json } = await httpClient( url, options );
          return {
            data: json.data,
            total: ( page - 1 ) * perPage + json.data.length + 1, // total number is unknown from firestore
          };
        }
      }
      else if ( resource === "order-review" )
      {
        const reviewStatus = filters.reviewStatus;

        const body = {
          limit: perPage,
          offset: ( page - 1 ) * perPage,
          status: reviewStatus,
        };

        const options = {
          headers: await authHeader(),
          method: "post",
          body: JSON.stringify( body ),
        };
        const { json } = await httpClient( url, options );
        return {
          data: json.data,
          total: ( page - 1 ) * perPage + json.data.length + 1, // total number is unknown from firestore
        };
      }
      else if ( resource === "seller-messages" )
      {
        query = [];

        if ( filters.id )
        {
          query.push( {
            name: "receiverIds",
            operator: "array-contains",
            value: filters.id,
          } );
        }

        const body = {
          limit: perPage,
          offset: ( page - 1 ) * perPage,
          query,
        };

        const options = {
          headers: await authHeader(),
          method: "post",
          body: JSON.stringify( body ),
        };
        const { json } = await httpClient( url, options );
        return {
          data: json.data,
          total: ( page - 1 ) * perPage + json.data.length + 1, // total number is unknown from firestore
        };
      }
      else if ( resource === "chat-reports" )
      {
        const reportStatus = filters.chatReportStatus;

        const body = {
          limit: perPage,
          offset: ( page - 1 ) * perPage,
          status: reportStatus,
        };

        const options = {
          headers: await authHeader(),
          method: "post",
          body: JSON.stringify( body ),
        };
        const { json } = await httpClient( url, options );
        return {
          data: json.data,
          total: ( page - 1 ) * perPage + json.data.length + 1, // total number is unknown from firestore
        };
      }
      else if ( resource === "fulfilled-orders" )
      {
        if ( sortField === "cart.items[0].brandName" )
        {
          sortField = "sortOptions.product.brandName";
        }

        if ( sortDirection || sortField )
        {
          additionalParameters.sortArray = [
            {
              querySortValue: sortField || "timestamps.fulfilled",
              querySortDirection: sortDirection || "desc",
            },
          ];

          if (
            sortField === "customer.vip" ||
            sortField === "sortOptions.product.brandName" ||
            sortField === "logistics.option"
          )
          {
            // Add secondary sort
            additionalParameters.sortArray.push( {
              querySortValue: "timestamps.fulfilled",
              querySortDirection: "desc",
            } );
          }
        }
      }
      else if ( resource.startsWith( "product-inventory-items" ) )
      {
        query = query.map( ( item ) =>
        {
          if ( item.name === "tagIds" )
          {
            return {
              name: item.name,
              operator: "array-contains",
              value: item.value,
            };
          }
          else
          {
            return item;
          }
        } );

        //Default query for product inventory items list view
        query.push( {
          name: "status",
          operator: "in",
          value: ["available", "in-event"],
        } );
      }
      else if ( resource === "shopper-markets" )
      {
        const options = {
          headers: await authHeader(),
          method: "get",
        };

        const { json } = await httpClient( url, options );
        return {
          data: ( json.data as string[] ).map( ( market ) => ( {
            id: market,
            name: market,
          } ) ),
          total: 1,
        };
      }
      else if ( resource === "partnerships" )
      {
        const options = {
          headers: await authHeader(),
          method: "get",
        };
        const { json } = await httpClient( url, options );
        return {
          data: ( json.data as string[] ).map( ( partnership ) => ( {
            id: partnership,
            name: partnership,
          } ) ),
          total: 1,
        };
      }
      else if ( resource === "get-tags" )
      {
        const options = {
          headers: await authHeader(),
          method: "get",
        };
        const { json } = await httpClient( url, options );
        return {
          data: ( json.data.tags as { id: string; name: string }[] ).map(
            ( tag ) => ( {
              id: tag.id,
              name: tag.name,
            } )
          ),
          total: 1,
        };
      }
      else if ( resource === "return-locations" )
      {
        const options = {
          headers: await authHeader(),
          method: "get",
        };
        const { json } = await httpClient( url, options );
        const locationOptions = [];
        const fulfillmentCenters = Object.keys( json.data );
        const fulfillmentNameLookup: { [key: string]: string } = {
          TO: "Toronto",
          NJ: "New Jersey",
        };
        for ( const center of fulfillmentCenters )
        {
          const centerCategories = Object.keys( json.data[center] ).sort( ( a, b ) =>
            a.localeCompare( b )
          );
          locationOptions.push( {
            id: `${center}`,
            name: fulfillmentNameLookup[center] || center,
            disabled: true,
          } );
          for ( const category of centerCategories )
          {
            const formattedCategoryName = category
              .split( " " )
              .map(
                ( categoryWord ) =>
                  categoryWord.charAt( 0 ).toUpperCase() + categoryWord.slice( 1 )
              )
              .join( " " );
            locationOptions.push( {
              id: `${center}-${category}`,
              name: formattedCategoryName,
              disabled: true,
            } );
            locationOptions.push(
              ...json.data[center][category].map( ( name: string ) => ( {
                id: `${name}`,
                name,
              } ) )
            );
          }
        }
        return {
          data: locationOptions,
          total: locationOptions.length,
        };
      }
      else if ( resource === "categories" )
      {
        const options = {
          headers: await authHeader(),
          method: "get",
        };
        const { json } = await httpClient( url, options );
        return {
          data: json.data.categories,
          total: 1,
        };
      }
      else if ( resource === "brands" )
      {
        const options = {
          headers: await authHeader(),
          method: "get",
        };
        const { json } = await httpClient( url, options );
        return {
          data: ( json.data.brands as { id: string; name: string }[] ).map(
            ( brand ) => ( {
              id: brand.id,
              name: brand.name,
            } )
          ),
          total: 1,
        };
      }
      else if ( resource === "cards" )
      {
        const parsedToken = await getParsedToken();
        url = `${apiUrl}${resourceMap[resource].list(
          parsedToken.user_id.toString()
        )}`;
        const options = {
          headers: await authHeader(),
          method: "post",
          body: JSON.stringify( {
            limit: perPage,
            offset: ( page - 1 ) * perPage,
            useStripeShopperCustomer: true,
          } ),
        };

        const { json } = await httpClient( url, options );
        return {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          data: json.data.map( ( card ) => ( { id: card.source, ...card } ) ),
          total: ( page - 1 ) * perPage + json.data.length + 1, // total number is unknown from firestore
        };
      }
      else if ( resource === "events-rank" )
      {
        url = `${apiUrl}${resourceMap[resource].list()}`;
        const options = {
          headers: await authHeader(),
          method: "get",
        };

        const { json } = await httpClient( url, options );
        return {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          data: json.data.map( ( event ) => ( { id: event.id, ...event } ) ),
          total: ( page - 1 ) * perPage + json.data.length + 1, // total number is unknown from firestore
        };
      }
      const body: { [key: string]: unknown } = {
        // Different resources might not accept same body structure
        limit: perPage,
        offset: ( page - 1 ) * perPage,
        // additional api body values
        ...additionalParameters,
      };

      if ( query )
      {
        body.query = query;
      }

      const options = {
        headers: await authHeader(),
        method: "post",
        body: JSON.stringify( body ),
      };

      const { json } = await httpClient( url, options );

      // ensure 0 results with offsets still return correct total count
      const totalCount = ( page - 1 ) * perPage + json.data.length;
      const total =
        json.data.length > 0
          ? totalCount + 1 // total number is unknown from firestore, assume 1 more than current if non-empty
          : totalCount;
      return {
        data: json.data,
        total,
      };
    }
    catch ( error )
    {
      return handleError( error, () => this.getList( resource, parameters ) );
    }
  },

  async getOne( resource, parameter )
  {
    try
    {
      const idName = resourceMap[resource].id();
      const parsedHeader = await authHeader();
      const parsedToken = await getParsedToken();

      let url = `${apiUrl}${resourceMap[resource].getOne(
        parameter?.id?.toString()
      )}`;

      const options = {
        headers: parsedHeader,
        method: "post",
        body: "{}",
      };

      let body: { [key: string]: unknown } = {};
      if ( resource === "potential-bundles" )
      {
        body = {
          mode: "fullMode",
        };
      }
      else if ( resource === "authenticatedUser" )
      {
        url = `${apiUrl}${resourceMap[resource].getOne(
          parameter?.id?.toString() || parsedToken.user_id.toString()
        )}`;
        body = {};
      }
      else if ( resource === "orders" || resource === "fulfilled-orders" )
      {
        body = {
          [idName]: parameter.id.toString(),
          mode: "partial",
          fromDashboard: true,
        };

        // If the requesting user is missing permissions to retrieve orders with elevated permissions, fetch it as a seller
        if (
          !hasPermissions(
            [
              BooleanRoles.FulfillmentReadOnly,
              BooleanRoles.Fulfillment,
              BooleanRoles.CustomerService,
              BooleanRoles.RefundCustomerService,
              BooleanRoles.Admin,
            ],
            parsedToken
          )
        )
        {
          url = `${apiUrl}${resourceMap[resource].sellerGetOne()}`;
        }
      }
      else if ( idName )
      {
        body = { [idName]: parameter.id.toString() };
      }

      options.body = JSON.stringify( body );
      if ( resource === "shipping-labels" )
      {
        // tl;dr: useGetOne made sense for a single shipping-rates request, but doesn't allow multiple parameters in the interface,
        // however in practice you can pass objects. useCreate is being used by the purchaseLabel request
        options.body = JSON.stringify(
          JSON.parse( JSON.stringify( parameter ) ).id.requestData
        );
      }

      if ( resource === "return-shipping-labels" )
      {
        options.body = JSON.stringify(
          JSON.parse( JSON.stringify( parameter ) ).id.requestData
        );
      }

      if ( resource === "authentication-shipping-labels" )
      {
        options.body = JSON.stringify(
          JSON.parse( JSON.stringify( parameter ) ).id.requestData
        );
      }

      if ( resource === "bundled-orders" )
      {
        return { data: { id: "react-admin-required-id" } };
      }

      if ( resource === "event-product-edit" )
      {
        const { json } = await httpClient( url, {
          headers: parsedHeader,
          method: "post",
          body: JSON.stringify( {
            productId: parameter.id.toString(),
          } ),
        } );
        return {
          data: json.product,
        };
      }

      if ( resource === "settings" )
      {
        const { json } = await httpClient( url, options );
        return { data: { id: "main", ...json.data } };
      }

      if ( resource === "bundled-orders-proxy" )
      {
        const { json } = await httpClient( url, options );
        return { data: { id: "react-admin-required-id", ...json.data } };
      }

      if ( resource === "product-inventory-items" )
      {
        const { json } = await httpClient( url, options );
        return {
          data: {
            id: "react-admin-required-id",
            ...json.data,
            media: json.data.media.map( ( value: Media ) => ( {
              storage: {
                ...value,
                preview: value.mediaUrl,
              },
            } ) ),
          },
        };
      }

      if ( resource === "announcements" )
      {
        const { json } = await httpClient( url, options );

        const app = getApp();

        return {
          data: {
            ...json.data,
            media: await Promise.all(
              json.data.media.map(
                async ( item: { mediaPath: string; isVideo: boolean } ) => ( {
                  ...item,
                  storage: {
                    ...item,
                    type: item.isVideo ? "video" : "image",
                    preview: await getFile( item.mediaPath, app ),
                  },
                } )
              )
            ),
          },
        };
      }

      try
      {
        const { json } = await httpClient( url, options );
        if ( resource === "potential-bundles-for-order" )
        {
          if ( json.data.length === 0 )
          {
            throw new Error(
              `Order ${parameter.id.toString()} doesn't belong to a bundle`
            );
          }
          return {
            data: { ...json.data[0], id: json.data[0].bundleId },
          };
        }
        return {
          data: json.data,
        };
      }
      catch ( error )
      {
        const errorResponse = {
          status: error.status,
          message: error.body.message,
        };
        throw JSON.stringify( errorResponse );
      }
    }
    catch ( error )
    {
      return handleError( error, () => this.getOne( resource, parameter ) );
    }
  },

  getMany: async ( resource ) =>
  {
    console.warn(
      `No getManyReference resource defined for resource ${resource}`
    );
    return {
      data: [],
      total: 0,
    };
  },

  async getManyReference( resource, parameters )
  {
    try
    {
      const { page, perPage } = parameters.pagination;
      const filters = parameters.filter;
      let url: string;
      if ( resource === "event-products" )
      {
        url = `${apiUrl}${resourceMap[resource].getManyReference(
          String( parameters.id )
        )}`;
        const options = {
          headers: await authHeader(),
          method: "post",
          body: JSON.stringify( {
            ...filters,
            limit: perPage,
            offset: ( page - 1 ) * perPage,
          } ),
        };

        const { json } = await httpClient( url, options );
        return {
          data: json.data,
          total: ( page - 1 ) * perPage + json.data.length + 1, // total number is unknown from firestore
        };
      }
      else if ( resource === "collaboration-event-events" )
      {
        url = `${apiUrl}${resourceMap[resource].getManyReference(
          String( parameters.id )
        )}`;
        const options = {
          headers: await authHeader(),
          method: "post",
          body: JSON.stringify( {
            ...filters,
            limit: perPage,
            offset: ( page - 1 ) * perPage,
          } ),
        };

        const { json } = await httpClient( url, options );
        return {
          data: json.data,
          total: ( page - 1 ) * perPage + json.data.length + 1, // total number is unknown from firestore
        };
      }
      else if ( resource === "products" )
      {
        url = `${apiUrl}${resourceMap[resource].getManyReference(
          String( parameters.id )
        )}`;
        const options = {
          headers: await authHeader(),
          method: "post",
          body: JSON.stringify( {
            ...filters,
            limit: perPage,
            offset: ( page - 1 ) * perPage,
          } ),
        };

        const { json } = await httpClient( url, options );
        return {
          data: json.data,
          total: ( page - 1 ) * perPage + json.data.length + 1, // total number is unknown from firestore
        };
      }
      else if ( resource.startsWith( "return-reasons" ) )
      {
        if ( parameters.filter.type )
        {
          url = `${apiUrl}${resourceMap[resource].getManyReference(
            String( parameters.filter.type )
          )}`;

          const options = {
            headers: await authHeader(),
            method: "get",
          };

          const { json } = await httpClient( url, options );
          return {
            data: ( json.data as string[] ).map( ( reason ) => ( {
              id: reason,
              name: reason,
            } ) ),
            total: 1,
          };
        }
        else
        {
          return {
            data: [],
            total: 1,
          };
        }
      }
      else if ( resource.startsWith( "recommendation-override-products" ) )
      {
        if ( parameters.filter.id )
        {
          url = `${apiUrl}${resourceMap[resource].getManyReference(
            String( parameters.filter.id )
          )}`;

          const options = {
            headers: await authHeader(),
            method: "post",
            body: JSON.stringify( {
              limit: perPage,
              offset: ( page - 1 ) * perPage,
            } ),
          };

          const { json } = await httpClient( url, options );
          return {
            data: json.data.products,
            total: ( page - 1 ) * perPage + json.data.products.length + 1, // total number is unknown from firestore
          };
        }
        else
        {
          return {
            data: [],
            total: 1,
          };
        }
      }
      else if ( resource === "membership" )
      {
        url = `${apiUrl}${resourceMap[resource].getManyReference(
          String( parameters.id )
        )}`;

        const options = {
          headers: await authHeader(),
          method: "post",
          body: JSON.stringify( {
            ...filters,
            limit: perPage,
            offset: ( page - 1 ) * perPage,
          } ),
        };

        const { json } = await httpClient( url, options );

        const total =
          ( page - 1 ) * perPage +
          json.data.length +
          ( json.data.length > 0 && json.data.length % perPage === 0 ? 1 : 0 );

        return {
          data: json.data.map(
            (
              value: {
                membershipStatus: string;
                memberSince: Date;
                memberRenewalDate: Date;
                expiryDate: Date;
                type: string;
                price: number;
                currency: string;
              },
              index: number
            ) => ( {
              id: `id_${index}`,
              ...value,
            } )
          ),
          total,
        };
      }
      else
      {
        console.error(
          `No getManyReference resource defined for resource ${resource}`
        );
        return new Promise( ( resolve ) => resolve( "" as unknown as never ) );
      }
    }
    catch ( error )
    {
      return handleError( error, () =>
        this.getManyReference( resource, parameters )
      );
    }
  },

  async update( resource: string, parameter: UpdateParams )
  {
    try
    {
      const url = `${apiUrl}${updateMap[resource]( parameter.id?.toString() )}`;
      if ( resource === "orders" )
      {
        const update = {
          orderId: parameter.id,
          asSupport: true,
          updates: parameter.data.updates,
          become: parameter.data.authId,
        };
        const options = {
          headers: await authHeader(),
          method: "post",
          body: JSON.stringify( update ),
        };
        const { json } = await httpClient( url, options );
        return {
          data: json.data,
        };
      }
      else if ( resource === "event-product-edit" )
      {
        const options = {
          headers: await authHeader(),
          method: "post",
          body: JSON.stringify( parameter.data ),
        };
        const { json } = await httpClient( url, options );
        return {
          data: json.data,
        };
      }
      else if ( resource === "orders-refund" )
      {
        const options = {
          headers: await authHeader(),
          method: "post",
          body: JSON.stringify( {
            orderId: parameter.id,
            amount: parameter.data.amount,
            reverseTransfer: parameter.data.reverseTransfer,
            addProcessingFee: parameter.data.addProcessingFee,
            notes: parameter.data.note,
            refundCategory: parameter.data.refundCategory,
          } ),
        };
        const { json } = await httpClient( url, options );
        return {
          data: json.data,
        };
      }
      else if ( resource === "users" )
      {
        let usersUrl = url;
        if ( parameter.data.issueCreditCard )
        {
          usersUrl = `${apiUrl}${updateMap["issueCreditCard"](
            parameter.id.toString()
          )}`;
        }

        const options = {
          headers: await authHeader(),
          method: "post",
          body: JSON.stringify( parameter.data.updates ),
        };
        const { json } = await httpClient( usersUrl, options );
        return {
          data: {
            ...json.data,
            // This has no use but react admin updates requires an id
            id: "conciliationId",
          },
        };
      }
      else if ( resource === "reconciliation" )
      {
        const { sellerId, amount, currency, description } = parameter.data;

        const options = {
          headers: await authHeader(),
          method: "post",
          body: JSON.stringify( {
            amount,
            currency,
            description,
          } ),
        };
        const { json } = await httpClient(
          `${apiUrl}${updateMap[resource]( sellerId )}`,
          options
        );
        return {
          data: {
            ...json.data,
            // This has no use but react admin updates requires an id
            id: "conciliationId",
          },
        };
      }
      else if (
        resource === "orders-create-fulfillment" ||
        resource === "orders-update-fulfillment"
      )
      {
        const options = {
          headers: await authHeader(),
          method: "post",
          body: JSON.stringify( parameter.data ),
        };
        try
        {
          const { json } = await httpClient( url, options );
          return {
            data: json.data,
          };
        }
        catch ( error )
        {
          const errorResponse = {
            status: error.status,
            message: error.body.message,
          };
          throw errorResponse;
        }
      }
      else if ( resource === "orders-fulfill" )
      {
        const options = {
          headers: await authHeader(),
          method: "post",
          body: JSON.stringify( parameter.data ),
        };

        const { json } = await httpClient( url, options );
        return {
          data: {
            id: parameter.data.orderId,
            ...json.data,
          },
        };
      }
      else if ( resource === "editorials" )
      {
        try
        {
          const options = {
            headers: await authHeader(),
            method: "post",
            body: JSON.stringify( parameter.data.updates ),
          };

          const { json } = await httpClient( url, options );
          return {
            data: json.data,
          };
        }
        catch ( error )
        {
          const errorResponse = {
            status: error.status,
            message: error.body.message,
          };
          throw errorResponse;
        }
      }
      else if ( resource === "editorial-removal" )
      {
        try
        {
          const options = {
            headers: await authHeader(),
            method: "post",
            body: JSON.stringify( parameter.data ),
          };

          const { json } = await httpClient( url, options );
          return {
            data: json.data,
          };
        }
        catch ( error )
        {
          const errorResponse = {
            status: error.status,
            message: error.body.message,
          };
          throw errorResponse;
        }
      }
      else if ( resource === "editorial-addition" )
      {
        try
        {
          const options = {
            headers: await authHeader(),
            method: "post",
            body: JSON.stringify( parameter.data ),
          };

          const { json } = await httpClient( url, options );
          return {
            data: json.data,
          };
        }
        catch ( error )
        {
          const errorResponse = {
            status: error.status,
            message: error.body.message,
          };
          throw errorResponse;
        }
      }
      else if ( resource === "collaboration-events" )
      {
        try
        {
          const options = {
            headers: await authHeader(),
            method: "post",
            body: JSON.stringify( parameter.data ),
          };

          const { json } = await httpClient( url, options );
          return {
            data: json.data,
          };
        }
        catch ( error )
        {
          const errorResponse = {
            status: error.status,
            message: error.body.message,
          };
          throw errorResponse;
        }
      }
      else if ( resource === "settings" )
      {
        try
        {
          const options = {
            headers: await authHeader(),
            method: "post",
            body: JSON.stringify( parameter.data.updates ),
          };

          const { json } = await httpClient( url, options );
          return {
            data: json.data,
          };
        }
        catch ( error )
        {
          const errorResponse = {
            status: error.status,
            message: error.body.message,
          };
          throw errorResponse;
        }
      }
      else if ( resource === "bundled-orders" )
      {
        const idName = resourceMap[resource].id();
        const options = {
          headers: await authHeader(),
          method: "post",
          body: JSON.stringify( {
            [idName]: parameter.id,
            ...parameter.data,
          } ),
        };
        const { json } = await httpClient( url, options );
        return {
          data: json.data,
        };
      }
      else if ( resource === "manifests" || resource === "partner-manifests" )
      {
        const options = {
          headers: await authHeader(),
          method: "post",
          body: JSON.stringify( {
            ...parameter.data,
          } ),
        };

        const { json } = await httpClient( url, options );
        return {
          data: {
            id: json.data?.manifest?.id || "manifest-id",
            ...json.data,
          },
        };
      }
      else if ( resource === "manual-review" )
      {
        const options = {
          headers: await authHeader(),
          method: "post",
        };

        await httpClient( url, options );
        return {
          data: {
            id: "update-id",
          },
        };
      }
      else if ( resource === "order-review" )
      {
        const options = {
          headers: await authHeader(),
          method: "put",
          body: JSON.stringify( {
            ...parameter.data,
          } ),
        };

        await httpClient( url, options );
        return {
          data: {
            id: "update-id",
          },
        };
      }
      else if ( resource === "chat-reports" )
      {
        const options = {
          headers: await authHeader(),
          method: "put",
          body: JSON.stringify( {
            ...parameter.data,
          } ),
        };

        await httpClient( url, options );
        return {
          data: {
            id: "update-id",
          },
        };
      }
      else if ( resource === "resolve-dispute" )
      {
        const options = {
          headers: await authHeader(),
          method: "post",
          body: JSON.stringify( {
            ...parameter.data,
          } ),
        };
        const { json } = await httpClient( url, options );
        return {
          data: {
            ...json.data,
            id: "react-admin-required-id",
          },
        };
      }
      else if ( resource === "resolve-failed-transfer" )
      {
        const options = {
          headers: await authHeader(),
          method: "post",
          body: JSON.stringify( {
            ...parameter.data,
          } ),
        };
        const { json } = await httpClient( url, options );
        return {
          data: {
            ...json.data,
            id: "react-admin-required-id",
          },
        };
      }
      else if ( resource === "add-note-to-transfer-failure" )
      {
        const options = {
          headers: await authHeader(),
          method: "post",
          body: JSON.stringify( {
            ...parameter.data,
          } ),
        };
        const { json } = await httpClient( url, options );
        return {
          data: {
            ...json.data,
            id: "react-admin-required-id",
          },
        };
      }
      else if ( resource === "initiate-return" )
      {
        const options = {
          headers: await authHeader(),
          method: "post",
          body: JSON.stringify( {
            ...parameter.data,
          } ),
        };
        const { json } = await httpClient( url, options );
        return {
          data: {
            ...json.data,
            id: "react-admin-required-id",
          },
        };
      }
      else if ( resource === "return" )
      {
        const options = {
          headers: await authHeader(),
          method: "post",
          body: JSON.stringify( {
            ...parameter.data,
          } ),
        };
        const { json } = await httpClient( url, options );
        return {
          data: {
            ...json.data,
            id: "react-admin-required-id",
          },
        };
      }
      else if ( resource === "resolve-return" )
      {
        const options = {
          headers: await authHeader(),
          method: "post",
          body: JSON.stringify( {
            ...parameter.data,
          } ),
        };
        const { json } = await httpClient( url, options );
        return {
          data: {
            ...json.data,
            id: "react-admin-required-id",
          },
        };
      }
      else if ( resource === "pickup-orders" )
      {
        const idName = resourceMap[resource].id();
        const options = {
          headers: await authHeader(),
          method: "post",
          body: JSON.stringify( {
            [idName]: parameter.id,
            ...parameter.data,
          } ),
        };
        const { json } = await httpClient( url, options );
        return {
          data: { ...json.status, id: "react-admin-required-id" },
        };
      }
      else if ( resource === "potential-bundles" )
      {
        const options = {
          headers: await authHeader(),
          method: "post",
          body: JSON.stringify( {
            ...parameter.data,
          } ),
        };
        const { json } = await httpClient( url, options );
        return {
          data: {
            ...json.status,
            link: json.fulfillmentLink,
            id: "react-admin-required-id",
          },
        };
      }
      else if ( resource === "events" )
      {
        const options = {
          headers: await authHeader(),
          method: "post",
          body: JSON.stringify( {
            ...parameter.data,
          } ),
        };
        const { json } = await httpClient( url, options );
        return {
          data: json.data,
        };
      }
      else if ( resource === "update-issued-card" )
      {
        const options = {
          headers: await authHeader(),
          method: "post",
          body: JSON.stringify( {
            ...parameter.data,
          } ),
        };
        const { json } = await httpClient( url, options );
        return {
          data: {
            ...json.data,
            id: "react-admin-required-id",
          },
        };
      }
      else if ( resource === "cards" )
      {
        const options = {
          headers: await authHeader(),
          method: "post",
          body: JSON.stringify( {
            ...parameter.data,
          } ),
        };

        const { json } = await httpClient( url, options );
        return {
          data: {
            ...json.data,
            id: "cards-id",
          },
        };
      }
      else if ( resource === "add-note-to-order" )
      {
        const options = {
          headers: await authHeader(),
          method: "post",
          body: JSON.stringify( {
            ...parameter.data,
          } ),
        };
        const { json } = await httpClient( url, options );
        return {
          data: {
            ...json.data,
            id: "react-admin-required-id",
          },
        };
      }
      else if ( resource === "products-clone" )
      {
        const options = {
          headers: await authHeader(),
          method: "post",
          body: JSON.stringify( {
            eventId: parameter.data.eventId,
            orderId: parameter.id,
          } ),
        };
        const { json } = await httpClient( url, options );
        return {
          data: {
            ...json.data,
            id: "react-admin-required-id",
          },
        };
      }
      else if ( resource === "recommendation-override-products" )
      {
        const options = {
          headers: await authHeader(),
          method: "post",
          body: JSON.stringify( {
            products: parameter.data,
          } ),
        };
        const { json } = await httpClient( url, options );
        return {
          data: {
            ...json.data,
            id: "react-admin-required-id",
          },
        };
      }
      else if ( resource === "product-inventory-items" )
      {
        const options = {
          headers: await authHeader(),
          method: "post",
          body: JSON.stringify( {
            ...parameter.data,
          } ),
        };
        const { json } = await httpClient( url, options );
        return {
          data: {
            ...json.data,
            id: "react-admin-required-id",
          },
        };
      }
      else if ( resource === "announcements" )
      {
        const options = {
          headers: await authHeader(),
          method: "post",
          body: JSON.stringify( {
            ...parameter.data,
          } ),
        };

        const { json } = await httpClient( url, options );
        return {
          data: {
            ...json.data,
            id: "react-admin-required-id",
          },
        };
      }
      else if ( resource === "order-authentication" )
      {
        const options = {
          headers: await authHeader(),
          method: "put",
          body: JSON.stringify( {
            ...parameter.data,
          } ),
        };

        const { json } = await httpClient( url, options );
        return {
          data: {
            ...json.data,
            id: "react-admin-required-id",
          },
        };
      }
      else
      {
        const idName = resourceMap[resource].id();
        const options = {
          headers: await authHeader(),
          method: "post",
          body: JSON.stringify( {
            [idName]: parameter.id,
          } ),
        };
        const { json } = await httpClient( url, options );
        return {
          data: json.data,
        };
      }
    }
    catch ( error )
    {
      return handleError( error, () => this.update( resource, parameter ) );
    }
  },

  updateMany: () => new Promise( ( resolve ) => resolve( "" as unknown as never ) ),

  async create( resource, parameters )
  {
    try
    {
      const url = `${apiUrl}${resourceMap[resource].create()}`;
      const creationData = parameters.data.creation;
      const headers = await authHeader();
      const method = "post";
      if ( resource === "editorials" )
      {
        const options = {
          headers,
          method,
          body: JSON.stringify( creationData ),
        };

        const { json } = await httpClient( url, options );
        return {
          data: json.data,
        };
      }
      else if ( resource === "orders" )
      {
        const options = {
          headers,
          method,
          body: JSON.stringify( creationData ),
        };

        const { json } = await httpClient( url, options );
        return {
          data: { id: "orders", ...json.data },
        };
      }
      else if ( resource === "product-inventory-items" )
      {
        const options = {
          headers,
          method,
          body: JSON.stringify( creationData ),
        };

        const { json } = await httpClient( url, options );
        return {
          data: { id: "react-admin-required-id", ...json.data },
        };
      }
      else if ( resource === "second-closet" )
      {
        const options = {
          headers,
          method,
          body: JSON.stringify( creationData ),
        };

        const { json } = await httpClient( url, options );
        return {
          data: json.data,
        };
      }
      else if ( resource === "reports" )
      {
        const options = {
          headers,
          method,
          body: JSON.stringify( creationData ),
        };
        try
        {
          const response = await httpClient( url, options );
          return {
            data: { id: "", csv: response.body },
          };
        }
        catch ( error )
        {
          const errorResponse = {
            status: error?.status,
            message: error?.body?.message,
          };
          throw JSON.stringify( errorResponse );
        }
      }
      else if ( resource === "generate-other-categories-report" )
      {
        const options = {
          headers,
          method,
          body: JSON.stringify( creationData ),
        };
        try
        {
          const response = await httpClient( url, options );
          return {
            data: { id: "", csv: response.body },
          };
        }
        catch ( error )
        {
          const errorResponse = {
            status: error?.status,
            message: error?.body?.message,
          };
          throw JSON.stringify( errorResponse );
        }
      }
      else if ( resource === "bundled-orders" )
      {
        const options = {
          headers,
          method,
          body: JSON.stringify( creationData ),
        };

        const { json } = await httpClient( url, options );

        return {
          data: json.data,
        };
      }
      else if ( resource === "shipping-labels" )
      {
        const options = {
          headers,
          method,
          body: JSON.stringify( creationData ),
        };

        const { json } = await httpClient( url, options );

        return {
          data: { ...json.data, id: "" },
        };
      }
      else if ( resource === "return-shipping-labels" )
      {
        const options = {
          headers,
          method,
          body: JSON.stringify( creationData ),
        };

        const { json } = await httpClient( url, options );

        return {
          data: { ...json.data, id: "" },
        };
      }
      else if ( resource === "authentication-shipping-labels" )
      {
        const options = {
          headers,
          method,
          body: JSON.stringify( creationData ),
        };

        const { json } = await httpClient( url, options );

        return {
          data: { ...json.data, id: "" },
        };
      }
      else if ( resource === "collaboration-events" )
      {
        const options = {
          headers,
          method,
          body: JSON.stringify( creationData ),
        };

        const { json } = await httpClient( url, options );

        return {
          data: { ...json.data, id: "" },
        };
      }
      else if ( resource === "announcements" )
      {
        const options = {
          headers,
          method,
          body: JSON.stringify( creationData ),
        };

        const { json } = await httpClient( url, options );

        return {
          data: { ...json.data, id: "" },
        };
      }
      else if ( resource === "cards" )
      {
        const options = {
          headers,
          method,
          body: JSON.stringify( creationData ),
        };

        const { json } = await httpClient( url, options );

        return {
          data: { ...json.data, id: "" },
        };
      }
      else if ( resource === "events-rank" )
      {
        const options = {
          headers,
          method,
          body: JSON.stringify( creationData ),
        };

        const { json } = await httpClient( url, options );

        return {
          data: { ...json.data, id: "" },
        };
      }

      return new Promise( ( resolve ) => resolve( "" as unknown as never ) );
    }
    catch ( error )
    {
      return handleError( error, () => this.create( resource, parameters ) );
    }
  },
  delete: async ( resource, parameter ) =>
  {
    try
    {
      const parsedHeader = await authHeader();
      const parsedToken = await getParsedToken();
      let url = `${apiUrl}${resourceMap[resource].delete(
        parsedToken.user_id.toString()
      )}`;

      const options = {
        headers: parsedHeader,
        method: "post",
        body: "{}",
      };

      let body: { [key: string]: unknown } = {};
      if ( resource === "cards" )
      {
        body = {
          source: parameter.id,
        };
      }

      if ( resource === "collaboration-events" )
      {
        url = `${apiUrl}${resourceMap[resource].delete(
          parameter.id.toString()
        )}`;
      }

      options.body = JSON.stringify( {
        ...body,
        useStripeShopperCostumer: true,
      } );
      try
      {
        const { json } = await httpClient( url, options );
        return {
          data: { ...json?.data, id: parameter.id },
        };
      }
      catch ( error )
      {
        const errorResponse = {
          status: error.status,
          message: error.body.message,
        };
        throw JSON.stringify( errorResponse );
      }
    }
    catch ( error )
    {
      return { data: { id: parameter } };
    }
  },
  deleteMany: () => new Promise( ( resolve ) => resolve( "" as unknown as never ) ),
  async realTimeOrderStatus(
    resource: string,
    parameters: { currency: string }
  )
  {
    try
    {
      const url = `${apiUrl}${resourceMap[
        resource
      ].realTimeOrderStatus()}?currency=${parameters.currency}`;
      const options = {
        headers: await authHeader(),
        method: "post",
      };
      const { json } = await httpClient( url, options );
      return json;
    }
    catch ( error )
    {
      return handleError( error, () =>
        this.realTimeOrderStatus( resource, parameters )
      );
    }
  },
  async getReturnHistory( targetResource: string, parameters: { id: string } )
  {
    try
    {
      const url = `${apiUrl}${resourceMap[targetResource].getReturnHistory(
        parameters.id
      )}`;
      const options = {
        headers: await authHeader(),
        method: "get",
      };
      const { json } = await httpClient( url, options );
      return json;
    }
    catch ( error )
    {
      return handleError( error, () =>
        this.getReturnHistory( targetResource, parameters )
      );
    }
  },
  async getDeliveryHistory( targetResource: string, parameters: { id: string } )
  {
    try
    {
      const url = `${apiUrl}${resourceMap[targetResource].getDeliveryHistory(
        parameters.id
      )}`;
      const options = {
        headers: await authHeader(),
        method: "get",
      };
      const { json } = await httpClient( url, options );
      return json;
    }
    catch ( error )
    {
      return handleError( error, () =>
        this.getDeliveryHistory( targetResource, parameters )
      );
    }
  },
  async getFailedTransferHistory(
    targetResource: string,
    parameters: { id: string }
  )
  {
    try
    {
      const url = `${apiUrl}${resourceMap[
        targetResource
      ].getFailedTransferHistory( parameters.id )}`;
      const options = {
        headers: await authHeader(),
        method: "get",
      };
      const { json } = await httpClient( url, options );
      return json;
    }
    catch ( error )
    {
      return handleError( error, () =>
        this.getFailedTransferHistory( targetResource, parameters )
      );
    }
  },
  async deactivateShopper( targetResource: string, parameters: { id: string } )
  {
    try
    {
      const url = `${apiUrl}${resourceMap[targetResource].deactivateShopper(
        parameters.id
      )}`;
      const options = {
        headers: await authHeader(),
        method: "put",
      };
      const { json } = await httpClient( url, options );
      return json;
    }
    catch ( error )
    {
      return handleError( error, () =>
        this.deactivateShopper( targetResource, parameters )
      );
    }
  },
  async addToEvent(
    targetResource: string,
    parameters: { id: string; eventId: string }
  )
  {
    try
    {
      const url = `${apiUrl}${resourceMap[targetResource].addToEvent(
        parameters.id
      )}`;
      const options = {
        headers: await authHeader(),
        method: "post",
        body: JSON.stringify( {
          eventId: parameters.eventId,
          productInventoryItemId: parameters.id,
          action: "in-event",
        } ),
      };
      const { json } = await httpClient( url, options );
      return { data: json };
    }
    catch ( error )
    {
      return handleError( error, () =>
        this.addToEvent( targetResource, parameters )
      );
    }
  },
  async removeFromEvent( targetResource: string, parameters: { id: string } )
  {
    try
    {
      const url = `${apiUrl}${resourceMap[targetResource].removeFromEvent(
        parameters.id
      )}`;
      const options = {
        headers: await authHeader(),
        method: "post",
        body: JSON.stringify( {
          action: "remove",
        } ),
      };
      const { json } = await httpClient( url, options );
      return { data: json };
    }
    catch ( error )
    {
      return handleError( error, () =>
        this.removeFromEvent( targetResource, parameters )
      );
    }
  },
  async releaseProduct( targetResource: string, parameters: { id: string } )
  {
    try
    {
      const url = `${apiUrl}${resourceMap[targetResource].releaseProduct(
        parameters.id
      )}`;
      const options = {
        headers: await authHeader(),
        method: "post",
        body: JSON.stringify( {
          action: "release",
        } ),
      };
      const { json } = await httpClient( url, options );
      return { data: json };
    }
    catch ( error )
    {
      return handleError( error, () =>
        this.releaseProduct( targetResource, parameters )
      );
    }
  },
  async getEditHistory( targetResource: string, parameters: { id: string } )
  {
    try
    {
      const url = `${apiUrl}${resourceMap[targetResource].getEditHistory(
        parameters.id
      )}`;
      const options = {
        headers: await authHeader(),
        method: "get",
      };
      const { json } = await httpClient( url, options );
      return { data: json.data };
    }
    catch ( error )
    {
      return handleError( error, () =>
        this.getEditHistory( targetResource, parameters )
      );
    }
  },
  async chatMessages(
    targetResource: string,
    parameters: { id: string; offset: number; limit: number }
  )
  {
    try
    {
      const url = `${apiUrl}${resourceMap[targetResource].chatMessages(
        parameters.id
      )}`;
      const options = {
        headers: await authHeader(),
        method: "post",
        body: JSON.stringify( {
          offset: parameters.offset,
          limit: parameters.limit,
        } ),
      };
      const { json } = await httpClient( url, options );
      return { data: json.data };
    }
    catch ( error )
    {
      return handleError( error, () =>
        this.chatMessages( targetResource, parameters )
      );
    }
  },
  async getSignedUrl( targetResource: string, parameters: { path: string } )
  {
    try
    {
      const url = `${apiUrl}${resourceMap[targetResource].getSignedUrl()}`;
      const options = {
        headers: await authHeader(),
        method: "post",
        body: JSON.stringify( {
          path: parameters.path,
        } ),
      };
      const { json } = await httpClient( url, options );
      return { data: json.data };
    }
    catch ( error )
    {
      return handleError( error, () =>
        this.getSignedUrl( targetResource, parameters )
      );
    }
  },
  async createSellerMessage(
    targetResource: string,
    parameters: {
      subject: string;
      message: string;
      receiverIds: string[];
      sellerTypes: string[];
    }
  )
  {
    try
    {
      const url = `${apiUrl}${resourceMap[
        targetResource
      ].createSellerMessage()}`;
      const options = {
        headers: await authHeader(),
        method: "post",
        body: JSON.stringify( parameters ),
      };
      const { json } = await httpClient( url, options );
      return { data: json.data };
    }
    catch ( error )
    {
      return handleError( error, () =>
        this.createSellerMessage( targetResource, parameters )
      );
    }
  },
} );
