import React from "react";
import {
  useNotify,
  useUpdate,
  SimpleForm,
  ReferenceInput,
  SelectInput
} from "react-admin";
import {
  BooleanRoles,
  extractAuthClaims,
  hasPermissions
} from "../../ts/interfaces/role-interface";
import { useAuth } from "../../contexts/auth-context";
import { CustomModal } from "@shopthing-opn-shared/admin-dashboard";
import { Button, IconButton, Link, Typography } from "@material-ui/core";
import { Spacer } from "../generics/spacer";
import { notifyMessage } from "../../utils/primitive";

interface ILocationButton {
  orderId: string;
  location?: string;
  [x: string]: unknown;
}

export const LocationButton: React.FunctionComponent<ILocationButton> = (
  properties
) =>
{
  const [open, setOpen] = React.useState( false );
  const [values, setValues] = React.useState( {
    location: properties.location || "",
  } );

  const notify = useNotify();
  const [update, { loading, error }] = useUpdate(
    "orders-update-fulfillment",
    `${properties.orderId}-${properties.fulfillmentId}`,
    {
      location: values.location,
      status: "stored",
    },
    {},
    {
      onSuccess: () =>
      {
        window.location.reload();
      },
    }
  );
  if ( error )
  {
    notifyMessage( error, notify );
  }

  const handleOpen = () =>
  {
    setOpen( true );
  };

  const handleClose = () =>
  {
    setOpen( false );
  };

  interface InputEvent {
    target: EventTarget;
  }

  interface EventTarget {
    name: string;
    value: string;
  }

  const handleInputChange = ( event: InputEvent ) =>
  {
    const { name, value } = event.target;
    setValues( { ...values, [name]: value } );
  };

  const body = (
    <>
      <SimpleForm toolbar={null}>
        <ReferenceInput
          label="Location"
          source="location"
          reference="return-locations"
          onChange={handleInputChange}
        >
          <SelectInput source="location" optionText="name" optionValue="id" />
        </ReferenceInput>
      </SimpleForm>
      <Spacer />
      <Button
        disabled={values.location.length > 1 ? false : true}
        onClick={update}
      >
        Save
      </Button>
    </>
  );

  const { permissions } = useAuth();
  const roleClaims = extractAuthClaims( permissions );

  return (
    <>
      <IconButton
        onClick={handleOpen}
        color={"primary"}
        disabled={loading}
        disableRipple
        disableFocusRipple
      >
        <Typography>
          <Link>Assign Location</Link>
        </Typography>
      </IconButton>
      {hasPermissions(
        [
          BooleanRoles.CustomerService,
          BooleanRoles.RefundCustomerService,
          BooleanRoles.Fulfillment,
          BooleanRoles.Admin,
        ],
        roleClaims
      ) && (
        <CustomModal open={open} onClose={handleClose}>
          {body}
        </CustomModal>
      )}
    </>
  );
};
