import * as React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

import {
  ImageUploader,
  FileUploader
} from "@shopthing-opn-shared/admin-dashboard";
import { getApp } from "firebase/app";

const useStyles = makeStyles( ( theme: Theme ) =>
  createStyles( {
    formField: {
      margin: theme.spacing( 1, 2, 1, 0 ),
    },
  } )
);

export const TrailerUpload: React.FC<{
  uploadPath: string;
  label: string;
}> = ( properties: { uploadPath: string; label: string } ) =>
{
  const { uploadPath, label } = properties;
  const classes = useStyles();
  const app = getApp();

  return (
    <React.Fragment>
      <FileUploader
        source="trailerPath"
        uploadPath={`${uploadPath}-trailer`}
        inputProperties={{
          accept: "video/*",
          label: `${label} Trailer`,
        }}
        app={app}
      />
      <ImageUploader
        source="trailerPosterPath"
        uploadPath={`${uploadPath}-poster`}
        inputProperties={{
          label: `${label} Preview Poster`,
          className: classes.formField,
          minSize: 10,
        }}
        previewProperties={{
          style: { maxHeight: "500px", maxWidth: "100%" },
        }}
        app={app}
      />
    </React.Fragment>
  );
};
