import { ShippingMethods } from "./product-interface";
import { countries, currencies, Timestamps } from "./shared";
import { NoteData } from "./notes";

export interface Event {
  id: string;
  address: string;
  city: string;
  country: countries;
  currency: currencies;
  hasProducts: boolean;
  name: string;
  shipping: ShippingMethods;
  state: string;
  taxRate: number;
  theme: number;
  userId: string;
  startDate: string;
  endDate: string;
  createdAt: string;
  updatedAt: string;
  trailerPath?: string;
  trailerPosterPath?: string;
  tripType?: string;
  earlyAccess?: boolean;
  exclusiveEvent?: boolean;
  earlyAccessDelayMinutes?: number;
  eventType?: string;
  partnership?: string;
  isPinned?: boolean;
  isUsedInCollaboration?: boolean;
  isClosetSale?: boolean;
  isRepeated?: boolean;
  notes: NoteData[];
}

export interface Editorial {
  id: string;
  name: string;
  isActive: boolean;
  productIds: string[];
  trailerPosterPath: string;
  trailerPath: string;
  timestamps: Timestamps;
}

export interface CollaborationEvent {
  id: string;
  name: string;
  isPinned: boolean;
  eventIds: string[];
  trailerPath: string;
  trailerPosterPath: string;
  startDate: Date;
  endDate: Date;
  timestamps: Timestamps;
  isClosetSale?: boolean;
}

export enum FeedEntryType {
  allEvent = "allEvent",
  live = "live",
  upcoming = "upcoming",
  editorial = "editorial",
}
