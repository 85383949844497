import * as React from "react";
import {
  Datagrid,
  List,
  TextField,
  Filter,
  SelectInput,
  BooleanField,
  useNotify,
  useUpdate,
  Button,
  useListContext,
  FunctionField,
  useRefresh,
  setListSelectedIds
} from "react-admin";
import { CustomModal } from "@shopthing-opn-shared/admin-dashboard";
import { FilterProps } from "ra-ui-materialui";
// import { setListSelectedIds } from "ra-core";
import { useDispatch } from "react-redux";

import { BundledOrder } from "../../ts/interfaces/bundled-order-interface";
import { Manifest } from "../../ts/interfaces/manifest-interface";
import {
  makeLocalStorageKey,
  StoragePage,
  StorageType
} from "../../utils/local-storage";
import { BasicPagination, perPage } from "../generics/pagination";
import {
  BooleanRoles,
  extractAuthClaims,
  hasPermissions
} from "../../ts/interfaces/role-interface";
import { usePermissions } from "ra-core";
import { notifyMessage } from "../../utils/primitive";

const BundleFilters: React.FC = ( properties: FilterProps ) => (
  <Filter {...properties}>
    <SelectInput
      source="status"
      choices={[
        { id: "created", name: "created" },
        { id: "shipped", name: "shipped" },
      ]}
    />
    <SelectInput
      source="hasManifest"
      choices={[
        { id: "true", name: "Yes" },
        { id: "false", name: "No" },
      ]}
    />
  </Filter>
);

const manifestStorageKey = makeLocalStorageKey(
  StoragePage.manifestList,
  StorageType.manifest,
  "list"
);
const BulkActions = ( props: { selectedIds: string[] } ) =>
{
  const dispatch = useDispatch();
  const refresh = useRefresh();
  const notify = useNotify();
  const { data } = useListContext();
  const { selectedIds } = props;
  const [init, setInit] = React.useState( true );
  const [createdManifestIds, setManifestIds] = React.useState( [[], []] );
  let bundleIds = [...selectedIds];
  if ( init )
  {
    setInit( false );
    const restoredManifestStorage =
      JSON.parse( localStorage.getItem( manifestStorageKey ) ) || {};
    const { storedList = [] } = restoredManifestStorage;
    if ( storedList?.length > 0 )
    {
      bundleIds = [...storedList];
      dispatch( setListSelectedIds( "bundled-orders", storedList ) );
    }
  }
  const shipmentIds: string[] = [];

  const manifestStorage: {
    storedList: string[];
    shippingMap: { [bundledId: string]: string };
  } = {
    storedList: bundleIds,
    shippingMap: {},
  };
  bundleIds.forEach( ( id ) =>
  {
    const localStorageShippingId = manifestStorage.shippingMap[id] || undefined;
    const listContextShippingId = data[id]?.shipping.labelProvider.shipmentId;
    const prevailingShippingId =
      listContextShippingId || localStorageShippingId;
    manifestStorage.shippingMap[id] = prevailingShippingId;
    shipmentIds.push( prevailingShippingId );
  } );
  localStorage.setItem( manifestStorageKey, JSON.stringify( manifestStorage ) );

  const [open, setOpen] = React.useState( false );
  const [manifestUrl, setManifestUrl] = React.useState( "" );

  const [update] = useUpdate(
    "manifests",
    "manifest-id",
    {
      bundleIds,
      shipmentIds,
    },
    {},
    {
      onSuccess: ( response: { data: { id: string; manifest: Manifest } } ) =>
      {
        if ( response.data.manifest.form_url )
        {
          setManifestIds( [bundleIds, shipmentIds] );
          setManifestUrl( response.data.manifest.form_url );
          localStorage.removeItem( manifestStorageKey );
          dispatch( setListSelectedIds( "bundled-orders", [] ) );
          window.open( response.data.manifest.form_url, "_blank" ).focus();
          setOpen( true );
        }
        else
        {
          notify( "Error creating manifest", "error" );
          refresh();
        }
      },
      onFailure: ( error: Error ) =>
      {
        notifyMessage( error, notify );
      },
    }
  );

  const [partnerUpdate] = useUpdate(
    "partner-manifests",
    "manifest-id",
    {
      bundleIds,
      shipmentIds,
    },
    {},
    {
      onSuccess: ( response: { data: { id: string; manifest: Manifest } } ) =>
      {
        if ( response.data.manifest.form_url )
        {
          setManifestIds( [bundleIds, shipmentIds] );
          setManifestUrl( response.data.manifest.form_url );
          localStorage.removeItem( manifestStorageKey );
          dispatch( setListSelectedIds( "bundled-orders", [] ) );
          window.open( response.data.manifest.form_url, "_blank" ).focus();
          setOpen( true );
        }
        else
        {
          notify( "Error creating manifest", "error" );
          refresh();
        }
      },
      onFailure: ( error: Error ) =>
      {
        notifyMessage( error, notify );
      },
    }
  );

  const handleClose = () =>
  {
    setManifestUrl( "" );
    setOpen( false );
    refresh();
  };

  const { loaded, permissions } = usePermissions();
  const roleClaims = extractAuthClaims( permissions );
  const isPartner = hasPermissions( [BooleanRoles.Partner], roleClaims );

  return (
    <React.Fragment>
      <Button
        onClick={loaded && isPartner ? partnerUpdate : update}
        label="Create Manifest"
      />
      <CustomModal open={open} onClose={handleClose}>
        <h2>Successfully created shipping manifest!</h2>
        <h4>
          You can view the manifest{" "}
          <a href={manifestUrl} target="_blank" rel="noopener noreferrer">
            here
          </a>
        </h4>
        <div>
          The manifest was created for bundles [
          {createdManifestIds[0].join( ", " )}]
        </div>
        <div>with shipment ids [{createdManifestIds[1].join( ", " )}]</div>
      </CustomModal>
    </React.Fragment>
  );
};

const RenderUrl = ( props: { url: string; label: string } ) =>
{
  const { url, label } = props;
  if ( url )
  {
    return (
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        onClick={( e ) => e.stopPropagation()}
      >
        {label}
      </a>
    );
  }

  return null;
};

export const BundledOrderList = (
  properties: Record<string, never>
): JSX.Element =>
{
  const { loaded, permissions } = usePermissions();
  const roleClaims = extractAuthClaims( permissions );
  const isPartner = hasPermissions( [BooleanRoles.Partner], roleClaims );

  return (
    <List
      {...properties}
      title="Manifests"
      pagination={<BasicPagination />}
      perPage={perPage}
      filters={<BundleFilters />}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore Props are passed via context
      bulkActionButtons={<BulkActions />}
    >
      <Datagrid
        rowClick="show"
        isRowSelectable={( record: BundledOrder ) =>
          !record.hasManifest &&
          record.shipping?.labelProvider?.shipmentId != null &&
          ( !record.shippingProvider || record.shippingProvider === "EasyPost" )
        }
      >
        <TextField label="ID" source="id" sortable={false} />
        <TextField source="status" sortable={false} />
        <BooleanField
          label="Has Manifest"
          source="hasManifest"
          sortable={false}
        />
        {loaded && !isPartner && (
          <TextField
            source="shippingProvider"
            label="Shipping Provider"
            emptyText={"EasyPost"}
            sortable={false}
          />
        )}
        {loaded && !isPartner && (
          <TextField
            source="fulfilledBy"
            label="Fulfilled By"
            sortable={false}
          />
        )}
        <TextField
          source="shipping.trackingNumber"
          label="Tracking Number"
          sortable={false}
        />
        <FunctionField
          label="Shipping Label"
          render={( record: BundledOrder ) => (
            <RenderUrl
              url={record.shipping?.labelProvider?.labelUrl}
              label="Shipping Label"
            />
          )}
        />
        <FunctionField
          label="Manifest Form"
          render={( record: BundledOrder ) => (
            <RenderUrl url={record.manifest?.form_url} label="Manifest" />
          )}
        />
        <TextField source="orderIds" label="Orders" sortable={false} />
      </Datagrid>
    </List>
  );
};
