import * as React from "react";

import { IconButton, Typography, Link } from "@material-ui/core";
import { IconButtonProps } from "@material-ui/core/IconButton";

type ActionButtonProps = IconButtonProps & { label: string };

export class ActionButton extends React.PureComponent<ActionButtonProps>
{
  public props: ActionButtonProps;
  constructor( props: ActionButtonProps )
  {
    super( props );
    this.props = props;
  }

  render(): JSX.Element
  {
    const { label, ...rest } = this.props;
    return (
      <IconButton color={"primary"} disableRipple disableFocusRipple {...rest}>
        <Typography>
          <Link>{label}</Link>
        </Typography>
      </IconButton>
    );
  }
}
