import * as React from "react";
import { Tab, TabbedShowLayout } from "react-admin";
import { Card, CardContent, Box, Grid, Typography } from "@material-ui/core";
import { PotentialBundle } from "src/ts/interfaces/potential-bundle-interface";
import { Spacer } from "../generics/spacer";
import { BundleOrderTable } from "./bundle-order-table";

interface DetailProperties {
  record?: PotentialBundle;
  [x: string]: unknown;
}

export const BundleDetail: React.FunctionComponent<DetailProperties> = (
  detailProperties: DetailProperties
) =>
{
  const { record } = detailProperties;
  const firstname =
    record.logistics.details.address.firstname ||
    record.logistics.details.firstname;
  const lastname =
    record.logistics.details.address.lastname ||
    record.logistics.details.lastname;
  const phone =
    record.logistics.details.address.phone || record.logistics.details.phone;
  const address = {
    ...record.logistics.details.address,
    firstname: `${firstname}`,
    lastname: `${lastname}`,
    phone,
  };
  delete address.saveAddress;
  return (
    <Card>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={8}>
            <Typography variant="h5" gutterBottom>
              {`Potential Bundle ${record.id}`}
            </Typography>
            <Box
              border={2}
              bgcolor={"#f7f9fa"}
              css={{ borderColor: "#C5D1D9" }}
              padding={2}
            >
              <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography
                    variant={"h6"}
                  >{`Address: ${record.logistics.details.address.line1}`}</Typography>
                  <Typography
                    variant={"h6"}
                  >{`Postal/Zip: ${record.logistics.details.address.postalCode}`}</Typography>
                  <Typography
                    variant={"h6"}
                  >{`City: ${record.logistics.details.address.city}`}</Typography>
                  <Typography
                    variant={"h6"}
                  >{`Country/Region: ${record.logistics.details.address.country}`}</Typography>
                  <Typography variant={"h6"}>{`Company: ${
                    record.logistics.details.address.company || ""
                  }`}</Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Typography variant="h5" gutterBottom>
              {`Shipping Details`}
            </Typography>
            <Box
              border={2}
              bgcolor={"#f7f9fa"}
              css={{ borderColor: "#C5D1D9" }}
              padding={2}
              marginLeft={1}
            >
              <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography variant={"h6"}>{`First Name: ${
                    firstname || ""
                  }`}</Typography>
                  <Typography variant={"h6"}>{`Last Name: ${
                    lastname || ""
                  }`}</Typography>
                  <Typography variant={"h6"}>{`Phone: ${
                    phone || ""
                  }`}</Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Spacer />
        <TabbedShowLayout>
          <Tab label="Orders">
            <BundleOrderTable
              orderMap={record.orderData}
              orderDetails={record.orderDetails}
              bundleAddress={address}
              bundleId={record.id}
              fulfilledBy={record.fulfilledBy}
            />
            <Spacer />
          </Tab>
        </TabbedShowLayout>
      </CardContent>
    </Card>
  );
};
