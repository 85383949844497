import { Customer } from "../../ts/interfaces/user-interface";
import * as React from "react";
import { List, Datagrid, TextField } from "react-admin";
import { ReconcileButton } from "../reconciliation/reconcile-button";
import { BasicPagination, perPage } from "../generics/pagination";

export const ReconciliationList = ( properties: Customer[] ): JSX.Element => (
  <List
    {...properties}
    pagination={<BasicPagination />}
    bulkActionButtons={false}
    exporter={false}
    perPage={perPage}
  >
    <Datagrid>
      <TextField label="Email" source="email" sortable={false} />
      <TextField label="Shopper" source="handle" sortable={false} />
      <TextField label="First Name" source="firstname" sortable={false} />
      <TextField label="Last Name" source="lastname" sortable={false} />
      <TextField
        label="Stripe Account"
        source="stripeAccount"
        sortable={false}
      />
      <ReconcileButton />
    </Datagrid>
  </List>
);
