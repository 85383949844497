import { Order } from "../../ts/interfaces/order-interface";
import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  FunctionField,
  ChipField
} from "react-admin";
import { BasicPagination, perPage } from "../generics/pagination";
import { formatPrice } from "../../utils/primitive";

export const PickupOrderList = ( properties: Order[] ): JSX.Element => (
  <List
    {...properties}
    pagination={<BasicPagination />}
    bulkActionButtons={false}
    exporter={false}
    perPage={perPage}
    sort={{ field: "timestamps.created", order: "DESC" }}
  >
    <Datagrid rowClick="show">
      <TextField label="Order ID" source="id" sortable={false} />
      <TextField label="Type" source="logistics.type" sortable={false} />
      <TextField label="Event ID" source="seller.event" sortable={false} />
      <FunctionField
        label="Customer"
        render={( order: Order ) =>
          `${order.customer.firstname} ${order.customer.lastname}`
        }
        sortable={false}
      />
      <TextField
        label="Product"
        source="cart.items[0].productName"
        sortable={false}
      />
      -{" "}
      <FunctionField
        label="Total"
        render={( order: Order ) =>
          `${formatPrice( order.totals.totalPay, order.specifications.currency )}`
        }
        sortable={false}
      />{" "}
      -{" "}
      <DateField
        label="Created At"
        source="timestamps.created"
        showTime
        sortable={true}
      />
      <ChipField label="Status" source="status" sortable={false} />
    </Datagrid>
  </List>
);
