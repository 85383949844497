import * as React from "react";
import { List, Datagrid, TextField } from "react-admin";
import { Settings } from "../../ts/interfaces/settings-interface";
import { BasicPagination, perPage } from "../generics/pagination";

export const SettingsList = ( properties: Settings[] ): JSX.Element => (
  <List
    {...properties}
    bulkActionButtons={false}
    pagination={<BasicPagination />}
    exporter={false}
    sort={undefined}
    perPage={perPage}
  >
    <Datagrid rowClick="edit">
      <TextField label="ID" source="id" sortable={false} />
    </Datagrid>
  </List>
);
