import * as React from "react";
import { useDataProvider } from "react-admin";
import { Box } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { OrderDataDisplay } from "./order-data-display";
import { OpenOrders } from "./open-orders";
import { ShippingHistory } from "./shipping-history";
import { useInterval } from "../../hooks/use-interval";
import { config } from "../../config";

const initialData = {
  currency: "usd",
  paidNotConfirmed: {
    totalOrderCount: 0,
    totalItemCount: 0,
    shoppers: [
      {
        handle: "maggie",
        orderCount: 0,
        itemCount: 0,
      },
    ],
  },
  paidAndConfirmed: {
    totalOrderCount: 0,
    totalItemCount: 0,
    shoppers: [
      {
        handle: "maggie",
        orderCount: 0,
        itemCount: 0,
      },
    ],
  },
  openOrders: {
    pending: {
      totalOrderCount: 0,
      totalItemCount: 0,
    },
    disputed: {
      totalOrderCount: 0,
      totalItemCount: 0,
    },
    pendingToday: {
      totalOrderCount: 0,
      totalItemCount: 0,
    },
  },
  shippingHistory: {
    shippedToday: {
      totalOrderCount: 0,
      totalItemCount: 0,
    },
    shippedYesterday: {
      totalOrderCount: 0,
      totalItemCount: 0,
    },
    shippedLastWeek: {
      totalOrderCount: 0,
      totalItemCount: 0,
    },
    shippedLastMonth: {
      totalOrderCount: 0,
      totalItemCount: 0,
    },
    shippedLastQuarter: {
      totalOrderCount: 0,
      totalItemCount: 0,
    },
    shippedYtd: {
      totalOrderCount: 0,
      totalItemCount: 0,
    },
    shippedWtd: {
      totalOrderCount: 0,
      totalItemCount: 0,
    },
  },
};

function CurrencyTabs( props: {
  setCurrency: React.Dispatch<React.SetStateAction<string>>;
  selectedCurrency: string;
} ): JSX.Element
{
  const selectedBorderStyle = "solid solid none solid";
  const notSelectedBorderStyle = "none none solid none ";
  return (
    <Box
      display="flex"
      style={{ height: "50px", marginTop: "5px", marginBottom: "10px" }}
    >
      <Box
        style={{
          width: "50px",
          borderBottom: "1px solid",
          borderColor: "#BBBBBB",
        }}
      />
      <button
        onClick={() => props.setCurrency( "usd" )}
        style={{
          outline: "none",
          borderRadius: "10px 10px 0px 0px",
          fontWeight: 900,
          fontSize: "22px",
          border: "1px solid",
          borderStyle:
            props.selectedCurrency === "usd"
              ? selectedBorderStyle
              : notSelectedBorderStyle,
          background: props.selectedCurrency === "usd" ? "#FFFFFF" : "#AAAAAA",
          padding: "10px",
          width: "150px",
          borderColor: "#BBBBBB",
        }}
      >
        USD 🇺🇸
      </button>
      <button
        onClick={() => props.setCurrency( "cad" )}
        style={{
          width: "150px",
          outline: "none",
          borderRadius: "10px 10px 0px 0px",
          fontWeight: 900,
          fontSize: "22px",
          border: "1px solid",
          borderStyle:
            props.selectedCurrency === "cad"
              ? selectedBorderStyle
              : notSelectedBorderStyle,
          background: props.selectedCurrency === "cad" ? "#FFFFFF" : "#AAAAAA",
          padding: "10px",
          borderColor: "#BBBBBB",
        }}
      >
        CAD 🇨🇦
      </button>
      <Box
        style={{
          width: "100%",
          borderBottom: "1px solid",
          borderColor: "#BBBBBB",
        }}
      />
    </Box>
  );
}

export const RtDashboard = (): JSX.Element =>
{
  const dataProvider = useDataProvider();
  const [dashboardData, setDashboardData] = React.useState( initialData );
  const [tick, setTick] = React.useState( false );
  const [currency, setCurrency] = React.useState( "usd" );
  const [reloadingData, setReloadingData] = React.useState( true );

  const updateCurrency = ( currency: string ) =>
  {
    setReloadingData( true );
    setCurrency( currency );
    setDashboardData( initialData );
    setTick( !tick );
  };
  React.useEffect( () =>
  {
    dataProvider
      .realTimeOrderStatus( "dashboards", { currency } )
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .then( ( { data } ) =>
      {
        setDashboardData( data );
        setReloadingData( false );
      } )
      .catch( ( error: never ) =>
      {
        console.log( error );
      } );
  }, [tick] );

  useInterval( async () =>
  {
    setTick( !tick );
  }, config.dashboards.realTimeOrderStatus.refreshDelayMs || 10000 );
  return (
    <Box>
      <CurrencyTabs setCurrency={updateCurrency} selectedCurrency={currency} />
      {reloadingData || dashboardData.currency !== currency ? (
        <Box style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box display="flex">
          <Box>
            <OrderDataDisplay
              orderData={dashboardData.paidNotConfirmed}
              title={`PAID AND NOT CONFIRMED`}
            />
          </Box>
          <Box>
            <OrderDataDisplay
              orderData={dashboardData.paidAndConfirmed}
              title="PAID AND CONFIRMED"
            />
          </Box>
          <Box>
            <OpenOrders openOrderData={dashboardData.openOrders} />
            <ShippingHistory shippingHistory={dashboardData.shippingHistory} />
          </Box>
        </Box>
      )}
    </Box>
  );
};
