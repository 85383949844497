import * as React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Spacer } from "./generics/spacer";

import {
  Edit,
  FormTab,
  TabbedForm,
  TextInput,
  useRefresh,
  useNotify
} from "react-admin";
import { Record } from "ra-core";

import { Address, Order } from "src/ts/interfaces/order-interface";
import { SaveOnlyToolbar } from "./toolbars/save-only";

const state = {
  order: {} as Order,
};

interface IEditProperties {
  id: string;
  [x: string]: unknown;
  record: Order;
}

const useStyles = makeStyles( ( theme: Theme ) =>
  createStyles( {
    formField: {
      margin: theme.spacing( 1, 2, 1, 0 ),
    },
  } )
);

const OrderDetails: React.FunctionComponent = () =>
{
  const classes = useStyles();
  return (
    <React.Fragment>
      <h2>Order Details</h2>
      <TextInput disabled source="id" className={classes.formField} />
      <TextInput
        disabled
        source="logistics.type"
        className={classes.formField}
      />
      <TextInput disabled source="status" className={classes.formField} />
      {state.order.customer.email && (
        <TextInput source="customer.email" className={classes.formField} />
      )}
    </React.Fragment>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore - Indicates the type of record being passed, should use FormTabProps but this is not exposed by the package
const LogisticsForm: React.FC = ( { record }: { record: Order } ) =>
{
  const classes = useStyles();
  state.order = record;
  return (
    <React.Fragment>
      <OrderDetails />
      {record?.logistics?.type !== "pickup" ? (
        <React.Fragment>
          <h2>Shipping Details</h2>
          <TextInput
            label="Line 1"
            source="logistics.details.address.line1"
            fullWidth
            className={classes.formField}
          />
          <TextInput
            label="Line 2"
            source="logistics.details.address.line2"
            className={classes.formField}
          />
          <TextInput
            label="City"
            source="logistics.details.address.city"
            className={classes.formField}
          />
          <TextInput
            label="State"
            source="logistics.details.address.state"
            className={classes.formField}
          />
          <TextInput
            label="Country"
            source="logistics.details.address.country"
            className={classes.formField}
          />
          <TextInput
            label="Postal Code"
            source="logistics.details.address.postalCode"
            className={classes.formField}
          />
          <Spacer />
          <TextInput
            label="First Name"
            source="logistics.details.address.firstname"
            className={classes.formField}
          />
          <TextInput
            label="Last Name"
            source="logistics.details.address.lastname"
            className={classes.formField}
          />
          <TextInput
            label="Company"
            source="logistics.details.address.company"
            className={classes.formField}
          />
          <TextInput
            label="Phone"
            source="logistics.details.address.phone"
            className={classes.formField}
          />
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};

const transform = ( data: Record ) =>
{
  const address = data.logistics.details.address;
  const orderUpdate: {
    id: string | number;
    authId: string;
    updates: {
      email: string;
      logistics: {
        type: string;
        details?: {
          firstname: string;
          lastname: string;
          company?: string;
          phone: string;
          address?: Address;
        };
      };
    };
  } = {
    id: data.id,
    authId: data.customer.id,
    updates: {
      email: data.customer.email,
      logistics: {
        type: data.logistics.type,
      },
    },
  };

  if ( data.logistics.type !== "pickup" )
  {
    ( orderUpdate.updates.logistics.details = {
      address,
      firstname: data.logistics.details.firstname,
      lastname: data.logistics.details.lastname,
      company: address.company,
      phone: address.phone,
    } ),
    delete orderUpdate.updates.logistics.details.address.oneLiner;

    if ( !address.company )
    {
      delete orderUpdate.updates.logistics.details.company;
      delete orderUpdate.updates.logistics.details.address.company;
    }

    if ( !address.line2 )
    {
      delete orderUpdate.updates.logistics.details.address.line2;
    }
  }
  else
  {
    orderUpdate.updates.logistics.details = data.logistics.details;
  }

  return orderUpdate;
};

export const OrderEdit: React.FunctionComponent<IEditProperties> = (
  properties: IEditProperties
) =>
{
  const refresh = useRefresh();
  const notify = useNotify();
  const onSuccess = () =>
  {
    notify( "Order Updated" );
    refresh();
  };

  const onFailure = ( error: Error ) =>
  {
    notify( `Failed updating order  ${error.message}`, "warning" );
  };

  return (
    <Edit
      transform={transform}
      undoable={false}
      onSuccess={onSuccess}
      onFailure={onFailure}
      {...properties}
    >
      <TabbedForm toolbar={<SaveOnlyToolbar />}>
        <FormTab label="Shipping Details">
          <LogisticsForm />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
