import * as React from "react";
import { FormDataConsumer, useGetList, SelectInput } from "react-admin";
import { ProductInventoryItem } from "../../ts/interfaces/product-inventory-interface";
import { Grid } from "@material-ui/core";
import { required } from "ra-core";
import { useForm } from "react-final-form";

export const CategoriesSelectInput: React.FunctionComponent<{
  record: ProductInventoryItem;
}> = ( { record }: { record: ProductInventoryItem | null } ) =>
{
  const { data, loading, error } = useGetList(
    "categories",
    { page: 1, perPage: 100 },
    undefined,
    {}
  );

  const form = useForm();

  if ( loading )
  {
    return <></>;
  }
  if ( error )
  {
    return <p>ERROR</p>;
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={4}>
          <SelectInput
            source="topLevelCategory"
            choices={Object.values( data )}
            initialValue={record?.categoryPathIds?.[0]?.split( "/" )[0]}
            validate={required()}
            isLoading={loading}
            onChange={() =>
            {
              form.change( "subCategory1", undefined );
              form.change( "subCategory2", undefined );
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormDataConsumer>
            {( { formData }: { formData: { topLevelCategory: string } } ) =>
            {
              if (
                !formData.topLevelCategory ||
                !data[formData.topLevelCategory].subCategories
              )
              {
                return <></>;
              }

              const initialValues = record?.categoryPathIds[0]?.split( "/" );
              const initialValue =
                initialValues?.length > 1 &&
                initialValues[0] === formData.topLevelCategory
                  ? initialValues[1]
                  : "";

              return (
                formData.topLevelCategory &&
                data[formData.topLevelCategory].subCategories && (
                  <SelectInput
                    source="subCategory1"
                    choices={data[formData.topLevelCategory].subCategories}
                    initialValue={initialValue}
                    validate={required()}
                    onChange={() =>
                    {
                      form.change( "subCategory2", undefined );
                    }}
                  />
                )
              );
            }}
          </FormDataConsumer>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormDataConsumer>
            {( {
              formData,
            }: {
              formData: { topLevelCategory: string; subCategory1: string };
            } ) =>
            {
              if (
                !formData.topLevelCategory ||
                !data[formData.topLevelCategory].subCategories ||
                !formData.subCategory1
              )
              {
                return <></>;
              }

              const initialValues = record?.categoryPathIds[0]?.split( "/" );
              const initialValue =
                initialValues?.length > 2 &&
                initialValues[0] === formData.topLevelCategory &&
                initialValues[1] === formData.subCategory1
                  ? initialValues[2]
                  : "";

              const index = data[formData.topLevelCategory].subCategories
                .map( ( value: { id: string; name: string } ) => value.id )
                .indexOf( formData.subCategory1 );

              return (
                formData.subCategory1 &&
                data[formData.topLevelCategory].subCategories &&
                index >= 0 &&
                data[formData.topLevelCategory].subCategories[index]
                  .subCategories && (
                  <SelectInput
                    source="subCategory2"
                    choices={
                      data[formData.topLevelCategory].subCategories[index]
                        .subCategories
                    }
                    initialValue={initialValue}
                    validate={required()}
                  />
                )
              );
            }}
          </FormDataConsumer>
        </Grid>
      </Grid>
    </>
  );
};
