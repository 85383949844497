import _ from "lodash";
import {
  ProductInventoryItem,
  ProductInventoryItemsListData
} from "../../ts/interfaces/product-inventory-interface";
import * as React from "react";
import {
  Filter,
  ReferenceInput,
  SelectInput,
  List,
  Datagrid,
  TextField,
  FunctionField
} from "react-admin";
import { BasicPagination, perPage } from "../generics/pagination";
import {
  formatPrice,
  formatPriceWithoutCurrency,
  getAge
} from "../../utils/primitive";
import { FilterProps } from "ra-ui-materialui";
import { PaginatedSelectInput } from "../generics/paginated-select-input";
import { paginatedSelectInputConfig } from "../component-config";
import {
  StoragePage,
  StorageType,
  makeLocalStorageKey
} from "../../utils/local-storage";

const setShopper = ( id: string, value: string ) =>
  localStorage.setItem(
    makeLocalStorageKey(
      StoragePage.productInventoryItemList,
      StorageType.seller,
      id
    ),
    value
  );

const InventoryItemsFilter: React.FC = (
  props: Partial<FilterProps> & {
    data?: ProductInventoryItem[];
    filterValues?: ProductInventoryItem;
    displayedFilters: { [key: string]: boolean };
    setFilters?: ( filter: { [key: string]: unknown } ) => void;
    resetSort: () => void;
  }
) =>
{
  const [sellerId, setSellerId] = React.useState(
    props.filterValues?.seller?.id || ""
  );

  let initialSellerValue;
  if ( sellerId )
  {
    if ( !props.displayedFilters || !props.displayedFilters["seller.id"] )
    {
      initialSellerValue = null;
      setSellerId( "" );
    }
    else
    {
      initialSellerValue = {
        id: sellerId,
        name:
          localStorage.getItem(
            makeLocalStorageKey(
              StoragePage.productInventoryItemList,
              StorageType.seller,
              sellerId
            )
          ) || sellerId,
      };
    }
  }

  return (
    <Filter {...props}>
      <PaginatedSelectInput
        source="seller.id"
        data={props.data}
        initialEntry={initialSellerValue}
        label="Shopper"
        list={{
          resource: "users",
          nameMappingKey: "handle",
          perPage: paginatedSelectInputConfig.perPage,
          filter: { isSeller: true },
          sort: {
            field: "sortOptions.handle",
            order: "ASC",
          },
          storageFunction: setShopper,
        }}
      />
      <ReferenceInput label="Tags" source="tagIds" reference="get-tags">
        <SelectInput optionText="name" resettable />
      </ReferenceInput>
      <SelectInput
        source="status"
        resettable
        choices={[
          { id: "available", name: "available" },
          { id: "in-event", name: "in-event" },
        ]}
      />
      <SelectInput
        source="currency"
        resettable
        choices={[
          { id: "cad", name: "cad" },
          { id: "usd", name: "usd" },
        ]}
      />
      <ReferenceInput
        label="Location"
        source="location"
        reference="return-locations"
      >
        <SelectInput source="location" optionText="name" optionValue="id" />
      </ReferenceInput>
    </Filter>
  );
};

export const ProductInventoryItemsList = (
  properties: ProductInventoryItemsListData[]
): JSX.Element => (
  <List
    {...properties}
    pagination={<BasicPagination />}
    bulkActionButtons={false}
    filters={<InventoryItemsFilter />}
    exporter={false}
    perPage={perPage}
  >
    <Datagrid rowClick="show">
      <TextField label="Product Inventory ID" source="id" sortable={false} />
      <TextField label="Shopper" source="seller.handle" sortable={false} />
      <FunctionField
        label="Tags"
        render={( data: ProductInventoryItemsListData ) =>
          data.tagNames
            ? data.tagNames
              .filter( ( tag ) => !_.isNil( tag ) )
              .filter( ( tag ) => tag !== "" )
              .join( ", " )
            : null
        }
        sortable={false}
      />
      <TextField label="Brand" source="brandName" sortable={false} />
      <FunctionField
        label="Product Name"
        render={( data: ProductInventoryItemsListData ) =>
        {
          let displayedName = data.productName;
          if ( data.skuOption )
          {
            displayedName += ` - ${data.skuOption}`;
          }
          return displayedName;
        }}
        sortable={false}
      />
      <FunctionField
        label="Regular Price"
        render={( data: ProductInventoryItemsListData ) =>
          data.regularPrice
            ? ( data.currency
              ? `${formatPrice( data.regularPrice.toString(), data.currency )}`
              : formatPriceWithoutCurrency( data.regularPrice.toString() ) )
            : null
        }
        sortable={false}
      />
      <FunctionField
        label="Sale Price"
        render={( data: ProductInventoryItemsListData ) =>
          data.salePrice
            ? ( data.currency
              ? `${formatPrice( data.salePrice.toString(), data.currency )}`
              : formatPriceWithoutCurrency( data.salePrice.toString() ) )
            : null
        }
        sortable={false}
      />
      <FunctionField
        label="Date created"
        render={( data: ProductInventoryItemsListData ) =>
          data?.timestamps?.created
            ? new Date( data.timestamps.created ).toLocaleDateString( "en-CA", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            } )
            : ""
        }
        sortable={false}
      />
      <FunctionField
        label="Age"
        render={( data: ProductInventoryItemsListData ) =>
          data?.timestamps?.accepted
            ? getAge( new Date( data.timestamps.accepted ) )
            : ""
        }
        sortable={false}
      />
      <TextField
        label="# Times Sold"
        source="numOfTimesSold"
        sortable={false}
      />
      <TextField label="Status" source="status" sortable={false} />
      <TextField label="Location" source="location" sortable={false} />
    </Datagrid>
  </List>
);
