import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  FunctionField
} from "react-admin";
import { Order } from "../../ts/interfaces/order-interface";
import { Settings } from "../../ts/interfaces/settings-interface";
import { BasicPagination, perPage } from "../generics/pagination";

const ListView: React.FC = () => (
  <Datagrid rowClick={( id: string ) => `orders/${id}/show`}>
    <TextField label="Order ID" source="id" sortable={false} />
    <FunctionField
      label="Customer"
      render={( order: Order ) =>
        `${order.customer.firstname} ${order.customer.lastname}`
      }
      sortable={false}
    />
    <DateField
      label="Date return was initiated"
      source="returns.timestamps.initiated"
      showTime
      sortable={false}
    />
  </Datagrid>
);

export const SellerManualReviewList = ( properties: Settings[] ): JSX.Element => (
  <List
    {...properties}
    pagination={<BasicPagination />}
    title="Manual Review"
    bulkActionButtons={false}
    exporter={false}
    perPage={perPage}
  >
    <ListView />
  </List>
);
