import * as React from "react";
import {
  BulkActionProps,
  ReferenceManyFieldProps,
  ListProps,
  ListView
} from "ra-ui-materialui";
import {
  ListContextProvider,
  Datagrid,
  TextField,
  FunctionField,
  useReferenceManyFieldController,
  SaveButton,
  Toolbar,
  useNotify,
  useDataProvider,
  Pagination
} from "react-admin";
import { formatPrice } from "../../utils/primitive";

export const RecommendationOverrideSaveOnlyToolbar: React.FunctionComponent<
  BulkActionProps &
    ListProps & {
      id: string;
      saving: boolean;
      allIds: string[];
      data: { [key: string]: { eventId: string } };
    }
> = ( properties ) =>
{
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [isLoading, setLoading] = React.useState( false );

  const handleClick = () =>
  {
    setLoading( true );
    const { resource, selectedIds, filterValues, allIds } = properties;

    // Each page is isolated from the rest
    // So we will set all values in id
    const updatedData = allIds.map( ( key ) => ( {
      id: key,
      recommendationOverride: selectedIds.includes( key ),
    } ) );

    return dataProvider
      .update( resource, { id: filterValues.id, data: updatedData } )
      .then( () =>
      {
        notify( "Updated product overrides" );
        setLoading( false );
      } )
      .catch( () =>
      {
        notify( `Could not update product overrides`, "warning" );
        setLoading( false );
      } );
  };

  return (
    <Toolbar {...properties}>
      <SaveButton handleSubmitWithRedirect={handleClick} disabled={isLoading} />
    </Toolbar>
  );
};
export const ProductPagination = (): JSX.Element => (
  <Pagination rowsPerPageOptions={[50, 100, 250]} />
);

export const CustomProductList = (
  properties: ReferenceManyFieldProps & ListProps //& { [key: string]: any }
): JSX.Element =>
{
  const {
    basePath,
    filter,
    page = 1,
    perPage,
    record,
    reference,
    resource,
    sort,
    source,
    target,
  } = properties;

  const controllerProps = useReferenceManyFieldController( {
    basePath,
    filter,
    page,
    perPage,
    record,
    reference,
    resource,
    sort,
    source,
    target,
  } );
  controllerProps.onUnselectItems = () => true;

  const [allIds, setAllIds] = React.useState( [] );
  const [seenProducts, setSeenProducts] = React.useState( false );
  const [pageWithAppliedDefault, setPageWithAppliedDefault] = React.useState(
    []
  );
  const [currentEventId, setCurrentEventId] = React.useState( "" );

  if ( currentEventId !== controllerProps.filterValues.id )
  {
    setCurrentEventId( controllerProps.filterValues.id );
  }

  if ( !controllerProps.loading && controllerProps.loaded )
  {
    const { data, selectedIds, ids } = controllerProps;
    const originallySelectedKeys = ids.filter(
      ( key: string ) =>
        data[key].recommendationOverride === true &&
        data[key].eventId === controllerProps.filterValues.id
    );

    controllerProps.ids?.forEach( ( id: string ) =>
    {
      if ( !allIds.includes( id ) )
      {
        allIds.push( id );
        setSeenProducts( false );
      }
    } );

    if (
      !pageWithAppliedDefault.includes( controllerProps.page ) &&
      !seenProducts
    )
    {
      originallySelectedKeys.forEach( ( key: string ) =>
      {
        if ( !selectedIds.includes( key ) )
        {
          selectedIds.push( key );
        }
      } );
      pageWithAppliedDefault.push( controllerProps.page );
      setSeenProducts( true );
    }

    return (
      <ListContextProvider value={controllerProps}>
        <ListView
          {...properties}
          {...controllerProps}
          bulkActionButtons={<></>}
          allIds={allIds}
          exporter={false}
          pagination={<ProductPagination />}
        />
        <RecommendationOverrideSaveOnlyToolbar
          {...controllerProps}
          allIds={allIds}
        />
      </ListContextProvider>
    );
  }
  else if (
    controllerProps.loading &&
    currentEventId !== controllerProps.filterValues.id
  )
  {
    setAllIds( [] );
    setCurrentEventId( controllerProps.filterValues.id );
    setPageWithAppliedDefault( [] );
    setSeenProducts( false );
    [...controllerProps.selectedIds].forEach( () =>
    {
      controllerProps.selectedIds.pop();
    } );
  }
  return <></>;
};

export const RecommendationOverrideProductList = (
  properties: ReferenceManyFieldProps & ListProps & { id: string }
): JSX.Element =>
{
  const updatedProperties = {
    ...properties,
    resource: `recommendation-override-products`,
  };

  return (
    <CustomProductList
      {...updatedProperties}
      perPage={100}
      reference="recommendation-override-products"
      target="eventId"
      filter={{ id: properties.id }}
    >
      <Datagrid hasBulkActions>
        <TextField source="brandName" />
        <TextField source="productName" />
        <TextField source="productDescription" />
        <FunctionField
          label="Sale Price"
          render={( record: { salePrice: string; currency: string } ) =>
            formatPrice( record.salePrice, record.currency )
          }
        />
      </Datagrid>
    </CustomProductList>
  );
};
