import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export interface IConfirmation {
  open: boolean;
  disabled: boolean;
  onClose: () => void;
  onAgree: () => void;
  onReject: () => void;

  style?: React.CSSProperties;
  title: string;
  text: string;
  confirmText?: string;
  rejectText?: string;
}

export function Confirmation( {
  open,
  disabled = false,
  onClose,
  onAgree,
  onReject,
  title,
  text,
  confirmText = "Confirm",
  rejectText = "Cancel",
}: React.PropsWithChildren<IConfirmation> ): JSX.Element
{
  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onReject} color="primary">
            {rejectText}
          </Button>
          <Button
            onClick={onAgree}
            disabled={disabled}
            color="primary"
            autoFocus
          >
            {confirmText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
