import * as React from "react";
import { Tab, TabbedShowLayout } from "react-admin";
import { Card, CardContent, Box, Grid, Typography } from "@material-ui/core";
import { Order } from "src/ts/interfaces/order-interface";
import { PickupOrderTable } from "./pickup-order-table";
import { Spacer } from "../generics/spacer";

interface DetailProperties {
  record?: Order;
  [x: string]: unknown;
}

export const PickupDetail: React.FunctionComponent<DetailProperties> = (
  detailProperties: DetailProperties
) =>
{
  const { record } = detailProperties;
  return (
    <Card>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={8}>
            <Typography variant="h5" gutterBottom>
              {`Pickup Order ${record.id}`}
            </Typography>
            <Box
              border={2}
              bgcolor={"#f7f9fa"}
              css={{ borderColor: "#C5D1D9" }}
              padding={2}
            >
              <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography
                    variant={"h6"}
                  >{`Address: ${record.customer.billing.address.line1}`}</Typography>
                  <Typography
                    variant={"h6"}
                  >{`Postal/Zip: ${record.customer.billing.address.postalCode}`}</Typography>
                  <Typography
                    variant={"h6"}
                  >{`City: ${record.customer.billing.address.city}`}</Typography>
                  <Typography
                    variant={"h6"}
                  >{`Country/Region: ${record.customer.billing.address.country}`}</Typography>
                  <Typography
                    variant={"h6"}
                  >{`Shipping Method: ${record.logistics.option}`}</Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Typography variant="h5" gutterBottom>
              {`Customer Details`}
            </Typography>
            <Box
              border={2}
              bgcolor={"#f7f9fa"}
              css={{ borderColor: "#C5D1D9" }}
              padding={2}
              marginLeft={1}
            >
              <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography
                    variant={"h6"}
                  >{`First Name: ${record.logistics.details.firstname}`}</Typography>
                  <Typography
                    variant={"h6"}
                  >{`Last Name: ${record.logistics.details.lastname}`}</Typography>
                  <Typography
                    variant={"h6"}
                  >{`Phone: ${record.logistics.details.phone}`}</Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Spacer />
        <TabbedShowLayout>
          <Tab label="Orders">
            <PickupOrderTable order={record} />
            <Spacer />
          </Tab>
        </TabbedShowLayout>
      </CardContent>
    </Card>
  );
};
