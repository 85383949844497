import { pick } from "lodash";

export interface UserClaims {
  [key: string]: number | boolean | string;
}

export const extractAuthClaims = (
  decodedClaims: UserClaims
): Partial<UserClaims> => pick( decodedClaims, [...Object.values( BooleanRoles )] );

export const extractId = ( decodedClaims: UserClaims ): Partial<UserClaims> =>
  pick( decodedClaims, "user_id" );

export const hasPermissions = (
  allowedRoles: BooleanRoles[],
  userClaims: UserClaims
): boolean =>
{
  if ( !userClaims )
  {
    return false;
  }

  for ( const role of allowedRoles )
  {
    if ( userClaims[role] )
    {
      return true;
    }
  }
  return false;
};

export enum BooleanRoles {
  Seller = "seller",
  FulfillmentReadOnly = "fulfillmentReadOnly",
  Fulfillment = "fulfillment",
  CustomerService = "customerService",
  RefundCustomerService = "refundCustomerService",
  Admin = "admin",
  Partner = "partner",
}
