import * as React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import { Spacer } from "../generics/spacer";
import { useGetOne } from "ra-core";
import {
  ChatReportData,
  ChatReportStatus
} from "../../ts/interfaces/chat-interface";
import { ChatMessageList } from "./message-list";
import { useNotify, useUpdate } from "react-admin";
import { ButtonStyles } from "../generics/button-styles";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

interface DetailProperties {
  chatReport?: ChatReportData;
  [x: string]: unknown;
}

const useStyles = makeStyles( ( theme: Theme ) =>
  createStyles( {
    fullWidth: {
      width: "100%",
    },
    formField: {
      minWidth: "200px",
      margin: theme.spacing( 1, 2, 1, 0 ),
    },
    selectRow: {
      display: "flex",
      marginTop: "20px",
    },
    table: {
      border: 0,
    },
    button: {
      marginLeft: "5px",
      border: "1px solid black",
    },
    divider: {
      margin: theme.spacing( 4, 0 ),
    },
  } )
);

function DateField( props: { date: Date; label: string; className?: string } )
{
  return (
    <Typography variant={"h6"} {...props}>{`${props.label}${new Date(
      props.date
    ).toLocaleString( "en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    } )}`}</Typography>
  );
}

export const ChatReportsEdit: React.FunctionComponent<
  DetailProperties
> = ( detailProperties: { id: string } ) =>
{
  const { id: chatReportId } = detailProperties;

  const [chatReport, setChatReport] = React.useState<ChatReportData | null>(
    null
  );
  const notify = useNotify();
  const buttonClasses = ButtonStyles();
  const classes = useStyles();

  const { loading: reportDataLoading, refetch } = useGetOne(
    "chat-reports",
    chatReportId,
    {
      onSuccess: ( response: { data: ChatReportData } ) =>
      {
        setChatReport( response.data );
      },
      onFailure: () =>
      {
        console.log( "No Reported Chats found" );
      },
    }
  );

  const [rejectChatReport, { loading: rejectChatReportLoading }] = useUpdate(
    "chat-reports",
    chatReportId,
    {
      status: ChatReportStatus.rejected,
    },
    {},
    {
      onSuccess: () =>
      {
        notify( "Chat report Rejected!" );
        refetch();
      },
      onFailure: ( error: Error ) =>
      {
        notify(
          `An error occurred, please try again later ${error.message}`,
          "warning"
        );
      },
    }
  );

  const onApproveClick = () =>
  {
    notify( "This functionality is not implemented yet." );
  };

  return (
    !reportDataLoading &&
    chatReport && (
      <Card>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={6}>
              <Typography variant="h5" gutterBottom>
                {`Reported Chat ${chatReport.id}`}
              </Typography>
              <Box
                border={2}
                bgcolor={"#f7f9fa"}
                css={{ borderColor: "#C5D1D9" }}
                padding={2}
                paddingBottom={6}
              >
                <Grid container>
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography variant={"h6"}>{`Status: ${
                      chatReport.status.charAt( 0 ).toUpperCase() +
                      chatReport.status.slice( 1 ).toLowerCase()
                    }`}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography
                      variant={"h6"}
                    >{`Chat ID: ${chatReport.chatId}`}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography
                      variant={"h6"}
                    >{`Product Name: ${chatReport.product.productName}`}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography variant={"h6"}>{`Reported By: ${
                      chatReport.isSellerReporter ? "Seller" : "Customer"
                    }`}</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Typography variant="h5" gutterBottom>
                Reporter
              </Typography>
              <Box
                border={2}
                bgcolor={"#f7f9fa"}
                css={{ borderColor: "#C5D1D9" }}
                padding={2}
              >
                <Grid container>
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="h6">
                      {`Name: ${
                        chatReport.isSellerReporter
                          ? `${
                            !chatReport.seller.firstname &&
                              !chatReport.seller.lastname
                              ? "N/A"
                              : `${chatReport.seller.firstname} ${chatReport.seller.lastname}`
                          }`
                          : `${
                            !chatReport.customer.firstname &&
                              !chatReport.customer.lastname
                              ? "N/A"
                              : `${chatReport.customer.firstname} ${chatReport.customer.lastname}`
                          }`
                      }`}
                    </Typography>
                    <Typography variant="h6">
                      {`Email: ${
                        chatReport.isSellerReporter
                          ? chatReport.seller.email
                          : chatReport.customer.email || "N/A"
                      }`}
                    </Typography>
                    <Typography variant="h6">
                      {`Reporter ID: ${
                        chatReport.isSellerReporter
                          ? chatReport.seller.id
                          : chatReport.customer.id
                      }`}
                    </Typography>
                    <Typography variant="h6">
                      {`Handle: ${
                        chatReport.isSellerReporter
                          ? chatReport.seller.handle
                          : "N/A"
                      }`}
                    </Typography>
                    <DateField
                      label="Reported Date: "
                      date={chatReport.timestamps.created as unknown as Date}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <Spacer />
          <ChatMessageList
            chatId={chatReport.chatId}
            seller={{
              id: chatReport.seller.id,
              firstname: chatReport.seller.firstname,
              lastname: chatReport.seller.lastname,
            }}
            customer={{
              id: chatReport.customer.id,
              firstname: chatReport.customer.firstname,
              lastname: chatReport.customer.lastname,
            }}
          />
          <Spacer />
          <Grid item xs={6} sm={6} md={6} style={{ marginTop: "20px" }}>
            <Box textAlign="left">
              {( chatReport.status === ChatReportStatus.pending ||
                chatReport.status === ChatReportStatus.rejected ) && (
                <Button
                  className={[buttonClasses.saveButton, classes.button].join(
                    " "
                  )}
                  disabled={rejectChatReportLoading}
                  onClick={onApproveClick}
                >
                  Approve
                </Button>
              )}

              {( chatReport.status === ChatReportStatus.pending ||
                chatReport.status === ChatReportStatus.approved ) && (
                <Button
                  className={[buttonClasses.saveButton, classes.button].join(
                    " "
                  )}
                  disabled={rejectChatReportLoading}
                  onClick={rejectChatReport}
                >
                  Reject
                </Button>
              )}
            </Box>
          </Grid>

          {chatReport.status !== "pending" && chatReport.history && (
            <>
              <hr />
              <TableContainer>
                <Table style={{ marginBottom: "20px" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">
                        <Typography align="left" noWrap={true}>
                          Date
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography align="left">Author</Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography>Action</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {chatReport.history.map( ( history ) => (
                      <TableRow key={history.id}>
                        <TableCell width={250} align="left">
                          <Typography align="left" noWrap={true}>
                            {history.timestamp ? (
                              <>
                                {new Date( history.timestamp ).toLocaleString(
                                  "en-US",
                                  {
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                  }
                                )}
                                <br />
                                {new Date( history.timestamp ).toLocaleString(
                                  "en-US",
                                  {
                                    hour: "numeric",
                                    minute: "numeric",
                                    second: "numeric",
                                  }
                                )}
                              </>
                            ) : (
                              ""
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell width={250} align="left">
                          <Typography align="left" noWrap={true}>
                            {`${history.reviewer.firstname} ${history.reviewer.lastname}`}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            textTransform: "capitalize",
                          }}
                        >
                          <Typography align="left" noWrap={true}>
                            {history.status}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ) )}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
          <Divider />
        </CardContent>
      </Card>
    )
  );
};
