import * as React from "react";

import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";

// FIXME Setup proper types here
export const TrueFalseIcon = ( {
  value,
  ...rest
}: {
  value: boolean;
  fontSize?: "small" | "inherit" | "default" | "large";
} ): JSX.Element =>
  value === true ? (
    <DoneIcon fontSize="small" {...rest} />
  ) : (
    <ClearIcon fontSize="small" {...rest} />
  );
